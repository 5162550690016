<template>
  <div>
    <risk ref="risk" @update="loadData"/>
    <b-modal v-model="showFilter" title="Filters">
      <b-form-group label="Risk">
        <b-form-input v-model="tempFilter.risk"/>
      </b-form-group>
      <b-form-group label="Causes">
        <b-form-input v-model="tempFilter.causes"/>
      </b-form-group>
      <b-form-group label="Correction">
        <b-form-input v-model="tempFilter.correction"/>
      </b-form-group>
      <b-form-group label="Corrective action">
        <b-form-input v-model="tempFilter.correctiveaction"/>
      </b-form-group>
      <b-form-group label="Area">
        <b-form-select v-model="tempFilter.risksareas_id" :options="addNullCombo(filtersMenu.risksareas)" value-field="id" text-field="val"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="filterSet">Filter</b-btn>
          <b-btn variant="warning" @click="filterReset">Reset</b-btn>
        </div>
      </template>
    </b-modal>
    <b-btn class="mr-2" variant="primary" @click="loadData">Refresh</b-btn>
    <b-btn class="mr-2" variant="secondary" @click="filterOpen">Filter</b-btn>
    <b-btn class="mr-2" variant="success" @click="$refs.risk.loadData(0)">New</b-btn>
    <b-btn variant="info" @click="generateData()">Generate</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 210) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="items.data" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sortby" :sort-desc.sync="search.sortdesc" @sort-changed="sortingChange" @row-clicked="$refs.risk.loadData($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>There are no results</template>
    </b-table>
    <tablebottom :busy="isBusy" :currentpage="search.page" @updateCurrentPage="search.page = $event" :total="items.total" @loadData="loadData"/>
  </div>
</template>

<script>
import risk from './risk.vue'

export default {
  name: "risks",
  components: { risk },
  data() {
    return {
      isBusy: true,
      showFilter: false,
      search:{
        page: 1,
        filters: {
          risk: null,
          causes: null,
          correction: null,
          correctiveaction: null,
          risksareas_id: null,
        },
        sortby: 'risk',
        sortdesc: false,
      },
      tempFilter: {
        risk: null,
        causes: null,
        correction: null,
        correctiveaction: null,
        risksareas_id: null
      },
      filtersMenu: {
        risksareas:[],
      },
      fields: [{
        key: "rownum",
        label: "#",
        sortable: true
      },{
        key: "risk",
        label: "Risk",
        sortable: true
      },{
        key: "areas",
        label: "Areas",
      },{
        key: 'causes',
        label: 'Causes',
      },{
        key: "sites",
        label: "Sites",
      },{
        key: 'seriousness',
        label: 'Risk seriousness',
      },{
        key: 'probability',
        label: 'Risk probability',
      },{
        key: 'detectability',
        label: 'Risk detectability',
      },{
        key: 'date',
        label: 'Date',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [ d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'mitigation',
        label: 'Correction',
      },{
        key: 'prevenction',
        label: 'Corrective action',
      },{
        key: 'seriousnessanalysis',
        label: 'Analysis seriousness',
      },{
        key: 'probabilityanalysis',
        label: 'Analysis probability',
      },{
        key: 'detectabilityanalysis',
        label: 'Analysis detectability',
      },{
        key: 'riskevaluation',
        label: 'Evalutation',
      }],
      items: {
        total: 0,
        data: [],
      },
    }  
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.isBusy = true;
      this.openHttp("risks/", this.search).then((result) => {
        this.items = result;
        this.isBusy = false;
      })
    },
    filterOpen(){
      this.openHttp("risks/filters", null).then((result) => {
        this.filtersMenu = result;
        this.showFilter = true;
      })
    },
    filterSet(){
      this.showFilter = false;
      this.search.page = 1;
      this.search.filters = this.tempFilter;
      this.loadData();
    },
    filterReset(){
      this.tempFilter = {
        risk: null,
        causes: null,
        correction: null,
        correctiveaction: null,
        risksareas_id: null
      }
      this.filterSet();
    },
    sortingChange(ctx) {
      this.search.sortby = ctx.sortBy;
      this.search.sortdesc = ctx.sortDesc;
      this.loadData();
    },
    generateData(){
      this.openHttp("risks/generate", {}).then((result) => {
        if (result === true) this.$bvToast.toast("Document in prepatation, please, check in the documents", { title: "Eurocertifications", variant: "info", appendToast: true });
      })
    }
  }
}
</script>

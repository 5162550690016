<template>
    <div>
        <b-modal v-if="show" v-model="show" title="Standard" size="lg" @hidden="closeForm" @close="closeForm">
            <b-row>
                <b-col lg="4">
                    <b-form-group label="Standard">
                        <b-form-select v-model="data.standard.standards_id" :options="data.standards" value-field="id" text-field="val" @change="data.standard.points = []"/>
                    </b-form-group>
                </b-col>
                <b-col lg="4" v-show="data.standardsexclusions.findIndex(x => x.standards_id === data.standard.standards_id) > -1">
                    <b-form-group label="Exclusions">
                        <b-form-checkbox-group v-model="data.standard.standardsexclusions">
                            <b-form-checkbox v-for="(item, index) in data.standardsexclusions.filter(x => x.standards_id === data.standard.standards_id)" :key="index" :value="item.id">{{ item.val }}</b-form-checkbox>
                        </b-form-checkbox-group>
                    </b-form-group>
                </b-col>
                <b-col lg="2" v-show="data.standardscount > 1">
                    <b-form-group label="Audit team integration">
                        <b-form-select v-model="data.standard.integrationauditteam" :options="integrationCombo" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="2">
                    <b-form-group label="Risk level">
                        <b-form-select v-model="data.standard.riskslevels_id" :options="data.riskslevels.filter(x => x.standards.includes(data.standard.standards_id))" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
                <b-col lg="2">
                    <b-form-group label="Mandays table">
                        <b-form-input v-model="data.standard.mandaystiletable" type="number" min="0" step="0.0001"/>
                    </b-form-group>
                </b-col>
                <b-col lg="2">
                    <b-form-group label="Mandays reduce">
                        <b-form-input v-model="data.standard.mandaysreduced" type="number" min="0" step="0.0001"/>
                    </b-form-group>
                </b-col>
                <b-col lg="2">
                    <b-form-group label="1st issue">
                        <b-form-input v-model="data.standard.certificatefirstissue" type="date"/>
                    </b-form-group>
                </b-col>
                <b-col lg="2">
                    <b-form-group label="Initial issue">
                        <b-form-select v-model="data.standard.firstcertificatewithanothercb" :options="firstcertificatewithanothercbCombo" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-form-group label="Calculation note">
                <b-form-textarea v-model="data.standard.notecalc" rows="3"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Save</b-btn>
                    <b-btn variant="danger" v-show="data.standard.id > 0" @click="deleteData">Delete</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "contractstandard",
    data() {
        return {
            show: false,
            data: null,
            integrationCombo: [{
                id: 0,
                val: '0%'
            },{
                id: 20,
                val: '20%'
            },{
                id: 40,
                val: '40%'
            },{
                id: 60,
                val: '60%'
            },{
                id: 80,
                val: '80%'
            },{
                id: 100,
                val: '100%'
            }],
            firstcertificatewithanothercbCombo: [{
                id: false,
                val: "Same CB"
            },{
                id: true,
                val: "Other CB"
            }]
        }
    },
    methods: {
        loadData( contractsId, id ){
            this.openHttp("contracts/standard", { contracts_id: contractsId, id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("contracts/standard/save", this.data.standard).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("contracts/standard/delete", { id: this.data.standard.id }).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>
<template>
  <div>
    <checklist ref="checklist" @update="loadData()"/>
    <checklistmonitoring ref="monitoring" @update="loadData()"/>
    <b-row>
      <b-col class="mb-2">
        <b-modal v-model="showFilter" title="Filters">
          <template v-slot:modal-footer>
            <div class="w-100">
              <b-btn variant="primary" class="mr-2" @click="filterSet">Filter</b-btn>
              <b-btn variant="warning" @click="filterReset">Reset</b-btn>filters
            </div>
          </template>
        </b-modal>
        <b-btn class="mr-2" variant="primary" @click="loadData()">Refresh</b-btn>
        <b-btn variant="secondary" @click="filterOpen()">Filter</b-btn>
      </b-col>
    </b-row>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Plans" active>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive hover show-empty mode="remote" :items="checklists2.items.data" :fields="fields" :busy="checklists2.isBusy" @row-clicked="$refs.checklist.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>There are no results</template>
            <template v-slot:cell(phasesstandards)="row">
              <div v-for="(item, index) of row.value" :key="index">
                {{ item.phase }}: {{ item.standards }}
              </div>
            </template>
          </b-table>
          <tablebottom :busy="checklists2.isBusy" :currentpage="checklists2.search.page" :total="checklists2.items.total" @updateCurrentPage="checklists2.search.page = $event" @loadData="loadData2()"/>
        </b-tab>
        <b-tab title="Checklists">
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive hover show-empty mode="remote" :items="checklists3.items.data" :fields="fields" :busy="checklists3.isBusy" @row-clicked="$refs.checklist.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>There are no results</template>
            <template v-slot:cell(phasesstandards)="row">
              <div v-for="(item, index) of row.value" :key="index">
                {{ item.phase }}: {{ item.standards }}
              </div>
            </template>
          </b-table>
          <tablebottom :busy="checklists3.isBusy" :currentpage="checklists3.search.page" :total="checklists3.items.total" @updateCurrentPage="checklists3.search.page = $event" @loadData="loadData3()"/>
        </b-tab>
        <b-tab title="Veto">
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive hover show-empty mode="remote" class="mt-3" :items="checklists4.items.data" :fields="fields" :busy="checklists4.isBusy" @row-clicked="$refs.checklist.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>There are no results</template>
            <template v-slot:cell(phasesstandards)="row">
              <div v-for="(item, index) of row.value" :key="index">
                {{ item.phase }}: {{ item.standards }}
              </div>
            </template>
          </b-table>
          <tablebottom :busy="checklists4.isBusy" :currentpage="checklists4.search.page" :total="checklists4.items.total" @updateCurrentPage="checklists4.search.page = $event" @loadData="loadData4()"/>
        </b-tab>
        <b-tab title="Monitorings" :disabled="monitorings.items.total === 0">
          <b-btn variant="primary" @click="loadMonitorings()">Refresh</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive hover show-empty mode="remote" class="mt-3" :items="monitorings.items.data" :fields="monitorings.fields" :busy="monitorings.isBusy" @row-clicked="$refs.monitoring.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>There are no results</template>
            <template v-slot:cell(phasesstandards)="row">
              <div v-for="(item, index) of row.value" :key="index">
                {{ item.phase }}: {{ item.standards }}
              </div>
            </template>
          </b-table>
          <tablebottom :busy="monitorings.isBusy" :currentpage="monitorings.search.page" :total="monitorings.items.total" @updateCurrentPage="monitorings.search.page = $event" @loadData="loadMonitorings()"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import checklistmonitoring from './checklistcomponents/checklistmonitoring.vue';

export default {
  name: "checklists",
  components : { checklistmonitoring },
  data() {
    return {
      showFilter: false,
      filtersMenu: {
        companies:[],
        standards:[]
      },
      tempFilter: {
        companies_id: null,
        standards_id: null
      },
      fields: [{
        key: 'company',
        label: 'Company',
        sortable: true,
      },{
        key: 'phasesstandards',
        label: 'Standards and phases',
      },{
        key: 'plan_date',
        label: "Plan",
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'expiry_date',
        label: "Expiry",
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: "date",
        label: "Date",
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      }],
      checklists2: {
        isBusy: true,
        search: {
          page: 1,
          filters: {
            auditsstatus_id: 2,
            companies_id: null,
            standards_id: null
          },
          sortby: 'date',
          sortdesc: false,
        },
        items: {
          total: 0,
          data: []
        }
      },
      checklists3: {
        isBusy: true,
        search: {
          page: 1,
          filters: {
            auditsstatus_id: 3,
            companies_id: null,
            standards_id: null
          },
          sortby: 'date',
          sortdesc: false,
        },
        items: {
          total: 0,
          data: []
        }
      },
      checklists4: {
        isBusy: true,
        showFilter: false,
        search: {
          page: 1,
          filters: {
            auditsstatus_id: 4,
            companies_id: null,
            standards_id: null
          },
          sortby: 'date',
          sortdesc: false,
        },
        items: {
          total: 0,
          data: []
        }
      },

      monitorings: {
        isBusy: true,
        showFilter: false,
        search: {
          page: 1,
          filters: {
            status: 0,
          },
          sortby: 'date',
          sortdesc: false,
        },
        fields: [{
          key: "date",
          label: "Date",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'company',
          label: 'Company',
          sortable: true
        },{
          key: 'phasesstandards',
          label: 'Standards and phases'
        },{
          key: 'fullname',
          label: 'Monitored',
          sortable: true
        },{
          key: 'percentage',
          label: 'Percentage'
        },{
          key: 'passed',
          label: 'Status',
          sortable: true,
          formatter: value => {
            return value === null ? "Pending" : (value === true ? "Passed" : "Not passed");
          }
        }],
        items: {
          total: 0,
          data: []
        }
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    filterOpen(){
      this.openHttp("checklists/filters", null ).then((result) => {
        this.filtersMenu = result;
        this.showFilter = true;
      })
    },
    filterSet(){
      this.showFilter = false;
      this.checklists2.search.page = 1;
      this.checklists3.search.page = 1;
      this.checklists4.search.page = 1;
      this.checklists2.search.filters = JSON.parse(JSON.stringify(this.tempFilter));
      this.checklists3.search.filters = JSON.parse(JSON.stringify(this.tempFilter));
      this.checklists4.search.filters = JSON.parse(JSON.stringify(this.tempFilter));
      this.checklists2.search.filters.auditsstatus_id = 2;
      this.checklists3.search.filters.auditsstatus_id = 3;
      this.checklists4.search.filters.auditsstatus_id = 4;
      this.loadData();
    },
    filterReset(){
      this.tempFilter = {
        companies_id: null,
        standards_id: null
      }
      this.filterSet();
    },
    loadData(){
      this.loadData2();
      this.loadData3();
      this.loadData4();
      this.loadMonitorings();
    },
    
    loadData2(){
      this.checklists2.isBusy = true;
      this.openHttp("checklists", this.checklists2.search).then((result) => {
        this.checklists2.items = result;
        this.checklists2.isBusy = false;
      })
    },
    loadData3(){
      this.checklists3.isBusy = true;
      this.openHttp("checklists", this.checklists3.search).then((result) => {
        this.checklists3.items = result;
        this.checklists3.isBusy = false;
      })
    },
    loadData4(){
      this.checklists4.isBusy = true;
      this.openHttp("checklists", this.checklists4.search).then((result) => {
        this.checklists4.items = result;
        this.checklists4.isBusy = false;
      })
    },
    loadMonitorings(){
      this.monitorings.isBusy = true;
      this.openHttp("checklists/monitorings", this.monitorings.search).then((result) => {
        this.monitorings.items = result;
        this.monitorings.isBusy = false;
      })
    }
  }
}
</script>
<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" title="Qualification" scrollable @hidden="closeForm()" @close="closeForm()">
      <document ref="document" @update="loadData(data.qualification.id)"/>
      <signature ref="signature" @update="loadData(data.qualification.id)"/>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Qualification" active>
            <b-row>
              <b-col lg="2">
                <b-form-group label="Person">
                  <b-form-select v-model="data.qualification.users_id" :options="data.users" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Standard">
                  <b-form-select v-model="data.qualification.standards_id" :options="data.standards" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Role">
                  <b-form-select v-model="data.qualification.usersroles_id" :options="data.usersroles" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Date">
                  <b-form-input type="date" :value="data.qualification.fromdate"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Approved by">
                  <b-form-input :value="data.qualification.approve_fullname"/>
                </b-form-group>
              </b-col>
              <b-col lg="2" v-if="data.qualification.signatures_id > 0">
                <b-form-group label="Signature">
                  <b-btn :variant="data.qualification.signatures_check === true ? 'success' : 'danger'" @click="$refs.signature.loadData(data.qualification.signatures_id)">{{ data.qualification.signatures_check === true ? 'Signed' : 'To be signed' }}</b-btn>
                </b-form-group>
              </b-col>
            </b-row>
            <b-table v-if="data.usersroles.find(x => x.id === data.qualification.usersroles_id).neednace === true" responsive :fields="fields" :items="data.naces" :sticky-header="(winHeight - 445)+ 'px'">
              <template v-slot:cell(naces)="row">
                <b-btn v-for="(item, index) of row.value" :key="index" size="sm" class="mr-1 mb-1" :variant="data.qualification.naces.includes(item.id) ? 'success':'secondary'" @click="addRemoveNace(item.id)" :disabled="!item.accreditated">
                  {{ item.nace }}
                  <div>
                    <b-badge variant="light">score <b-progress :value="item.score" :variant="item.score >= 100 ? 'success' : (item.score >= 80 ? 'warning' : 'danger')"></b-progress></b-badge>
                  </div>
                </b-btn>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn v-if="data.qualification.id > 0" variant="warning" class="mr-2" @click="extendData()">Extend</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData()">Save</b-btn>
          <b-btn variant="danger" class="mr-2" @click="deleteData()">Delete</b-btn>
          <b-btn v-if="data.qualification.id > 0" variant="info" class="mr-2" @click="$refs.document.generateData(data.qualification.id)">Generate</b-btn>
          <b-btn v-if="data.qualification.documents_id > 0" variant="primary" @click="$refs.document.downloadData(data.qualification.documents_id)">Download</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import document from './document.vue';
import signature from './signature.vue';

export default {
  name: "qualification",
  components: { document, signature },
  data() {
    return {
      show: false,
      data: null,
      fields: [{
        key: 'iafcode',
        label: "IAF",
        sortable: true,
      },{
        key: 'naces',
        label: 'NACEs'
      }]
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    addRemoveNace(naceId){
      if (this.data.qualification.naces.includes(naceId)){
        this.data.qualification.naces.splice(this.data.qualification.naces.indexOf(naceId),1);
      } else {
        this.data.qualification.naces.push(naceId)
      }
    },
    extendData(){
      this.data.qualification.id = 0;
      this.data.qualification.fromdate = null;
    },
    loadData( id ){
      this.openHttp("qualifications/qualification", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("qualifications/qualification/save", this.data.qualification).then((result) => {
        if (result !== null) this.loadData(result);
      })
    },
    generateData(){
      this.saveHttp("qualifications/qualification/generate", {id: this.data.qualification.users_id});
    },
    deleteData(){
      this.deleteHttp("qualifications/qualification/delete", {id: this.data.qualification.id }).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    }
  },
}
</script>
<template>
  <div>
    <mail ref="mail" @update="loadDataMails()"/>
    <mailnew ref="mailnew" @update="loadDataMails()"/>
    <b-modal v-if="showFilter" v-model="showFilter" title="Filters">
      <b-form-group label="Mail">
        <b-form-input v-model="tempFilter.mail"/>
      </b-form-group>
      <b-form-group label="Subject">
        <b-form-input v-model="tempFilter.subject"/>
      </b-form-group>
      <b-row>
        <b-col>
          <b-form-group label="From date">
            <b-form-input type="date" v-model="tempFilter.from"/>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="To date">
            <b-form-input type="date" v-model="tempFilter.to"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Status">
        <b-form-select v-model="tempFilter.status" :options="statusCombo" value-field="id" text-field="val"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="filterSet">Filter</b-btn>
          <b-btn variant="warning" @click="filterReset">Reset</b-btn>
        </div>
      </template>
    </b-modal>
    <b-modal v-model="showFilterMails" title="Mails list" hide-footer>
      <b-list-group>
        <b-list-group-item class="d-flex justify-content-between align-items-center" v-for="(item, index) of mailboxes" :key="index" :active="item.id === search.filters.mailboxes_id" @click="changeMailbox(item.id)">
          {{ item.mail }}
          <b-badge variant="success" v-if="item.received > 0" pill>{{ item.received }}</b-badge>
          <b-badge variant="warning" v-if="item.spam > 0" pill>{{ item.spam }}</b-badge>  
        </b-list-group-item>
      </b-list-group>
    </b-modal>
    <b-btn class="mr-2" variant="secondary" @click="showFilterMails = true" v-if="mailboxes.length > 0" >{{ mailboxes.find(x => x.id === search.filters.mailboxes_id).mail }}</b-btn>
    <b-dropdown class="mr-2" variant="secondary">
      <template #button-content>
        {{ search.filters.folders_id === 1 ? 'Inbox': ( search.filters.folders_id === 2 ? 'Spam': search.filters.folders_id === 3 ? 'Sent': 'Trash' ) }}
        <b-badge variant="success" v-if="search.filters.folders_id === 1 && mailboxes.length > 0 && mailboxes.find(x => x.id === search.filters.mailboxes_id).received > 0" pill>{{ mailboxes.find(x => x.id === search.filters.mailboxes_id).received }}</b-badge>
        <b-badge variant="warning" v-if="search.filters.folders_id === 2 && mailboxes.length > 0 && mailboxes.find(x => x.id === search.filters.mailboxes_id).spam > 0" pill>{{ mailboxes.find(x => x.id === search.filters.mailboxes_id).spam }}</b-badge>
      </template>
      <b-dropdown-item @click="changeFolder(1)">Inbox <b-badge variant="success" v-if="mailboxes.length > 0 && mailboxes.find(x => x.id === search.filters.mailboxes_id).received > 0" pill>{{ mailboxes.find(x => x.id === search.filters.mailboxes_id).received }}</b-badge></b-dropdown-item>
      <b-dropdown-item @click="changeFolder(2)">Spam <b-badge variant="warning" v-if="mailboxes.length > 0 && mailboxes.find(x => x.id === search.filters.mailboxes_id).spam > 0" pill>{{ mailboxes.find(x => x.id === search.filters.mailboxes_id).spam }}</b-badge></b-dropdown-item>
      <b-dropdown-item @click="changeFolder(3)">Sent</b-dropdown-item>
      <b-dropdown-item @click="changeFolder(4)">Trash</b-dropdown-item>
    </b-dropdown>
    <b-btn class="mr-2" variant="primary" @click="loadData(); loadDataMails();">Refresh</b-btn>
    <b-btn class="mr-2" variant="secondary" @click="showFilter = true">Filter</b-btn>
    <b-btn variant="success" @click="newItem">New</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :tbody-tr-class="rowClass" :items="items.data" :fields="fields" :busy="isBusy" :no-local-sorting="true" @row-clicked="$refs.mail.loadData($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>There are no results</template>
      <template v-slot:cell(datetime)="row">
        <nobr>{{ row.value }}</nobr>
      </template>
    </b-table>
    <tablebottom :busy="isBusy" :currentpage="search.page" :total="items.total" @updateCurrentPage="search.page = $event" @loadData="loadData"/>
  </div>
</template>

<script>
export default {
  name: "mails",
  data() {
    return {
      mailboxes: [],
      isBusy: true,
      showFilterMails: false,
      showFilter: false,
      search: {
        page: 1,
        filters: {
          mailboxes_id: null,
          folders_id: null,
          mail: null,
          subject: null,
          from: null,
          to: null,
          status: null
        }
      },
      tempFilter: {
        mailboxes_id: null,
        folders_id: null,
        mail: null,
        subject: null,
        from: null,
        to: null,
        status: null
      },
      statusCombo: [{
        id: null,
        val: "Tutte",
      },{
        id: true,
        val: "Non lette"
      }],
      fields: [{
        key: 'email',
        label: 'Address',
      },{
        key: 'subject',
        label: 'Subject',
      },{
        key: 'datetime',
        label: 'Date',
        formatter: value => {
          if (value == null) return null;
          const today = new Date();
          var d = new Date(value);
          if ( d.getDate() == today.getDate() && d.getMonth() == today.getMonth() && d.getFullYear() == today.getFullYear()){
            return 'Today, ' + [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':');
          } else {
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },
      },{
        key: 'attachments',
        label: '',
        formatter: value => {
          return value === true ? 'All.' : null;
        },
      }],
      items: {
        total: 0,
        data: [],
      },
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.isBusy = true;
      this.showFilter = false;
      this.showFilterMails = false;
      this.mailboxes = [];
      this.openHttp("mails/mailboxes", null).then((result) => {
        this.mailboxes = result;
        if (this.search.filters.mailboxes_id === null ) this.search.filters.mailboxes_id = result[0].id;
        if (this.search.filters.folders_id === null ) this.search.filters.folders_id = 1;
        this.tempFilter.mailboxes_id = this.search.filters.mailboxes_id;
        this.tempFilter.folders_id = this.search.filters.folders_id;
        this.loadDataMails();
        this.isBusy = true;
      })
    },
    changeMailbox(id){
      this.tempFilter = {
        mailboxes_id: id,
        folders_id: 1,
        mail: null,
        subject: null,
        from: null,
        to: null,
        status: null
      };
      this.search.filters = this.tempFilter;
      this.loadDataMails();
    },
    changeFolder(folder){
      this.tempFilter = {
        mailboxes_id: this.search.filters.mailboxes_id,
        folders_id: folder,
        mail: null,
        subject: null,
        from: null,
        to: null,
        status: null,
      };
      this.search.filters = this.tempFilter;
      this.loadDataMails();
    },
    loadDataMails(){
      this.showFilterMails = false;      
      this.showFilter = false;
      this.openHttp("mails/", this.search).then((result) => {
        this.items = result;
        this.isBusy = false;
      })
    },
    rowClass( item ){
      if ( item !== null ) return item.seen === false ? "table-primary" : ( item.ping === true ? "table-success" : "" );
    },
    filterSet(){
      this.search.filters = this.tempFilter;
      this.loadDataMails();
    },
    filterReset(){
      this.tempFilter = {
        mailboxes_id: this.tempFilter.mailboxes_id,
        folders_id: this.tempFilter.folders_id,
        mail: null,
        subject: null,
        from: null,
        to: null,
        status: null
      };
      this.search.filters = this.tempFilter;
      this.loadDataMails();
    },
    newItem(){
      this.$refs.mailnew.loadData( {
        id: 0,
        mailboxes_id: this.search.filters.mailboxes_id,
        to: null,
        subject: null,
        subjectOriginal: null,
        body: '',
        bodyOriginal: '',
      });
    },
  }
}
</script>
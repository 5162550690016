<template>
  <div>
    <b-modal v-if="data != null" v-model="show" size="lg" title="Non Conformity" @hidden="closeForm()" @close="closeForm()">
      <nonconformitydocument ref="document" @update="loadData(data.nonconformity.id)"/>
      <documentdownload ref="documentdownload"/>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Open" active>
            <b-row>
              <b-col lg="2">
                <b-form-group label="#">
                  <b-form-input v-model="data.rownum" disabled/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Open date">
                  <b-form-input type="date" v-model="data.nonconformity.opendate"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Limit close date">
                  <b-form-input type="date" v-model="data.nonconformity.limitdate"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Status">
                  <b-form-select v-model="data.nonconformity.nonconformitiesstatus_id" :options="data.status" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Found by">
              <b-form-textarea v-model="data.nonconformity.foundby" rows="2"/>
            </b-form-group>
            <b-form-group label="Nonconformity">
              <b-form-textarea v-model="data.nonconformity.nonconformity" rows="6"/>
            </b-form-group>
            <b-form-group label="Causes">
              <b-form-textarea v-model="data.nonconformity.causes" rows="6"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Treatment" :disabled="data.nonconformity.id === 0">
            <b-form-group label="Correction">
              <b-form-textarea v-model="data.nonconformity.treatment" rows="6"/>
            </b-form-group>
            <b-form-group label="Corrective action">
              <b-form-textarea v-model="data.nonconformity.correctiveaction" rows="6"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Effectiveness" :disabled="data.nonconformity.id === 0">
            <b-row>
              <b-col lg="4">
                <b-form-group label="Date">
                  <b-form-input type="date" v-model="data.nonconformity.effectivenessdate"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Effectiveness">
              <b-form-select v-model="data.nonconformity.effectiveness" :options="effectivenessCombo" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Note">
              <b-form-textarea v-model="data.nonconformity.effectivenessnote" rows="4"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Documents" :disabled="data.nonconformity.id === 0">
            <b-btn variant="primary" size="sm" @click="prepareDocuments()">Prepare</b-btn>
            <b-table class="mt-3" :sticky-header="(winHeight - 205) + 'px'" :tbody-tr-class="rowClassDocuments" responsive :items="data.documents" :fields="fields.documents" @row-clicked="$refs.document.loadData(data.nonconformity.id, $event.id)">
              <template v-slot:cell(id)="row">
                <b-btn variant="primary" size="sm" v-show="row.item.datetime !== null" @click="$refs.documentdownload.downloadData('nonconformities', row.value)">Download</b-btn>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="loadData(data.nonconformity.id)">Refresh</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData()">Save</b-btn>
          <b-btn variant="danger" v-show="data.nonconformity.id > 0" @click="deleteData()">Delete</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import nonconformitydocument from './nonconformitiescomponents/nonconformitydocument.vue';

export default {
  name: "nonconformity",
  components : { nonconformitydocument },
  data() {
    return {
      show: false,
      data: null,
      effectivenessCombo: [{
        id: null,
        val: "Pending"
      },{
        id: true,
        val: "Positive"
      },{
        id: false,
        val: "Not positive"
      }],
      fields: {
        documents: [{
          key: "filename",
          label: "File",
          sortable: true,
        },{
          key: "datetime",
          label: "Updated",
          formatter: value => {
            let d = new Date(value);
            return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':') + " " + [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
          sortable: true,
        },{
          key: "locked",
          label: "Locked",
        },{
          key: "id",
          label: "",
        }]
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    rowClassDocuments(item) {
      return item !== null && item.datetime === null ? 'table-danger' : '';
    },
    loadData(id, internalaudits_id = null){
      this.openHttp("nonconformities/nonconformity", { id: id, internalaudits_id: internalaudits_id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("nonconformities/nonconformity/save", this.data.nonconformity).then((result) => {
        if (result !== null ) this.closeForm(true);
      })
    },
    deleteData(){
      this.deleteHttp("nonconformities/nonconformity/delete", { id: this.data.nonconformity.id }).then((result) => {
        if (result !== null ) this.closeForm(true);
      })
    },
    prepareDocuments(){
      this.openHttp("nonconformities_documents_generate", { id: this.data.nonconformity.id }).then((result) => {
        if (result !== null ) this.loadData( this.data.nonconformity.id );
      })
    },
    closeForm(reload = false){
      this.show = false;
      this.data = null;
      if (reload === true) this.$emit('update');
    },
  }
}
</script>

<template>
  <div>
    <internalauditprogram ref="program" @update="loadData()"/>
    <internalauditaudit ref="audit" @update="loadData()"/>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Confirmed" active>
          <b-btn class="mr-2" variant="primary" @click="loadData()">Refresh</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="approved.items.data" :fields="approved.fields" :busy="approved.isBusy" :no-local-sorting="true" :sort-by.sync="approved.search.sortby" :sort-desc.sync="approved.search.sortdesc" @sort-changed="sortingChangeApproved" @row-clicked="$refs.audit.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>There are no results</template>
          </b-table>
          <tablebottom :currentpage="approved.search.page" @updateCurrentPage="approved.search.page = $event" :total="approved.items.total" @loadData="loadDataApproved()"/>
        </b-tab>
        <b-tab title="Planned">
          <b-btn class="mr-2" variant="primary" @click="loadData()">Refresh</b-btn>
          <b-btn class="mr-2" variant="success" @click="$refs.program.loadData(0)">New</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="planned.items.data" :fields="planned.fields" :busy="planned.isBusy" :no-local-sorting="true" :sort-by.sync="planned.search.sortby" :sort-desc.sync="planned.search.sortdesc" @sort-changed="sortingChangePlanned" @row-clicked="$refs.program.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>There are no results</template>
          </b-table>
          <tablebottom :currentpage="planned.search.page" @updateCurrentPage="planned.search.page = $event" :total="planned.items.total" @loadData="loadDataPlanned()"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import internalauditprogram from './internalauditprogram.vue'
import internalauditaudit from './internalauditaudit.vue'

export default {
  name: "internalaudits",
  components: { internalauditprogram, internalauditaudit },
  data() {
    return {
      approved: {
        isBusy: true,
        search:{
          page: 1,
          filters: {
            approved: true
          },
          sortby: 'fromdate',
          sortdesc: false,
        },
        fields: [{
          key: 'fromdate',
          label: 'From',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'todate',
          label: 'To',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'users',
          label: 'Audit Team',
        },{
          key: 'standards',
          label: 'Standards',
        },{
          key: 'percentage',
          label: 'Percentage',
        },{
          key: 'opportunities',
          label: 'Opportunities',
        },{
          key: 'closedate',
          label: 'Closed',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'plan',
          label: 'Plan',
        },{
          key: 'report',
          label: 'Report',
        }],
        items: {
          total: 0,
          data: [],
        }
      },
      planned: {
        isBusy: true,
        search:{
          page: 1,
          filters: {
            approved: false
          },
          sortby: 'fromdate',
          sortdesc: false,
        },
        fields: [{
          key: 'fromdate',
          label: 'From',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'todate',
          label: 'To',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'users',
          label: 'Audit Team',
        },{
          key: 'standards',
          label: 'Standards',
        },{
          key: 'percentage',
          label: 'Percentage',
        }],
        items: {
          total: 0,
          data: [],
        }
      }
    }  
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.loadDataApproved();
      this.loadDataPlanned();
    },
    loadDataApproved(){
      this.approved.isBusy = true;
      this.openHttp("internalaudits", this.approved.search).then((result) => {
        this.approved.items = result;
        this.approved.isBusy = false;
      })
    },
    sortingChangeApproved(ctx) {
      this.approved.search.sortby = ctx.sortBy;
      this.approved.search.sortdesc = ctx.sortDesc;
      this.loadDataApproved();
    },
    loadDataPlanned(){
      this.planned.isBusy = true;
      this.openHttp("internalaudits", this.planned.search).then((result) => {
        this.planned.items = result;
        this.planned.isBusy = false;
      })
    },
    sortingChangePlanned(ctx) {
      this.planned.search.sortby = ctx.sortBy;
      this.planned.search.sortdesc = ctx.sortDesc;
      this.loadDataPlanned();
    }
  }
}
</script>
import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import router from './router'
import store from './store'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import Multiselect from 'vue-multiselect'
Vue.component('multiselect', Multiselect);

import VueSignature from 'vue-signature-pad';
Vue.use( VueSignature );

import VueQrcode from '@chenfengyuan/vue-qrcode';
Vue.component(VueQrcode.name, VueQrcode);

import company from './components/companies/company.vue';
import application from './components/applications/application.vue';
import audit from './components/audits/audit.vue';
import checklist from './components/checklists/checklist.vue';
import contract from './components/contracts/contract.vue';
import mail from './components/mails/mail.vue';
import mailnew from './components/mails/mailnew.vue';
import training from './components/trainings/training.vue';
import internalauditaudit from './components/internalaudits/internalauditaudit.vue';
import managementreview from './components/managementreviews/managementreview.vue';
import tablebottom from './components/tablebottom.vue';

import documentdownload from './components/documentdownload.vue';

Vue.component("company", company);
Vue.component("application", application);
Vue.component("audit", audit);
Vue.component("checklist", checklist);
Vue.component("contract", contract);
Vue.component("mail", mail);
Vue.component("mailnew", mailnew);
Vue.component("training", training);
Vue.component("internalauditaudit", internalauditaudit);
Vue.component("managementreview", managementreview);
Vue.component('tablebottom', tablebottom);
Vue.component('documentdownload', documentdownload);

Vue.config.productionTip = false

new Vue({ router, store, render: h => h(App), }).$mount('#app')
<template>
  <div>
    <company ref="company" @update="loadData"/>
    <b-modal v-if="showFilter" v-model="showFilter" title="Filters">
      <b-form-group label="Company">
        <b-form-input v-model="tempFilter.company"/>
      </b-form-group>
      <b-form-group label="VAT">
        <b-form-input v-model="tempFilter.vat"/>
      </b-form-group>
      <b-form-group label="Worker">
        <b-form-input v-model="tempFilter.lastname"/>
      </b-form-group>
      <b-form-group label="Contact (phone, mail, web)">
        <b-form-input v-model="tempFilter.contact"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="filterSet">Filter</b-btn>
          <b-btn variant="warning" @click="filterReset">Reset</b-btn>
        </div>
      </template>
    </b-modal>
    <b-btn class="mr-2" variant="primary" @click="loadData">Refresh</b-btn>
    <b-btn class="mr-2" variant="secondary" @click="showFilter = true">Filter</b-btn>
    <b-btn variant="success" class="mr-2" @click="$refs.company.loadData(0)">New</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="items.data" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sortby" :sort-desc.sync="search.sortdesc" @sort-changed="sortingChange" @row-clicked="$refs.company.loadData($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>There are no results</template>
    </b-table>
    <tablebottom :busy="isBusy" :currentpage="search.page" :total="items.total" @updateCurrentPage="search.page = $event" @loadData="loadData()"/>
  </div>
</template>

<script>
export default {
  name: "companies",
  data() {
    return {
      isBusy: true,
      showFilter: false,
      search: {
        page: 1,
        filters: {
          company: null,
          vat: null,
          contact: null,
          lastname: null,
        },
        sortby: null,
        sortdesc: false,
      },
      tempFilter: {
        company: null,
        vat: null,
        contact: null,
        lastname: null,
      },
      fields: [{
        key: 'company',
        label: 'Company',
        sortable: true,
      },{
        key: 'vat',
        label: 'VAT',
      },{
        key: 'city',
        label: 'City',
      }],
      items: {
        total: 0,
        data: [],
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.isBusy = true;
      this.openHttp("companies/companies", this.search).then((result) => {
        this.isBusy = false;
        this.items = result;
      })
    },
    filterSet(){
      this.search.page = 1;
      this.showFilter = false;
      this.search.filters = this.tempFilter;
      this.loadData();
    },
    filterReset(){
      this.tempFilter = {
        company: null,
        vat: null,
        contact: null,
        lastname: null,
      };
      this.filterSet();
    },
    sortingChange(ctx) {
      this.search.sortby = ctx.sortBy;
      this.search.sortdesc = ctx.sortDesc;
      this.loadData();
    }
  }
}
</script>
<template>
  <div>
    <b-modal v-if="data != null" v-model="show" title="Record" scrollable @hidden="closeForm()" @close="closeForm()">
      <document ref="document" @update="loadData(data.record.id, data.record.documentssystem_id)"/>
      <b-form-group label="Date">
        <b-form-input type="date" v-model="data.record.date"/>
      </b-form-group>
      <b-form-group label="Note">
        <b-form-textarea v-model="data.record.note" rows="3"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" v-show="data.document.id > 0" class="mr-2" @click="loadData(data.document.id)">Refresh</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData">Save</b-btn>
          <b-btn variant="danger" class="mr-2" v-show="data.record.id > 0" @click="deleteData">Delete</b-btn>
          <b-btn variant="info" class="mr-2" v-if="data.record.id > 0" @click="$refs.document.loadData(data.document.id)">Upload</b-btn>
          <b-btn variant="primary" v-if="data.record.id > 0" v-show="data.document.check === true" @click="$refs.document.downloadData(data.document.id)">Download</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import document from './document.vue';

export default {
  name: "documentrecord",
  components: { document },
  data() {
    return {
      show: false,
      data: null,
    }
  },
  methods: {
    loadData(id, documentsId){
      this.openHttp("documentssystem/documentrecord", { id: id, documentssystem_id: documentsId }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    fileChange(event){
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = ()=> {
        this.data.record.filename = event.target.files[0].name;
        this.data.record.filedata = reader.result;
      };
    },
    saveData(){
      this.saveHttp("documentssystem/documentrecord/save", this.data.record).then((result) => {
        if (result !== null) this.closeForm(true);
      })
    },
    deleteData(){
      this.deleteHttp("documentssystem/documentrecord/delete", { id: this.data.record.id }).then((result) => {
        if (result !== null) this.closeForm(true);
      })
    },
    closeForm(reload = false){
      this.show = false;
      this.data = null;
      if (reload === true) this.$emit('update');
    }
  }
}
</script>

<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" :title="translateCheck('nonconformity')" @hidden="closeForm()" @close="closeForm()">
      <b-form-group :label="translateCheck('nonconformity')">
        <b-input-group :prepend="item.lang" v-for="(item, index) of data.nonconformity.languages" :key="index" rows="2" class="mb-1">
          <b-form-textarea :value="item.nonconformity" rows="2"/>
        </b-input-group>
      </b-form-group>
      <b-row>
        <b-col lg="3">
          <b-form-group :label="translateCheck('nctype')">
            <b-form-input :value="data.nonconformity.nctype"/>
          </b-form-group>
        </b-col>
        <b-col lg="9">
          <b-form-group :label="translateCheck('points')">
            <b-form-input :value="data.nonconformity.standardspoints"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="4">
          <b-form-group :label="translateCheck('decision taken by')">
            <b-form-select v-model="data.nonconformity.approve_workers_id" :options="data.workers" value-field="id" text-field="val" :disabled="data.nonconformity.effectivenessdecision === true"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group :label="translateCheck('decision by client')">
            <b-form-select v-model="data.nonconformity.acceptdecision" value-field="id" text-field="val"  :disabled="data.nonconformity.effectivenessdecision === true">
              <b-form-select-option :value="true">{{ translateCheck('accepted') }}</b-form-select-option>
              <b-form-select-option :value="false">{{ translateCheck('not accepted') }}</b-form-select-option>
              <b-form-select-option :value="null">{{ translateCheck('pending') }}</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-show="data.nonconformity.acceptdecision !== null">
        <b-col lg="4">
          <b-form-group :label="translateCheck('causes')">
            <b-input-group :prepend="item.lang" v-for="(item, index) of data.nonconformity.languages" :key="index" rows="2" class="mb-1">
              <b-form-textarea v-model="item.cause" rows="4" :disabled="data.nonconformity.effectivenessdecision === true"/>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group :label="translateCheck('treatment')">
            <b-input-group :prepend="item.lang" v-for="(item, index) of data.nonconformity.languages" :key="index" rows="2" class="mb-1">
              <b-form-textarea v-model="item.treatment" rows="4" :disabled="data.nonconformity.effectivenessdecision === true"/>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group :label="translateCheck('correctiveaction')">
            <b-input-group :prepend="item.lang" v-for="(item, index) of data.nonconformity.languages" :key="index" rows="2" class="mb-1">
              <b-form-textarea v-model="item.correctiveaction" rows="4" :disabled="data.nonconformity.effectivenessdecision === true"/>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="3">
          <b-form-group :label="translateCheck('effectiveness')">
            <b-form-input :value="data.nonconformity.effectivenessdecision === true ? translateCheck('accepted') : ( data.nonconformity.effectivenessdecision === false ? translateCheck('not accepted') : translateCheck('pending') )"/>
          </b-form-group>
        </b-col>
      </b-row>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData()">{{ translateCheck('save') }}</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "checklistnonconformity",
  data() {
    return {
      show: false,
      data: null,
      lang: "en",
      token: null,
      languages: [{
        lang: "en",
        langFull: "English",
        dictionary: [
          { word: "nonconformity", translate: "Non Conformity"},
          { word: "nctype", translate: "Non Conformity type"},
          { word: "points", translate: "Points"},
          { word: "decision taken by", translate: "Decision taken by"},
          { word: "decision by client", translate: "Decision by client"},
          { word: "accepted", translate: "Accepted"},
          { word: "not accepted", translate: "Not accepted"},
          { word: "pending", translate: "Pending"},
          { word: "causes", translate: "Causes"},
          { word: "treatment", translate: "Treatment"},
          { word: "correctiveaction", translate: "Corrective Action"},
          { word: "effectiveness", translate: "Effectiveness"},
          { word: "positive", translate: "Positive"},
          { word: "negative", translate: "Negative"},
          { word: "approved", translate: "Approved"},
          { word: "to be approved", translate: "To be approved"},
          { word: "save", translate: "Save"}
        ]
      },{
        lang: "it",
        langFull: "Italiano",
        dictionary: [
          { word: "nonconformity", translate: "Non Conformità"},
          { word: "nctype", translate: "Tipo Non Conformità"},
          { word: "points", translate: "Punti"},
          { word: "decision taken by", translate: "Decisione presa da"},
          { word: "decision by client", translate: "Decisione del cliente"},
          { word: "accepted", translate: "Accettata"},
          { word: "not accepted", translate: "Non accettata"},
          { word: "pending", translate: "Da decidere"},
          { word: "causes", translate: "Cause"},
          { word: "treatment", translate: "Trattamento"},
          { word: "correctiveaction", translate: "Azione correttiva"},
          { word: "effectiveness", translate: "Efficacia"},
          { word: "positive", translate: "Positivo"},
          { word: "negative", translate: "Negativo"},
          { word: "approved", translate: "Approvato"},
          { word: "to be approved", translate: "Da approvare"},
          { word: "save", translate: "Salva"}
        ]
      }]
    }
  },
  methods: {
    translateCheck(word){
      if (this.languages.find(x => x.lang === this.lang).dictionary.findIndex(x => x.word === word) === -1) return '??? ' + word;
      return this.languages.find(x => x.lang === this.lang).dictionary.find(x => x.word === word).translate;
    },
    loadData(id, token, lang){
      this.show = true;
      this.lang = lang;
      this.token = token;
      this.openHttp("externalaudit/nonconformity", { id: id, token: token }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("externalaudit/nonconformity/save", { token: this.token, nonconformity: this.data.nonconformity }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>
<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" title="Curriculum" scrollable @hidden="closeForm()" @close="closeForm()">
      <languege ref="language"  @update="loadData(data.curriculum.id)"/>
      <certificate ref="certificate" @update="loadData(data.curriculum.id)"/>
      <education ref="education" @update="loadData(data.curriculum.id)"/>
      <experience ref="experience" @update="loadData(data.curriculum.id)"/>
      <consultancy ref="consultancy" @update="loadData(data.curriculum.id)"/>
      <auditlog ref="auditlog" @update="loadData(data.curriculum.id)"/>
      <document ref="document" @update="loadData(data.curriculum.id)"/>
      <signature ref="signature" @update="loadData(data.curriculum.id)"/>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Form" active>
            <b-row>
              <b-col lg="3">
                <b-form-group label="Firstname">
                  <b-form-input v-model="data.curriculum.firstname"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Lastname">
                  <b-form-input v-model="data.curriculum.lastname"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Tax code">
                  <b-form-input v-model="data.curriculum.taxcode"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Personal ID">
                  <b-form-input v-model="data.curriculum.personalid"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3">
                <b-form-group label="Birth place">
                  <b-form-input v-model="data.curriculum.birthplace"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Birthdate">
                  <b-form-input v-model="data.curriculum.birthdate" type="date"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="5">
                <b-form-group label="Address">
                  <b-form-input v-model="data.curriculum.address"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Phone">
                  <b-form-input v-model="data.curriculum.phone"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Mail">
                  <b-form-input v-model="data.curriculum.mail"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Main language">
                  <b-form-select v-model="data.curriculum.languages_id" :options="data.languages" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3">
                <b-form-group label="Date update">
                  <b-form-input v-model="data.curriculum.updatedate" type="date"/>
                </b-form-group>
              </b-col>
            </b-row>            
          </b-tab>
          <b-tab title="Impartiality">
            <b-row>
              <b-col lg="2">
                <b-form-group label="Begin date">
                  <b-form-input v-model="data.curriculum.begindate" type="date"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Signature">
                  <b-btn :variant="data.curriculum.signatures1_check === true ? 'success' : 'danger'" size="sm" @click="$refs.signature.loadData(data.curriculum.signatures1_id)">Signature</b-btn>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Contract">
            <b-row>
              <b-col lg="6">
                <b-form-group label="Manager">
                  <b-form-select v-model="data.curriculum.users_id2" :options="data.users" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Signature">
                  <b-btn :variant="data.curriculum.signatures2_check === true ? 'success' : 'danger'" size="sm" @click="$refs.signature.loadData(data.curriculum.signatures2_id)">Signature</b-btn>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="2">
                <b-form-group label="End date">
                  <b-form-input v-model="data.curriculum.enddate" type="date"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Languages">
            <b-btn variant="primary" size="sm" @click="$refs.language.loadData(data.curriculum.id, 0)">New</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 400) + 'px'" responsive class="mt-2" :items="data.languageslevels" :fields="fields.languages" @row-clicked="$refs.language.loadData(data.curriculum.id, $event.id)"/>
          </b-tab>
          <b-tab :title="'Certificates ' + data.certificates.length">
            <b-btn variant="primary" size="sm" @click="$refs.certificate.loadData(data.curriculum.id, 0)">New</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 400) + 'px'" responsive class="mt-3" :items="data.certificates" :fields="fields.certificates" @row-clicked="$refs.certificate.loadData(data.curriculum.id, $event.id)"/>
          </b-tab>
          <b-tab :title="'Educations ' + data.education.length">
            <b-btn variant="primary" size="sm" @click="$refs.education.loadData(data.curriculum.id, 0)">New</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 400) + 'px'" responsive class="mt-3" :items="data.education" :fields="fields.education" @row-clicked="$refs.education.loadData(data.curriculum.id, $event.id)"/>
          </b-tab>
          <b-tab :title="'Esperiences ' + data.experiences.length">
            <b-btn variant="primary" size="sm"  @click="$refs.experience.loadData(data.curriculum.id, 0)">New</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 400) + 'px'" responsive class="mt-3" :items="data.experiences" :fields="fields.experiences" @row-clicked="$refs.experience.loadData(data.curriculum.id, $event.id)"/>
          </b-tab>
          <b-tab :title="'Consultancies ' + data.consultancies.length">
            <b-btn variant="primary" size="sm"  @click="$refs.consultancy.loadData(data.curriculum.id, 0)">New</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 400) + 'px'" responsive class="mt-3" :items="data.consultancies" :fields="fields.consultancies" @row-clicked="$refs.consultancy.loadData(data.curriculum.id, $event.id)"/>
          </b-tab>
          <b-tab :title="'Audit logs ' + data.auditlogs.length">
            <b-btn variant="primary" size="sm" class="mr-2" @click="$refs.auditlog.loadData(data.curriculum.id, 0)">New</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 400) + 'px'" responsive class="mt-3" :items="data.auditlogs" :fields="fields.auditlogs" @row-clicked="$refs.auditlog.loadData(data.curriculum.id, $event.id)"/>
          </b-tab>
          <b-tab :title="'Trainings ' + data.trainings.length">
            <b-table no-border-collapse :sticky-header="(winHeight - 380) + 'px'" responsive :items="data.trainings" :fields="fields.trainings"/>
          </b-tab>
          <b-tab :title="'Documents ' + data.documents.filter(x=> x.jsonkey !== null && x.jsonkey.certificationbodies_id == 133).length">
            <b-table no-border-collapse :sticky-header="(winHeight - 380) + 'px'" responsive :items="data.documents.filter(x=> x.jsonkey !== null && x.jsonkey.certificationbodies_id == 133)" :fields="fields.documents" @row-clicked="$refs.document.loadData('users', data.curriculum.id, $event.id)">
              <template v-slot:cell(id)="row">
                <b-btn variant="primary" size="sm" @click="$refs.document.downloadFile(row.value)">Download</b-btn>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn class="mr-2" variant="primary" @click="loadData(data.curriculum.id)">Refresh</b-btn>
          <b-btn class="mr-2" variant="success" @click="saveData">Save</b-btn>
          <b-btn variant="info" @click="$refs.document.generateData(data.curriculum.id)">Generate</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import languege from './languege.vue';
import certificate from './certificate.vue';
import experience from './experience.vue';
import education from './education.vue';
import consultancy from './consultancy.vue';
import auditlog from './auditlog.vue';
import document from './document.vue';
import signature from './signature.vue';

export default {
  name: "curriculum",
  components: { languege, certificate, experience, education, consultancy, auditlog, document, signature },
  data() {
    return {
      show: false,
      data: null,
      languagesSupport: [],
      fields: {
        languages: [{
          key: 'val',
          label: 'Language',
        },{
          key: 'speak',
          label: 'Speak',
        },{
          key: 'read',
          label: 'Read',
        },{
          key: 'write',
          label: 'Write',
        }],
        certificates: [{
          key: 'fromdate',
          label: 'From',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }        
        },{
          key: 'todate',
          label: 'To',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'degree',
          label: 'Degree',
          formatter: value => {
            return (value.findIndex(x => x.lang === "EN") > -1) ? value.find(x => x.lang === "EN").val : "???";
          }
        },{
          key: 'issuer',
          label: 'Issued by',
        },{
          key: "code",
          label: "Code",
        },{
          key: 'standards',
          label: 'Standards',
        },{
          key: 'duration',
          label: 'Duration',
          formatter: value => {
            return value + "h";
          }
        },{
          key: 'iafs',
          label: 'IAFs',
        },{
          key: 'documentscount',
          label: 'Documents',
          sortable: true,
        }],
        education: [{
          key: 'fromdate',
          label: 'From',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }        
        },{
          key: 'todate',
          label: 'To',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'school',
          label: 'School',
          sortable: true,
        },{
          key: 'discipline',
          label: 'Discipline',
          formatter: value => {
            return (value.findIndex(x => x.lang === "EN") > -1) ? value.find(x => x.lang === "EN").val : "???";
          }
        },{
          key: 'title',
          label: 'Title',
          formatter: value => {
            return (value.findIndex(x => x.lang === "EN") > -1) ? value.find(x => x.lang === "EN").val : "???";
          }
        },{
          key: 'standardsareas',
          label: 'Areas',
        },{
          key: 'iafs',
          label: 'IAFs',
        },{
          key: 'documentscount',
          label: 'Documents',
          sortable: true,
        }],
        experiences: [{
          key: 'fromdate',
          label: 'From',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }        
        },{
          key: 'todate',
          label: 'To',
          sortable: true,
          formatter: value => {
            if (value == null) return 'On going';
            var d = new Date(value);
            return [d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'company',
          label: 'Company',
          sortable: true,
        },{
          key: 'sector',
          label: 'Sector',
          formatter: value => {
            return (value.findIndex(x => x.lang === "EN") > -1) ? value.find(x => x.lang === "EN").val : "???";
          }
        },{
          key: 'responsibilities',
          label: 'Responsibilities',
          formatter: value => {
            return (value.findIndex(x => x.lang === "EN") > -1) ? value.find(x => x.lang === "EN").val : "???";
          }
        },{
          key: 'standardsareas',
          label: 'Areas',
        },{
          key: 'iafs',
          label: 'IAFs',
        },{
          key: 'documentscount',
          label: 'Documents',
          sortable: true,
        }],
        trainings: [{
          key: 'fromdate',
          label: 'From',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }        
        },{
          key: 'todate',
          label: "To",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "types",
          label: "Types",
        },{
          key: "training",
          label: "Training",
        },{
          key: "hours",
          label: "Hours",
        }],
        consultancies: [{
          key: 'fromdate',
          label: 'From',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }        
        },{
          key: 'todate',
          label: 'To',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'company',
          label: 'Company',
          sortable: true,
        },{
          key: 'sector',
          label: 'Sector',
          formatter: value => {
            return (value.findIndex(x => x.lang === "EN") > -1) ? value.find(x => x.lang === "EN").val : "???";
          }
        },{
          key: 'responsibilities',
          label: 'Responsibilities',
          formatter: value => {
            return (value.findIndex(x => x.lang === "EN") > -1) ? value.find(x => x.lang === "EN").val : "???";
          }
        },{
          key: "standardsareas",
          label: "Areas",
        },{
          key: 'iafs',
          label: 'IAFs',
        },{
          key: 'documentscount',
          label: 'Documents',
          sortable: true,
        }],
        auditlogs: [{
          key: 'datereport',
          label: 'Data',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'company',
          label: 'Company',
          sortable: true,
        },{
          key: 'certificationbody',
          label: 'Certification body',
        },{
          key: 'manday',
          label: 'Mandays',
        },{
          key: 'usersrole',
          label: 'Role',
        },{
          key: 'standards',
          label: 'Standards',
        },{
          key: 'phase',
          label: 'Phase',
        },{
          key: 'iafs',
          label: 'IAFs',
        },{
          key: 'documentscount',
          label: 'Documents',
          sortable: true,
        }],
        documents: [{
          key: "filename",
          label: "File",
          sortable: true,
        },{
          key: "id",
          label: "",
        }],
      },
    }
  },
  computed: {
    winHeight(){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadData(id){
      this.openHttp("curricula/curriculum", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("curricula/curriculum/save", this.data.curriculum).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    }
  }
}
</script>
<template>
  <div>
    <user ref="user" @update="loadData()"/>
    <b-modal v-model="showFilter" title="Filter">
      <b-form-group label="User">
        <b-form-input v-model="tempFilter.name"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="filterSet">Filter</b-btn>
          <b-btn variant="warning" @click="filterReset">Reset</b-btn>
        </div>
      </template>
    </b-modal>
    <b-btn class="mr-2" variant="primary" @click="loadData()">Refresh</b-btn>
    <b-btn class="mr-2" variant="secondary" @click="showFilter = true">Filter</b-btn>
    <b-btn variant="success" @click="$refs.user.loadData(0)">New</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 210) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="items.data" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sortby" :sort-desc.sync="search.sortdesc" @sort-changed="sortingChange" @row-clicked="$refs.user.loadData($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>There are no results</template>
    </b-table>
    <tablebottom :busy="isBusy" :currentpage="search.page" :total="items.total" @updateCurrentPage="search.page = $event" @loadData="loadData()"/>
  </div>
</template>

<script>
import user from './user.vue'

export default {
  name: "users",
  components : {
    user
  },
  data() {
    return {
      isBusy: true,
      showFilter: false,
      search: {
        page: 1,
        filters: {
          name: null,
        },
        sortby: 'active',
        sortdesc: true,
      },
      tempFilter: {
        name: null,
      },
      fields: [{
        key: 'firstname',
        label: 'Firstname',
        sortable: true
      },{
        key: 'lastname',
        label: 'Lastname',
        sortable: true
      },{
        key: 'active',
        label: "Status",
        sortable: true,
        formatter: value => {
          return value === true ? 'Active' : 'Not Active'; 
        }
      },{
        key: 'forms',
        label: 'Forms',
      }],
      items: {
        total: 0,
        data: [],
      } 
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.isBusy = true;
      this.openHttp( "users", this.search ).then((result) => {
        this.items = result;
        this.isBusy = false;
      })
    },
    filterSet(){
      this.showFilter = false;
      this.search.page = 1;
      this.search.filters = this.tempFilter;
      this.loadData();
    },
    filterReset(){
      this.tempFilter = {
        name: null,
      }
      this.filterSet();
    },
    sortingChange(ctx) {
      this.search.sortby = ctx.sortBy;
      this.search.sortdesc = ctx.sortDesc;
      this.loadData();
    }
  }
}
</script>
<template>
    <div>
        <b-modal v-if="data!== null" v-model="show" title="Monitoring" @hidden="closeForm()" @close="closeForm()">
            <b-form-group label="Date">
                <b-form-select v-model="data.monitoring.date" :options="data.dates" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Monitored">
                <b-form-select v-model="data.monitoring.users_id_monitored" :options="data.users" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Monitored by">
                <b-form-select v-model="data.monitoring.users_id_monitoring" :options="data.usersmonitoring" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Standards">
                <multiselect v-model="standardsSupport" :options="data.standards" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.monitoring.standards = updateCombo(standardsSupport)"/>
            </b-form-group>
            <b-form-group label="Role (monitored)">
                <b-form-select v-model="data.monitoring.usersroles_id" :options="data.roles" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Type">
                <b-form-select v-model="data.monitoring.underaccreditation" :options="underaccreditationCombo" value-field="id" text-field="val"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Save</b-btn>
                    <b-btn variant="danger" class="mr-2" v-show="data.monitoring.id > 0" @click="deleteData">Delete</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "auditmonitoring",
    data() {
        return {
            data: null,
            show: true,
            standardsSupport: [],
            underaccreditationCombo: [{
                id: false,
                val: "Internal"
            },{
                id: true,
                val: "Under accreditation"
            }]
        }
    },
    methods: {
        loadData( auditsId, id ){
            this.openHttp("audits/monitoring", { audits_id: auditsId, id: id }).then((result) => {
                this.standardsSupport = this.convertCombo(result.standards, result.monitoring.standards);
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("audits/monitoring/save", this.data.monitoring).then((result) => {
                if (result !== true) this.closeForm(true);
            })
        },
        deleteData(){
            this.deleteHttp("audits/monitoring/delete", {id: this.data.monitoring.id}).then((result) => {
                if (result !== true) this.closeForm(true);
            })
        },
        closeForm(reload = false){
            this.show = false;
            this.data = null;
            if (reload === true ) this.$emit('update');
        }
    },
}
</script>
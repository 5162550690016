<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" title="Contract" scrollable @hidden="closeForm()" @close="closeForm()">
      <contractstandard ref="contractstandard" @update="loadData(data.contract.id)"/>
      <contractsite ref="site" @update="loadData(data.contract.id)"/>
      <contractscope ref="scope" @update="loadData(data.contract.id)"/>
      <contractcost ref="cost" @update="loadData(data.contract.id)"/>
      <contractparameter ref="parameter" @update="loadData(data.contract.id)"/>
      <contractdocument ref="document" @update="loadData(data.contract.id)"/>
      <audit ref="audit" @update="loadData(data.contract.id)"/>
      <mail ref="mail" @update="loadData(data.contract.id)"/>
      <mailnew ref="mailnew" @update="loadData(data.application.id)"/>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Contract" active>
            <b-row>
              <b-col lg="6">
                <b-form-group label="Company">
                  <b-form-select v-model="data.contract.companies_id" :options="data.companies" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Contract">
                  <b-form-input v-model="data.contract.contract"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Issue date">
                  <b-form-input type="date" v-model="data.contract.issuedate"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Expiry date">
                  <b-form-input type="date" v-model="data.contract.expiracydate"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3">
                <b-form-group label="Type">
                  <b-form-select v-model="data.contract.contractstypes_id" :options="data.contractstypes" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Management representative">
                  <b-form-select v-model="data.contract.workers_id" :options="data.workers" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Employees">
                  <b-form-input type="number" v-model="data.contract.contractemployees" min="0" step="1"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="NACE (IAF)">
              <multiselect v-model="nacesSupport" :options="data.naces" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.contract.naces = updateCombo(nacesSupport)"/>
            </b-form-group>
            <b-form-group label="Standards">
              <b-btn variant="primary" class="mr-2" size="sm" @click="$refs.contractstandard.loadData(data.contract.id, 0)">New</b-btn>
              <b-btn variant="info" size="sm" @click="calculateMandays">Calculate mandays</b-btn>
              <b-table no-border-collapse :sticky-header="(winHeight - 205) / 3 + 'px'" class="mt-3" :items="data.standards" :fields="fields.standards" @row-clicked="$refs.contractstandard.loadData(data.contract.id, $event.id)" :tbody-tr-class="rowClassStandards"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Sites">
            <b-btn variant="primary" size="sm" @click="$refs.site.loadData(data.contract.id, 0)">New</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 335)+ 'px'" class="mt-3" :items="data.sites" :fields="fields.sites" @row-clicked="$refs.site.loadData(data.contract.id, $event.id)"/>
          </b-tab>
          <b-tab title="Scopes">
            <b-btn variant="primary" size="sm" @click="$refs.scope.loadData(data.contract.id, 0)">New</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 335) + 'px'" class="mt-3" :items="data.scopes" :fields="fields.scopes" @row-clicked="$refs.scope.loadData(data.contract.id, $event.id)"/>
          </b-tab>
          <b-tab title="Parameters">
            <b-table no-border-collapse :sticky-header="(winHeight - 335) + 'px'" :items="data.parameters" :fields="fields.parameters" @row-clicked="$refs.parameter.loadData(data.contract.id, $event.id)"/>
          </b-tab>
          <b-tab title="Years/Prices/Audits" v-if="data.contract.id > 0">
            <b-btn variant="info" size="sm" class="mr-2" @click="prepareAudits()">Genera Programma</b-btn>
            <b-btn variant="info" size="sm" class="mr-2" @click="mandaysSteps()">Fasi gg/uomo</b-btn>
            <b-btn variant="primary" size="sm" class="mr-2" @click="$refs.cost.loadData(data.contract.id, 0)">New</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 335) + 'px'" class="mt-3" :items="data.costsyears" :fields="fields.costsyears" @row-clicked="$refs.cost.loadData(data.contract.id, $event.id)">
              <template v-slot:cell(audits_id)="row">
                <b-link v-show="row.item.audits_id !== null" variant="info" size="sm" @click="$refs.audit.loadData(row.value)">Audit</b-link>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Mails">
            <b-btn variant="primary" size="sm" class="mr-2" v-show="data.contract.id > 0" @click="mailNew()">New</b-btn>
            <b-btn variant="primary" size="sm" class="mr-2" v-show="data.contract.id > 0" @click="mailContract()">Contract</b-btn>
            <b-table :sticky-header="(winHeight - 205) + 'px'" :tbody-tr-class="rowClassMails" responsive :items="data.mails" :fields="fields.mails" @row-clicked="loadMail($event.id)" :filter="fields.mailsCriteria"/>
          </b-tab>
          <b-tab title="Documents">
            <b-btn variant="info" class="mr-2" size="sm" @click="$refs.document.generateData(data.contract.id)">Generate</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 305)+ 'px'" :tbody-tr-class="rowClassDocuments" class="mt-3" :items="data.documents" :fields="fields.documents" @row-clicked="$refs.document.loadData(data.contract.id, $event.id)">
              <template v-slot:cell(id)="row">
                <b-btn variant="primary" size="sm" @click="$refs.document.downloadData(row.item.id)">Download</b-btn>
              </template>            
            </b-table>
          </b-tab>
          <!--
          <b-tab :title="'Errors ' + errors.length" v-show="errors.length > 0">
            <b-table no-border-collapse :sticky-header="(winHeight - 205)+ 'px'" :items="errors" :fields="fields.errors"/>
          </b-tab>
          -->
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" v-show="data.contract.id > 0" @click="loadData(data.contract.id)">Refresh</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData()">Save</b-btn>
          <b-btn variant="danger" class="mr-2" v-show="data.contract.id > 0" @click="deleteData()">Delete</b-btn>
          <b-btn variant="info" v-show="data.contract.id > 0" @click="externalLink()">Link</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import contractstandard from './contractcomponents/contractstandard.vue';
import contractsite from './contractcomponents/contractsite.vue';
import contractscope from './contractcomponents/contractscope.vue';
import contractcost from './contractcomponents/contractcost.vue';
import contractparameter from './contractcomponents/contractparameter.vue';
import contractdocument from './contractcomponents/contractdocument.vue';

export default {
  name: "contract",
  components: { contractstandard, contractsite, contractscope, contractcost, contractparameter, contractdocument },
  data() {
    return {
      show: true,
      data: null,
      errors: [],
      companies_idSupport: [],
      nacesSupport: [],
      fields: {
        standards: [{
          key: 'standard',
          label: "Standard",
        },{
          key: 'riskslevel',
          label: 'Risk level',
        },{
          key: 'accreditated',
          label: 'Accreditated',
          formatter: value => {
            return value === null ? "?" : (value === false ? "Not accreditated" : "Accreditated");
          },
        },{
          key: 'mandaystiletable',
          label: 'Mandays table',
        },{
          key: 'mandaysreduced',
          label: 'Mandays reduced',
        },{
          key: 'excludedpoints',
          label: 'Exclusions',
        },{
          key: 'notecalc',
          label: 'Note',
        }],
        notes: [{
          key: 'create_user',
          label: 'Created by',
        },{
          key: 'create_date',
          label: 'Creation date',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'note',
          label: 'Note',
        },{
          key: 'approve_user',
          label: 'Approved by',
        },{
          key: 'approve_date',
          label: 'Approval date',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'approve_result',
          label: 'Approval result',
          formatter: value => {
            return value === null ? 'Pending' : ( value === true ? 'Approved' : 'Not approved' );
          },
        },{
          key: 'approve_note',
          label: 'Approval note',
        }],
        sites: [{
          key: 'address',
          label: 'Address',
        },{
          key: 'main',
          label: 'Main',
          formatter: value => {
            return value === true ? 'Yes' : 'No';
          },
        },{
          key: 'registered',
          label: 'Registered',
          formatter: value => {
            return value === true ? 'Yes' : 'No';
          },
        },{
          key: 'state',
          label: 'State',
        },{
          key: 'employeesnumber',
          label: 'Wrks. totals',
        },{
          key: 'employeeseffectivenumber',
          label: 'Wrks. effective',
        },{
          key: 'employeesexternal',
          label: 'Wrks. extermal',
        },{
          key: 'employeesrepetitive',
          label: 'Wrks. repetitive',
        },{
          key: 'shifts',
          label: 'Shifts',
        },{
          key: 'note',
          label: 'Note',
        }],
        scopes: [{
          key: 'lang',
          label: 'Lang',
        },{
          key: 'scope',
          label: 'Scope',
        }],
        parameters: [{
          key: 'type',
          label: 'Parameter'
        },{
          key: 'val',
          label: 'Value'
        }],
        audits: [{
          key: 'plandate',
          label: 'Plan date',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'status',
          label: 'Status',
        },{
          key: 'standardsphases',
          label: 'Standards and phases',
          formatter: value => {
            if (value == null) return null;
            return value.join("\n");
          },
        },{
          key: 'points',
          label: 'Points',
        },{
          key: 'sites',
          label: 'Sites',
        },{
          key: 'veto',
          label: 'Veto',
        },{
          key: 'expiracy',
          label: 'Expiracy',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        }],
        costsyears: [{
          key: 'year',
          label: 'Year',
        },{
          key: 'phase',
          label: 'Phase',
        },{
          key: 'cost',
          label: 'Price',
        },{
          key: 'mandays',
          label: 'Mandays',
        },{
          key: 'audits_id',
          label: "Audit",
        },{
          key: "plandate",
          label: 'Plan',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: "status",
          label: "Status",
        },{
          key: 'expiracy',
          label: 'Expiry',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'phases',
          label: 'Phases',
        },{
          key: 'sites',
          label: 'Sites',
        }],
        mails: [{
          key: 'folder',
          label: "Folder",
          sortable: true
        },{
          key: 'from',
          label: 'By',
        },{
          key: 'to',
          label: 'To',
        },{
          key: 'subject',
          label: "Subject",
        },{
          key: 'datetime',
          label: "Date",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            const today = new Date();
            var d = new Date(value);
            if ( d.getDate() == today.getDate() && d.getMonth() == today.getMonth() && d.getFullYear() == today.getFullYear()){
              return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':');
            } else {
              return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
            }
          },
        }],
        mailsCriteria: null,
        documents: [{
          key: "filename",
          label: "File",
          sortable: true,
        },{
          key: "id",
          label: "",
        }],
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    rowClassStandards(item, type){
      if (!item || type !== 'row') return "";
      return item.active === false ? "table-danger" : "";
    },
    rowClassMails(item) {
      if ( item !== null ) return item.seen === false ? "table-primary" : (item.ping === true ? "table-success" : "");
    },
    rowClassDocuments(item) {
      return item !== null && item.check === false ? "table-danger" : "";
    },
    loadData( id ){
      if ( this.data === null ) this.show = false;
      this.fields.mailsCriteria = null;
      this.openHttp("contracts/contract", { id: id }).then((result) => {
        this.nacesSupport = this.convertCombo(result.naces, result.contract.naces);
        this.data = result;
        this.show = true;
        this.loadErrors();
      })
    },
    loadErrors(){
      this.openHttp("contracts/errors", { id: this.data.contract.id }).then((result) => {
        this.errors = result;
      })
    },
    loadMail(id){
      this.$refs.mail.loadData( id );
    },
    loadEmployees(){
      this.saveHttp("contracts/contract/employees", { id: this.data.contract.id, companies_id: this.data.contract.companies_id } ).then((result) => {
        this.data.contract.contractemployees = result.employees;
        this.data.contract.naces = result.naces;
        this.nacesSupport = this.convertCombo(this.data.naces, this.data.contract.naces);
      })
    },
    calculateMandays(){
      this.saveHttp("contracts/mandays", { id: this.data.contract.id }).then((result) => {
        if (result !== null) this.loadData( this.data.contract.id );
      })
    },
    prepareAudits(){
      this.saveHttp("contracts/contract/auditsprepare", { id: this.data.contract.id }).then((result) => {
        if (result !== null) this.loadData( this.data.contract.id );
      })
    },
    mandaysSteps(){
      this.saveHttp("contracts/contract/mandayssteps", { id: this.data.contract.id }).then((result) => {
        if (result !== null) this.loadData( this.data.contract.id );
      })
    },
    mailNew(){
      this.openHttp("contracts/contract/mail", { id: this.data.contract.id }).then((result) => {
        this.$refs.mailnew.loadData(result);
      })
    },
    mailContract(){
      this.openHttp("contracts/contract/mailcontract", { id: this.data.contract.id }).then((result) => {
        this.$refs.mailnew.loadData(result);
      })
    },
    externalLink(){
      window.open("/#/externalcontract/" + this.data.token, '_blank');
    },
    saveData(){
      this.saveHttp("contracts/contract/save", this.data.contract).then((result) => {
        if (result !== null) this.loadData(this.data.contract.id);
      })
    },
    deleteData(){
      this.deleteHttp("contracts/contract/delete", { id: this.data.contract.id }).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>
<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" title="Application" scrollable @hidden="closeForm()" @close="closeForm()">
      <applicationscope ref="scope" @update="loadData(data.application.id)"/>
      <applicationsite ref="site" @update="loadData(data.application.id)"/>
      <applicationparameter ref="parameter" @update="loadData(data.application.id)"/>
      <applicationstandard ref="standard" @update="loadData(data.application.id)"/>
      <applicationreview ref="review" @update="loadData(data.application.id)"/>
      <applicationdocument ref="document" @update="loadData(data.application.id)"/>
      <mail ref="mail" @update="loadData(data.application.id)"/>
      <mailnew ref="mailnew" @update="loadData(data.application.id)"/>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Application" active>
            <b-row>
              <b-col lg="2">
                <b-form-group label="Creation date">
                  <b-form-input type="date" v-model="data.application.create_date"/>
                </b-form-group>
              </b-col>
              <b-col lg="8">
                <company ref="company" @update="loadData(data.application.id)"/>
                <b-form-group>
                  <b-link v-show="data.application.id > 0" @click="$refs.company.loadData( data.application.companies_id )"> Company</b-link>
                  <b-form-select v-model="data.application.companies_id" :options="data.companies" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Type">
                  <b-form-select v-model="data.application.contractstypes_id" :options="data.contractstypes" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="2">
                <b-form-group label="Employees">
                  <b-form-input type="number" v-model="data.application.contractemployees" min="0" step="1"/>
                </b-form-group>
              </b-col>
              <b-col lg="8">
              </b-col>
            </b-row>
            <b-form-group label="Scopes" v-if="data.application.id > 0">
              <b-btn variant="primary" size="sm" @click="$refs.scope.loadData( data.application.id, 0)">New</b-btn>
              <b-table no-border-collapse class="mt-2" :items="data.scopes" :fields="fields.scopes" @row-clicked="$refs.scope.loadData( data.application.id, $event.id)"/>
            </b-form-group>
            <b-form-group label="NACE" v-if="data.application.id > 0">
              <multiselect v-model="nacesSupport" :options="data.naces" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.application.naces = updateCombo(nacesSupport)"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Sites" v-if="data.application.id > 0">
            <b-btn variant="primary" size="sm" @click="$refs.site.loadData( data.application.id, 0)">New</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 335)+ 'px'" class="mt-3" :items="data.sites" :fields="fields.sites" @row-clicked="$refs.site.loadData(data.application.id, $event.id)"/>
          </b-tab>
          <b-tab title="Parameters" v-if="data.application.id > 0">
            <b-table no-border-collapse :sticky-header="(winHeight - 335) + 'px'" :items="data.parameters" :fields="fields.parameters" @row-clicked="$refs.parameter.loadData(data.application.id, $event.id)"/>
          </b-tab>
          <b-tab title="Standards">
            <b-btn variant="primary" class="mr-2" size="sm" @click="$refs.standard.loadData( data.application.id, 0)">New</b-btn>
            <b-btn variant="warning" size="sm" @click="calculateMandays()">Calculate mandays</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 335) + 'px'" class="mt-3" :items="data.standards" :fields="fields.standards" @row-clicked="$refs.standard.loadData( data.application.id, $event.id)"/>
          </b-tab>
          <b-tab title="Reviews">
            <b-btn variant="primary" size="sm" @click="$refs.review.loadData(data.application.id, 0)">New</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 335) + 'px'" class="mt-2" :items="data.reviews" :fields="fields.reviews" @row-clicked="$refs.review.loadData(data.application.id, $event.id)"/>
          </b-tab>
          <b-tab title="Mails" v-if="data.application.id > 0">
            <b-btn variant="primary" size="sm" class="mr-2" v-show="data.application.id > 0" @click="mailNew()">New</b-btn>
            <b-btn variant="primary" size="sm" class="mr-2" v-show="data.application.id > 0" @click="mailQuery()">Query</b-btn>
            <mail ref="mail" @update="loadData(data.application.id)"/>
            <b-table :sticky-header="(winHeight - 205) + 'px'" :tbody-tr-class="rowClassMails" responsive :items="data.mails" :fields="fields.mails" @row-clicked="$refs.mail.loadData($event.id)"/>
          </b-tab>
          <b-tab title="Documents">
            <b-btn variant="primary" size="sm" @click="prepareDocuments">Prepare</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 205)+ 'px'" :tbody-tr-class="rowClassDocuments" class="mt-3" :items="data.documents" :fields="fields.documents" @row-clicked="$refs.document.loadData(data.application.id, $event.id)">
              <template v-slot:cell(id)="row">
                <b-btn variant="primary" size="sm" v-show="row.item.datetime !== null" @click="$refs.document.downloadData(row.item.id)">Download</b-btn>
              </template>            
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" v-show="data.application.id > 0" @click="loadData(data.application.id)">Refresh</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData">Save</b-btn>
          <b-btn variant="danger" class="mr-2" v-show="data.application.id > 0" @click="deleteData">Delete</b-btn>
          <b-btn variant="info" v-show="data.application.id > 0" @click="externalLink()">Link</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import applicationstandard from './applicationstandard.vue';
import applicationsite from './applicationsite.vue';
import applicationscope from './applicationscope.vue';
import applicationparameter from './applicationparameter.vue';
import applicationreview from './applicationreview.vue';
import applicationdocument from './applicationdocument.vue';

export default {
  name: "application",
  components: { applicationstandard, applicationsite, applicationscope, applicationparameter, applicationreview, applicationdocument },
  data() {
    return {
      show: true,
      data: null,
      nacesSupport: [],
      comboapprove_result: [{
        id: true,
        val: "Approved"
      },{
        id: false,
        val: "Not approved"
      },{
        id: null,
        val: "To be approved"
      }],
      fields: {
        standards: [{
          key: 'standard',
          label: "Standard",
        },{
          key: 'excludedpoints',
          label: 'Not applicable',
        },{
          key: 'accreditated',
          label: 'Accreditation',
          formatter: value => {
            return value === null ? "?" : (value === false ? "Not verified" : "Verified");
          },
        },{
          key: 'riskslevel',
          label: 'Risk level'
        },{
          key: 'mandaystiletable',
          label: 'Mandays table'
        },{
          key: 'mandaysreduced',
          label: 'Mandays reduced'
        }],
        sites: [{
          key: 'site',
          label: 'Site',
        },{
          key: 'ismain',
          label: 'Main',
          formatter: value => {
            return value === true ? 'Yes' : 'No';
          },
        },{
          key: 'isregistered',
          label: 'Registered',
          formatter: value => {
            return value === true ? 'Yes' : 'No';
          }
        },{
          key: 'isoperative',
          label: 'Operative',
          formatter: value => {
            return value === true ? 'Yes' : 'No';
          }
        },{
          key: 'state',
          label: 'State',
        },{
          key: 'employeesnumber',
          label: 'Workers',
        },{
          key: 'employeeseffectivenumber',
          label: 'Effective',
        },{
          key: 'employeesexternal',
          label: 'External',
        },{
          key: 'employeesrepetitive',
          label: 'External',
        },{
          key: 'shifts',
          label: 'Shifts',
        },{
          key: 'naces',
          label: 'Naces',
        }],
        scopes: [{
          key: 'lang',
          label: 'Language',
        },{
          key: 'scope',
          label: 'Scope',
        },{
          key: 'used',
          label: 'Used',
          formatter: value => {
            return value === true ? "Used" : "Not used";
          },
        }],
        parameters: [{
          key: 'type',
          label: 'Type'
        },{
          key: 'val',
          label: 'Value'
        }],
        mails: [{
          key: 'folder',
          label: "Folder",
          sortable: true
        },{
          key: 'from',
          label: 'From',
          sortable: false
        },{
          key: 'to',
          label: 'To',
          sortable: false
        },{
          key: 'subject',
          label: "Subject",
          sortable: false
        },{
          key: 'datetime',
          label: "Date",
          sortable: false,
          formatter: value => {
            if (value == null) return null;
            const today = new Date();
            var d = new Date(value);
            if ( d.getDate() == today.getDate() && d.getMonth() == today.getMonth() && d.getFullYear() == today.getFullYear()){
              return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':');
            } else {
              return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
            }
          },
        }],
        reviews: [{
          key: "date",
          label: 'Date',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'clientdata',
          label: 'Client data',
          formatter: value => {
            return value === true ? "Positive" : "Negative";
          },
        },{
          key: 'scopecover',
          label: "Scope",
          formatter: value => {
            return value === true ? "Positive" : "Negative";
          },
        },{
          key: 'sitecover',
          label: "Sites",
          formatter: value => {
            return value === true ? "Positive" : "Negative";
          },
        },{
          key: 'auditteamcover',
          label: "Audit team",
          formatter: value => {
            return value === true ? "Positive" : "Negative";
          },
        },{
          key: 'technicalcover',
          label: 'Veto team',
          formatter: value => {
            return value === true ? "Positive" : "Negative";
          },
        },{
          key: 'timecover',
          label: "Time avaiability",
          formatter: value => {
            return value === true ? "Positive" : "Negative";
          },
        },{
          key: "user",
          label: "Reviewed by",
        },{
          key: 'signature_check',
          label: 'Signature',
          formatter: value => {
            return value === true ? "Signed" : "To be signed";
          },
        }],
        documents: [{
          key: "filename",
          label: "File",
          sortable: true,
        },{
          key: "datetime",
          label: "Date",
          formatter: value => {
            let d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
          sortable: true,
        },{
          key: "id",
          label: "",
        }],
      },
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    rowClassMails(item) {
      if ( item !== null ) return item.seen === false ? "table-primary" : (item.ping === true ? "table-success" : "");
    },
    rowClassDocuments(item) {
      return item !== null && item.datetime === null ? "table-danger" : "";
    },
    loadData( id ){
      if ( this.data === null ) this.show = false;
      this.openHttp("applications/application", { id: id }).then((result) => {
        this.nacesSupport = this.convertCombo(result.naces, result.application.naces);
        this.data = result;
        this.show = true;
      })
    },
    calculateMandays(){
      this.saveHttp("applications/application/mandays", { id: this.data.application.id }).then((result) => {
        if ( result !== null ) this.loadData(this.data.application.id);
      })
    },
    saveData(){
      this.saveHttp("applications/application/save", this.data.application).then((result) => {
        if ( result !== null ) this.loadData(result);
      })
    },
    deleteData(){
      this.deleteHttp("applications/application/delete", { id: this.data.application.id }).then((result) => {
        if ( result !== null ) this.closeForm(true);
      })
    },
    approveData(){
      this.saveHttp("applications/application/approve", this.data.application).then((result) => {
        if ( result !== null ) this.closeForm(true);
      })
    },
    prepareDocuments(){
      this.saveHttp("applications/documents/generate", { id: this.data.application.id }).then((result) => {
        if (result !== null) this.loadData( this.data.application.id );
      })
    },
    mailNew(){
      this.openHttp("applications/application/mail", { id: this.data.application.id }).then((result) => {
        this.$refs.mailnew.loadData(result);
      })
    },
    mailQuery(){
      this.openHttp("applications/application/mailquery", { id: this.data.application.id }).then((result) => {
        this.$refs.mailnew.loadData(result);
      })
    },
    externalLink(){
      window.open("/#/externalcontract/" + this.data.token, '_blank');
    },
    closeForm(reload = false){
      this.show = false;
      this.data = null;
      if (reload === true) this.$emit("update");
    },
  },
}
</script>
<template>
  <div>
    <b-modal v-if="data != null" v-model="show" size="xl" title="Internal audit" @hidden="closeForm()" @close="closeForm()">
      <document ref="document" @update="loadData(data.internalaudit.id)"/>
      <nonconformity ref="nonconformity" @update="loadData(data.internalaudit.id)"/>
      <signature ref="signature" @update="loadData(data.internalaudit.id)"/>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Audit" active>
            <b-table-simple responsive>
              <b-thead head-variant="light">
                <b-tr>
                  <b-th style="width: 25%">Parameter</b-th>
                  <b-th>Value</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td>Dates</b-td>
                  <b-td>{{ data.internalaudit.fromdate }} - {{ data.internalaudit.todate }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td>Period analysed</b-td>
                  <b-td>{{ data.internalaudit.periodfrom }} - {{ data.internalaudit.periodto }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td>Approval</b-td>
                  <b-td>{{ data.internalaudit.approvedate }} by {{ data.internalaudit.approveuser }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td>Audit team</b-td>
                  <b-td>{{ data.internalaudit.users }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td>Scope and sites</b-td>
                  <b-td>{{ data.internalaudit.scope_site }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td>Objectives</b-td>
                  <b-td>{{ data.internalaudit.objectives }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td>Risks and opportunities</b-td>
                  <b-td>{{ data.internalaudit.risks_opportunities }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td>Criteria</b-td>
                  <b-td>{{ data.internalaudit.criteria }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td>Methods</b-td>
                  <b-td>{{ data.internalaudit.methods }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td>Note</b-td>
                  <b-td>{{ data.internalaudit.note }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-tab>
          <b-tab title="Plan">
            <b-table no-border-collapse :sticky-header="(winHeight - 380) + 'px'" responsive :items="data.plan" :fields="fields.plan"></b-table>
          </b-tab>
          <b-tab title="Signatures" v-if="data.signatures.length > 0">
            <b-table no-border-collapse :sticky-header="(winHeight - 380) + 'px'" responsive :items="data.signatures" :fields="fields.signatures">
              <template v-slot:cell(signatures_id)="row">
                <b-btn :variant="row.item.signatures_check === true ? 'success' : 'danger'" size="sm" @click="$refs.signature.loadData(row.item.signatures_id)">Signature</b-btn>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Checklist">
            <b-table no-border-collapse :sticky-header="(winHeight - 340 ) + 'px'" responsive :items="data.questions" :fields="fields.evidences">
              <template v-slot:cell(point)="row">
                <b>{{ row.item.points }}</b><br>
                <b>{{ row.item.title }}</b>
                <div v-html="row.item.description"></div>
                <div v-show="row.item.note !== null"><br><i v-html="row.item.note"></i></div>
              </template>
              <template v-slot:cell(evidences)="row">
                <b-form-textarea size="sm" :value="row.item.evidences" rows="10" max-rows="20" @change="saveEvidence(row.item.id, $event)"/>
              </template>
              <template v-slot:cell(opportunity)="row">
                <b-form-textarea size="sm" :value="row.item.opportunity" rows="10" max-rows="20" @change="saveOpportunity(row.item.id, $event)"/>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Non conformities">
            <b-btn variant="primary" size="sm" @click="$refs.nonconformity.loadData(0, data.internalaudit.id)">New</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 340 ) + 'px'" class="mt-3" responsive :items="data.nonconformities" :fields="fields.nonconformities" @row-clicked="$refs.nonconformity.loadData($event.id, data.internalaudit.id)"/>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="loadData(data.internalaudit.id)" v-show="data.internalaudit.closedate === null">Refresh</b-btn>
          <b-btn variant="primary" @click="$refs.downloadData.downloadRecord(data.internalaudit.documentssystem_id)" v-show="data.internalaudit.documentssystem_id !== null">Download</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import document from './document.vue';
import nonconformity from '../nonconformities/nonconformity.vue';
import signature from './signature.vue';

export default {
  name: "internalauditaudit",
  components : { document, nonconformity, signature },
  data() {
    return {
      show: false,
      data: null,
      fields: {
        signatures: [{
          key: "fullname",
          label: "Fullname"
        },{
          key: "signatures_id",
          label: ""
        }],
        plan: [{
          key: "date",
          label: "Date"
        },{
          key: "fromtime",
          label: "From"
        },{
          key: "totime",
          label: "To"
        },{
          key: "users",
          label: "Participants"
        },{
          key: "standardspoints",
          label: "Points"
        },{
          key: "note",
          label: "Note"
        }],
        evidences: [{
          key: "point",
          label: "Point",
          thStyle: { width: "35%" }
        },{
          key: "evidences",
          label: "Evidences",
          thStyle: { width: "35%" }
        },{
          key: "opportunity",
          label: "Opportunity",
          thStyle: { width: "30%" }
        }],
        nonconformities: [{
          key: 'prog',
          label: "Progressive",
          sortable: true
        },{
          key: 'nonconformity',
          label: 'Non conformity',
        },{
          key: 'causes',
          label: 'Causes',
        },{
          key: 'treatment',
          label: 'Treatment',
        },{
          key: 'correctiveaction',
          label: 'Corrective action',
        }]
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadData(id){
      this.openHttp("internalaudits/internalaudit", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveEvidence(questionsId, evidence){
      this.saveHttp("internalaudits/internalaudit/evidencesave", { internalaudits_id: this.data.internalaudit.id, questions_id: questionsId, evidences: evidence}).then((result) => {
        if (result !== null) this.loadData(this.data.internalaudit.id);
      })
    },
    saveOpportunity(questionsId, opportunity){
      this.saveHttp("internalaudits/internalaudit/opportunitysave", { internalaudits_id: this.data.internalaudit.id, questions_id: questionsId, opportunity: opportunity}).then((result) => {
        if (result !== null) this.loadData(this.data.internalaudit.id);
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  }
}
</script>
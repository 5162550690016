<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" title="Questionnaire" scrollable @hidden="closeForm()" @close="closeForm()">
      <b-row>
        <b-col lg="4">
          <b-form-group label="Participant">
            <b-form-input :value="data.fullname"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-table no-border-collapse :sticky-header="(winHeight - 375) + 'px'" responsive show-empty :items="data.questions" :fields="fields">
        <template v-slot:cell(yesno)="row">
          <b-form-textarea v-if="row.item.answer !== null" v-model="row.item.answer" rows="3" :disabled="row.item.isof !== null"/>
          <div v-else>
            <b-form-radio v-model="row.item.yesno" :value="true">Yes</b-form-radio>
            <b-form-radio v-model="row.item.yesno" :value="false">No</b-form-radio>
          </div>
        </template>
        <template v-slot:cell(isok)="row">
          <b-button-group size="sm">
            <b-btn v-if="row.value === true" variant="success" size="sm">Correct</b-btn>
            <b-btn v-if="row.value === false" variant="danger" size="sm"><nobr>Not correct</nobr></b-btn>
          </b-button-group>
        </template>
      </b-table>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData()">Save</b-btn>
          <b-btn variant="warning" class="mr-2" @click="repeatData()">Repeat</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "trainingquestions",
  data() {
    return {
      show: false,
      data: null,
      fields: [{
        key: "question",
        label: "Question",
        thStyle: { width: "70%" },
      },{
        key: "yesno",
        label: "Yesno",
        thStyle: { width: "20%" },
      },{
        key: "isok",
        label: "Ok",
        thStyle: { width: "10%" },
      }]
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    }
  },
  methods: {
    loadData(id){
      this.openHttp("trainings/questions", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("trainings/questions/save", this.data).then((result) => {
        if (result !== null) this.closeForm(true);
      })
    },
    repeatData(){
      this.saveHttp("trainings/questions/repeat", { id: this.data.id }).then((result) => {
        if (result !== null) this.loadData(this.data.id);
      })
    },
    closeForm(reload = false){
      this.show = false;
      this.data = null;
      if ( reload === true ) this.$emit('update');
    }
  }
}
</script>

<template>
    <div>
        <b-modal id="modal-signature" v-if="data !== null" v-model="show" title="Signature" @hidden="closeForm()" @close="closeForm()">
            <b-row>
                <b-col>
                    <b-form-group label="Sign here">
                        <VueSignaturePad v-if="showSignature" id="signature" width="760px" height="400px" ref="signature" :options="{ onEnd }" :customStyle="{ border: 'black 3px solid' }"/>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="Scan here">
                        <qrcode :value="'https://private.euro-certifications.com/#/externalsignature/' + data.token" :options="{ width: 175 }"></qrcode>
                    </b-form-group>
                    <b-form-group>
                        or <b-link :href="'https://private.euro-certifications.com/#/externalsignature/' + data.token" target="_blank">Click here</b-link>
                    </b-form-group>
                </b-col>
            </b-row>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Save</b-btn>
                    <b-btn variant="warning" class="mr-2" v-show="data.signature !== null" @click="cleanData">Clean</b-btn>
                    <b-btn variant="danger" v-show="data.signature !== null" @click="deleteData">Delete</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "impartialitycommitteessignature",
    data() {
        return {
            show: false,
            data: null,
            showSignature: false,
        }
    },
    mounted() {
        window.addEventListener('resize', this.onResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        onResize() {
            if (this.showSignature === true){
                this.$refs.signature.clearSignature();
                this.launchSignature();
            }
        },
        onEnd() {
            const { isEmpty, data } = this.$refs.signature.saveSignature();
            console.log(isEmpty);
            this.data.signature = data;
        },
        launchSignature(){
            this.$refs.signature.fromDataURL(this.data.signature, {width: 760, height: 400});      
        },
        loadData( id ){
            this.showSignature = false;
            this.show = false;
            this.openHttp("impartialitycommittees/signature", { id: id }).then((result) => {
                this.show = true;
                this.data = result;
                var self = this;
                setTimeout(function(){
                    self.showSignature = true;
                    setTimeout(function(){ 
                        self.launchSignature();
                    }, 100);
                }, 100);
            })
        },
        cleanData(){
            this.$refs.signature.clearSignature();
        },
        saveData(){
            this.saveHttp("impartialitycommittees/signature/save", this.data).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        deleteData( ){
            this.deleteHttp("impartialitycommittees/signature/delete", { id: this.data.id }).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>

<style>
  #modal-signature > .modal-dialog {
    min-height: 600px;
    min-width: 800px;
  }
</style>
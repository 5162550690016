<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Impartiality declaration" @hidden="closeForm()" @close="closeForm()">
      <b-row>
        <b-col>
          <b-form-group label="I declare that">
            <b-form-checkbox v-model="data.impartiality.gdpr">
              GDPR
            </b-form-checkbox>
            <b-form-checkbox v-model="data.impartiality.noconsultancy3years">
              in the last 3 (three) years I haven’t performed any consultancy for {{ data.impartiality.company }}
            </b-form-checkbox>
            <b-form-checkbox v-model="data.impartiality.nointernalaudit3years">
              in the last 3 (three) years I haven’t performed any internal audits for {{ data.impartiality.company }}
            </b-form-checkbox>
            <b-form-checkbox v-model="data.impartiality.nocontractual3years">
              I have no ties or contractual relationships with the consultancy organization of {{ data.impartiality.company }} or I did not perform any other activities that would have been in conflict of interest with the activities as Auditor
            </b-form-checkbox>
            <b-form-checkbox v-model="data.impartiality.interestofcompetiting">
              I will not provide information and I will defend the interests of competing organizations in carrying out my activities as Auditor 
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Otherwise, I declare that I have the followings conflicts of interests:">
            <b-form-textarea v-model="data.impartiality.note" rows="2" max-rows="6"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b>I am aware that I am responsible for incorrect information pursuant Art. 313 of the Penal Code (Bulgaria) and I undertake to inform the Certification Body in the event of a change of the circumstances declared by me.</b>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b>And I undertake to:</b>
          <ul>
            <li>operate objectively, impartially and independently, and I will not allow commercial, financial or other pressures which may affect the results of my work and harm the trust of its independence and objectivity</li>
            <li>perform any other activity that is contrary to the requirements of ISO/IEC 17021-1 and certification schemes</li>
            <li>inform the CB for any possible conflict of interest at the start of work</li>
            <li>inform the CB when I'm asked to give confidential information concerning the client's interests, both in oral and written form</li>
          </ul>
        </b-col>
      </b-row>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData()">Save</b-btn>
          <b-btn v-if="data.impartiality.signatures_id > 0" :variant="data.impartiality.signature_check === true ? 'success' : 'danger'" @click="$refs.signature.loadData(data.impartiality.signatures_id)">{{ data.impartiality.signature_check === true ? 'Signed' : 'Not signed' }}</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "checklistimpartiality",
  data() {
    return {
      show: false,
      data: null,
      fields: [{
        key: 'question',
        label: "Question",
      },{
        key: 'rating',
        label: "Rating",
      }]
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    }
  },
  methods: {
    loadData( id ){
      this.openHttp("checklists/impartiality", { id: id }).then((result) => {
        if (result.impartiality === undefined) {
          this.closeForm();
        } else {
          this.data = result;
          this.show = true;
        }
      })
    },
    saveData(){
      this.saveHttp("checklists/impartiality/save", this.data.impartiality).then((result) => {
        if ( result !== true ) this.loadData(result);
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>
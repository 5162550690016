<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="dark">
      <b-navbar-brand to="/homepage" v-show="checkRoute(['homepage'])">Eurocertifications</b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item v-show="checkRoute(['companies'])" to="/companies">Companies</b-nav-item>
          <b-nav-item-dropdown text="Contracts" v-show="checkRoute(['applications', 'contracts', 'certificates'])">
            <b-dropdown-item to="/applications" v-show="checkRoute(['applications'])">Applications</b-dropdown-item>
            <b-dropdown-item to="/contracts" v-show="checkRoute(['contracts'])">Contracts</b-dropdown-item>
            <b-dropdown-item to="/certificates" v-show="checkRoute(['certificates'])">Certificates</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown text="Audits" v-show="checkRoute(['audits', 'vetoaudits', 'checklists'])">
            <b-dropdown-item to="/audits" v-show="checkRoute(['audits'])">Audits</b-dropdown-item>
            <b-dropdown-item to="/checklists" v-show="checkRoute(['checklists'])">Checklists</b-dropdown-item>
            <b-dropdown-item to="/veto" v-show="checkRoute(['veto'])">Veto</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item v-show="checkRoute(['documentsconsultation'])" to="/documentsconsultation">Documents consultation</b-nav-item>
          <b-nav-item-dropdown text="Management system" v-show="checkRoute(['documentssystem', 'interestedparties', 'risks', 'jobs', 'laws', 'internalaudits', 'managementreviews'])">
            <b-dropdown-item to="/documentssystem" v-show="checkRoute(['documentssystem'])">System documents</b-dropdown-item>
            <b-dropdown-item to="/interestedparties" v-show="checkRoute(['interestedparties'])">Interested parties</b-dropdown-item>
            <b-dropdown-item to="/risks" v-show="checkRoute(['risks'])">Risks</b-dropdown-item>
            <b-dropdown-item to="/jobs" v-show="checkRoute(['jobs'])">Job description</b-dropdown-item>
            <b-dropdown-item to="/nonconformities" v-show="checkRoute(['nonconformities'])">Non-conformities</b-dropdown-item>
            <b-dropdown-item to="/laws" v-show="checkRoute(['laws'])">Laws</b-dropdown-item>
            <b-dropdown-item to="/complaints" v-show="checkRoute(['complaints'])">Complaints</b-dropdown-item>
            <b-dropdown-item to="/internalaudits" v-show="checkRoute(['internalaudits'])">Internal audits</b-dropdown-item>
            <b-dropdown-item to="/managementreviews" v-show="checkRoute(['managementreviews'])">Management reviews</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown text="Staff" v-show="checkRoute(['curricula', 'jobsappointments', 'competences', 'trainings', 'impartialitycommittee', 'qualifications', 'monitorings'])">
            <b-dropdown-item to="/curricula" v-show="checkRoute(['curricula'])">Curricula</b-dropdown-item>
            <b-dropdown-item to="/jobsappointments" v-show="checkRoute(['jobsappointments'])">Job appointments</b-dropdown-item>
            <b-dropdown-item to="/competences" v-show="checkRoute(['competences'])">Competences</b-dropdown-item>
            <b-dropdown-item to="/qualifications" v-show="checkRoute(['qualifications'])">Qualifications</b-dropdown-item>
            <b-dropdown-item to="/trainings" v-show="checkRoute(['trainings'])">Trainings</b-dropdown-item>
            <b-dropdown-item to="/impartialitycommittees" v-show="checkRoute(['impartialitycommittees'])">Impartiality committees</b-dropdown-item>
            <b-dropdown-item to="/monitorings" v-show="checkRoute(['monitorings'])">Monitorings</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item v-show="checkRoute(['mails'])" to="/mails">Mails</b-nav-item>
          <b-nav-item v-show="checkRoute(['users'])" to="/users">Users</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item to="/login">Log out</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "navbar",
  computed: {
    routesAllowed (){
      return this.$store.getters.getRoutes;
    },
  },
  methods: {
    checkRoute(routeToCheck){
      return this.routesAllowed.map(x => x.route).filter(x => routeToCheck.indexOf(x) !== -1).length > 0;
    }
  }
}
</script>
<template>
    <div>
        <law ref="law" @update="loadData()"/>
        <b-modal v-model="showFilter" title="Filters">
            <b-form-group label="Law">
                <b-form-input v-model="tempFilter.law"/>
            </b-form-group>
            <b-form-group label="Standard">
                <b-form-select v-model="tempFilter.standards_id" :options="addNullCombo(filtersMenu.standards)" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="State">
                <b-form-select v-model="tempFilter.states_id" :options="addNullCombo(filtersMenu.states)" value-field="id" text-field="val"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="primary" class="mr-2" @click="filterSet()">Filter</b-btn>
                    <b-btn variant="warning" @click="filterReset()">Reset</b-btn>
                </div>
            </template>
        </b-modal>
        <b-btn class="mr-2" variant="primary" @click="loadData()">Refresh</b-btn>
        <b-btn class="mr-2" variant="secondary" @click="filterOpen()">Filter</b-btn>
        <b-btn class="mr-2" variant="success" @click="$refs.law.loadData(0)">New</b-btn>
        <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="items.data" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sortby" :sort-desc.sync="search.sortdesc" @sort-changed="sortingChange" @row-clicked="$refs.law.loadData($event.id)">
            <template v-slot:table-busy>
                <div class="text-center text-primary">
                    <b-spinner class="align-middle"/>
                </div>
            </template>
            <template v-slot:empty>There are no results</template>
        </b-table>
        <tablebottom :busy="isBusy" :currentpage="search.page" :total="items.total" @updateCurrentPage="search.page = $event" @loadData="loadData()"/>
    </div>
</template>

<script>
import law from './law.vue';

export default {
    name: "laws",
    components : { law },
    data() {
        return {
            isBusy: true,
            showFilter: false,
            search: {
                page: 1,
                filters: {
                    law: null,
                    standards_id: null,
                    states_id: null,
                },
                sortby: 'law',
                sortdesc: false
            },
            tempFilter: {
                law: null,
                standards_id: null,
                states_id: null,
            },
            filtersMenu: {
                standards: [],
                states: [],
            },
            fields: [{
                key: 'law',
                label: 'Legge',
                sortable: true,
            },{
                key: "standards",
                label: "Standards",
            },{
                key: 'states',
                label: 'States',
            }],
            items: {
                total: 0,
                data: [],
            }
        }
    },
    computed: {
        winHeight (){
            return this.$store.getters.getWinHeight;
        },
    },
    created() {
        this.loadData();
    },
    methods: {
        loadData(){
            this.isBusy = true;
            this.openHttp("laws", this.search).then((result) => {
                this.items = result;
                this.isBusy = false;
            })
        },
        filterOpen(){
            this.openHttp("laws/filters", {}).then((result) => {
                this.filtersMenu = result;
                this.showFilter = true;
            })
        },
        filterSet(){
            this.search.page = 1;
            this.showFilter = false;
            this.search.filters = this.tempFilter;
            this.loadData();
        },
        filterReset(){
            this.tempFilter = {
                law: null,
                standards_id: null,
                states_id: null,
            }
            this.filterSet();
        },
        sortingChange(ctx) {
            this.search.sortby = ctx.sortBy;
            this.search.sortdesc = ctx.sortDesc;
            this.loadData();
        }
    }
}
</script>
<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Finding to be closed" @hidden="closeForm()" @close="closeForm()">
      <b-form-group label="Finding">
        <b-form-textarea size="sm" v-model="data.opportunity.opportunity" rows="5"/>
      </b-form-group>
      <b-form-group label="Closure">
        <b-form-textarea size="sm" v-model="data.opportunity.closure" rows="5"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" @click="saveData">Save</b-btn>
        </div>
      </template>           
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "checklistopportunitytoclose",
  data() {
    return {
      show: false,
      data: null
    }
  },
  methods: {
    loadData( id, auditsId ){
      this.openHttp("checklists/opportunitytobeclosed", { id: id, audits_id: auditsId }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("checklists/opportunitytobeclosed/save", this.data.opportunity).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>
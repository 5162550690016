<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" title="Evaluation" size="xl" @hidden="closeForm()" @close="closeForm()">
      <document ref="document"/>
      <b-row>
        <b-col lg="4">
          <b-form-group label="Candiate">
            <b-form-select v-model="data.evaluation.users_id" :options="data.users" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group label="Date">
            <b-form-input type="date" v-model="data.evaluation.date"/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group label="Evaluated by">
            <b-form-select v-model="data.evaluation.review_users_id" :options="data.users" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Evaluation">
        <b-table no-border-collapse responsive :sticky-header="(winHeight - 375) + 'px'" :items="data.evaluation.requirements" :fields="fields">
          <template v-slot:cell(requirement)="row">
            {{ data.requirements.find(x => x.id === row.item.id).requirement }}
          </template>
          <template v-slot:cell(evaluationmethod)="row">
            <b-btn variant="info" size="sm" @click="data.requirements.find(x => x.id === row.item.id).show = ! data.requirements.find(x => x.id === row.item.id).show">Show</b-btn>
            <div v-if="data.requirements.find(x => x.id === row.item.id).show === true" v-html="data.requirements.find(x => x.id === row.item.id).evaluationmethod.replaceAll('\n', '<br>')"></div>
          </template>
          <template v-slot:cell(passed)="row">
            <b-form-select v-model="row.item.passed" :options="yesNoCombo" value-field="id" text-field="val"/>
          </template>
          <template v-slot:cell(note)="row">
            <b-form-textarea v-model="row.item.note" rows="3"/>
          </template>
        </b-table>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData()">Save</b-btn>
          <b-btn variant="danger" class="mr-2" v-show="data.evaluation.id > 0" @click="deleteData()">Delete</b-btn>
          <b-btn variant="info" @click="$refs.document.generateDataRequirements(data.evaluation.id)">Generate</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import document from './document.vue';

export default {
  name: "requirementevaluation",
  components: { document },
  data() {
    return {
      show: false,
      data: null,
      yesNoCombo: [{
        id: null,
        val: "Not evaluated"
      },{
        id: true,
        val: "Positive"
      },{
        id: false,
        val: "Negative"
      }],
      fields: [{
        key: "requirement",
        label: "Requirement",
        sortable: true
      },{
        key: "evaluationmethod",
        label: "Evaluation method"
      },{
        key: "passed",
        label: "Status",
      },{
        key: "note",
        label: "Note",
      }],
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    }
  },
  methods: {
    loadData( id, usersId = null ){
      this.openHttp("jobsappointments/requirementsevaluation", {id: id, users_id: usersId}).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("jobsappointments/requirementsevaluation/save", this.data.evaluation).then((result) => {
        if (result !== null) this.closeForm(true);
      })
    },
    deleteData(){
      this.saveHttp("jobsappointments/requirementsevaluation/delete", { id: this.data.evaluation.id }).then((result) => {
        if (result !== null) this.closeForm(true);
      })
    },
    closeForm( reload = false ){
      this.show = false;
      this.data = null;
      if (reload === true) this.$emit('update');
    }
  }
}
</script>
<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" title="User" size="lg" @hidden="closeForm()" @close="closeForm()">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="User" active>
            <b-row>
              <b-col lg="2">
                <b-form-group label="Title">
                  <b-form-select v-model="data.user.titles_id" :options="data.titles" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="5">
                <b-form-group label="Firstname">
                  <b-form-input v-model="data.user.firstname"/>
                </b-form-group>
              </b-col>
              <b-col lg="5">
                <b-form-group label="Lastname">
                  <b-form-input v-model="data.user.lastname"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="2">
                <b-form-group label="Status">
                  <b-form-select v-model="data.user.active" :options="activeCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="5">
                <b-form-group label="Username">
                  <b-form-input v-model="data.user.username" :disabled="!data.user.active"/>
                </b-form-group>
              </b-col>
              <b-col lg="5">
                <b-form-group label="Password">
                  <b-form-input v-model="data.user.psw" :disabled="!data.user.active"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Language">
                  <b-form-select v-model="data.user.languages_id" :options="data.languages" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Nationality">
                  <b-form-select v-model="data.user.states_id" :options="data.states" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Form">
            <b-table no-border-collapse :sticky-header="(winHeight - 325) + 'px'" responsive show-empty :items="data.forms" :fields="fields">
              <template v-slot:cell(levels)="row">
                <b-btn v-for="(item, index) of row.value" :key="index" :variant="data.user.forms.find(x => x.id === row.item.id).level === item.id ? 'success' : ''" size="sm" class="mr-2" @click="data.user.forms.find(x => x.id === row.item.id).level = item.id">Level {{ item.val }}</b-btn>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData()">Save</b-btn>
          <b-btn variant="danger" v-show="data.user.id > 0" @click="deleteData()">Delete</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: "usersuser",
  data() {
    return {
      data: null,
      show: false,
      activeCombo: [{
        id: true,
        val: "Active",
      },{
        id: false,
        val: "Not active",
      }],
      fields: [{
        key: 'form',
        label: 'Form',
        sortable: true
      },{
        key: 'levels',
        label: 'Level'
      }]
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    }
  },
  methods: {
    loadData(id){      
      if ( this.data === null ) this.show = false;
      this.openHttp("users/user", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("users/user/save", this.data.user).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("users/user/delete", { id: this.data.user.id }).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  }
}
</script>
<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Impartiality committee meeting" @hidden="closeForm()" @close="closeForm()">
      <document ref="document" @update="loadData(data.impartialitycommittee.id)"/>
      <signature ref="signature" @update="loadData(data.impartialitycommittee.id)"/>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Meeting" active>
            <b-row>
              <b-col lg="10" v-if="data.impartialitycommittee.id === 0">
                <b-form-group label="Participants">
                  <multiselect v-model="usersSupport" :options="data.users" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.impartialitycommittee.users = updateCombo(usersSupport)"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Date">
                  <b-form-input type="date" v-model="data.impartialitycommittee.date"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Signatures" v-if="data.impartialitycommittee.id > 0">
              <b-table no-border-collapse :sticky-header="(winHeight - 375) + 'px'" responsive show-empty :items="data.signatures" :fields="fields.signatures">
                <template v-slot:empty>There are no results</template>
                <template v-slot:cell(id)="row">
                  <b-btn :variant="row.item.check === true ? 'success' : 'danger'" size="sm" @click="$refs.signature.loadData(row.value)">Signature</b-btn>
                </template>
              </b-table>
            </b-form-group>
          </b-tab>
          <b-tab title="Meeting">
            <b-table no-border-collapse :sticky-header="(winHeight - 375) + 'px'" responsive show-empty :items="data.results" :fields="fields.results" class="mt-3">
              <template v-slot:cell(topic)="row">
                <strong>{{ row.item.topic }}</strong>
                <b-row>
                  <b-col>
                    <b-form-group label="Discussions">
                      <b-form-textarea v-model="row.item.discussions" rows="3" @change="saveDiscussions(row.item.id, $event)"/>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Conclusions">
                      <b-form-textarea v-model="row.item.conclusions" rows="3" @change="saveConclusions(row.item.id, $event)"/>
                    </b-form-group>
                  </b-col>
                </b-row>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" v-if="data.impartialitycommittee.id > 0" @click="loadData(data.impartialitycommittee.id)">Refresh</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData()">Save</b-btn>
          <b-btn variant="danger" class="mr-2" v-show="data.impartialitycommittee.id > 0" @click="deleteData()">Delete</b-btn>
          <b-btn variant="info" class="mr-2" v-show="data.impartialitycommittee.id > 0" @click="$refs.document.generateData()">Generate</b-btn>
          <b-btn variant="primary" v-show="data.impartialitycommittee.documents_id > 0" @click="$refs.document.downloadData(data.impartialitycommittee.documents_id)">Download</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import document from './document.vue';
import signature from './signature.vue';

export default {
  name: "impartialitycommittee",
  components: { document, signature },
  data() {
    return {
      usersSupport: [],
      show: false,
      data: null,
      fields: {
        signatures: [{
          key: "user",
          label: "Participant"
        },{
          key: "id",
          label: "",
        }],
        results: [{
          key: "topic",
          label: "Topic"
        }]
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    }
  },
  methods: {
    loadData(id){
      this.openHttp("impartialitycommittees/impartialitycommittee", { id: id }).then((result) => {
        this.usersSupport = this.convertCombo(result.users, result.impartialitycommittee.users);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("impartialitycommittees/impartialitycommittee/save", this.data.impartialitycommittee).then((result) => {
        if (result !== null) this.closeForm(true);
      })
    },
    saveDiscussions(id, discussions){
      this.saveHttp("impartialitycommittees/impartialitycommittee/discussionsave", {impartialitycommittees_id: this.data.impartialitycommittee.id, impartialitycommitteestopics_id: id, discussions: discussions }).then((result) => {
        if (result !== null) this.loadData(this.data.impartialitycommittee.id);
      })
    },
    saveConclusions(id, conclusions){
      this.saveHttp("impartialitycommittees/impartialitycommittee/conclusionsave", {impartialitycommittees_id: this.data.impartialitycommittee.id, impartialitycommitteestopics_id: id, conclusions: conclusions }).then((result) => {
        if (result !== null) this.loadData(this.data.impartialitycommittee.id);
      })
    },
    deleteData(){
      this.deleteHttp("impartialitycommittees/impartialitycommittee/delete", {id: this.data.impartialitycommittee.id}).then((result) => {
        if (result !== null) this.closeForm(true);
      })
    },
    closeForm(reload = false){
      this.show = false;
      this.data = null;
      if (reload === true) this.$emit('update');
    },
    openExternalSignature (token){
        window.open('/#/externalsignature/' + token)
    }
  }
}
</script>

<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" title="Document" @hidden="closeForm()" @close="closeForm()">
            <b-form-group label="File name" v-show="data.document.filename != null">
                <b-form-input v-model="data.document.filename"/>
            </b-form-group>
            <b-form-group label="File">
                <b-form-file @change="fileChange"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Save</b-btn>
                    <b-btn variant="danger" v-show="data.document.id > 0" @click="deleteData">Delete</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "checklistdocument",
    data() {
        return {
            show: false,
            data: null,
        }
    },
    computed: {
        token (){
            return this.$store.getters.getToken;
        },
    },
    methods: {
        loadData(nonconformitiesId, id){
            this.show = false;
            this.openHttp("nonconformities_document", { nonconformities_id: nonconformitiesId, id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        fileChange(event){
            let reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = ()=> {
                if ( this.data.document.id > 0 ){
                    this.data.document.filenamereal = event.target.files[0].name;
                } else {
                    this.data.document.filename = event.target.files[0].name.replace(/\.[^/.]+$/, "");
                    this.data.document.filenamereal = event.target.files[0].name;
                }
                this.data.document.file = reader.result;
            };
        },
        saveData(){
            this.openHttp("nonconformities_document_save", this.data.document, "save").then((result) => {
                if ( result.result == true ) this.closeForm( true );
            })
        },
        deleteData(){
            this.openHttp("nonconformities_document_delete", { nonconformities_id: this.data.document.nonconformities_id, id: this.data.document.id }, "delete").then((result) => {
                if ( result.result == true ) this.closeForm( true );
            })
        },
        closeForm(reload = false){
            this.show = false;
            this.data = null;
            if (reload === true) this.$emit('update');
        },
    },
}
</script>
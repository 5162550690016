import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        showNavBar: false,
        token: localStorage.getItem("token"),
        lang: localStorage.getItem("lang"),
        routes: localStorage.getItem("routes") !== null || localStorage.getItem("routes") !== undefined ? JSON.parse(localStorage.getItem("routes")) : [],
        windowWidth: 0,
        windowHeight: 0,
        lastMarketingphonesId: null,
        lastEmailAddressId: null,
        lastEmailTemplatesId: null,
    },
    getters: {
        getNavBar : state => {
            return state.showNavBar;
        },
        getToken : state => {
            return state.token;
        },
        getLang : state => {
            return state.lang;
        },
        getRoutes : state => {
            return state.routes;
        },
        checkRoute: (state) => (route, level) => {
            return state.routes.findIndex(x => x.route === route && x.level >= level) > -1;
        },
        getWinHeight : state => {
            return state.windowHeight;
        },
        getWinWidth : state => {
            return state.windowWidth;
        },
    },
    mutations: {
        setToken: (state, token) => {
            if (token != null){
                localStorage.setItem("token", token);
                state.token = token;
            }
        },
        setLang: (state, lang) => {
            localStorage.setItem("lang", lang);
            state.lang = lang;
        },
        setRoutes: (state, routes) => {
            localStorage.setItem("routes", JSON.stringify(routes));
            state.routes = routes;
        },
        setWinHeight : (state, height) => {
            state.windowHeight = height;
        },
        setWinWidth : (state, width) => {
            state.windowWidth = width;
        },
        unSetToken : (state) => {
            state.showNavBar = false;
            localStorage.clear();
            state.token = null;
            state.routes = [];
            localStorage.clear();
        }
    },
})
<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" title="Audit" scrollable @hidden="closeForm()" @close="closeForm()">
      <auditstandardphase ref="standardphase" @update="loadData(data.audit.id)"/>
      <auditsite ref="site" @update="loadData(data.audit.id)"/>
      <auditimpartiality ref="impartiality" @update="loadData(data.audit.id)"/>
      <auditpreviousaudit ref="previousaudit" @update="loadData(data.audit.id)"/>
      <auditcalendardate ref="calendardate" @update="loadData(data.audit.id)"/>
      <auditmonitoring ref="monitoring" @update="loadData(data.audit.id)"/>
      <auditopportunitytobeclosed ref="opportunitytobeclosed" @update="loadData(data.audit.id)"/>
      <auditopportunitytobeclosedimport ref="opportunitytobeclosedimport" @update="loadData(data.audit.id)"/>
      <auditnonconformitytobeclosed ref="nonconformitytobeclosed" @update="loadData(data.audit.id)"/>
      <auditnonconformitytobeclosedimport ref="nonconformitytobeclosedimport" @update="loadData(data.audit.id)"/>
      <auditdocument ref="document" @update="loadData(data.audit.id)"/>
      <auditsignature ref="signature" @update="loadData(data.audit.id)"/>
      <auditdecision ref="decision" @update="loadData(data.audit.id)"/>
      <auditveto ref="veto" @update="loadData(data.audit.id)"/>
      <mail ref="mail" @update="loadData(data.audit.id)"/>
      <mailnew ref="mailnew" @update="loadData(data.audit.id)"/>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Audit" active>
            <b-row>
              <b-col lg="6">
                <b-form-group label="Contract">
                  <b-form-select v-model="data.audit.contracts_id" :options="data.contracts" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Status">
                  <b-form-select v-model="data.audit.auditsstatus_id" :options="data.auditsstatus" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Plan date">
                  <b-form-input type="date" v-model="data.audit.plandate"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Exipiry date">
                  <b-form-input type="date" v-model="data.audit.expiracy"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="data.audit.auditsstatus_id != 1 && data.audit.auditsstatus_id <= 2">
              <b-col lg="4">
                <b-form-group label="Audit father">
                  <multiselect v-model="f_audits_idSupport" :options="data.auditsfathers" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.audit.f_audits_id = updateComboSimple(f_audits_idSupport)" @search-change="asyncFathers"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Languages">
                  <multiselect v-model="languagesSupport" :options="data.languages" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.audit.languages = updateCombo(languagesSupport)"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Standards, phase and points">
              <b-btn variant="primary" class="mb-2" size="sm" @click="$refs.standardphase.loadData(data.audit.id, 0)" v-if="data.audit.auditsstatus_id == 1">New</b-btn>
              <b-table responsive :items="data.standardsphases" :fields="fields.standardsphases" @row-clicked="$refs.standardphase.loadData(data.audit.id, $event.id)"/>
            </b-form-group>
            <b-form-group label="Sites & People">
              <b-btn size="sm" variant="primary" @click="$refs.site.loadData( 0, data.audit.id)" v-if="data.audit.auditsstatus_id <= 2">New</b-btn>
              <b-table responsive :fields="fields.sites" :items="data.sites" class="mt-2" :sticky-header="(winHeight - 305) + 'px'" @row-clicked="$refs.site.loadData($event.id, data.audit.id)"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Calendar" v-if="data.audit.auditsstatus_id >= 2 && data.audit.auditsstatus_id <= 3">
            <b-row>
              <b-col lg="6">
                <b-table responsive :fields="fields.controlteam" :items="data.controlteam">
                  <template v-slot:cell(team)="row">
                    <div v-for="(item, index) in row.value" :key="index">
                      {{ item.date }}: {{ item.team }}
                    </div>
                  </template>
                </b-table>
              </b-col>
              <b-col lg="3">
                <b-table responsive :fields="fields.controltime" :items="data.controltime"/>
              </b-col>
              <b-col lg="3">
                <b-table responsive :fields="fields.controlnaces" :items="data.controlnaces"/>
              </b-col>
            </b-row>
            <b-btn class="mr-2 mb-2" style="float: left" size="sm">{{ calendarMonth + '-' + calendarYear}}</b-btn>
            <b-btn variant="primary" class="mr-2 mb-2" style="float: left" size="sm" @click="calendarPreviousMonth">Previous</b-btn>
            <b-btn variant="primary" class="mr-2 mb-2" style="float: left" size="sm" @click="calendarNextMonth">Next</b-btn>
            <b-form-input size="sm" v-model="calendarfilterUser" style="width: 300px"/>
            <b-table-simple hover responsive bordered :sticky-header="(winHeight - 630) + 'px'" v-if="calendar.days.length > 0">
              <b-thead head-variant="light">
                <b-th>Person</b-th>
                <b-th>Qualification</b-th>
                <b-th>Ok</b-th>
                <b-th v-for="(item, index) in calendar.days" :key="index">{{ item }}</b-th>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(item, index) in calendar.users.filter( x => x.fullname.toLowerCase().indexOf( calendarfilterUser.toLowerCase() ) > -1 )" :key="index">
                  <b-td>{{ item.fullname }}</b-td>
                  <b-td>
                    <div v-for="(item2, index2) of item.qualifications" :key="index2">
                      {{ item2.standard }} <strong>{{ item2.roles }}</strong> <font color="green">{{ item2.ok }}</font> <font color="red">{{ item2.ko }}</font>
                    </div>
                  </b-td>
                  <b-td>
                    {{ item.percentage }}%
                  </b-td>
                  <b-td v-for="(item2, index2) in calendar.days" :key="index2" @click="$refs.calendardate.loadData(data.audit.id, item.id, calendarYear + '-' + calendarMonth + '-' + item2)" :variant="calendar.thisaudit.findIndex(x => x.day === item2 && x.users_id === item.id ) > -1 ? 'success' : (calendar.otherauditsothercb.findIndex(x => x.day === item2 && x.users_id === item.id ) > -1 ? 'warning' : (calendar.otherauditscb.findIndex(x => x.day === item2 && x.users_id === item.id ) > -1 ? 'danger' : ''))"></b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-tab>
          <b-tab title="Veto" v-if="data.audit.auditsstatus_id >= 2 && data.audit.auditsstatus_id <= 3">
            <b-btn variant="primary" size="sm" @click="$refs.veto.loadData(data.audit.id, 0)" v-if="data.audit.auditsstatus_id <= 2">New</b-btn>
            <b-table responsive :items="data.vetoauditors" :fields="fields.vetos" :sticky-header="(winHeight - 405) + 'px'" class="mt-3" @row-clicked="$refs.veto.loadData(data.audit.id, $event.id)"/>
          </b-tab>
          <b-tab title="Monitoring" v-if="data.audit.auditsstatus_id >= 2">
            <!--
            {{ data.monitoringsneed }}
            -->
            <b-btn variant="primary" size="sm" @click="$refs.monitoring.loadData(data.audit.id, 0)" v-if="data.audit.auditsstatus_id <= 2">New</b-btn>
            <b-table responsive :items="data.monitoring" :fields="fields.monitoring" :sticky-header="(winHeight - 420) + 'px'" class="mt-3" @row-clicked="$refs.monitoring.loadData(data.audit.id, $event.id)"/>
          </b-tab>
          <b-tab title="Reservation" v-if="data.audit.auditsstatus_id >= 2">
            <b-table-simple responsive :sticky-header="(winHeight - 320) + 'px'">
              <b-thead>
                <b-tr>
                  <b-th>From</b-th>
                  <b-th>To</b-th>
                  <b-th>Type</b-th>
                  <b-th>Team</b-th>
                  <b-th>Points</b-th>
                  <b-th>Sites</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <template v-for="(item, index) of data.reservations">
                  <b-tr :key="index + '_tab'">
                    <b-th colspan="6">
                      <b-btn class="mr-2" size="sm">{{ cleanDate(item.date) }}</b-btn>
                      <b-btn class="mr-2" size="sm" :variant="item.todo === item.done ? 'success' : 'warning'">{{ (item.todo - item.done) !== 0 ? ( item.todo > item.done ? ('Missing ' + (Math.round((item.todo - item.done) * 10000) / 10000) + 'h' ) : 'Excess ' + (Math.round((item.done - item.todo) * 10000) / 10000) + 'h') : 'OK' }}</b-btn>
                    </b-th>
                  </b-tr>
                  <b-tr v-for="(item2, index2) of item.reservations" :key="index + '_tab_' + index2">
                    <b-td>{{ item2.fromtime }}</b-td>
                    <b-td>{{ item2.totime }}</b-td>
                    <b-td>{{ item2.auditstype }}</b-td>
                    <b-td>
                      <div v-for="(item3, index3) of item2.usersroles" :key="index3">
                        <nobr><strong>{{ item3.user + ' ' + item3.role }}</strong></nobr> {{ item3.standardsphases }}
                      </div>
                    </b-td>
                    <b-td>
                      <div v-for="(item3, index3) of item2.standardspoints" :key="index3">
                        <nobr><strong>{{ item3.standard }}</strong> {{ item3.points }}</nobr>
                      </div>
                    </b-td>
                    <b-td>{{ item2.site }}</b-td>
                  </b-tr>
                </template>
              </b-tbody>
            </b-table-simple>
          </b-tab>
          <b-tab title="Appointments" v-if="data.audit.auditsstatus_id >= 2">
            <b-form-group label="Audit team">
              <b-table responsive :items="data.impartialities" :fields="fields.impartialities" :sticky-header="(winHeight - 305) + 'px'" @row-clicked="$refs.impartiality.loadData($event.id, data.audit.id)"/>
            </b-form-group>
            <b-form-group label="Company">
              <b-row>
                <b-col lg="4">
                  <b-form-group label="Company representative">
                    <b-form-select v-model="data.audit.workers_id" :options="data.workers" value-field="id" text-field="val"/>
                  </b-form-group>
                </b-col>
                <b-col lg="2">
                  <b-form-group label="Decision">
                    <b-form-select v-model="data.audit.decision_yesno" :options="decisionCombo" value-field="id" text-field="val"/>
                  </b-form-group>
                </b-col>
                <b-col lg="4">
                  <b-form-group label="Note">
                    <b-form-textarea v-model="data.audit.decision_note" rows="6"/>
                  </b-form-group>
                </b-col>
                <b-col lg="2">
                  <b-form-group label="Signature">
                    <b-btn :variant="data.audit.signatures1_check === true ? 'success' : 'danger'">Signature</b-btn>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form-group>
          </b-tab>
          <b-tab title="Transfer" v-if="data.audit.auditsstatus_id >= 2 && data.ispretransfer === true">
            <b-btn variant="primary" size="sm" @click="$refs.previousaudit.loadData(data.audit.id, 0)">New</b-btn>
            <b-table responsive :items="data.previousaudits" :fields="fields.previousaudits" :sticky-header="(winHeight - 420) + 'px'" class="mt-3" @row-clicked="$refs.previousaudit.loadData(data.audit.id, $event.id)">
              <template v-slot:cell(standardspoints)="row">
                <div v-for="(item, index) of row.value" :key="index">{{ item }}</div>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Evidences" v-if="[2,3].includes(data.audit.auditsstatus_id)">
            <b-card no-body>
              <b-tabs card>
                <b-tab v-for="(item, index) of data.evidences.evidences" :key="index" :title="item.paragraph + ' ' + item.percentualdone + '%'">
                  <b-table responsive :fields="fields.evidences" :items="item.questions" :sticky-header="(winHeight - 460) + 'px'">
                    <template v-slot:cell(id)="row">
                      <strong>{{ row.item.question }}</strong>
                      <div v-if="row.item.notapplicable === false || row.item.isnotapplicable === false">
                        <b-row>
                          <b-col v-for="item in row.item.subquestions" :key="item.id" :lg="item.width">
                            <b-form-group :label="item.types_id === 7 ? '' : item.question">
                              <b-row>
                                <b-col v-for="(item2, index2) in item.evidences" :key="index2" class="mb-2">
                                  <b-input-group size="sm" :prepend="item2.lang">                                          
                                    <b-form-input v-if="item.types_id === 1" :value="item2.evidence"/>
                                    <b-form-textarea v-if="item.types_id === 2" :value="item2.evidence" rows="2" max-rows="5"/>
                                    <b-form-input v-if="item.types_id === 3" v-model="item2.evidence" type="date"/>
                                    <b-form-input v-if="item.types_id === 4" v-model="item2.evidence" type="number"/>
                                    <b-form-select v-if="item.types_id === 5" v-model="item2.evidence" :options="data.questionspossibleanswerscombo.find(x => x.id === item.subquestions_id).answers" value-field="id" text-field="val"/>
                                    <b-form-checkbox v-if="item.types_id === 7" v-model="item2.evidence" value="t" unchecked-value="f">{{ item.question }}</b-form-checkbox>
                                  </b-input-group>
                                </b-col>
                              </b-row>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </div>
                      <div v-else>Not applicable</div>
                    </template>
                  </b-table>
                </b-tab>
                <b-tab :title="'Strengths ' + data.strengths.total" v-if="data.audit.auditsstatus_id === 3">
                  <b-table responsive :fields="fields.strengths" :items="data.strengths.strengths" :sticky-header="(winHeight - 475) + 'px'" class="mt-2" @row-clicked="$refs.strength.loadData(data.audit.id, $event.id)">
                    <template v-slot:cell(strength)="row">
                      <div v-for="(item, index) in row.value" :key="index">
                        <strong>{{ item.lang }}</strong> {{ item.strength }}
                      </div>
                    </template>
                    <template v-slot:cell(points)="row">
                      <div v-for="(item, index) in row.value" :key="index">
                        <strong>{{ item.standard }}</strong> {{ item.points }}
                      </div>
                    </template>
                  </b-table>
                </b-tab>
                <b-tab :title="'Findings ' + data.opportunities.total" v-if="data.audit.auditsstatus_id === 3">
                  <b-table responsive :fields="fields.opportunities" :items="data.opportunities.opportunities" :sticky-header="(winHeight - 475) + 'px'" class="mt-2" @row-clicked="$refs.opportunity.loadData(data.audit.id, $event.id)">
                    <template v-slot:cell(opportunity)="row">
                      <div v-for="(item, index) in row.value" :key="index">
                        <strong>{{ item.lang }}</strong> {{ item.opportunity }}
                      </div>
                    </template>
                    <template v-slot:cell(points)="row">
                      <div v-for="(item, index) in row.value" :key="index">
                        <strong>{{ item.standard }}</strong> {{ item.points }}
                      </div>
                    </template>
                  </b-table>
                </b-tab>
                <b-tab :title="'Non-conformities ' + data.nonconformities.total" v-if="data.audit.auditsstatus_id === 3">
                  <b-table responsive :fields="fields.nonconformities" :items="data.nonconformities.nonconformities" :sticky-header="(winHeight - 475) + 'px'" class="mt-2" @row-clicked="$refs.nonconformity.loadData(data.audit.id, $event.id)">
                    <template v-slot:cell(nonconformity)="row">
                      <div v-for="(item, index) in row.value" :key="index">
                        <strong>{{ item.lang }}</strong> {{ item.nonconformity }}
                      </div>
                    </template>
                    <template v-slot:cell(acceptnote)="row">
                      <div v-for="(item, index) in row.value" :key="index">
                        <strong>{{ item.lang }}</strong> {{ item.acceptnote }}
                      </div>
                    </template>
                    <template v-slot:cell(cause)="row">
                      <div v-for="(item, index) in row.value" :key="index">
                        <strong>{{ item.lang }}</strong> {{ item.cause }}
                      </div>
                    </template>
                    <template v-slot:cell(treatment)="row">
                      <div v-for="(item, index) in row.value" :key="index">
                        <strong>{{ item.lang }}</strong> {{ item.treatment }}
                      </div>
                    </template>
                    <template v-slot:cell(correctiveaction)="row">
                      <div v-for="(item, index) in row.value" :key="index">
                        <strong>{{ item.lang }}</strong> {{ item.correctiveaction }}
                      </div>
                    </template>
                    <template v-slot:cell(effectivenessnote)="row">
                      <div v-for="(item, index) in row.value" :key="index">
                        <strong>{{ item.lang }}</strong> {{ item.effectivenessnote }}
                      </div>
                    </template>
                    <template v-slot:cell(points)="row">
                      <div v-for="(item, index) in row.value" :key="index">
                        <strong>{{ item.standard }}</strong> {{ item.points }}
                      </div>
                    </template>
                  </b-table>
                </b-tab>
                <b-tab title="Non-conformities to be verified"> 
                  <b-btn variant="primary" size="sm" class="mr-2" @click="$refs.nonconformitytobeclosed.loadData(data.audit.id, 0)" v-if="data.audit.auditsstatus_id === 2">New</b-btn>
                  <b-btn variant="info" size="sm" @click="$refs.nonconformitytobeclosedimport.loadData(data.audit.id)" v-if="data.audit.auditsstatus_id === 2">Import</b-btn>
                  <b-table responsive :fields="fields.nonconformitiestobeclosed" :items="data.nonconformitiestobeclosed" :sticky-header="(winHeight - 475) + 'px'" class="mt-2" @row-clicked="$refs.nonconformitytobeclosed.loadData(data.audit.id, $event.id)">
                    <template v-slot:cell(nonconformity)="row">
                      <div v-for="(item, index) in row.value" :key="index">
                        <strong>{{ item.lang }}</strong> {{ item.nonconformity }}
                      </div>
                    </template>
                    <template v-slot:cell(causes)="row">
                      <div v-for="(item, index) in row.value" :key="index">
                        <strong>{{ item.lang }}</strong> {{ item.cause }}
                      </div>
                    </template>
                    <template v-slot:cell(treatment)="row">
                      <div v-for="(item, index) in row.value" :key="index">
                        <strong>{{ item.lang }}</strong> {{ item.treatment }}
                      </div>
                    </template>
                    <template v-slot:cell(correctiveaction)="row">
                      <div v-for="(item, index) in row.value" :key="index">
                        <strong>{{ item.lang }}</strong> {{ item.correctiveaction }}
                      </div>
                    </template>
                    <template v-slot:cell(points)="row">
                      <div v-for="(item, index) in row.value" :key="index">
                        <strong>{{ item.standard }}</strong> {{ item.points }}
                      </div>
                    </template>
                  </b-table>           
                </b-tab>
                <b-tab title="Findings to be verified">
                  <b-btn variant="primary" size="sm" class="mr-2" @click="$refs.opportunitytobeclosed.loadData(data.audit.id, 0)" v-if="data.audit.auditsstatus_id === 2">New</b-btn>
                  <b-btn variant="info" size="sm" @click="$refs.opportunitytobeclosedimport.loadData(data.audit.id)" v-if="data.audit.auditsstatus_id === 2">Import</b-btn>
                  <b-table responsive :fields="fields.opportunitiestobeclosed" :items="data.opportunitiestobeclosed" :sticky-header="(winHeight - 475) + 'px'" class="mt-2" @row-clicked="$refs.opportunitytobeclosed.loadData(data.audit.id, $event.id)">
                    <template v-slot:cell(opportunity)="row">
                      <div v-for="(item, index) in row.value" :key="index">
                        <strong>{{ item.lang }}</strong> {{ item.opportunity }}
                      </div>
                    </template>
                    <template v-slot:cell(points)="row">
                      <div v-for="(item, index) in row.value" :key="index">
                        <strong>{{ item.standard }}</strong> {{ item.points }}
                      </div>
                    </template>
                  </b-table>
                </b-tab>
              </b-tabs>
            </b-card>
          </b-tab>
          <b-tab title="Report" v-if="data.audit.auditsstatus_id >= 4 && data.ispretransfer === false">
            <b-table responsive :fields="fields.vetoresults" :items="data.vetoresults" :sticky-header="(winHeight - 345) + 'px'">
              <template v-slot:cell(report)="row">
                <nobr><b>{{ row.item.standard + ' ' + row.item.point }}</b></nobr>
                <b-row>
                  <b-col v-for="(item, index) in row.value" :key="index">
                    <strong>{{ item.lang }}</strong><span v-html="item.report"></span>
                  </b-col>
                </b-row>
              </template>
              <template v-slot:cell(strengths)="row">
                <b-alert v-for="(item, index) in row.value" :key="index" variant="success" show>
                  <div v-for="(item2, index2) in item.strength" :key="index2" >
                    <b>{{ item2.lang }}</b>: {{ item2.strength }}
                  </div>
                </b-alert>
              </template>
              <template v-slot:cell(opportunities)="row">
                <b-alert v-for="(item, index) in row.value" :key="index" variant="warning" show>
                  <div v-for="(item2, index2) in item.opportunity" :key="index2" >
                    <b>{{ item2.lang }}</b>: {{ item2.opportunity }}
                  </div>
                </b-alert>
              </template>
              <template v-slot:cell(nonconformities)="row">
                <b-alert v-for="(item, index) in row.value" :key="index" variant="danger" show>
                  Nonconformity:
                  <div v-for="(item2, index2) in item.nonconformity" :key="index2">
                    <b>{{ item2.lang }}</b>: {{ item2.nonconformity }}
                  </div>
                  Type: {{ item.nctype }} Accepted: {{ item.acceptdecision === true ? 'Accepted' : (item.acceptdecision === false ? 'Not Accepted' : 'Pending' ) }}<br>
                  Causes:
                  <div v-for="(item2, index2) in item.cause" :key="index2">
                    <b>{{ item2.lang }}</b>: {{ item2.cause }}
                  </div>
                  Treatment:
                  <div v-for="(item2, index2) in item.treatment" :key="index2">
                    <b>{{ item2.lang }}</b>: {{ item2.treatment }}
                  </div>
                  Corrective action:
                  <div v-for="(item2, index2) in item.correctiveaction" :key="index2">
                    <b>{{ item2.lang }}</b>: {{ item2.correctiveaction }}
                  </div>
                  Effectiveness Decision: {{ item.effectivenessdecision === true ? 'Accepted' : (item.effectivenessdecision === false ? 'Not Accepted' : 'Pending' ) }}<br>
                  Effectiveness note:
                  <div v-for="(item2, index2) in item.effectivenessnote" :key="index2">
                    <b>{{ item2.lang }}</b>: {{ item2.effectivenessnote }}
                  </div>
                </b-alert>
              </template>
              <template v-slot:cell(opportunitiestobeclosed)="row">
                <b-alert v-for="(item, index) in row.value" :key="index" variant="warning" show>
                  Findings:
                  <div v-for="(item2, index2) in item.opportunity" :key="index2">
                    <b>{{ item2.lang }}</b>: {{ item2.opportunity }}
                  </div>
                  Closure:
                  <div v-for="(item2, index2) in item.closure" :key="index2">
                    <b>{{ item2.lang }}</b>: {{ item2.closure }}
                  </div>
                </b-alert>
              </template>
              <template v-slot:cell(nonconformitiestobeclosed)="row">
                <b-alert v-for="(item, index) in row.value" :key="index" variant="danger" show>
                  Nonconformity:
                  <div v-for="(item2, index2) in item.nonconformity" :key="index2">
                    <b>{{ item2.lang }}</b>: {{ item2.nonconformity }}
                  </div>
                  Type: {{ item.nctype }}<br>
                  Causes:
                  <div v-for="(item2, index2) in item.causes" :key="index2">
                    <b>{{ item2.lang }}</b>: {{ item2.causes }}
                  </div>
                  Treatment:
                  <div v-for="(item2, index2) in item.treatment" :key="index2">
                    <b>{{ item2.lang }}</b>: {{ item2.treatment }}
                  </div>
                  Corrective action:
                  <div v-for="(item2, index2) in item.correctiveaction" :key="index2">
                    <b>{{ item2.lang }}</b>: {{ item2.correctiveaction }}
                  </div>
                  Effectiveness Decision: {{ item.effectivenessdecision === true ? 'Accepted' : (item.effectivenessdecision === false ? 'Not Accepted' : 'Pending' ) }}<br>
                  Effectiveness note:
                  <div v-for="(item2, index2) in item.effectivenessnote" :key="index2">
                    <b>{{ item2.lang }}</b>: {{ item2.effectivenessnote }}
                  </div>
                </b-alert>
              </template>
              <template v-slot:cell(ok)="row">
                <b-btn :variant="row.item.ok === true ? 'success' : 'secondary'" size="sm" class="mr-2" @click="$refs.veto.controlData(data.audit.id, row.item.id, true, null)">Ok</b-btn>
                <b-btn :variant="row.item.ok === false ? 'danger' : 'secondary'" size="sm" @click="$refs.veto.controlData(data.audit.id, row.item.id, false, row.item.noteko)">No</b-btn>
                <b-form-group label="Note" v-if="row.item.ok === false">
                  <b-form-textarea v-model="row.item.noteko" rows="2" @change="$refs.veto.controlData(data.audit.id, row.item.id, false, $event)"/>
                </b-form-group>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Veto" v-if="data.audit.auditsstatus_id >= 4">
            <b-row>
              <b-col lg="9">
                <b-form-group label="Checks">
                  <b-table responsive :items="data.vetoerrors" :fields="fields.vetoerrors"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Decisions">
                  <b-table responsive :items="data.vetoauditors" :fields="fields.vetosdecision" @row-clicked="$refs.veto.loadData(data.audit.id, $event.id)"/>                
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Decision" v-if="data.audit.auditsstatus_id >= 2">           
            <b-form-group label="Decision Certification manager">
              <b-table responsive :items="data.decisions" :fields="fields.decisions1" @row-clicked="$refs.decision.loadData(data.audit.id, $event.id)"/>                
            </b-form-group>
            <b-form-group label="Decision Lead Auditor">
              <b-table responsive :items="data.decisions" :fields="fields.decisions2" @row-clicked="$refs.decision.loadData(data.audit.id, $event.id)"/>                
            </b-form-group>
            <b-form-group label="Decision Veto Auditor">
              <b-table responsive :items="data.decisions" :fields="fields.decisions3" @row-clicked="$refs.decision.loadData(data.audit.id, $event.id)"/>                
            </b-form-group>
          </b-tab>
          <b-tab title="Mails" v-if="data.audit.auditsstatus_id >= 2">
            <b-btn variant="primary" size="sm" class="mr-2" v-show="data.audit.id > 0" @click="mailNew()">New</b-btn>
            <b-btn variant="primary" size="sm" class="mr-2" v-show="data.audit.id > 0" @click="mailPlan()">Plan</b-btn>
            <b-btn variant="primary" size="sm" v-show="data.audit.id > 0" @click="mailClosure()">Closure</b-btn>
            <b-table :sticky-header="(winHeight - 405) + 'px'" class="mt-2" :tbody-tr-class="rowClassMails" responsive :items="data.mails" :fields="fields.mails" @row-clicked="$refs.mail.loadData($event.id)"/>
          </b-tab>
          <b-tab title="Documents">
            <b-btn variant="info" size="sm" @click="$refs.document.generateData(data.audit.id)">Generate</b-btn>
            <b-table class="mt-3" :sticky-header="(winHeight - 405) + 'px'" responsive :items="data.documents" :fields="fields.documents" @row-clicked="$refs.document.loadData(data.audit.id, $event.id)">
              <template v-slot:cell(jsonkey)="row">{{ row.value }}</template>
              <template v-slot:cell(id)="row">
                <b-btn variant="warning" class="mr-2" v-show="row.item.datetime !== null && row.item.locked === false" size="sm" @click="$refs.document.lockData(row.item.id)">Lock</b-btn>
                <b-btn variant="primary" v-show="row.item.datetime !== null" size="sm"  @click="$refs.document.downloadData(row.value)">Download</b-btn>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="loadData(data.audit.id)">Refresh</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData()">Save</b-btn>
          <b-btn variant="danger" class="mr-2" v-show="data.audit.id > 0" @click="deleteData()">Delete</b-btn>
          <b-btn variant="info" v-show="data.audit.id > 0" @click="externalLink()">Link</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import auditstandardphase from './auditscomponents/auditstandardphase.vue';
import auditsite from './auditscomponents/auditsite.vue';
import auditimpartiality from './auditscomponents/auditimpartiality.vue';
import auditcalendardate from './auditscomponents/auditcalendardate.vue';
import auditveto from './auditscomponents/auditveto.vue';
import auditmonitoring from './auditscomponents/auditmonitoring.vue';
import auditpreviousaudit from './auditscomponents/auditpreviousaudit.vue';
import auditopportunitytobeclosed from './auditscomponents/auditopportunitytobeclosed.vue';
import auditopportunitytobeclosedimport from './auditscomponents/auditopportunitytobeclosedimport.vue';
import auditnonconformitytobeclosed from './auditscomponents/auditnonconformitytobeclosed.vue';
import auditnonconformitytobeclosedimport from './auditscomponents/auditnonconformitytobeclosedimport.vue';
import auditdecision from './auditscomponents/auditdecision.vue';
import auditsignature from './auditscomponents/auditsignature.vue';
import auditdocument from './auditscomponents/auditdocument.vue';

export default {
  name: "audit",
  components: {
    auditstandardphase, auditsite, auditimpartiality, auditcalendardate, auditveto, auditmonitoring, auditpreviousaudit, auditopportunitytobeclosed, auditopportunitytobeclosedimport, auditnonconformitytobeclosed, auditnonconformitytobeclosedimport, auditdecision, auditsignature, auditdocument
  },
  data() {
    return {
      show: true,
      data: null,
      errors: [],
      showMail: false,
      f_audits_idSupport: [],
      languagesSupport: [],
      ordersSupport: [],
      usersSupport: [],
      decisionCombo:[{
        id: null,
        val: "Pending"
      },{
        id: true,
        val: "Approved"
      },{
        id: false,
        val: "Not approved"
      }],
      calendarfilterUser: "",
      calendarMonth: null,
      calendarYear: null,
      calendar: {
        days: [],
        users: [],
        calendar: [],
        dates: [],
      },
      fields: {
        controlteam: [{
          key: "phase",
          label: "Phase"
        },{
          key: "standard",
          label: "Standard",
        },{
          key: "team",
          label: "Team",
        }],
        controltime: [{
          key: "phase",
          label: "Phase"
        },{
          key: "todo",
          label: "Mandays to do",
        },{
          key: "done",
          label: "Mandays done",
        }],
        controlnaces: [{
          key: "standard",
          label: "Standard",
        },{
          key: "phase",
          label: "Phase"
        },{
          key: "nacesok",
          label: "Ok",
        },{
          key: "nacesko",
          label: "KO",
        }],
        sites: [{
          key: 'site',
          label: "Sito",
        },{
          key: 'presence',
          label: "Sampled",
          formatter: value => {
            return value === true ? "Yes" : "No";
          }
        },{
          key: 'type',
          label: "Type",
          formatter: (value, key, item) => {
            let arr = [];
            if (item.ismain === true) arr.push("Main");
            if (item.isregistered === true) arr.push("Registered");
            if (item.isyard === true) arr.push("Yard");
            return (item.active === false ? "Not active - " : "") + arr.join(";");
          }
        },{
          key: 'onremote',
          label: "Location",
          formatter: value => {
            return value === true ? "Remote" : "On site";
          }
        },{
          key: 'shifts',
          label: "Shifts",
        },{
          key: 'employeesnumber',
          label: "Employees number",
        },{
          key: 'employeeseffectivenumber',
          label: "Employees effective number",
        },{
          key: 'employeesexternal',
          label: "Employees external",
        },{
          key: 'employeesrepetitive',
          label: "Employees repetitive",
        },{
          key: 'naces',
          label: "NACEs",
        }],
        standardsphases: [{
          key: 'standard',
          label: "Standard",
        },{
          key: 'phase',
          label: 'Phase',
        },{
          key: 'standardspoints',
          label: 'Points',
        }],
        impartialities: [{
          key: 'fullname',
          label: 'Fullname',
        },{
          key: 'date',
          label: 'Date',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'signed',
          label: "Signed",
          formatter: value => {
            return value === null ? "Not necessary" : (value === true ? "Yes" : "No");
          }
        },{
          key: 'decision',
          label: 'Decision',
          formatter: value => {
            return value === null ? "Pending" : (value === true ? "Accepted" : "Not accepted");
          }
        },{
          key: 'decision_date',
          label: 'Date',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'decision_fullname',
          label: 'Decided by',
        }],
        previousaudits: [{
          key: 'phase',
          label: 'Phase',
        },{
          key: 'plandate',
          label: 'Plan',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'standardspoints',
          label: 'Points',
        },{
          key: "reviewuser",
          label: "Reviewer",
        },{
          key: "reviewdate",
          label: "Date",
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "reviewsuccess",
          label: "Review",
          formatter: value => {
            return value === null ? "Pending" : (value === true ? "Accepted" : "Not accepted");
          }
        }],
        reservations: [{
          key: "fromtime",
          label: "Da",
          formatter: value => {
            let d = value.split(":");
            return [ d[0], d[1] ].join(':');
          },
        },{
          key: "totime",
          label: "A",
          formatter: value => {
            let d = value.split(":");
            return [ d[0], d[1] ].join(':');
          },
        },{
          key: "auditstype",
          label: "Tipologia"
        },{
          key: "usersroles",
          label: "Team",
        },{
          key: "standardspoints",
          label: "Punti",
        },{
          key: "site",
          label: "Sede",
        }],
        vetos: [{
          key: "user",
          label: "Veto"
        },{
          key: "role",
          label: "Role",
        },{
          key: "standards",
          label: "Standards",
        }],
        vetosdecision: [{
          key: "user",
          label: "Veto"
        },{
          key: "date",
          label: "Date",
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "decision",
          label: "Decision",
          formatter: value => { 
            return value === null ? "Pending" : (value === true ? "Accepted" : "Not accepted");
          },
        }],
        monitoring: [{
          key: "monitored",
          label: "Monitored"
        },{
          key: "monitoring",
          label: "Monitoring",
        },{
          key: "standards",
          label: "Standards",
        },{
          key: "role",
          label: "Role",
        }],
        evidences: [{
          key: "id",
          label: "Question",
        }],
        strengths: [{
          key: "strength",
          label: "Strength",
        },{
          key: "points",
          label: "Points"
        }],
        opportunities: [{
          key: "opportunity",
          label: "Osservazioni",
        },{
          key: "points",
          label: "Points"
        }],
        opportunitiestobeclosed: [{
          key: "opportunity",
          label: "Finding",
        },{
          key: "points",
          label: "Points"
        }],
        nonconformities: [{
          key: "nonconformity",
          label: "Non conformità",
        },{
          key: "nctype",
          label: "Tipologia",
        },{
          key: "acceptdecision",
          label: "Decisione cliente",
          formatter: value => {
            if (value == null) return null;
            return value === true ? "Accettata" : "Non accettata";
          },
        },{
          key: "acceptnote",
          label: "Decisione note",
        },{
          key: "cause",
          label: "Causa",
        },{
          key: "treatment",
          label: "Trattamento",
        },{
          key: "correctiveaction",
          label: "Azione correttiva",
        },{
          key: "effectivenessdecision",
          label: "Decisione efficacia",
          formatter: value => {
            if (value == null) return null;
            return value === true ? "Verificata" : "Non verificata";
          },
        },{
          key: "effectivenessnote",
          label: "Decisione note",
        },{
          key: "points",
          label: "Points"
        }],
        nonconformitiestobeclosed: [{
          key: "nonconformity",
          label: "Non conformity",
        },{
          key: "nctype",
          label: "Type",
        },{
          key: "causes",
          label: "Causes",
        },{
          key: "treatment",
          label: "Treatment",
        },{
          key: "correctiveaction",
          label: "Corrective action",
        },{
          key: "points",
          label: "Points"
        }],
        vetoresults: [{
          key: "report",
          label: "Report",
          thStyle: { width: "50%" }
        },{
          key: "strengths",
          label: "Stren.",
          thStyle: { width: "8%" }
        },{
          key: "opportunities",
          label: "Find.",
          thStyle: { width: "8%" }
        },{
          key: "nonconformities",
          label: "NC",
          thStyle: { width: "8%" }
        },{
          key: "opportunitiestobeclosed",
          label: "Find. to be closed",
          thStyle: { width: "8%" }
        },{
          key: "nonconformitiestobeclosed",
          label: "NC to be closed",
          thStyle: { width: "8%" }
        },{
          key: "ok",
          label: "Checked",
          thStyle: { width: "10%" }
        }],
        vetoerrors: [{
          key: "errorname",
          label: "Check",
        },{
          key: "standards",
          label: "Standards",
        },{
          key: "status",
          label: "Status",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            return value === true ? "Passed" : "Fail";
          }
        },{
          key: "note",
          label: "Note",
        }],
        decisions1: [{
          key: "phaseen",
          label: "Phase",
          thStyle: { width: "15%" }
        },{
          key: "user1",
          label: "Decided by",
          thStyle: { width: "15%" }
        },{
          key: "decision1",
          label: "Decision",
          thStyle: { width: "20%" }
        },{
          key: "date1",
          label: "Date",
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
          thStyle: { width: "15%" }
        },{
          key: "note1",
          label: "Note",
          thStyle: { width: "35%" }
        }],
        decisions2: [{
          key: "phaseen",
          label: "Phase",
          thStyle: { width: "15%" }
        },{
          key: "user2",
          label: "Decided by",
          thStyle: { width: "15%" }
        },{
          key: "decision2",
          label: "Decision",
          thStyle: { width: "20%" }
        },{
          key: "date2",
          label: "Date",
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
          thStyle: { width: "15%" }
        },{
          key: "note2",
          label: "Note",
          thStyle: { width: "35%" }
        }],
        decisions3: [{
          key: "phaseen",
          label: "Phase",
          thStyle: { width: "15%" }
        },{
          key: "user3",
          label: "Decided by",
          thStyle: { width: "15%" }
        },{
          key: "decision3",
          label: "Decision",
          thStyle: { width: "20%" }
        },{
          key: "date3",
          label: "Date",
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
          thStyle: { width: "15%" }
        },{
          key: "note3",
          label: "Note",
          thStyle: { width: "35%" }
        }],
        mails: [{
          key: 'folder',
          label: "Folder",
          sortable: true
        },{
          key: 'from',
          label: 'From',
        },{
          key: 'to',
          label: 'To',
        },{
          key: 'subject',
          label: "Subject",
        },{
          key: 'datetime',
          label: "Data",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            const today = new Date();
            var d = new Date(value);
            if ( d.getDate() == today.getDate() && d.getMonth() == today.getMonth() && d.getFullYear() == today.getFullYear()){
              return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':');
            } else {
              return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
            }
          },
        }],
        documents: [{
          key: "filename",
          label: "File",
          sortable: true,
        },{
          key: "id",
          label: "",
        }],
        contacts: [{
          key: "company",
          label: "Azienda",
          sortable: true
        },{
          key: "contactname",
          label: "Nome",
          sortable: true
        },{
          key: "contact",
          label: "Mail",
          sortable: true
        },{
          key: "active",
          label: "Stato",
          sortable: true,
          formatter: value => {
            return value === true ? "Attivo" : "Non attivo";
          },
        },{
          key: "job",
          label: "Mansione",
          sortable: true
        },{
          key: "note",
          label: "Note",
          sortable: true
        },{
          key: "areas",
          label: "Aree",
          sortable: true
        },{
          key: "selected",
          label: "",
          sortable: true
        }]
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    rowClassMails(item) {
      if ( item !== null ) return item.seen === false ? "table-primary" : (item.ping === true ? "table-success" : "");
    },
    cleanDate(date){
      if (date == null) return null;
      var d = new Date(date);
      return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map( n => n < 10 ? `0${n}` : `${n}` ).join('-');
    },
    loadData(id){
      if (this.data === null) this.calendar.filterUser = "";
      this.openHttp("audits/audit", { id: id }).then((result) => {
        let date = new Date(result.audit.plandate);
        if (this.calendarMonth === null) this.calendarMonth = date.getMonth() + 1;
        if (this.calendarYear === null) this.calendarYear = date.getFullYear();
        this.f_audits_idSupport = this.convertComboSimple(result.auditsfathers, result.audit.f_audits_id);
        this.languagesSupport = this.convertCombo(result.languages, result.audit.languages);
        this.data = result;
        this.show = true;
        this.loadCalendar();
      })
    },
    asyncFathers ( query ) {
      if ( query.length > 2 ){
        this.openHttp("audits/audit/fathers", { search: query }).then((result) => {
          for ( let item of result ) {
            if ( this.data.auditsfathers.findIndex( x => x.id === item.id ) === -1 ) this.data.auditsfathers.push(item);
          }
          this.data.auditsfathers = this.data.auditsfathers.sort((a,b) => ( a.val > b.val ) ? 1 : 0 );
          this.f_audits_idSupport = this.convertComboSimple(this.data.auditsfathers, this.data.audit.f_audits_id);
        })
      }
    },
    calendarPreviousMonth(){
      let date = new Date(this.calendarYear, this.calendarMonth - 1, 1);
      this.calendarYear = date.getMonth() === 0 ? date.getFullYear() - 1 : date.getFullYear();
      this.calendarMonth = date.getMonth() === 0 ? 12 : date.getMonth();
      this.loadCalendar();
    },
    calendarNextMonth(){
      let date = new Date(this.calendarYear, this.calendarMonth, 1);
      this.calendarYear = date.getFullYear();
      this.calendarMonth = date.getMonth() + 1;
      this.loadCalendar();
    },
    loadCalendar(){
      this.openHttp("audits/audit/calendar", { audits_id: this.data.audit.id, month: this.calendarMonth, year: this.calendarYear }).then((result) => {
        this.calendar = result;
      })
    },
    mailNew(){
      this.openHttp("audits/audit/mail", { id: this.data.audit.id }).then((result) => {
        this.$refs.mailnew.loadData(result);
      })
    },
    mailPlan(){
      this.openHttp("audits/audit/mailplan", { id: this.data.audit.id }).then((result) => {
        this.$refs.mailnew.loadData(result);
      })
    },
    mailClosure(){
      this.openHttp("audits/audit/mailclosure", { id: this.data.audit.id }).then((result) => {
        this.$refs.mailnew.loadData(result);
      })
    },
    externalLink(){
      window.open("/#/externalaudit/" + this.data.token, '_blank');
    },
    saveData(){
      this.saveHttp("audits/audit/save", this.data.audit).then((result) => {
        if (result !== null) this.loadData(this.data.audit.id);
      })
    },
    deleteData(){
      this.deleteHttp("audits/audit/delete", { id: this.data.audit.id }).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    closeForm(){
      this.calendarMonth = null;
      this.calendarYear = null;
      this.show = false;
      this.data = null;
      this.$emit('update');
    }
  }
}
</script>

<template>
  <div>
    <training ref="training" @update="loadData()"/>
    <document ref="document" @update="loadData()"/>
    <trainingtype ref="type" @update="loadData()"/>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Trainings" active>
          <b-modal v-model="trainings.showFilter" title="Filters">
            <b-form-group label="From">
              <b-form-input type="date" v-model="trainings.tempFilter.fromdate"/>
            </b-form-group>
            <b-form-group label="To">
              <b-form-input type="date" v-model="trainings.tempFilter.todate"/>
            </b-form-group>
            <b-form-group label="Type">
              <b-form-select v-model="trainings.tempFilter.trainingstypes_id" :options="addNullCombo(trainings.filtersMenu.trainingstypes)" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Participant">
              <b-form-select v-model="trainings.tempFilter.users_id" :options="addNullCombo(trainings.filtersMenu.users)" value-field="id" text-field="val"/>
            </b-form-group>
            <template v-slot:modal-footer>
              <div class="w-100">
                <b-btn variant="primary" class="mr-2" @click="filterSetTrainings">Filter</b-btn>
                <b-btn variant="warning" @click="filterResetTrainings">Reset</b-btn>
              </div>
            </template>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadTrainings">Refresh</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="filterOpenTrainings">Filter</b-btn>
          <b-btn class="mr-2" variant="success" @click="$refs.training.loadData(0)">New</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="trainings.items.data" :fields="trainings.fields" :busy="trainings.isBusy" :no-local-sorting="true" :sort-by.sync="trainings.search.sortby" :sort-desc.sync="trainings.search.sortdesc" @sort-changed="sortingChangeTrainings" @row-clicked="$refs.training.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>There are no results</template>
            <template v-slot:cell(users)="row">
              <font :color="item.signature === true ? 'green' : 'red'" v-for="(item, index) of row.value" :key="index">{{ item.fullname + " " + item.percentage + "%" }} </font>
            </template>
            <template v-slot:cell(documents_id)="row">
              <b-btn v-show="row.value > 0" variant="primary" size="sm" @click="$refs.document.downloadData(row.value)">Download</b-btn>
            </template>
          </b-table>
          <tablebottom :currentpage="trainings.search.page" @updateCurrentPage="trainings.search.page = $event" :total="trainings.items.total" @loadData="loadTrainings()"/>
        </b-tab>
        <b-tab title="Missing" v-if="level >= 2">
          <b-btn variant="primary" @click="loadMissing()">Refresh</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 275) + 'px'" responsive :items="missing.items" class="mt-3" :fields="missing.fields">
            <template v-slot:cell(plan)="row">
              <b-btn variant="warning" size="sm" @click="planMissing(row.item.plan_date, row.item.trainingtypes_ids, row.item.users_ids)">Plan</b-btn>
            </template>
          </b-table>
        </b-tab>
        <b-tab title="Types" v-if="level >= 2">
          <b-modal v-model="types.showFilter" title="Filters">
            <b-form-group label="Status">
              <b-form-select v-model="types.tempFilter.active" :options="addNullCombo(types.filtersMenu.activeCombo)" value-field="id" text-field="val"/>
            </b-form-group>
            <template v-slot:modal-footer>
              <div class="w-100">
                <b-btn variant="primary" class="mr-2" @click="filterSetTypes()">Filter</b-btn>
                <b-btn variant="warning" @click="filterResetTypes()">Reset</b-btn>
              </div>
            </template>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadTypes()">Refresh</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="types.showFilter = true">Filter</b-btn>
          <b-btn class="mr-2" variant="success" @click="$refs.type.loadData(0)">New</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="types.items.data" :fields="types.fields" :busy="types.isBusy" :no-local-sorting="true" :sort-by.sync="types.search.sortby" :sort-desc.sync="types.search.sortdesc" @sort-changed="sortingChangeTypes" @row-clicked="$refs.type.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>There are no results</template>
            <template v-slot:cell(jobdescriptions)="row">
              <div v-for="(item, index) of row.value" :key="index">{{ item }}</div>
            </template>
          </b-table>
          <tablebottom :currentpage="types.search.page" @updateCurrentPage="types.search.page = $event" :total="types.items.total" @loadData="loadTypes"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import training from './training.vue'
import document from './document.vue'
import trainingtype from './trainingtype.vue'

export default {
  name: "trainings",
  components: { training, document, trainingtype },
  data() {
    return {
      trainings: {
        isBusy: true,
        showFilter: false,
        search:{
          page: 1,
          filters: {
            trainingstypes_id: null,
            fromdate: null,
            todate: null,
            users_id: null,
            mine: true,
          },
          sortby: "fromdate",
          sortdesc: true,
        },
        tempFilter: {
          trainingstypes_id: null,
          fromdate: null,
          todate: null,
          users_id: null,
        },
        filtersMenu: {
          trainingstypes: [],
          certificationbodies: [],
          users: [],
        },
        fields: [{
          key: 'fromdate',
          label: 'From',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
          sortable: true
        },{
          key: 'todate',
          label: 'To',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
          sortable: true
        },{
          key: "trainingstypes",
          label: "Types"
        },{
          key: 'teachers',
          label: 'Teachers',
        },{
          key: 'users',
          label: 'Participants',
        },{
          key: "documents_id",
          label: ""
        }],
        items: {
          total: 0,
          data: [],
          records_id: null,
          records_date: null,
        },
      },
      missing: {
        fields: [{
          key: 'fullname',
          label: "Fullname",
        },{
          key: 'trainingtype',
          label: "Training",
        }],
        items: [],
      },
      types: {
        isBusy: true,
        showFilter: false,
        search:{
          page: 1,
          filters: {
            active: true
          },
          sortby: 'training',
          sortdesc: false,
        },
        tempFilter: {
          active: true,
        },
        filtersMenu: {
          activeCombo: [{
            id: true,
            val: "Active"
          },{
            id: false,
            val: "Not Active"
          }]
        },
        fields: [{
          key: 'trainingtype',
          label: "Training type",
          sortable: true
        },{
          key: 'note',
          label: 'Note',
        },{
          key: 'fromdate',
          label: 'From',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
          sortable: true
        },{
          key: 'todate',
          label: 'To',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
          sortable: true
        },{
          key: 'jobdescriptions',
          label: "Jobdescriptions"
        },{
          key: "documentscount",
          label: "Documents",
          sortable: true
        },{
          key: "questionscount",
          label: "Questions",
          sortable: true
        }],
        items: {
          total: 0,
          data: [],
        },
      }
    }  
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
    level(){
      return this.$store.getters.getRoutes.find(x => x.route === "trainings").level;
    }
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.loadTrainings();
      if (this.level >= 2) this.loadMissing();
      if (this.level >= 2) this.loadTypes();
    },
    loadTrainings(){
      this.openHttp("trainings/", this.trainings.search).then((result) => {
        this.trainings.items = result;
        this.trainings.isBusy = false;
      })
    },
    filterOpenTrainings(){
      this.openHttp("trainings/filters", null).then((result) => {
        this.trainings.filtersMenu = result;
        this.trainings.showFilter = true;
      })
    },
    filterSetTrainings(){
      this.trainings.search.page = 1;
      this.trainings.search.filters = this.trainings.tempFilter;
      this.trainings.showFilter = false;
      this.loadTrainings();
    },
    filterResetTrainings(){
      this.trainings.tempFilter = {
        trainingstypes_id: null,
        certificationbodies_id: 133,
        fromdate: null,
        todate: null,
        users_id: null,
      };
      this.filterSetTrainings();
    },
    sortingChangeTrainings(ctx) {
      this.trainings.search.sortby = ctx.sortBy;
      this.trainings.search.sortdesc = ctx.sortDesc;
      this.loadTrainings();
    },
    loadMissing(){
      this.openHttp("trainings/missing", {}).then((result) => {
        this.missing.items = result;
      })
    },
    planMissing(date, typesIds, usersIds){

      this.openHttp("trainings/missingplan", {date: date, trainingstypes: typesIds, users: usersIds}).then((result) => {
        this.loadData();
        this.$refs.training.loadData(result);
      })
    },
    loadTypes(){
      this.openHttp("trainings/types", this.types.search).then((result) => {
        this.types.items = result;
        this.types.isBusy = false;
      })
    },
    filterSetTypes(){
      this.types.search.page = 1;
      this.types.search.filters = this.types.tempFilter;
      this.types.showFilter = false;
      this.loadTypes();
    },
    filterResetTypes(){
      this.types.tempFilter = {
        active: true
      };
      this.filterSetTypes();
    },
    sortingChangeTypes(ctx) {
      this.types.search.sortby = ctx.sortBy;
      this.types.search.sortdesc = ctx.sortDesc;
      this.loadTypes();
    }
  }
}
</script>
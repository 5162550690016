<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Monitoring" scrollable @hidden="closeForm()" @close="closeForm()" hide-footer>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Audit" active>
            <b-row>
              <b-col lg="2">
                <b-form-group label="Date">
                  <b-form-input type="date" :value="data.monitoring.date"/>
                </b-form-group>
              </b-col>
              <b-col lg="8">
                <b-form-group label="Company">
                  <b-form-input :value="data.monitoring.company"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Monitored">
                  <b-form-input :value="data.monitoring.fullname"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Role">
                  <b-form-input :value="data.monitoring.usersrole"/>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group label="Standards">
                  <b-form-input :value="data.monitoring.standards"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3">
                <b-form-group label="Signature Monitored" v-if="data.monitoring.signatures1_id > 0">
                  <b-btn :disabled="data.monitoring.passed == null" :variant="data.appointment.signed === true ? 'success' : 'danger'" @click="$refs.signature.loadData(data.appointment.signatures1_id)">{{ data.appointment.signature1_check === true ? 'Signed' : 'Not signed' }}</b-btn>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Signauture Monitoring" v-if="data.monitoring.signatures2_id > 0">
                  <b-btn :disabled="data.monitoring.passed == null" :variant="data.appointment.signed === true ? 'success' : 'danger'" @click="$refs.signature.loadData(data.appointment.signatures1_id)">{{ data.appointment.signature2_check === true ? 'Signed' : 'Not signed' }}</b-btn>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Questions">
            <b-table no-border-collapse :sticky-header="(winHeight - 405) + 'px'" responsive :items="data.questions" :fields="fields">
              <template v-slot:cell(rating)="row">
                <div v-if="row.item.evaluation === null">
                  <b-form-rating v-model="row.item.rating" stars="4" :disabled="data.monitoring.passed !== null" @change="saveRating(row.item.id, $event)"/>
                </div>
                <div v-else>
                  <nobr>
                    <b-btn :variant="row.item.evaluation === true ? 'success' : ''" size="sm" :disabled="data.monitoring.passed !== null" class="mr-2" @click="saveEvaluation(row.item.id, true, row.item.note)">Yes</b-btn>
                    <b-btn :variant="row.item.evaluation === false ? 'danger' : ''" size="sm" :disabled="data.monitoring.passed !== null" @click="saveEvaluation(row.item.id, false, row.item.note)">No</b-btn>
                  </nobr>
                  <b-form-group label="Note" v-show="row.item.evaluation === false">
                    <b-form-textarea v-model="row.item.note" rows="2" :disabled="data.monitoring.passed !== null" @click="saveEvaluation(row.item.id, false, row.item.note)"/>
                  </b-form-group>
                </div>
              </template>
            </b-table>
            <b-row>
              <b-col lg="9">
                <b-form-group label="Note (complete before mark passed / not passed)">
                  <b-form-textarea v-model="data.monitoring.note" @change="savePassed(data.monitoring.passed, $event)" :disabled="data.monitoring.passed !== null"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Final evaluation">
                  <nobr>
                    <b-btn :disabled="data.monitoring.passed !== null || data.questions.filter(x => x.rating === null && x.evaluation === null).length > 0" :variant="data.monitoring.passed === true ? 'success' : ''" class="mr-2" @click="savePassed(true, data.monitoring.note)">Passed</b-btn>
                    <b-btn :disabled="data.monitoring.passed !== null || data.questions.filter(x => x.rating === null && x.evaluation === null).length > 0" :variant="data.monitoring.passed === false ? 'danger' : ''" @click="savePassed(false, data.monitoring.note)">Not passed</b-btn>
                  </nobr>
                </b-form-group>
              </b-col>          
            </b-row>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "checklistmonitoring",
  data() {
    return {
      show: false,
      data: null,
      fields: [{
        key: 'question',
        label: "Question",
      },{
        key: 'rating',
        label: "Rating",
      }]
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    }
  },
  methods: {
    loadData( id ){
      this.openHttp("checklists/monitoring", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    savePassed(passed, note){
      this.saveHttp("checklists/monitoring/passedsave", { monitoring_id: this.data.monitoring.id, passed: passed, note: note }).then((result) => {
        if ( result !== true ) this.loadData(result);
      })
    },
    saveRating(questionsId, rating){
      this.saveHttp("checklists/monitoring/ratingsave", { monitoring_id: this.data.monitoring.id, questions_id: questionsId, rating: rating }).then((result) => {
        if ( result !== true ) this.loadData(result);
      })
    },
    saveEvaluation(questionsId, evaluation, note){
      this.saveHttp("checklists/monitoring/evaluationsave", { monitoring_id: this.data.monitoring.id, questions_id: questionsId, evaluation: evaluation, note: note }).then((result) => {
        if ( result !== true ) this.loadData(result);
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>
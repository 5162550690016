<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" title="Worker" @hidden="closeForm()" @close="closeForm()">
      <b-form-group label="Title">
        <b-form-select v-model="data.worker.titles_id" :options="data.titles" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Firstname">
        <b-form-input v-model="data.worker.firstname"/>
      </b-form-group>
      <b-form-group label="Lastname">
        <b-form-input v-model="data.worker.lastname"/>
      </b-form-group>
      <b-form-group label="Status">
        <b-button-group>
          <b-btn :variant="data.worker.active === true ? 'success' : ''" @click="data.worker.active = true">Active</b-btn>
          <b-btn :variant="data.worker.active === true ? '' : 'danger'" @click="data.worker.active = false">Not active</b-btn>
        </b-button-group>
      </b-form-group>
      <b-form-group label="Type">
        <b-button-group>
          <b-btn :variant="data.worker.internal === true ? 'primary' : ''" @click="data.worker.internal = true">Internal</b-btn>
          <b-btn :variant="data.worker.internal === true ? '' : 'primary'" @click="data.worker.internal = false">External</b-btn>
        </b-button-group>
      </b-form-group>
      <b-form-group label="Job">
        <b-form-input v-model="data.worker.job"/>
      </b-form-group>
      <b-form-group label="Areas">
        <multiselect v-model="rolestypesSupport" :options="data.workersrolestypes" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.worker.rolestypes = updateCombo(rolestypesSupport)"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Save</b-btn>
        </div>
      </template>           
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "checklistworker",
  data() {
    return {
      rolestypesSupport: [],
      show: false,
      data: null
    }
  },
  methods: {
    loadData( id, companiesId, auditsId ){
      this.openHttp("checklists/worker", { id: id, companies_id: companiesId, audits_id: auditsId }).then((result) => {
        this.rolestypesSupport = this.convertCombo(result.workersrolestypes, result.worker.rolestypes);        
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("checklists/worker/save", this.data.worker).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>
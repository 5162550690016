<template>
  <div>
    <curriculum ref="curriculum" @update="loadData()"/>
    <document ref="document" @update="loadData()"/>
    <b-modal v-model="showFilter" title="Filter">
      <b-form-group label="Person">
        <b-form-select v-model="tempFilter.users_id" :options="addNullCombo(filtersMenu.users)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Status">
        <b-form-select v-model="tempFilter.active" :options="addNullCombo(activeCombo)" value-field="id" text-field="val"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="filterSet">Filter</b-btn>
          <b-btn variant="warning" @click="filterReset">Reset</b-btn>
        </div>
      </template>
    </b-modal>
    <b-btn class="mr-2" variant="primary" @click="loadData()">Refresh</b-btn>
    <b-btn variant="secondary" @click="filterOpen()" v-if="level >= 2">Filter</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="computedData" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sortby" :sort-desc.sync="search.sortdesc" @sort-changed="sortingChange" @row-clicked="$refs.curriculum.loadData($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>There are no results</template>
      <template v-slot:cell(signature1)="row">
        <b-btn variant="primary" size="sm" v-if="row.item.document1 > 0" @click="$refs.document.downloadFile(row.item.document1)">Download</b-btn>
      </template>
      <template v-slot:cell(signature2)="row">
        <b-btn variant="primary" size="sm" v-if="row.item.document2 > 0" @click="$refs.document.downloadFile(row.item.document2)">Download</b-btn>
      </template>
    </b-table>
    <tablebottom :busy="isBusy" :currentpage="search.page" :total="items.total" @updateCurrentPage="search.page = $event" @loadData="loadData"/>
  </div>
</template>

<script>
import curriculum from './curriculum.vue';
import document from './document.vue';

export default {
  name: "curricula",
  components : { curriculum, document },
  data() {
    return {
      isBusy: true,
      showFilter: false,
      activeCombo: [{
        id: true,
        val: "Active"
      },{
        id: false,
        val: "Not Active"
      }],
      search: {
        page: 1,
        filters: {
          users_id: null,
          active: true,
        },
        sortby: 'lastname',
        sortdesc: false,
      },
      tempFilter: {
        users_id: null,
        active: true,
      },
      filtersMenu: {
        users: [],
      },
      fields: [{
        key: 'fullname',
        label: 'Fullname',
        sortable: true
      },{
        key: 'begindate',
        label: 'Begin',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'endate',
        label: 'End',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: "certificatescount",
        label: "Certificates"
      },{
        key: "experiencescount",
        label: "Experiences"
      },{
        key: "educationcount",
        label: "Education"
      },{
        key: "consultanciescount",
        label: "Consultancies"
      },{
        key: "auditlogscount133",
        label: "Audit logs"
      },{
        key: "signature1",
        label: "Impartiality"
      },{
        key: "signature2",
        label: "Contract"
      }],
      items: {
        total: 0,
        data: [],
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
    level(){
      return this.$store.getters.getRoutes.find(x => x.route === "curricula").level;
    },
    computedData(){
      let tempItems = this.items.data;
      for ( let item of tempItems ){
        item._cellVariants = {
          signature1: item.signature1 == true ? 'success' : 'danger',
          signature2: item.signature2 == true ? 'success' : 'danger'
        }
      }
      return tempItems;
    }
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.isBusy = true;
      this.openHttp("curricula", this.search).then((result) => {
        this.items = result;
        this.isBusy = false;
      })
    },
    filterOpen(){
      this.openHttp("curricula/filters", null).then((result) => {
        this.filtersMenu = result;
        this.showFilter = true;
      })
    },
    filterSet(){
      this.showFilter = false;
      this.search.page = 1;
      this.search.filters = this.tempFilter;
      this.loadData();
    },
    filterReset(){
      this.tempFilter = {
        user: null,
        active: true,
      };
      this.filterSet();
    },
    sortingChange(ctx) {
      this.search.sortby = ctx.sortBy;
      this.search.sortdesc = ctx.sortDesc;
      this.loadData();
    }
  }
}
</script>
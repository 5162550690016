<template>
  <div>
    <b-modal v-model="show" v-if="data !== null" title="Site" @hidden="closeForm()" @close="closeForm()">
      <b-form-group label="Address">
        <b-form-input v-model="data.site.address"/>
      </b-form-group>
      <b-form-group label="City">
        <multiselect v-model="cities_idSupport" :options="data.cities" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.site.cities_id = updateComboSimple(cities_idSupport)"/>
      </b-form-group>
      <b-form-group label="Status">
        <b-form-select v-model="data.site.active" :options="comboActive" value-field="id" text-field="val"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData()">Save</b-btn>
          <b-btn variant="danger" v-show="data.site.id > 0" @click="deleteData()">Delete</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "companysite",
  data() {
    return {
      show: false,
      data: null,
      cities_idSupport: [],
      comboActive: [{
        id: true,
        val: "Active"
      },{
        id: false,
        val: "Not active"
      },]
    }
  },
  methods: {
    loadData( id, companies_id ){
      this.openHttp("companies/site", { id: id, companies_id: companies_id }).then((result) => {
        this.cities_idSupport = this.convertComboSimple( result.cities, result.site.cities_id );
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("companies/site/save", this.data.site).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("companies/site/delete", { id: this.data.site.id }).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    }
  },
}
</script>
<template>
  <div>
    <mailnew ref="mailnew"/>
    <b-modal v-if="data" v-model="show" size="xl" :title="'Mail: ' + data.mail.subject" scrollable @hide="closeForm" @close="closeForm">
      <mailarchive ref="archive" @update="loadData(data.mail.id)"/>
      <form ref="downloadMail" method="POST" target="_blank" action="file.php">
        <input type="hidden" name="token" :value="token">
        <input type="hidden" name="action" value="mailmail">
        <input type="hidden" name="document" :value="data.mail.id">
      </form>
      <form ref="downloadAttachment" method="POST" target="_blank" action="file.php">
        <input type="hidden" name="token" :value="token">
        <input type="hidden" name="action" value="mailattachment">
        <input type="hidden" name="document" :value="data.mail.id">
        <input type="hidden" name="file" ref="attachmentfile">
      </form>
      <b-row>
        <b-col lg="8">
          <div>
            <b>Mailbox:</b> {{ data.mail.folder }}
          </div>
          <div>
            <b>From:</b> {{ data.mail.from }}
          </div>
          <div>
            <b>To:</b> <a>{{ data.mail.to.join(", ") }}</a>
          </div>
          <div v-if="data.mail.cc.length > 0">
            <b>CC:</b> <a>{{ data.mail.cc.join(", ") }}</a>
          </div>
          <div v-if="data.mail.bcc.length > 0">
            <b>BCC:</b> <a>{{ data.mail.bcc.join(", ") }}</a>
          </div>
          <div>
            <b>Subject: </b> {{ data.mail.subject }}
          </div>
        </b-col>
        <b-col lg="4" style="text-align: right;">
          <b>Date and time: </b> {{ hourtime }}
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col lg="12">
          <div class="mb-1" style="background-color:white; overflow:auto;  border-style: solid; border-width: 1px; border-color: gainsboro; padding: 10px;" :style="{ 'min-height': ( winHeight - 400) + 'px', 'max-height': ( winHeight - 400) + 'px' }">
            <div v-html="data.mail.body"></div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b>Attachments: </b>
          <a v-for="(item, index) in data.mail.attachments" :key="index" class="mr-2" @click="downloadAttachment(item)">{{ item }}</a>
          <span v-show="data.mail.attachments.length === 0">There are no attachments</span>
        </b-col>
      </b-row>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="replyMail">Reply</b-btn>
          <b-btn variant="primary" class="mr-2" @click="forwardMail">Forward</b-btn>
          <b-btn variant="primary" class="mr-2" @click="downloadMail">Download</b-btn>
          <b-btn variant="secondary" v-if="[1, 2].includes(data.mail.mailboxesfolders_id_original)" class="mr-2" @click="notSeen">Not seen</b-btn>
          <b-btn variant="secondary" class="mr-2" v-if="data.mail.id > 0" @click="loadArchive">Archive</b-btn>
          <b-btn variant="warning" class="mr-2" v-if="data.mail.mailboxesfolders_id_original === 1 && data.mail.mailboxesfolders_id === 1" @click="setSpam">Spam</b-btn>
          <b-btn variant="warning" class="mr-2" v-if="data.mail.mailboxesfolders_id_original === 1 && data.mail.mailboxesfolders_id === 2" @click="unsetSpam">Restore Spam</b-btn>
          <b-btn variant="danger" class="mr-2" v-if="data.mail.mailboxesfolders_id === 4" @click="recoverData">Restore</b-btn>
          <b-btn variant="danger" @click="deleteData">Delete</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import mailarchive from './mailscomponents/mailarchive.vue';

export default {
  name: "mail",
  components: { 
    mailarchive,
  },
  data() {
    return {
      show: false,
      data: null,
    }
  },
  computed: {
    token (){
      return this.$store.getters.getToken;
    },
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
    hourtime (){
      var d = new Date( this.data.mail.datetime );
      const today = new Date();
      if ( d.getDate() == today.getDate() && d.getMonth() == today.getMonth() && d.getFullYear() == today.getFullYear()){
        return 'Today, ' + [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':');
      } else {
        return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':') + " " + [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
      }
    },
  },
  methods: {
    loadData( id ){
      if ( this.data === null ) this.show = false;
      this.openHttp("mails/mail", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    replyMail(){
      this.$refs.mailnew.loadData({
        id: 0,
        mailboxes_id: this.data.mail.mailboxes_id,
        to: this.data.mail.from,
        cc: this.data.mail.cc.join(","),
        bcc: this.data.mail.bcc.join(","),
        subject: 'R: ' + this.data.mail.subject,
        subjectOriginal: 'R: ' + this.data.mail.subject,
        body: '<!--template--><hr><b>From: </b>' + this.data.mail.from + '<br><b>To: </b>' + this.data.mail.to.join(",") + (this.data.mail.cc.length > 0 ? ( '<br><b>CC: </b>' + this.data.mail.cc.join(",") ) : '') + (this.data.mail.bcc.length > 0 ? ( '<br><b>BCC: </b>' + this.data.mail.bcc.join(",") ) : '') + '<br><b>Date and time: </b>' + this.hourtime + '<br><b>Subject: </b>' + this.data.mail.subject + '<br><br>' + this.data.mail.body,
        bodyOriginal: '<!--template--><hr><b>From: </b>' + this.data.mail.from + '<br><b>To: </b>' + this.data.mail.to.join(",") + (this.data.mail.cc.length > 0 ? ( '<br><b>CC: </b>' + this.data.mail.cc.join(",") ) : '') + (this.data.mail.bcc.length > 0 ? ( '<br><b>BCC: </b>' + this.data.mail.bcc.join(",") ) : '') + '<br><b>Date and time: </b>' + this.hourtime + '<br><b>Subject: </b>' + this.data.mail.subject + '<br><br>' + this.data.mail.body,
        companies_id: this.data.mail.companies_id,
        invoices_id: this.data.mail.invoices_id,
        orders_id: this.data.mail.orders_id,
        quotes_id: this.data.mail.quotes_id,
        contracts_id: this.data.mail.contracts_id,
        audits_id: this.data.mail.audits_id
      });
    },
    forwardMail(){
      this.openHttp("mails_forward_prepare", { id: this.data.mail.id }).then((result) => {
        if ( result.result == true ){
          this.$refs.mailnew.loadData({
            id: result.id,
            mailboxes_id: this.data.mail.mailboxes_id,
            to: "",
            subject: 'F: ' + this.data.mail.subject,
            subjectOriginal: 'F: ' + this.data.mail.subject,
            body: '<!--template--><hr><b>From: </b>' + this.data.mail.from + '<br><b>To: </b>' + this.data.mail.to.join(",") + (this.data.mail.cc.length > 0 ? ( '<br><b>CC: </b>' + this.data.mail.cc.join(",") ) : '') + (this.data.mail.bcc.length > 0 ? ( '<br><b>BCC: </b>' + this.data.mail.bcc.join(",") ) : '') + '<br><b>Date and time: </b>' + this.hourtime + '<br><b>Subject: </b>' + this.data.mail.subject + '<br><br>' + this.data.mail.body,
            bodyOriginal: '<!--template--><hr><b>From: </b>' + this.data.mail.from + '<br><b>To: </b>' + this.data.mail.to.join(",") + (this.data.mail.cc.length > 0 ? ( '<br><b>CC: </b>' + this.data.mail.cc.join(",") ) : '') + (this.data.mail.bcc.length > 0 ? ( '<br><b>BCC: </b>' + this.data.mail.bcc.join(",") ) : '') + '<br><b>Date and time </b>' + this.hourtime + '<br><b>Subject: </b>' + this.data.mail.subject + '<br><br>' + this.data.mail.body,
            companies_id: this.data.mail.companies_id,
            invoices_id: this.data.mail.invoices_id,
            orders_id: this.data.mail.orders_id,
            quotes_id: this.data.mail.quotes_id,
            contracts_id: this.data.mail.contracts_id,
            audits_id: this.data.mail.audits_id
          });
        }
      })
    },
    downloadMail(){
      this.$refs.downloadMail.submit();
    },
    notSeen(){
      this.saveHttp("mails/mail/notseen", { id: this.data.mail.id }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    loadArchive(){
      this.$refs.archive.loadData( this.data.mail.id );
    },
    setSpam(){
      this.saveHttp("mails/mail/spamset", { id: this.data.mail.id }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    unsetSpam(){
      this.saveHttp("mails/mail/spamunset", { id: this.data.mail.id }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("mails/mail/delete", { id: this.data.mail.id }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    recoverData(){
      this.saveHttp("mails/mail/recover", { id: this.data.mail.id }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    downloadAttachment(filename){
      this.$refs.attachmentfile.value = filename;
      this.$refs.downloadAttachment.submit();
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>
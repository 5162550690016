
<template>
  <div>
    <nonconformity ref="nonconformity" @update="loadData"/>
    <b-modal v-if="showFilter" v-model="showFilter" title="Filters">
      <b-form-group label="Non conformity">
        <b-form-input v-model="tempFilter.nonconformity"/>
      </b-form-group>
      <b-form-group label="Status">
        <multiselect v-model="nonconformitiesstatusSupport" :options="filtersMenu.nonconformitiesstatus" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="tempFilter.nonconformitiesstatus_id = updateCombo(nonconformitiesstatusSupport)"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="filterSet">Filter</b-btn>
          <b-btn variant="warning" @click="filterReset">Reset</b-btn>
        </div>
      </template>
    </b-modal>
    <b-btn class="mr-2" variant="primary" @click="loadData">Refresh</b-btn>
    <b-btn class="mr-2" variant="secondary" @click="filterOpen">Filter</b-btn>
    <b-btn class="mr-2" variant="success" @click="$refs.nonconformity.loadData(0, null)">New</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 210) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="items.data" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sortby" :sort-desc.sync="search.sortdesc" @sort-changed="sortingChange" @row-clicked="$refs.nonconformity.loadData($event.id, null)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>There are no results</template>
      <template v-slot:cell(documents)="row">
        <div v-for="(item, index) of row.value" :key="index"><nobr>{{ item.filename }}</nobr></div>
      </template>
    </b-table>
    <tablebottom :busy="isBusy" :currentpage="search.page" @updateCurrentPage="search.page = $event" :total="items.total" @loadData="loadData"/>
  </div>
</template>

<script>
import nonconformity from './nonconformity.vue'

export default {
  name: "nonconformities",
  components: { nonconformity },
  data() {
    return {
      isBusy: true,
      showFilter: false,
      search:{
        page: 1,
        showFilter: false,
        filters: {
          nonconformity: null,
          nonconformitiesstatus_id : []
        },
        sortby: 'rownum',
        sortdesc: true,
      },
      tempFilter: {
        nonconformity: null,
        nonconformitiesstatus_id : []
      },
      nonconformitiesstatusSupport: [],
      fields: [{
        key: 'rownum',
        label: "#",
        sortable: true
      },{
        key: 'nonconformity',
        label: 'Non conformity',
      },{
        key: 'causes',
        label: 'Causes',
      },{
        key: 'nonconformitystatus',
        label: 'Status',
        sortable: true
      },{
        key: 'opendate',
        label: 'Open date',
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'treatment',
        label: 'Treatment',
      },{
        key: 'correctiveaction',
        label: 'Corrective action',
      },{
        key: 'closedate',
        label: 'Close date',
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'effectiveness',
        label: 'Effectiveness',
        formatter: value => {
          return (value === null ? 'Pending' : ( value === true ? 'Positive' : 'Negative' ));
        }
      },{
        key: 'documents',
        label: 'Documents',
      }],
      items: {
        total: 0,
        data: [],
      },
    }  
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.isBusy = true;
      this.openHttp("nonconformities/", this.search).then((result) => {
        this.items = result;
        this.isBusy = false;
      })
    },
    filterOpen(){
      this.openHttp("nonconformities/filters", null).then((result) => {
        this.filtersMenu = result;
        this.nonconformitiesstatusSupport = this.convertCombo(this.filtersMenu.nonconformitiesstatus, this.tempFilter.nonconformitiesstatus_id);
        this.showFilter = true;
      })
    },
    filterSet(){
      this.showFilter = false;
      this.nonconformitiesstatusSupport = this.convertCombo(this.filtersMenu.nonconformitiesstatus, this.tempFilter.nonconformitiesstatus_id);
      this.search.filters = this.tempFilter;
      this.loadData();
    },
    filterReset(){
      this.tempFilter = {
        nonconformity: null,
        nonconformitiesstatus_id : []
      };      
      this.filterSet();
    },
    sortingChange(ctx) {
      this.search.sortby = ctx.sortBy;
      this.search.sortdesc = ctx.sortDesc;
      this.loadData();
    }
  }
}
</script>
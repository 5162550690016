<template>
    <div>
        <form method="post" action="/api/documentsconsultation/download" ref="downloadFormSubmit" target="_blank">
            <input type="hidden" name="token" :value="token">
            <input type="hidden" ref="downloadFormId" name="id">
        </form>
    </div>
</template>

<script>
export default {
    name: "document",
    computed: {
        token (){
            return this.$store.getters.getToken;
        }
    },
    methods: {
        downloadData(id){
            this.$refs.downloadFormId.value = id;
            this.$refs.downloadFormSubmit.submit();
        }
    }
}
</script>
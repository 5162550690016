<template>
  <div>
    <appointment ref="appointment" @update="loadData()"/>
    <document ref="document" @update="loadData()"/>
    <b-modal v-model="appointments.showFilter" title="Filter">
      <b-form-group label="Status">
        <b-form-select v-model="appointments.tempFilter.type" :options="addNullCombo(appointments.typeCombo)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Jobdescription">
        <b-form-select v-model="appointments.tempFilter.jobs_id" :options="addNullCombo(appointments.filtersMenu.jobs)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="User">
        <b-form-select v-model="appointments.tempFilter.users_id" :options="addNullCombo(appointments.filtersMenu.users)" value-field="id" text-field="val"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="filterSetAppointments">Filter</b-btn>
          <b-btn variant="warning" @click="filterResetAppointments">Reset</b-btn>
        </div>
      </template>
    </b-modal>
    <b-btn variant="primary" class="mr-2" @click="loadDataAppointments()">Refresh</b-btn>
    <b-btn variant="secondary" class="mr-2" @click="filterOpenAppointments()">Filter</b-btn>
    <b-btn variant="success" class="mr-2" @click="$refs.appointment.loadData(0)">New</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="computedAppointments" :fields="appointments.fields" :busy="appointments.isBusy" :no-local-sorting="true" :sort-by.sync="appointments.search.sortby" :sort-desc.sync="appointments.search.sortdesc" @sort-changed="sortingChangeAppointments" @row-clicked="$refs.appointment.loadData($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:cell(jobs)="row">
        <div v-for="(item, index) of row.value" :key="index">{{ item }}</div>
      </template>
      <template v-slot:empty>There are no results</template>
      <template v-slot:cell(document1)="row">
        <b-btn variant="primary" size="sm" v-if="row.value > 0" @click="$refs.document.downloadFile(row.value)">Download</b-btn>
      </template>
      <template v-slot:cell(document2)="row">
        <b-btn variant="primary" size="sm" v-if="row.value > 0" @click="$refs.document.downloadFile(row.value)">Download</b-btn>
      </template>
    </b-table>
    <tablebottom :currentpage="appointments.search.page" @updateCurrentPage="appointments.search.page = $event" :total="appointments.items.total" @loadData="loadDataAppointments"/>
  </div>
</template>

<script>
import appointment from './appointment.vue';
import document from './document.vue';

export default {
  name: "jobsappointments",
  components: { appointment, document },
  data() {
    return {
      appointments: {
        showFilter: false,
        fields: [{
          key: 'fullname',
          label: 'Fullname',
          sortable: true,
        },{
          key: "jobs",
          label: "Jobs",
        },{
          key: "office",
          label: "Office",
        },{
          key: "percentage",
          label: "Requirements",
          sortable: true,
          formatter: value => {
            return value + "%";
          }
        },{
          key: 'creation_date',
          label: 'Proposal',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "approval_date",
          label: 'Approval',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "document1",
          label: "Appointment",
        },{
          key: "cancel_date",
          label: "Cancellation",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "document2",
          label: "Dismission",
        }],
        isBusy: true,
        items: {
          total: 0,
          data: [],
        },
        search:{
          page: 1,
          filters: {
            users_id: null,
            job: null,
            type: 2,
          },
          sortby: 'fullname',
          sortdesc: false,
        },
        tempFilter: {
          users_id: null,
          jobs_id: null,
          type: 2,
        },
        filtersMenu: {
          users: [],
          jobs: [],
        },
        typeCombo: [{
          id: 1,
          val: "Proposed"
        },{
          id: 2,
          val: "Approved"
        },{
          id: 3,
          val: "Cancelled"
        }]
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
    computedAppointments(){
      let tempItems = this.appointments.items.data;
      for ( let item of tempItems ){
        item._cellVariants = {
          creation_date: item.creation_date === null ? '' : (item.signature1 === true ? 'success' : 'danger'),
          approval_date: item.approval_date === null ? '' : (item.signature2 === true ? 'success' : 'danger'),
          cancel_date: item.cancel_date === null ? '' : (item.signature3 === true ? 'success' : 'danger')
        }
      }
      return tempItems;
    }
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.loadDataAppointments();
    },
    loadDataAppointments(){
      this.appointments.isBusy = true;
      this.openHttp("jobsappointments", this.appointments.search).then((result) => {
        this.appointments.items = result;
        this.appointments.isBusy = false;
      })
    },
    filterOpenAppointments(){
      this.openHttp("jobsappointments/filters", null).then((result) => {
        this.appointments.filtersMenu = result;
        this.appointments.showFilter = true;
      })
    },
    filterSetAppointments(){
      this.appointments.search.filters = this.appointments.tempFilter;
      this.appointments.showFilter = false;
      this.loadDataAppointments();
    },
    filterResetAppointments(){
      this.appointments.tempFilter = {
        users_id: null,
        jobdescriptions_id: null,
        type: 2
      }
      this.filterSetAppointments();
    },
    sortingChangeAppointments(ctx) {
      this.appointments.search.sortby = ctx.sortBy;
      this.appointments.search.sortdesc = ctx.sortDesc;
      this.loadDataAppointments();
    }
  }
}
</script>
<template>
    <div>
        <b-modal v-model="show" v-if="data !== null" title="Appointment" size="lg" @hidden="closeForm()" @close="closeForm()">
            <auditsignature ref="signature" @update="loadData(data.audit.id)"/>
            <b-row>
                <b-col lg="6">
                    <b-form-group label="Memeber of team">
                        <b-form-input :value="data.impartiality.fullname"/>
                    </b-form-group>
                </b-col>
                <b-col lg="3">
                    <b-form-group label="Date">
                        <b-form-input type="date" :value="data.impartiality.date"/>
                    </b-form-group>
                </b-col>
                <b-col lg="3">
                    <b-form-group label="Signature" v-if="data.impartiality.signatures_id !== null">
                        <b-btn :variant="data.impartiality.signed === true ? 'success' : 'danger'">{{ data.impartiality.signed === true ? 'Signed' : 'Not signed' }}</b-btn>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-form-group label="Checks">
                <b-table-simple responsive>
                    <b-thead>
                        <b-tr>
                            <b-th>Requirement</b-th>
                            <b-th>Status</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr v-if="data.impartiality.gdpr !== null">
                            <b-td>GDPR privacy acceptance</b-td>
                            <b-td>{{ data.impartiality.gdpr === null ? 'Pending' : (data.impartiality.gdpr === true ? 'Ok' : 'No') }}</b-td>
                        </b-tr>
                        <b-tr>
                            <b-td>in the last 3 (three) years I haven’t performed any consultancy </b-td>
                            <b-td>{{ data.impartiality.noconsultancy3years === null ? 'Pending' : (data.impartiality.noconsultancy3years === true ? 'Ok' : 'No') }}</b-td>
                        </b-tr>
                        <b-tr>
                            <b-td>in the last 3 (three) years I haven’t performed any internal audits</b-td>
                            <b-td>{{ data.impartiality.nointernalaudit3years === null ? 'Pending' : (data.impartiality.nointernalaudit3years === true ? 'Ok' : 'No') }}</b-td>
                        </b-tr>
                        <b-tr>
                            <b-td>I have no ties or contractual relationships with the consultancy organization or I did not perform any other activities that</b-td>
                            <b-td>{{ data.impartiality.nocontractual3years === null ? 'Pending' : (data.impartiality.nocontractual3years === true ? 'Ok' : 'No') }}</b-td>
                        </b-tr>
                        <b-tr>
                            <b-td>I will not provide information and I will defend the interests of competing organizations in carrying out my activities as Pre-transfer reviewer</b-td>
                            <b-td>{{ data.impartiality.interestofcompetiting === null ? 'Pending' : (data.impartiality.interestofcompetiting === true ? 'Ok' : 'No') }}</b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
                <b-form-group label="Note in case of conflict">
                    <b-form-textarea :value="data.impartiality.note" rows="2"/>
                </b-form-group>
            </b-form-group>
            <b-row>
                <b-col lg="6" v-if="data.impartiality.decisionfullname !== null">
                    <b-form-group label="Decided by">
                        <b-form-input :value="data.impartiality.decision_fullname"/>
                    </b-form-group>
                </b-col>
                <b-col lg="3">
                    <b-form-group label="Decision">
                        <b-form-select v-model="data.impartiality.decision" :options="decisionCombo" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
                <b-col lg="3" v-if="data.impartiality.decisionfullname !== null">
                    <b-form-group label="Date">
                        <b-form-input type="date" :value="data.impartiality.decision_date"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" @click="saveData()">Save</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import auditsignature from './auditsignature.vue';

export default {
    name: "auditimpartiality",
    components: {
        auditsignature
    },
    data() {
        return {
            show: false,
            data: null,
            decisionCombo: [{
                id: null,
                val: "Pending"
            },{
                id: true,
                val: "Accepted"
            },{
                id: true,
                val: "Rejected"
            }]
        }
    },
    methods: {
        loadData(id, auditsId){
            this.openHttp("audits/impartiality", { id: id, audits_id: auditsId }).then((result) => {
                result.impartiality.type = true;
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("audits/impartiality/save", this.data.impartiality).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        }
    }
}
</script>
<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" title="New Mail" scrollable @hidden="closeForm" @close="closeForm" no-close-on-backdrop>
      <b-row>
        <b-col lg="6">
          <b-form-group label="From">
            <b-form-select v-model="data.mailboxes_id" :options="mailboxesOptions" value-field="id" text-field="val" @change="setMail"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6">
          <b-form-group label="To">
            <b-form-input v-model="data.to" class="mr-2" style="float: left"/>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="CC">
            <b-form-input v-model="data.cc"/>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="BCC">
            <b-form-input v-model="data.bcc"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b-form-group label="Subject">
            <b-form-input v-model="data.subject"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <customeditor v-model="data.body" :height="winHeight - 600" :showToolbar="true"/>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b-form-group label="Attachments">
            <b-dropdown class="mr-2 mb-2" size="sm" v-for="(item, index) of attachments" :key="index" :text="item">
              <b-dropdown-item @click="deleteAttachments(item)">Delete</b-dropdown-item>
            </b-dropdown>
          </b-form-group>
        </b-col>
      </b-row>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-modal id="modal-upload" v-model="showUpload" title="Upload file" hide-footer>
            <b-form-file @change="fileChange"/>
          </b-modal>
          <b-btn variant="success" class="mr-2" @click="sendData">Send</b-btn>
          <b-btn variant="primary" v-b-modal.modal-upload>Upload attachment</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import customeditor from '../customeditor.vue';
export default {
  name: "mailnew",
  components: {
    customeditor
  },
  data() {
    return {
      show: false,
      showUpload: false,
      data: null,
      mailboxesOptions: [],
      templatesOptions: [],
      templatesId: null,
      attachments: [],
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadData( dataFrom ){
      if ( this.data === null ) this.show = false;
      this.openHttp("mails/mailnew", dataFrom).then((result) => {
        this.show = true;
        this.mailboxesOptions = result.mailboxes;
        this.attachments = result.attachments;
        if ( result.data.subject === undefined ) result.data.subject = "";
        if ( result.data.body === undefined ) result.data.body = "";
        if ( result.data.mailboxes_id === null && this.$store.state.lastEmailAddressId !== null ) result.data.mailboxes_id = this.$store.state.lastEmailAddressId;
        this.data = result.data;
        this.show = true;
      })
    },
    setMail(mailboxes_id){
      this.$store.state.lastEmailAddressId = mailboxes_id;
    },
    sendData(){
      this.saveHttp("mails/mailnew/send", this.data).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    fileChange(event){
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = ()=> {
        this.saveHttp("mails_mail_new_uploadattachments", { mails_id: this.data.id, name: event.target.files[0].name, file: reader.result }, "Attachment uploaded!" ).then((result) => {
          if ( result !== null ){
            this.showUpload = false;
            this.loadData(this.data);
          }
        })
      };
    },
    uploadData(){
      this.showUpload = false;
    },
    deleteAttachments(filename){
      this.deleteHttp("mails_mail_new_deleteattachments", { mails_id: this.data.id, filename: filename }).then((result) => {
        if ( result !== null ) this.loadData( this.data );
      })
    },
    closeForm(){
      this.templatesId = null;
      this.show = false;
      this.showUpload = false;
      this.data = null;
      this.$emit("update");
    },
  },
}
</script>
<template>
    <div>
        <b-modal v-model="show" v-if="data !== null" title="Non conformity to be closed" size="lg">
            <b-form-group label="Non conformity">
                <b-form-textarea v-model="data.nonconformity.nonconformity" rows="3"/>
            </b-form-group>
            <b-row>
                <b-col lg="2">
                    <b-form-group label="Type">
                        <b-form-select v-model="data.nonconformity.auditsnonconformitiescertificationbodiestypes_id" :options="data.types" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-form-group label="Causes">
                <b-form-textarea v-model="data.nonconformity.causes" rows="3"/>
            </b-form-group>
            <b-form-group label="Treatment">
                <b-form-textarea v-model="data.nonconformity.treatment" rows="3"/>
            </b-form-group>
            <b-form-group label="Corrective action">
                <b-form-textarea v-model="data.nonconformity.correctiveaction" rows="3"/>
            </b-form-group>
            <b-row>
                <b-col lg="2">
                    <b-form-group label="Effectiveness">
                        <b-form-select v-model="data.nonconformity.effectivenessdecision" :options="effectivenessdecisionCombo" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-form-group label="Effectiveness note">
                <b-form-textarea v-model="data.nonconformity.effectivenessnote" rows="3"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" @click="saveData">Save</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "checklistnonconformitytobeclosed",
    data() {
        return {
            show: false,
            data: null,
            effectivenessdecisionCombo: [{
                id: true,
                val: "Approved"
            },{
                id: false,
                val: "Not Approved"
            },{
                id: null,
                val: "Pending"
            },]
        }
    },
    methods: {
        loadData( id ){
            this.openHttp("checklists/nonconformitytobeclosed", { id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("checklists/nonconformitytobeclosed/save", this.data.nonconformity).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>
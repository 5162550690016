<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Complaint / Appeal" @hidden="closeForm()" @close="closeForm()">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Contract" active>
            <b-form-group label="Complaint">
              <b-form-textarea v-model="data.complaint.complaint" rows="2" max-rows="10"/>
            </b-form-group>
            <b-row>
              <b-col lg="9">
                <b-form-group label="Audit">
                  <b-form-select v-model="data.complaint.audits_id" :options="data.audits" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Date">
                  <b-form-input type="date" v-model="data.complaint.date"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Note">
              <b-form-textarea v-model="data.complaint.note" rows="4" max-rows="20"/>
            </b-form-group>
            <b-form-group label="Contacts">
              <b-form-textarea v-model="data.complaint.contacts" rows="2"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Histories">
            <b-btn variant="primary" size="sm" @click="data.complaint.histories.push({id: 0, date: null, complaintsstatus_id: null, users_id: null, note: null})">New</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" class="mt-3" :items="data.complaint.histories" :fields="fields">
              <template v-slot:cell(date)="row">
                <b-form-input type="date" v-model="row.item.date" size="sm"/>
              </template>
              <template v-slot:cell(users_id)="row">
                <b-form-select v-model="row.item.users_id" :options="data.users" value-field="id" text-field="val" size="sm"/>
              </template>
              <template v-slot:cell(complaintsstatus_id)="row">
                <b-form-select v-model="row.item.complaintsstatus_id" :options="data.status" value-field="id" text-field="val" size="sm"/>
              </template>
              <template v-slot:cell(note)="row">
                <b-form-textarea v-model="row.item.note" rows="4" size="sm"/>
              </template>
              <template v-slot:cell(id)="row">
                <b-btn variant="danger" size="sm" @click="data.complaint.histories.splice(row.index, 1)">Delete</b-btn>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" v-show="data.complaint.id > 0" @click="loadData(data.complaint.id)">Refresh</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData()">Save</b-btn>
          <b-btn variant="danger" class="mr-2" v-show="data.complaint.id > 0" @click="deleteData()">Delete</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "complaint",
  data() {
    return {
      show: true,
      data: null,
      fields: [{
        key: 'date',
        label: "Date",
      },{
        key: 'complaintsstatus_id',
        label: 'Status',
      },{
        key: 'users_id',
        label: 'Fullname',
      },{
        key: 'note',
        label: 'Note',
      },{
        key: 'id',
        label: ""
      }]
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadData( id ){
      this.openHttp("complaints/complaint", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("complaints/complaint/save", this.data.complaint).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("complaints/complaint/delete", { id: this.data.complaint.id }).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>
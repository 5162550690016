<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Certificate" @hidden="closeForm()" @close="closeForm()">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Form" active>
            <b-row>
              <b-col lg="2">
                <b-form-group label="From">
                  <b-form-input v-model="data.certificate.fromdate" type="date"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="To">
                  <b-form-input v-model="data.certificate.todate" type="date"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Released by">
                  <b-form-input v-model="data.certificate.issuer"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Certificate code">
                  <b-form-input v-model="data.certificate.code"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Duration (h)">
                  <b-form-input v-model="data.certificate.duration" type="number"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="6">
                <b-form-group label="Degree">
                  <b-input-group v-for="item of data.certificate.languages" :key="item.id" v-show="item.id === 2">
                    <b-form-input v-model="item.degree"/>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Accreditated 17024">
                  <b-form-select v-model="data.certificate.accr17024" :options="accr17024" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Qualification">
                  <b-form-select v-model="data.certificate.recognisedbyothercb" :options="recognisedbyothercbCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Standards">
              <multiselect v-model="standardsSupport" :options="data.standards" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.certificate.standards = updateCombo(standardsSupport)"/>
            </b-form-group>
            <b-form-group label="Areas">
              <multiselect v-model="standardsareasSupport" :options="data.standardsareas" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.certificate.standardsareas = updateCombo(standardsareasSupport)"/>
            </b-form-group>
            <b-form-group label="IAFs">
              <multiselect v-model="nacesiafcodesSupport" :options="data.nacesiafcodes" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.certificate.nacesiafcodes = updateCombo(nacesiafcodesSupport)"/>
            </b-form-group>
          </b-tab>
          <b-tab :title="'Documents ' + data.documents.length" v-if="data.certificate.id > 0">
            <document ref="document" @update="loadData(data.certificate.users_id, data.certificate.id)"/>
            <b-btn variant="primary" @click="loadDocument(null)">New</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 380) + 'px'" responsive class="mt-3" :items="data.documents" :fields="fields.documents" @row-clicked="loadDocument($event.file)">
              <template v-slot:cell(id)="row">
                <b-btn variant="primary" size="sm" @click="$refs.document.downloadFile(row.value)">Download</b-btn>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Save</b-btn>
          <b-btn variant="danger" v-show="data.certificate.id > 0" @click="deleteData">Delete</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import document from './document.vue';

export default {
  name: "certificate",
  components: { document },
  data() {
    return {
      show: false,
      data: null,
      standardsSupport: [],
      standardsareasSupport: [],
      nacesiafcodesSupport: [],
      fields: {
        documents: [{
          key: 'filename',
          label: "Document",
        },{
          key: 'id',
          label: "",
        }],
      },
      accr17024: [{
        id: false,
        val: "No",
      },{
        id: true,
        val: "Yes",
      }],
      recognisedbyothercbCombo: [{
        id: false,
        val: "No"
      },{
        id: true,
        val: "Recognised by another CB"
      }]
    }
  },
  computed: {
    winHeight(){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadData( usersId, id ){
      this.openHttp("curricula/certificate", { users_id: usersId, id: id }).then((result) => {
        this.standardsSupport = this.convertCombo(result.standards, result.certificate.standards);
        this.standardsareasSupport = this.convertCombo(result.standardsareas, result.certificate.standardsareas);
        this.nacesiafcodesSupport = this.convertCombo(result.nacesiafcodes, result.certificate.nacesiafcodes);
        this.data = result;
        this.show = true;
      })
    },
    loadDocument(fileName){
      this.$refs.document.loadData("certificates", this.data.certificate.id, fileName);
    },
    saveData(){
      this.saveHttp("curricula/certificate/save", this.data.certificate).then((result) => {
        console.log(result);
        if (result !== null) this.closeForm(true);
      })
    },
    deleteData(){
      this.deleteHttp("curricula/certificate/delete", { id: this.data.certificate.id, users_id: this.data.certificate.users_id }).then((result) => {
        if (result !== null) this.closeForm(true);
      })
    },
    closeForm(reload = false){
      this.data = null;
      this.show = false;
      if ( reload === true ) this.$emit('update');      
    }
  }
}
</script>
<template>
    <div>
        <b-modal v-if="show" v-model="show" title="Standard" size="lg" @hidden="closeForm" scrollable @close="closeForm">
            <b-form-group label="Standard">
                <b-form-select v-model="data.standard.standards_id" :options="data.standards" value-field="id" text-field="val" @change="data.standard.points = []"/>
            </b-form-group>
            <b-row v-if="data.standardsexclusions.findIndex(x => x.standards_id === data.standard.standards_id) > -1">
                <b-col lg=12>
                    <b-form-group label="Exclusions">
                        <b-form-checkbox-group v-model="data.standard.exclusions">
                            <b-form-checkbox v-for="(item, index) in data.standardsexclusions.filter(x => x.standards_id === data.standard.standards_id)" :key="index" :value="item.id">{{ item.val }}</b-form-checkbox>
                        </b-form-checkbox-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="3" v-if="data.standardscount > 1">
                    <b-form-group label="Integration of audit team">
                        <b-form-select v-model="data.standard.integrationauditteam" :options="integrationAuditTeamCombo" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
                <b-col lg="3">
                    <b-form-group label="Risk level">
                        <b-form-select v-model="data.standard.riskslevels_id" :options="data.riskslevels" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
                <b-col lg="3">
                    <b-form-group label="Certificate first issue">
                        <b-form-input type="date" v-model="data.standard.certificatefirstissue"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-form-group label="Note calculation">
                <b-form-textarea v-model="data.standard.notecalc" rows="8"/>
            </b-form-group>
            <b-row>
                <b-col lg="3">
                    <b-form-group label="Mandays table">
                        <b-form-input type="number" v-model="data.standard.mandaystiletable"/>
                    </b-form-group>
                </b-col>
                <b-col lg="3">
                    <b-form-group label="Mandays reduced">
                        <b-form-input type="number" v-model="data.standard.mandaysreduced"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Save</b-btn>
                    <b-btn variant="danger" v-show="data.standard.id > 0" @click="deleteData">Delete</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "applicationtandard",
    data() {
        return {
            show: false,
            data: null,
            integrationAuditTeamCombo: [{
                id: 0,
                val: '0%'
            },{
                id: 20,
                val: '20%'
            },{
                id: 40,
                val: '40%'
            },{
                id: 60,
                val: '60%'
            },{
                id: 80,
                val: '80%'
            },{
                id: 100,
                val: '100%'
            }],
            fields: [{
                key: 'name',
                label: 'Lead auditor / auditor',
                sortable: true,
            },{
                key: 'standards',
                label: 'Standards',
            }],
        }
    },
    computed: {
        winHeight (){
            return this.$store.getters.getWinHeight;
        },
    },
    methods: {
        loadData( applicationsId, id ){
            this.show = false;
            this.openHttp("applications/standard", { applications_id: applicationsId, id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("applications/standard/save", this.data.standard).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("applications/standard/delete", { standards_id: this.data.standard.standards_id, contracts_id: this.data.standard.contracts_id }).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>
<template>
  <div>
    <audit ref="audit" @update="loadData()"/>
    <b-row class="mb-2">
      <b-col>
        <b-modal v-model="showFilter" title="Filters">
          <b-form-group label="Contract">
            <b-form-input v-model="tempFilter.contract"/>
          </b-form-group>
          <b-form-group label="Company">
            <b-form-select v-model="tempFilter.companies_id" :options="addNullCombo(filtersMenu.companies)" value-field="id" text-field="val"/>
          </b-form-group>
          <b-form-group label="Standards">
            <b-form-select v-model="tempFilter.standards_id" :options="addNullCombo(filtersMenu.standards)" value-field="id" text-field="val"/>
          </b-form-group>
          <b-form-group label="IAF">
            <b-form-select v-model="tempFilter.nacesiafcodes_id" :options="addNullCombo(filtersMenu.iafcodes)" value-field="id" text-field="val"/>
          </b-form-group>
          <b-form-group label="User">
            <b-form-select v-model="tempFilter.users_id" :options="addNullCombo(filtersMenu.users)" value-field="id" text-field="val"/>
          </b-form-group>
          <template v-slot:modal-footer>
            <div class="w-100">
              <b-btn variant="primary" class="mr-2" @click="filterSet()">Filter</b-btn>
              <b-btn variant="warning" @click="filterReset()">Reset</b-btn>
            </div>
          </template>
        </b-modal>
        <b-btn class="mr-2" variant="primary" @click="loadData">Refresh</b-btn>
        <b-btn class="mr-2" variant="secondary" @click="filterOpen">Filter</b-btn>
      </b-col>
    </b-row>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Calendar" active>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" :items="audits1.items.data" :fields="audits1.fields" :busy="audits1.isBusy" :no-local-sorting="true" :sort-by.sync="audits1.search.sortby" :sort-desc.sync="audits1.search.sortdesc" @sort-changed="sortingChange1" @row-clicked="$refs.audit.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>There are no results</template>
            <template v-slot:cell(standardsphasescontract)="row">
              <div v-for="(item, index) of row.value" :key="index">{{ item }}</div>
            </template>
          </b-table>
          <tablebottom :busy="audits1.isBusy" :currentpage="audits1.search.page" :total="audits1.items.total" @updateCurrentPage="audits1.search.page = $event" @loadData="loadData1()"/>
        </b-tab>
        <b-tab title="Planning">
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" :items="audits2.items.data" :fields="audits2.fields" :busy="audits2.isBusy" :no-local-sorting="true" :sort-by.sync="audits2.search.sortby" :sort-desc.sync="audits2.search.sortdesc" @sort-changed="sortingChange2" @row-clicked="$refs.audit.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>There are no results</template>
            <template v-slot:cell(standardsphasescontract)="row">
              <div v-for="(item, index) of row.value" :key="index">{{ item }}</div>
            </template>
            <template v-slot:cell(team)="row">
              <div v-for="(item, index) of row.value" :key="index">{{ item }}</div>
            </template>
          </b-table>
          <tablebottom :busy="audits2.isBusy" :currentpage="audits2.search.page" :total="audits2.items.total" @updateCurrentPage="audits2.search.page = $event" @loadData="loadData2()"/>
        </b-tab>
        <b-tab title="Checklist">
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" :items="audits3.items.data" :fields="audits3.fields" :busy="audits3.isBusy" :no-local-sorting="true" :sort-by.sync="audits3.search.sortby" :sort-desc.sync="audits3.search.sortdesc" @sort-changed="sortingChange3" @row-clicked="$refs.audit.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>There are no results</template>
            <template v-slot:cell(standardsphasescontract)="row">
              <div v-for="(item, index) of row.value" :key="index">{{ item }}</div>
            </template>
            <template v-slot:cell(team)="row">
              <div v-for="(item, index) of row.value" :key="index">{{ item }}</div>
            </template>
          </b-table>
          <tablebottom :busy="audits3.isBusy" :currentpage="audits3.search.page" :total="audits3.items.total" @updateCurrentPage="audits3.search.page = $event" @loadData="loadData3()"/>
        </b-tab>
        <b-tab title="Veto">
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" :items="audits4.items.data" :fields="audits4.fields" :busy="audits4.isBusy" :no-local-sorting="true" :sort-by.sync="audits4.search.sortby" :sort-desc.sync="audits4.search.sortdesc" @sort-changed="sortingChange4" @row-clicked="$refs.audit.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>There are no results</template>
            <template v-slot:cell(standardsphasescontract)="row">
              <div v-for="(item, index) of row.value" :key="index">{{ item }}</div>
            </template>
            <template v-slot:cell(vetos)="row">
              <div v-for="(item, index) of row.value" :key="index">{{ item }}</div>
            </template>
          </b-table>
          <tablebottom :busy="audits4.isBusy" :currentpage="audits4.search.page" :total="audits4.items.total" @updateCurrentPage="audits4.search.page = $event" @loadData="loadData4()"/>
        </b-tab>
        <b-tab title="Concluded">
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" :items="audits5.items.data" :fields="fields" :busy="audits5.isBusy" :no-local-sorting="true" :sort-by.sync="audits5.search.sortby" :sort-desc.sync="audits5.search.sortdesc" @sort-changed="sortingChange5" @row-clicked="$refs.audit.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>There are no results</template>
            <template v-slot:cell(standardsphasescontract)="row">
              <div v-for="(item, index) of row.value" :key="index">{{ item }}</div>
            </template>
          </b-table>
          <tablebottom :busy="audits5.isBusy" :currentpage="audits5.search.page" :total="audits5.items.total" @updateCurrentPage="audits5.search.page = $event" @loadData="loadData5()"/>
        </b-tab>
        <b-tab title="Failed">
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" :items="audits6.items.data" :fields="fields" :busy="audits6.isBusy" :no-local-sorting="true" :sort-by.sync="audits6.search.sortby" :sort-desc.sync="audits6.search.sortdesc" @sort-changed="sortingChange6" @row-clicked="$refs.audit.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>There are no results</template>
            <template v-slot:cell(standardsphasescontract)="row">
              <div v-for="(item, index) of row.value" :key="index">{{ item }}</div>
            </template>
          </b-table>
          <tablebottom :busy="audits6.isBusy" :currentpage="audits6.search.page" :total="audits6.items.total" @updateCurrentPage="audits6.search.page = $event" @loadData="loadData6()"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "audits",
  data() {
    return {
      showFilter: false,
      filtersMenu: {
        companies:[],
        standards:[],
        iafcodes: [],
        users: []
      },
      tempFilter: {
        contract: null,
        auditsstatus_id: null,
        companies_id: null,
        standards_id: null,
        nacesiafcodes_id: null,
        users_id: null,
      },
      fields: [{
        key: 'plandate',
        label: 'Plan',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: "company",
        label: "Company",
        sortable: true
      },{
        key: "contract",
        label: "Contract",
        sortable: true
      },{
        key: "standardsphasescontract",
        label: "Standards & Phases"
      },{
        key: 'expiracy',
        label: 'Expiry',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      }],
      audits1: {
        isBusy: true,
        search: {
          page: 1,
          filters: {
            contract: null,
            auditsstatus_id: 1,
            companies_id: null,
            standards_id: null,
            nacesiafcodes_id: null,
            users_id: null,
          },
          sortby: "plandate",
          sortdesc: false,
        },
        items: {
          total: 0,
          data: []
        },
        fields: [{
          key: 'plandate',
          label: 'Plan',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "company",
          label: "Company",
          sortable: true
        },{
          key: "contract",
          label: "Contract",
          sortable: true
        },{
          key: "standardsphasescontract",
          label: "Standards & Phases"
        },{
          key: 'expiracy',
          label: 'Expiry',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        }]
      },
      audits2: {
        isBusy: true,
        showFilter: false,
        search: {
          page: 1,
          filters: {
            contract: null,
            auditsstatus_id: 2,
            companies_id: null,
            standards_id: null,
            nacesiafcodes_id: null,
            users_id: null,
          },
          sortby: "expiracy",
          sortdesc: false,
        },
        items: {
          total: 0,
          data: []
        },
        fields: [{
          key: 'plandate',
          label: 'Plan',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "company",
          label: "Company",
          sortable: true
        },{
          key: "contract",
          label: "Contract",
          sortable: true
        },{
          key: "standardsphasescontract",
          label: "Standards & Phases"
        },{
          key: "team",
          label: "Team"
        },{
          key: "teamdates",
          label: "Dates"
        },{
          key: "plan_percentage",
          label: "Planned",
          formatter: value => {
            if (value == null) return null;
            return value + "%";
          }
        },{
          key: "firstteamdate",
          label: "Audit",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'expiracy',
          label: 'Expiry',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        }]
      },
      audits3: {
        isBusy: true,
        showFilter: false,
        search: {
          page: 1,
          filters: {
            contract: null,
            auditsstatus_id: 3,
            companies_id: null,
            standards_id: null,
            nacesiafcodes_id: null,
            users_id: null,
          },
          sortby: "plandate",
          sortdesc: false,
        },
        fields: [{
          key: 'plandate',
          label: 'Plan',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "company",
          label: "Company",
          sortable: true
        },{
          key: "contract",
          label: "Contract",
          sortable: true
        },{
          key: "standardsphasescontract",
          label: "Standards & Phases"
        },{
          key: "team",
          label: "Team"
        },{
          key: "check_percentage",
          label: "Checklist",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            return value + "%";
          }
        },{
          key: "lastteamdate",
          label: "Report",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'expiracy',
          label: 'Expiry',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        }],
        items: {
          total: 0,
          data: []
        }
      },

      audits4: {
        isBusy: true,
        showFilter: false,
        search: {
          page: 1,
          filters: {
            contract: null,
            auditsstatus_id: 4,
            companies_id: null,
            standards_id: null,
            nacesiafcodes_id: null,
            users_id: null,
          },
          sortby: "plandate",
          sortdesc: false,
        },
        fields: [{
          key: 'plandate',
          label: 'Plan',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "company",
          label: "Company",
          sortable: true
        },{
          key: "contract",
          label: "Contract",
          sortable: true
        },{
          key: "standardsphasescontract",
          label: "Standards & Phases"
        },{
          key: "vetos",
          label: "Veto"
        },{
          key: "veto_percentage",
          label: "Veto",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            return value + "%";
          }
        },{
          key: "lastteamdate",
          label: "Date report",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'expiracy',
          label: 'Expiry',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        }],
        items: {
          total: 0,
          data: []
        }
      },

      audits5: {
        isBusy: true,
        showFilter: false,
        search: {
          page: 1,
          filters: {
            contract: null,
            auditsstatus_id: 5,
            companies_id: null,
            standards_id: null,
            nacesiafcodes_id: null,
            users_id: null,
          },
          sortby: "plandate",
          sortdesc: false,
        },
        items: {
          total: 0,
          data: []
        }
      },
      audits6: {
        isBusy: true,
        showFilter: false,
        search: {
          page: 1,
          filters: {
            contract: null,
            auditsstatus_id: 6,
            companies_id: null,
            standards_id: null,
            nacesiafcodes_id: null,
            users_id: null,
          },
          sortby: "plandate",
          sortdesc: false,
        },
        items: {
          total: 0,
          data: []
        }
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.loadData1();
      this.loadData2();
      this.loadData3();
      this.loadData4();
      this.loadData5();
      this.loadData6();
    },
    loadData1(){
      this.audits1.isBusy = true;
      this.openHttp("audits/", this.audits1.search).then((result) => {
        this.audits1.items = result;
        this.audits1.isBusy = false;
      })
    },
    loadData2(){
      this.audits2.isBusy = true;
      this.openHttp("audits/", this.audits2.search).then((result) => {
        this.audits2.items = result;
        this.audits2.isBusy = false;
      })
    },
    loadData3(){
      this.audits3.isBusy = true;
      this.openHttp("audits/", this.audits3.search).then((result) => {
        this.audits3.items = result;
        this.audits3.isBusy = false;
      })
    },
    loadData4(){
      this.audits4.isBusy = true;
      this.openHttp("audits/", this.audits4.search).then((result) => {
        this.audits4.items = result;
        this.audits4.isBusy = false;
      })
    },
    loadData5(){
      this.audits5.isBusy = true;
      this.openHttp("audits/", this.audits5.search).then((result) => {
        this.audits5.items = result;
        this.audits5.isBusy = false;
      })
    },
    loadData6(){
      this.audits6.isBusy = true;
      this.openHttp("audits/", this.audits6.search).then((result) => {
        this.audits6.items = result;
        this.audits6.isBusy = false;
      })
    },

    filterOpen(){
      this.openHttp("audits/filters", null).then((result) => {
        this.filtersMenu = result;
        this.showFilter = true;
      })
    },
    filterSet(){
      this.showFilter = false;
      this.audits1.search.page = 1;
      this.audits1.search.filters = JSON.parse(JSON.stringify(this.tempFilter));
      this.audits1.search.filters.auditsstatus_id = 1;

      this.audits2.search.page = 1;
      this.audits2.search.filters = JSON.parse(JSON.stringify(this.tempFilter));
      this.audits2.search.filters.auditsstatus_id = 2;

      this.audits3.search.page = 1;
      this.audits3.search.filters = JSON.parse(JSON.stringify(this.tempFilter));
      this.audits3.search.filters.auditsstatus_id = 3;

      this.audits4.search.page = 1;
      this.audits4.search.filters = JSON.parse(JSON.stringify(this.tempFilter));
      this.audits4.search.filters.auditsstatus_id = 4;

      this.audits5.search.page = 1;
      this.audits5.search.filters = JSON.parse(JSON.stringify(this.tempFilter));
      this.audits5.search.filters.auditsstatus_id = 5;

      this.audits6.search.page = 1;
      this.audits6.search.filters = JSON.parse(JSON.stringify(this.tempFilter));
      this.audits6.search.filters.auditsstatus_id = 6;
      this.loadData();
    },
    filterReset(){
      this.tempFilter = {
        contract: null,
        companies_id: null,
        standards_id: null,
        nacesiafcodes_id: null,
        users_id: null,
      };
      this.filterSet();
    },
    sortingChange1(ctx) {
      this.audits1.search.sortby = ctx.sortBy;
      this.audits1.search.sortdesc = ctx.sortDesc;
      this.loadData1();
    },
    sortingChange2(ctx) {
      this.audits2.search.sortby = ctx.sortBy;
      this.audits2.search.sortdesc = ctx.sortDesc;
      this.loadData2();
    },

    sortingChange3(ctx) {
      this.audits3.search.sortby = ctx.sortBy;
      this.audits3.search.sortdesc = ctx.sortDesc;
      this.loadData3();
    },
    sortingChange4(ctx) {
      this.audits4.search.sortby = ctx.sortBy;
      this.audits4.search.sortdesc = ctx.sortDesc;
      this.loadData4();
    },
    sortingChange5(ctx) {
      this.audits5.search.sortby = ctx.sortBy;
      this.audits5.search.sortdesc = ctx.sortDesc;
      this.loadData5();
    },
    sortingChange6(ctx) {
      this.audits6.search.sortby = ctx.sortBy;
      this.audits6.search.sortdesc = ctx.sortDesc;
      this.loadData6();
    }
  }
}
</script>
<template>
  <div>
    <document ref="document" @update="loadData()"/>
    <evaluation ref="evaluation" @update="loadData()"/>
    <competence ref="competence" @update="loadData()"/>
    <question ref="question" @update="loadData()"/>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Evaluations" active>
          <b-modal v-model="evaluations.showFilter" title="Filters">
            <b-form-group label="Person">
              <b-form-select v-model="evaluations.tempFilter.users_id" :options="addNullCombo(evaluations.filtersMenu.users)" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Standard">
              <b-form-select v-model="evaluations.tempFilter.standards_id" :options="addNullCombo(evaluations.filtersMenu.standards)" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="IAF code">
              <b-form-select v-model="evaluations.tempFilter.nacesiafcodes_id" :options="addNullCombo(evaluations.filtersMenu.nacesiafcodes)" value-field="id" text-field="val"/>
            </b-form-group>
            <template v-slot:modal-footer>
              <div class="w-100">
                <b-btn variant="primary" class="mr-2" @click="filterSetEvaluations()">Filter</b-btn>
                <b-btn variant="warning" @click="filterResetEvaluations()">Reset</b-btn>
              </div>
            </template>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadEvaluations()">Refresh</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="filterOpenEvaluations()">Filter</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="evaluations.items.data" :fields="evaluations.fields" :busy="evaluations.isBusy" :no-local-sorting="true" :sort-by.sync="evaluations.search.sortby" :sort-desc.sync="evaluations.search.sortdesc" @sort-changed="sortingChangeEvaluations" @row-clicked="$refs.evaluation.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>There are no results</template>
            <template v-slot:cell(documents_id)="row">
              <b-btn variant="primary" v-if="row.value > 0" size="sm" @click="$refs.document.downloadData(row.value)">Download</b-btn>
            </template>
          </b-table>
          <tablebottom :busy="evaluations.isBusy" :currentpage="evaluations.search.page" :total="evaluations.items.total" @updateCurrentPage="evaluations.search.page = $event" @loadData="loadEvaluations()"/>
        </b-tab>
        <b-tab title="Competences" :disabled="!$store.getters.checkRoute('competences', 2)">
          <b-modal v-model="competences.showFilter" title="Filters">
            <b-form-group label="User">
              <b-form-select v-model="competences.tempFilter.users_id" :options="addNullCombo(competences.filtersMenu.users)" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Standard">
              <b-form-select v-model="competences.tempFilter.standards_id" :options="addNullCombo(competences.filtersMenu.standards)" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="IAF">
              <b-form-select v-model="competences.tempFilter.nacesiafcodes_id" :options="addNullCombo(competences.filtersMenu.nacesiafcodes)" value-field="id" text-field="val"/>
            </b-form-group>
            <template v-slot:modal-footer>
              <div class="w-100">
                <b-btn variant="primary" class="mr-2" @click="filterSetCompetences()">Filter</b-btn>
                <b-btn variant="warning" @click="filterResetCompetences()">Reset</b-btn>
              </div>
            </template>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadCompetences()">Refresh</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="filterOpenCompetences()">Filter</b-btn>
          <b-btn variant="success" @click="$refs.competence.loadData(0)">New</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="competences.items.data" :fields="competences.fields" :busy="competences.isBusy" :no-local-sorting="true" :sort-by.sync="competences.search.sortby" :sort-desc.sync="competences.search.sortdesc" @sort-changed="sortingChangeCompetences" @row-clicked="$refs.competence.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>There are no results</template>
          </b-table>
          <tablebottom :busy="competences.isBusy" :currentpage="competences.search.page" :total="competences.items.total" @updateCurrentPage="competences.search.page = $event" @loadData="loadCompetences()"/>
        </b-tab>
        <b-tab title="Questions" :disabled="!$store.getters.checkRoute('competences', 2)">
          <b-btn class="mr-2" variant="primary" @click="loadQuestions()">Refresh</b-btn>
          <b-btn variant="success" @click="$refs.question.loadData(0)">New</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="questions.items.data" :fields="questions.fields" :busy="questions.isBusy" :no-local-sorting="true" :sort-by.sync="questions.search.sortby" :sort-desc.sync="questions.search.sortdesc" @sort-changed="sortingChangeQuestions" @row-clicked="$refs.question.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>There are no results</template>
            <template v-slot:cell(languages)="row">
              <div v-for="(item, index) of row.value" :key="index">{{ item.lang }} {{ item.question }}</div>
            </template>
          </b-table>
          <tablebottom :busy="questions.isBusy" :currentpage="questions.search.page" :total="questions.items.total" @updateCurrentPage="questions.search.page = $event" @loadData="loadQuestions()"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import document from './document.vue'
import evaluation from './evaluation.vue'
import competence from './competence.vue'
import question from './question.vue'

export default {
  name: "competences",
  components : {
    document, evaluation, competence, question
  },
  data() {
    return {
      evaluations: {
        isBusy: true,
        showFilter: false,
        search: {
          page: 1,
          filters: {
            users_id: null,
            standards_id: null,
            nacesiafcodes_id: null
          },
          sortby: 'lastname',
          sortdesc: false,
        },
        tempFilter: {
          users_id: null,
          standards_id: null,
          nacesiafcodes_id: null
        },
        filtersMenu: {
          users: [],
          standards:[],
          nacesiafcodes: []
        },
        fields: [{
          key: 'fullname',
          label: 'Fullname',
          sortable: true,
        },{
          key: 'date',
          label: "Date",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'standards',
          label: "Standards"
        },{
          key: 'progress',
          label: "Progress"
        },{
          key: "progressok",
          label: "Ok"
        },{
          key: "progressko",
          label: "Ko"
        },{
          key: "documents_id",
          label: ""
        }],
        items: {
          total: 0,
          data: [],
        }        
      },
      competences: {
        isBusy: true,
        showFilter: false,
        search: {
          page: 1,
          filters: {
            users_id: null,
            standards_id: null,
            nacesiafcodes_id: null,
          },
          sortby: 'competence',
          sortdesc: false,
        },
        tempFilter: {
          users_id: null,
          standards_id: null,
          nacesiafcodes_id: null,
        },
        filtersMenu: {
          users: [],
          standards: [],
          nacesiafcodes:[],
        },
        fields: [{
          key: 'fullname',
          label: 'Fullname',
          sortable: true,
        },{
          key: 'date',
          label: "Date",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'standards',
          label: 'Standards'
        },{
          key: 'iafcodes',
          label: "IAFs",
        },{
          key: 'questions',
          label: "Questions"
        },{
          key: "progress",
          label: "Filled"
        },{
          key: "checked",
          label: "Checked"
        }],
        items: {
          total: 0,
          data: [],
        }
      },
      questions: {
        isBusy: true,
        showFilter: false,
        search: {
          page: 1,
          sortby: 'question',
          sortdesc: false,
        },
        fields: [{
          key: 'question',
          label: 'Question',
          sortable: true,
        },{
          key: 'languages',
          label: "Questions",
        },{
          key: 'standards',
          label: "Standards"
        }],
        items: {
          total: 0,
          data: [],
        }
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.loadEvaluations();
      if (this.$store.getters.checkRoute('competences', 2)){
        this.loadCompetences();
        this.loadQuestions();
      }
    },
    loadEvaluations(){
      this.evaluations.isBusy = true;
      this.openHttp("competences/evaluations", this.evaluations.search).then((result) => {
        this.evaluations.items = result;
        this.evaluations.isBusy = false;
      });
    },
    filterOpenEvaluations(){
      this.openHttp("competences/filters", null).then((result) => {
        this.evaluations.filtersMenu = result;
        this.evaluations.showFilter = true;
      })
    },
    filterSetEvaluations(){
      this.evaluations.showFilter = false;
      this.evaluations.search.page = 1;
      this.evaluations.search.filters = this.evaluations.tempFilter;
      this.loadEvaluations();
    },
    filterResetEvaluations(){
      this.evaluations.tempFilter = {
        users_id: null,
        standards_id: null,
        nacesiafcodes_id: null
      };
      this.filterSetEvaluations();
    },
    sortingChangeEvaluations(ctx) {
      this.evaluations.search.sortby = ctx.sortBy;
      this.evaluations.search.sortdesc = ctx.sortDesc;
      this.loadEvaluations();
    },
    loadCompetences(){
      this.competences.isBusy = true;
      this.openHttp("competences/", this.competences.search).then((result) => {
        this.competences.items = result;
        this.competences.isBusy = false;
      });
    },
    filterOpenCompetences(){
      this.openHttp("competences/filters", null).then((result) => {
        this.competences.filtersMenu = result;
        this.competences.showFilter = true;
      })
    },
    filterSetCompetences(){
      this.competences.showFilter = false;
      this.competences.search.page = 1;
      this.competences.search.filters = this.competences.tempFilter;
      this.loadCompetences();
    },
    filterResetCompetences(){
      this.competences.tempFilter = {
        users_id: null,
        standards_id: null,
        nacesiafcodes_id: null
      };
      this.filterSetCompetences();
    },
    sortingChangeCompetences(ctx) {
      this.competences.search.sortby = ctx.sortBy;
      this.competences.search.sortdesc = ctx.sortDesc;
      this.loadCompetences();
    },
    loadQuestions(){
      this.questions.isBusy = true;
      this.openHttp("competences/questions", this.questions.search).then((result) => {
        this.questions.items = result;
        this.questions.isBusy = false;
      });
    },
    sortingChangeQuestions(ctx) {
      this.questions.search.sortby = ctx.sortBy;
      this.questions.search.sortdesc = ctx.sortDesc;
      this.loadQuestions();
    }
  }
}
</script>
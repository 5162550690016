<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" title="Document" @hidden="closeForm()" @close="closeForm()">
            <b-form-group label="File name" v-if="data.filename !== null">
                <b-form-input v-model="data.document.filename"/>
            </b-form-group>
            <b-form-group label="File">
                <b-form-file @change="fileChange"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" v-show="data.document.locked === false" class="mr-2" @click="saveData()">Save</b-btn>
                    <b-btn variant="danger" v-show="data.document.id > 0 && data.document.locked === false" @click="deleteData()">Delete</b-btn>
                </div>
            </template>
        </b-modal>
        <form method="post" action="/api/jobsappointments/document/download" ref="downloadForm" target="_blank">
            <input type="hidden" name="token" :value="token">
            <input type="hidden" ref="downloadFormId" name="id">
        </form>
    </div>
</template>

<script>
export default {
    name: "curriculumdocument",
    data() {
        return {
            show: false,
            data: null,
        }
    },
    computed: {
        token(){
            return this.$store.getters.getToken;
        }
    },
    methods: {
        loadData(table, tablesId, id){
            this.show = false;
            this.openHttp("jobsappointments/document", { table: table, tables_id: tablesId, id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        downloadFile(documentsId){
            this.$refs.downloadFormId.value = documentsId;
            this.$refs.downloadForm.submit();
        },
        generateDataRequirements(id){
            this.saveHttp("jobsappointments/document/generate/requirements", {id: id});
        },
        generateDataAppointments(id){
            this.saveHttp("jobsappointments/document/generate/appointments", {id: id});
        },
        fileChange(event){
            var reader = new FileReader();
            if (event.target.files != null){
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = ()=> {
                    this.data.document.extension = event.target.files[0].name.split(".").slice(-1)[0].toLowerCase();
                    this.data.document.filename = event.target.files[0].name;
                    this.data.document.content = reader.result;
                }
            } else {
                reader.readAsDataURL(event.dataTransfer.files[0]);
                reader.onload = ()=> {
                    this.data.document.extension = event.dataTransfer.files[0].name.split(".").slice(-1)[0].toLowerCase();
                    this.data.document.filename = event.dataTransfer.files[0].name;
                    this.data.document.content = reader.result;
                }
            }
        },
        saveData(){
            this.saveHttp("jobsappointments/document/save", this.data.document).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("jobsappointments/document/delete", { table: this.data.document.table, tables_id: this.data.document.tables_id, id: this.data.document.id }).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>
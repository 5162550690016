<template>
  <div>
    <div class="bottom-table" v-if="!busy">
      <div class="slot1">
        From: {{ (currentpage - 1) * perpage + (total > 0 ? 1 : 0) }} to: {{ currentpage * perpage > total ? total : currentpage * perpage }}, Total: {{ total }} {{ extraText }}
      </div>
      <div class="slot2" v-if="total > 0">
        <b-pagination :value="currentpage" :per-page="perpage" :limit="Math.ceil(total / perpage) > 10 ? 10: Math.ceil(total / perpage)" :total-rows="total" align="right" class="my-0" @input="updateCurrentPage"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "tablebottom",
    props: [ "busy", "extraText", "currentpage", "total" ],
    data() {
        return {
            perpage: 200,
        }
    },
    methods: {
        updatePerPage( event ){
            this.$emit( "updatePerPage", event );
            this.loadData();
        },
        updateCurrentPage( event ){
            this.$emit( "updateCurrentPage", event );
            this.loadData();
        },
        loadData(){
            this.$emit( "loadData" );
        }
    }
}
</script>

<style scoped>
:scope .bottom-table {
  display: flex;
  margin:0;
  padding:0;
}

:scope .bottom-table > .slot1 {
  width: 100%;
  height: 38px;
  padding-top: 7px;
  padding-left: 10px;
  overflow-x: hidden;
  overflow-y: hidden;
}

:scope .bottom-table > .slot2 {
  min-width: 100px;
}
</style>
<template>
    <div>
        <b-modal v-if="data!= null" v-model="show" title="Veto">
            <b-form-group label="Person">
                <b-form-select v-model="data.veto.users_id" :options="data.users" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Standards">
                <b-form-checkbox-group v-model="data.veto.standards" :options="data.standards" value-field="id" text-field="val" stacked/>
            </b-form-group>
            <b-form-group label="Approve" v-if="data.veto.auditsstatus_id >= 4">
                <b-form-select v-model="data.veto.decision" :options="decisionCombo" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Note" v-if="data.veto.auditsstatus_id >= 4">
                <b-form-textarea v-model="data.veto.note" rows="6"/>
            </b-form-group>
           <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData()">Save</b-btn>
                    <b-btn variant="danger" v-show="data.veto.id > 0" @click="deleteData()">Delete</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "veto",
    data() {
        return {
            data: null,
            show: true,
            decisionCombo: [{
                id: null,
                val: "Pending"
            },{
                id: true,
                val: "Accepted"
            },{
                id: null,
                val: "Not accepted"
            }]
        }
    },
    methods: {
        loadData( auditsId, id ){
            this.openHttp("audits/veto", { audits_id: auditsId, id: id } ).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        controlData(auditsId, standardspointsId, ok, noteko){
            this.saveHttp("audits/veto/control", {audits_id: auditsId, standardspoints_id: standardspointsId, ok: ok, noteko: noteko}).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        saveData(){
            this.saveHttp("audits/veto/save", this.data.veto).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("audits/veto/delete", { id: this.data.veto.id, audits_id: this.data.veto.audits_id }).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>
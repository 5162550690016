<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" title="Competence evalutation" @hidden="closeForm()" @close="closeForm()">
      <document ref="document"/>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Competence" active>
            <b-row>
              <b-col lg="9">
                <b-form-group label="User">
                  <b-form-select v-model="data.competenceuserevaluation.users_id" :options="addNullCombo(data.users)" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Date">
                  <b-form-input v-model="data.competenceuserevaluation.date" type="date"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Standard">
              <multiselect v-model="standardsSupport" placeholder="" selectLabel="" deselectLabel="" :options="data.standards" :multiple="true" track-by="id" label="val" @input="data.competenceuserevaluation.standards = updateCombo(standardsSupport)"/>
            </b-form-group>
            <b-form-group label="IAF">
              <multiselect v-model="nacesiafcodesSupport" placeholder="" selectLabel="" deselectLabel="" :options="data.nacesiafcodes" :multiple="true" track-by="id" label="val" @input="data.competenceuserevaluation.nacesiafcodes = updateCombo(nacesiafcodesSupport)"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Correction">
            <b-table no-border-collapse :sticky-header="(winHeight - 395) + 'px'" responsive :items="data.competenceuserevaluation.answers" :fields="fields.answers">
              <template v-slot:cell(question)="row">
                <div><b>{{ standardsSupport.map(x => x.val).join(",") }}</b></div>
                {{ row.value }}
              </template>
              <template v-slot:cell(correct)="row">
                <b-button-group>
                  <b-button v-for="(item, index) of correctCombo" :key="index" :variant="row.item.correct === item.id ? item.variant : ''" @click="row.item.correct = item.id">{{ item.val }}</b-button>
                </b-button-group>
              </template>
              <template v-slot:cell(correctnote)="row">
                <b-form-textarea size="sm" rows="2" max-rows="5" v-model="row.item.correctnote"/>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData()">Save</b-btn>
          <b-btn variant="danger" class="mr-2" v-show="data.competenceuserevaluation.id > 0" @click="deleteData()">Delete</b-btn>
          <b-btn variant="info" v-show="data.competenceuserevaluation.id > 0" @click="$refs.document.generateData(data.competenceuserevaluation.id)">Generate</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import document from './document.vue';

export default {
  name: "competence",
  components: { document },
  data() {
    return {
      show: false,
      data: null,
      standardsSupport: [],
      nacesiafcodesSupport: [],
      correctCombo:[{
        id: true,
        variant: "success",
        val: "Correct"
      },{
        id: false,
        variant: "danger",
        val: "Not correct"
      },{
        id: null,
        variant: "warning",
        val: "Pending"
      }],
      fields: {
        answers: [{
          key: 'question',
          label: "Question",
          thStyle: { width: "20%" }
        },{
          key: 'answer',
          label: "Answer",
          thStyle: { width: "50%" }
        },{
          key: 'correct',
          label: "Correct",
          thStyle: { width: "10%" }
        },{
          key: 'correctnote',
          label: "Note",
          thStyle: { width: "20%" }
        }]
      }
    }
  },
  computed: {
    winHeight(){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadData(id){
      this.openHttp("competences/competence", { id: id }).then((result) => {
        this.standardsSupport = this.convertCombo(result.standards, result.competenceuserevaluation.standards);
        this.nacesiafcodesSupport = this.convertCombo(result.nacesiafcodes, result.competenceuserevaluation.nacesiafcodes);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("competences/competence/save", this.data.competenceuserevaluation).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("competences/competence/delete", { id: this.data.competenceuserevaluation.id }).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit("update");
    }
  },
}
</script>
<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" size="lg" title="Question" scrollable @hidden="closeForm()" @close="closeForm()">
            <b-row>
                <b-col lg="3">
                    <b-form-group label="Order">
                        <b-form-input type="number" min="1" v-model="data.question.orderquestion"/>
                    </b-form-group>
                </b-col>
                <b-col lg="3">
                    <b-form-group label="Status">
                        <b-form-select v-model="data.question.hide" :options="hideCombo" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-form-group :label="'Question ' + item.lang" v-for="(item, index) of data.question.languages" :key="index">
                <b-form-textarea v-model="item.question" rows="2"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData()">Save</b-btn>
                    <b-btn variant="danger" v-show="data.question.id> 0" @click="deleteData()">Delete</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "monitoringquestion",
    data() {
        return {
            show: false,
            data: null,
            hideCombo: [{
                id: false,
                val: "Active"
            },{
                id: true,
                val: "Not active"
            }]
        }
    },
    methods: {
        loadData(id){
            this.openHttp("monitorings/question", { id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("monitorings/question/save", this.data.question).then((result) => {
                if (result !== null) this.loadData(result);
            })
        },
        deleteData(){
            this.deleteHttp("monitorings/question/delete", { id: this.data.question.id }).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>
import axios from "axios";

export default {
    methods: {
        async openHttp (functionName, parameters){
            let self = this;
            let dataHttp = {
                token: this.$store.getters.getToken
            };
            if ( parameters !== null ) dataHttp.data = parameters;
            const response = await axios.post("api/" + functionName, dataHttp).then(response => {
                if (response.data.result === true){
                    return response.data.data;
                } else {
                    throw response.data.data;
                }
            }).catch(error => {
                this.$bvToast.toast(error.response.data.error ? error.response.data.error : error.message, { title: "Eurocertifications", variant: "danger", appendToast: true });
                return null;
            })
            return response;
        },
        async saveHttp (functionName, parameters){
            const response = await axios.post("api/" + functionName, { token: this.$store.getters.getToken, data: parameters }).then(response => {
                if (response.data.result === true){
                    this.$bvToast.toast("Saved", { title: "Eurocertifications", variant: "success", autoHideDelay: 1500, appendToast: true })
                    return response.data.data;
                } else {
                    throw response.data.data;
                }
            }).catch(error => {
                this.$bvToast.toast(error.response.data.error ? error.response.data.error : error.message, { title: "Eurocertifications", variant: "danger", appendToast: true });
                return null;
            })
            return response;
        },
        async deleteHttp (functionName, parameters){
            const response = await axios.post("api/" + functionName, { token: this.$store.getters.getToken, data: parameters }).then(response => {
                if (response.data.result === true){
                    this.$bvToast.toast("Deleted", { title: "Eurocertifications", variant: "danger", autoHideDelay: 1500, appendToast: true })
                    return response.data.data;
                } else {
                    throw response.data.data;
                }
            }).catch(error => {
                this.$bvToast.toast(error.response.data.error ? error.response.data.error : error.message, { title: "Eurocertifications", variant: "danger", appendToast: true });
                return null;
            })
            return response;
        },
        addNullCombo( fromData ){
            return [{ id: null, val: "See all" }].concat(fromData);
        },
        convertCombo( fromData, fromValues ){
            return fromValues.map(x => fromData.find(y => y.id === x) );
        },
        updateCombo( fromValues ){
            this.$forceUpdate();
            return fromValues.map(x => x.id);
        },
        convertComboSimple( fromData, fromValue ){
            return fromData.find(x => x.id === fromValue);
        },
        updateComboSimple( fromValue ){
            this.$forceUpdate();
            return fromValue != null ? fromValue.id : null;
        }
    }
}

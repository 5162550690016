<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" title="Scope" @hidden="closeForm()" @close="closeForm()">
            <b-form-group label="Language">
                <b-form-select v-model="data.scope.languages_id" :options="data.languages" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Language used for the audit?">
                <b-form-select v-model="data.scope.auditlang" :options="comboAuditlang" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Scope">
                <b-form-textarea v-model="data.scope.scope" rows="6"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData()">Save</b-btn>
                    <b-btn variant="danger" v-show="data.scope.languages_id > 0" @click="deleteData()">Delete</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "contractscope",
    data() {
        return {
            show: false,
            data: null,
            comboAuditlang: [{
                id: false,
                val: "Not used",
            },{
                id: true,
                val: "Used",
            }]
        }
    },
    methods: {
        loadData( contractsId, id ){
            this.show = false;
            this.openHttp("contracts/scope", { contracts_id: contractsId, id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("contracts/scope/save", this.data.scope).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("contracts/scope/delete", { languages_id: this.data.scope.languages_id, contracts_id: this.data.scope.contracts_id }).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>
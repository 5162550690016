<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" title="Year Phase Mandays Price" @hidden="closeForm()" @close="closeForm()">
            <b-form-group label="Year">
                <b-form-input v-model="data.costyear.year" type="number" min="0" step="1"/>
            </b-form-group>
            <b-form-group label="Phase">
                <b-form-select v-model="data.costyear.contractsphases_id" :options="data.contractsphases" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Mandays">
                <b-form-input v-model="data.costyear.mandays" type="number" min="0" step="0.0001"/>
            </b-form-group>
            <b-form-group label="Price">
                <b-form-input v-model="data.costyear.cost" type="number" min="0" step="0.01"/>
            </b-form-group>
            <b-form-group label="Audit">
                <b-form-select v-model="data.costyear.audits_id" :options="addNullCombo(data.audits, 'Vuoto')" value-field="id" text-field="val"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Save</b-btn>
                    <b-btn variant="danger" v-show="data.costyear.id > 0" @click="deleteData">Delete</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "contractcost",
    data() {
        return {
            show: false,
            data: null,
        }
    },
    methods: {
        loadData( contractsId, id ){
            this.openHttp("contracts/costyear", { contracts_id: contractsId, id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("contracts/costyear/save", this.data.costyear).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("contracts/costyear/delete", { id: this.data.costyear.id }).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>
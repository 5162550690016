<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" title="Company" scrollable @hidden="closeForm()" @close="closeForm()">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Profile" active>
            <b-row>
              <b-col lg="10">
                <b-form-group label="Business name">
                  <b-form-input v-model="data.company.company"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Nationality">
                  <b-form-select v-model="data.company.states_id" :options="data.states" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Vat">
                  <b-form-input v-model="data.company.vat"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Tax code">
                  <b-form-input v-model="data.company.taxcode"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Employees">
                  <b-form-input v-model="data.company.employees"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Shifts">
                  <b-form-input v-model="data.company.shifts"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Type">
                  <b-form-select v-model="data.company.companiestypes_id" :options="data.companiestypes" value-field="id" text-field="val2"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12">
                <b-form-group label="NACE">
                  <multiselect v-model="data.company.nacesSupport" :options="data.naces" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.company.naces = updateCombo(data.company.nacesSupport)"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="6">
                <b-form-group label="Main site">
                  <b-form-select v-model="data.company.main_sites_id" :options="sitesCombo" value-field="id" text-field="val" :disabled="data.sites.length === 0"/>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group label="Registered site">
                  <b-form-select v-model="data.company.registered_sites_id" :options="sitesCombo" value-field="id" text-field="val" :disabled="data.sites.length === 0"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Sites" :disabled="data.company.id === 0">
            <companysite ref="site" @update="loadData(data.company.id)"/>
            <b-btn class="mb-2" variant="primary" size="sm" @click="$refs.site.loadData(0, this.data.company.id)">New</b-btn>
            <b-table :sticky-header="(winHeight - 500) + 'px'" responsive :items="data.sites" :fields="fields.sites" @row-clicked="$refs.site.loadData($event.id, this.data.company.id)"/>
          </b-tab>
          <b-tab title="Contacts" :disabled="data.company.id === 0">
            <companycontact ref="contact" @update="loadData(data.company.id)"/>
            <b-row>
              <b-col lg="12">
                <b-btn class="mb-2" variant="primary" size="sm" @click="loadContact( 0, null )">New</b-btn>
                <b-table-simple responsive>
                  <b-thead>
                    <b-tr>
                      <b-th>Mails</b-th>
                      <b-th>Phones</b-th>
                      <b-th>Webs</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr>
                      <b-td>
                        <b-input-group size="sm" v-for="item in data.contacts.mail" :key="item.id" class="mb-2">
                          <b-form-input :ref="'contact' + item.id" :value="item.contact" readonly/>
                          <template #append>
                            <b-dropdown size="sm" variant="info">
                              <b-dropdown-item @click="prepareMail(item.contact, 'Dear ' + data.company.company)">Write a mail</b-dropdown-item>
                              <b-dropdown-item @click="loadContact(item.id, null)">Edit</b-dropdown-item>
                              <b-dropdown-item @click="copyContact(item.id)">Copy</b-dropdown-item>
                            </b-dropdown>
                          </template>
                        </b-input-group>
                      </b-td>
                      <b-td>
                        <b-input-group size="sm" v-for="item in data.contacts.phone" :key="item.id" class="mb-2">
                          <b-form-input :ref="'contact' + item.id" :value="item.contact" readonly/>
                          <template #append>
                            <b-dropdown size="sm" variant="info">
                              <b-dropdown-item @click="loadContact(item.id, null)">Edit</b-dropdown-item>
                              <b-dropdown-item @click="copyContact(item.id)">Copy</b-dropdown-item>
                            </b-dropdown>
                          </template>
                        </b-input-group>
                      </b-td>
                      <b-td>
                        <b-input-group size="sm" v-for="item in data.contacts.web" :key="item.id" class="mb-2">
                          <b-form-input :ref="'contact' + item.id" :value="item.contact" readonly/>
                          <template #append>
                            <b-dropdown size="sm" variant="info">
                              <b-dropdown-item @click="prepareWeb(item.contact)">Navigate</b-dropdown-item>
                              <b-dropdown-item @click="loadContact(item.id, null)">Edit</b-dropdown-item>
                              <b-dropdown-item @click="copyContact(item.id)">Copy</b-dropdown-item>
                            </b-dropdown>
                          </template>
                        </b-input-group>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12">
                <companyworker ref="worker" @update="loadData(data.company.id)"/>
                <b-btn class="mb-2" variant="primary" size="sm" @click="loadWorker(0)">New</b-btn>
                <b-table responsive :items="data.workers" :fields="fields.workers" @row-clicked="loadWorker($event.id)" :tbody-tr-class="rowClassWorker">
                  <template v-slot:cell(mails)="row">
                    <b-input-group size="sm" v-for="item in row.value" :key="item.id" class="mb-2">
                      <b-form-input :ref="'contact' + item.id" :value="item.contact" readonly/>
                      <template #append>
                        <b-dropdown size="sm" variant="info">
                          <b-dropdown-item @click="prepareMail(item.contact, (row.item.titleletter + ' ' + row.item.firstname + ' ' + row.item.lastname).replace('  ', ' '))">Write</b-dropdown-item>
                          <b-dropdown-item @click="loadContact(item.id, row.item.id)">Edit</b-dropdown-item>
                          <b-dropdown-item @click="copyContact(item.id)">Copy</b-dropdown-item>
                        </b-dropdown>
                      </template>
                    </b-input-group>
                  </template>
                  <template v-slot:cell(phones)="row">
                    <b-input-group size="sm" v-for="item in row.value" :key="item.id" class="mb-2">
                      <b-form-input :ref="'contact' + item.id" :value="item.contact" readonly/>
                      <template #append>
                        <b-dropdown size="sm" variant="info">
                          <b-dropdown-item @click="loadContact(item.id, row.item.id)">Edit</b-dropdown-item>
                          <b-dropdown-item @click="copyContact(item.id)">Copy</b-dropdown-item>
                        </b-dropdown>
                      </template>
                    </b-input-group>
                  </template>
                  <template v-slot:cell(webs)="row">
                    <b-input-group size="sm" v-for="item in row.value" :key="item.id" class="mb-2">
                      <b-form-input :ref="'contact' + item.id" :value="item.contact" readonly/>
                      <template #append>
                        <b-dropdown size="sm" variant="info">
                          <b-dropdown-item @click="prepareWeb(item.contact)">Navigate</b-dropdown-item>
                          <b-dropdown-item @click="loadContact(item.id, row.item.id)">Edit</b-dropdown-item>
                          <b-dropdown-item @click="copyContact(item.id)">Copy</b-dropdown-item>
                        </b-dropdown>
                      </template>
                    </b-input-group>
                  </template>
                  <template v-slot:cell(linkedins)="row">
                    <b-input-group size="sm" v-for="item in row.value" :key="item.id" class="mb-2">
                      <b-form-input :ref="'contact' + item.id" :value="item.contact" readonly/>
                      <template #append>
                        <b-dropdown size="sm" variant="info">
                          <b-dropdown-item @click="prepareWeb(item.contact)">Navigate</b-dropdown-item>
                          <b-dropdown-item @click="loadContact(item.id, row.item.id)">Edit</b-dropdown-item>
                          <b-dropdown-item @click="copyContact(item.id)">Copy</b-dropdown-item>
                        </b-dropdown>
                      </template>
                    </b-input-group>
                  </template>
                  <template v-slot:cell(pecs)="row">
                    <b-input-group size="sm" v-for="item in row.value" :key="item.id" class="mb-2">
                      <b-form-input :ref="'contact' + item.id" :value="item.contact" readonly/>
                      <template #append>
                        <b-dropdown size="sm" variant="info">
                          <b-dropdown-item @click="prepareMail(item.contact, (row.item.titleletter + ' ' + row.item.firstname + ' ' + row.item.lastname).replace('  ', ' '))">Write</b-dropdown-item>
                          <b-dropdown-item @click="loadContact(item.id, row.item.id)">Edit</b-dropdown-item>
                          <b-dropdown-item @click="copyContact(item.id)">Copy</b-dropdown-item>
                        </b-dropdown>
                      </template>
                    </b-input-group>
                  </template>
                  <template v-slot:cell(pecfats)="row">
                    <b-input-group size="sm" v-for="item in row.value" :key="item.id" class="mb-2">
                      <b-form-input :ref="'contact' + item.id" :value="item.contact" readonly/>
                      <template #append>
                        <b-dropdown size="sm" variant="info">
                          <b-dropdown-item @click="prepareMail(item.contact, (row.item.titleletter + ' ' + row.item.firstname + ' ' + row.item.lastname).replace('  ', ' '))">Write</b-dropdown-item>
                          <b-dropdown-item @click="loadContact(item.id, row.item.id)">Edit</b-dropdown-item>
                          <b-dropdown-item @click="copyContact(item.id)">Copy</b-dropdown-item>
                        </b-dropdown>
                      </template>
                    </b-input-group>
                  </template>
                  <template v-slot:cell(addcontact)="row">
                    <b-btn class="mb-2" variant="primary" size="sm" @click="loadContact( 0, row.item.id )">Add contact</b-btn>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Mails" :disabled="data.company.id === 0 || data.level < 2">
            <mailnew ref="mailnew" @update="loadData(data.company.id)"/>
            <mail ref="mail" @update="loadData(data.company.id)"/>
            <b-table :sticky-header="(winHeight - 375) + 'px'" :tbody-tr-class="rowClassMails" responsive :items="data.mails" :fields="fields.mails" @row-clicked="loadMail($event.id)"/>            
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Save</b-btn>
          <b-btn variant="danger" class="mr-2" v-if="data.level >= 2" v-show="data.company.id > 0" @click="deleteData">Delete</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import companysite from './companysite.vue';
import companycontact from './companycontact.vue';
import companyworker from './companyworker.vue';

export default {
  name: "company",
  components: { companysite, companycontact, companyworker },
  data() {
    return {
      show: false,
      data: null,
      fields: {
        sites: [{
          key: 'address',
          label: "Address",
          sortable: true,
        },{
          key: 'city',
          label: 'City',
          sortable: true,
        },{
          key: 'active',
          label: 'Status',
          sortable: true,
          formatter: value => {
            return value === true ? 'Active' : 'Not active';
          },
        }],
        workers: [{
          key: 'title',
          label: 'Title',
        },{
          key: 'firstname',
          label: "Firstname",
          sortable: true
        },{
          key: 'lastname',
          label: "Lastname",
          sortable: true
        },{
          key: 'active',
          label: "Status",
          sortable: true,
          formatter: value => {
            return value === true ? 'Active' : 'Not active';
          },
        },{
          key: 'internal',
          label: "Type",
          sortable: true,
          formatter: value => {
            return value === true ? 'Internal' : 'External';
          },
        },{
          key: 'job',
          label: "Job",
        },{
          key: 'workersrolestypes',
          label: "Areas",
        },{
          key: 'note',
          label: "Note",
        },{
          key: 'mails',
          label: "Mails",
        },{
          key: 'phones',
          label: "Phones",
        },{
          key: 'webs',
          label: "Web",
        },{
          key: 'linkedins',
          label: "Linkedin",
        },{
          key: 'pecs',
          label: "PEC",
        },{
          key: 'pecfats',
          label: "PEC invoice",
        },{
          key: 'addcontact',
          label: "",
        }],
        mails: [{
          key: 'folders_id',
          label: "Folder",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            let arr = [{"id" : 1, "val" : "INBOX"}, {"id" : 2, "val" : "Junk"}, {"id" : 3, "val" : "Sent"}, {"id" : 4, "val" : "Trash"}];
            return arr.find(x => x.id === value).val;
          },
        },{
          key: 'from',
          label: 'From',
        },{
          key: 'to',
          label: 'To',
        },{
          key: 'subject',
          label: "Subject",
        },{
          key: 'datetime',
          label: "Date",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            const today = new Date();
            var d = new Date(value);
            if ( d.getDate() == today.getDate() && d.getMonth() == today.getMonth() && d.getFullYear() == today.getFullYear()){
              return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':');
            } else {
              return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
            }
          },
        }],
        mailsCriteria: null,
      },
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
    sitesCombo(){
      return this.data.sites.length > 0 ? this.data.sites.map(x => ({ id: x.id, val: x.address + ' ' + x.city }) ) : [];
    }
  },
  methods: {
    rowClassWorker ( item ){
      return item.active === false ? 'table-warning': '';
    },
    rowClassMails(item) {
      if ( item !== null ) return item.seen === false ? "table-primary" : (item.ping === true ? "table-success" : "");
    },
    copyContact(contactId){
      this.$refs["contact" + contactId][0].select();
      document.execCommand('copy');
    },
    loadData(id){
      if ( this.data === null ) this.show = false;
      this.fields.mailsCriteria = null;
      this.openHttp("companies/company", { id: id }).then((result) => {
        result.company.nacesSupport = this.convertCombo(result.naces, result.company.naces);
        this.data = result;
        this.show = true;
      })
    },
    loadWorker( id ){
      this.$refs.worker.loadData( id, this.data.company.id );
    },
    loadContact( id, workersId ){
      this.$refs.contact.loadData( id, this.data.company.id, workersId );
    },
    loadMail(id){
      if (id !== null) this.$refs.mail.loadData(id);
    },
    prepareMail(contact, contactname){
      this.$refs.mailnew.loadData({
        id: 0,
        mailboxes_id: null,
        to: contact,
        contactname: contactname,
        subject: '',
        subjectOriginal: '',
        body: '',
        bodyOriginal: '',
        companies_id: this.data.company.id,
      });
    },
    prepareWeb(contact){
      window.open("http://" + contact, "_blank");
    },
    saveData(){
      this.saveHttp("companies/company_save", this.data.company).then((result) => {
        if ( result.result == true ) this.loadData(result.id);
      })
    },
    deleteData(){
      this.deleteHttp("companies_company_delete", { id: this.data.company.id }).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    }
  },
}
</script>
<template>
  <div>
    <b-modal v-if="data != null" v-model="show" title="Monitoring" size="lg">
      <monitoringdocument ref="document" @update="loadData(data.monitoring.id)"/>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Monitoring" active>
            <b-row>
              <b-col lg="6">
                <b-form-group label="Monitored">
                  <b-form-input :value="data.monitoring.fullname_monitored"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Date">
                  <b-form-input type="date" :value="data.monitoring.date"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Signature">
                  
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8">
                <b-form-group label="Standards">
                  <b-form-input :value="data.monitoring.standards"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Role">
                  <b-form-input :value="data.monitoring.usersrole"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="6">
                <b-form-group label="Monitoring">
                  <b-form-input :value="data.monitoring.fullname_monitoring"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Sotto accreditamento">
                  <b-form-input :value="data.monitoring.underaccreditation === true ? 'Yes' : 'No'"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Signature">
                  
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3">
                <b-form-group label="Audit plan">
                  <b-form-input type="date" :value="data.monitoring.plandate"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Contract">
                  <b-form-input :value="data.monitoring.contract"/>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group label="Company">
                  <b-form-input :value="data.monitoring.company"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab :title="'Answers - ' + data.answers.length">
            <b-table no-border-collapse :sticky-header="(winHeight - 375) + 'px'" responsive :items="data.answers" :fields="fields.answers">
              <template v-slot:cell(question)="row">
                <div v-for="(item, index) of row.value" :key="index">{{ item.lang + ": " + item.question }}</div>
              </template>
              <template v-slot:cell(rating)="row">
                <div v-if="row.item.evaluation !== null">{{ (row.item.evaluation === true ? "Ok" : "Ko") + " " + row.item.note }}</div>
                <div v-else><b-form-rating v-model="row.value" readonly stars="4"/></div>
              </template>
            </b-table>
            <b-row>
              <b-col lg="2">
                <b-form-group label="Status">
                  <b-form-input :value="data.monitoring.passed ===  null ? 'Pending' : (data.monitoring.passed ===  true ? 'Passed' : 'Failed')"/>
                </b-form-group>
              </b-col>
              <b-col lg="10">
                <b-form-group label="Note">
                  <b-form-textarea :value="data.monitoring.note"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="info" class="mr-2" @click="$refs.document.generateData(data.monitoring.id)">Generate</b-btn>
          <b-btn variant="primary" v-if="data.monitoring.documents_id > 0" @click="$refs.document.downloadData(data.monitoring.documents_id)">Download</b-btn>

        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import monitoringdocument from './monitoringdocument.vue';

export default {
  name: "monitoring",
  components : { monitoringdocument },
  data() {
    return {
      show: false,
      data: null,
      fields: {
        answers: [{
          key: "question",
          label: "Question"
        },{
          key: "rating",
          label: "Evaluation"          
        }]
      },
      documents: []
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    }
  },
  methods: {
    loadData(id){
      this.openHttp("monitorings/monitoring", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    }
  }
}
</script>

<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Certificate" @hidden="closeForm()" @close="closeForm()">
      <mail ref="mail" @update="loadData(data.certificate.id)"/>
      <mailnew ref="mailnew" @update="loadData(data.certificate.id)"/>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Certificate" active>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Company">
                  <b-form-input :value="data.details.company"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="VAT">
                  <b-form-input :value="data.details.stateshort + ' ' + data.details.vat"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Standard">
                  <b-form-input :value="data.details.standard"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="IAFS">
                  <b-form-input :value="data.details.iafs.join(', ')"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Exclusions">
                  <b-form-input :value="data.details.exclusions.join(', ')"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12">
                <b-form-group label="Sites">
                  <b-table no-border-collapse :items="data.details.sites" :fields="fields.sites"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12">
                <b-form-group label="Scopes">
                  <b-table no-border-collapse :items="data.details.scopes" :fields="fields.scopes"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Releases">
            <b-btn variant="primary" size="sm" @click="addRelease()" :disabled="data.certificate.releases.findIndex(x => x.id === 0) > -1">New</b-btn>
            <b-table no-border-collapse class="mt-2" :items="data.certificate.releases" :fields="fields.releases">
              <template v-slot:cell(revnumber)="row">
                <b-form-input type="number" v-model="row.item.revnumber" :disabled="row.item.iafhash !== null"/>
              </template>
              <template v-slot:cell(issuedate)="row">
                <b-form-input type="date" v-model="row.item.issuedate"/>
              </template>
              <template v-slot:cell(validfromdate)="row">
                <b-form-input type="date" v-model="row.item.validfromdate"/>
              </template>
              <template v-slot:cell(expiracydate)="row">
                <b-form-input type="date" v-model="row.item.expiracydate"/>
              </template>
              <template v-slot:cell(note)="row">
                <b-form-input v-model="row.item.note"/>
              </template>
              <template v-slot:cell(id)="row">
                <b-btn variant="danger" size="sm" v-if="row.item.iafhash === null" @click="data.certificate.releases.splice(row.index, 1)">Delete</b-btn>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Actions">
            <b-btn variant="primary" size="sm" @click="addHistory()" :disabled="data.certificate.histories.findIndex(x => x.id === 0) > -1">New</b-btn>
            <b-table no-border-collapse class="mt-2" :items="data.certificate.histories" :fields="fields.histories">
              <template v-slot:cell(contractsstandardsstatus_id)="row">
                <b-form-select v-model="row.item.contractsstandardsstatus_id" :options="data.contractsstandardsstatus" value-field="id" text-field="val"/>
              </template>
              <template v-slot:cell(users_id)="row">
                <b-form-select v-model="row.item.users_id" :options="data.users" value-field="id" text-field="val"/>
              </template>                
              <template v-slot:cell(date)="row">
                <b-form-input type="date" v-model="row.item.date"/>
              </template>
              <template v-slot:cell(audits_id)="row">
                <b-form-select v-model="row.item.audits_id" :options="data.audits.concat({id: null, val: 'None'})" value-field="id" text-field="val"/>
              </template>
              <template v-slot:cell(contractsstandardsreleases_id)="row">
                <b-form-select v-model="row.item.contractsstandardsreleases_id" :options="data.certificate.releases.filter(x => x.id > 0).map(x => ({ id: x.id, val: 'rev. ' + x.revnumber + ' of ' + x.issuedate}))" value-field="id" text-field="val"/>
              </template>
              <template v-slot:cell(note)="row">
                <b-form-input v-model="row.item.note"/>
              </template>
              <template v-slot:cell(id)="row">
                <b-btn variant="danger" size="sm" v-if="row.item.id === 0" @click="data.certificate.histories.splice(row.index, 1)">Delete</b-btn>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Mails">
            <b-btn variant="primary" size="sm" @click="mailNew()">New</b-btn>
            <b-table :sticky-header="(winHeight - 405) + 'px'" class="mt-2" :tbody-tr-class="rowClassMails" responsive :items="data.mails" :fields="fields.mails" @row-clicked="$refs.mail.loadData($event.id)"/>
          </b-tab>
          <b-tab title="Documents">
            <document ref="document" @update="loadData(data.certificate.id)"/>
            <b-btn variant="info" size="sm" class="mr-2" @click="$refs.document.generateData(data.certificate.conttracts_id)">Prepare</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 205)+ 'px'" :tbody-tr-class="rowClassDocuments" class="mt-3" :items="data.documents" :fields="fields.documents" @row-clicked="$refs.document.loadData(data.certificate.id, $event.id)">
              <template v-slot:cell(id)="row">
                <b-btn variant="primary" size="sm" @click="$refs.document.downloadData(row.value)">Download</b-btn>
              </template>            
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="loadData(data.certificate.id)">Refresh</b-btn>
          <b-btn variant="success" @click="saveData">Save</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import document from './document.vue';

export default {
  name: "certificate",
  components : {
    document
  },
  data() {
    return {
      show: true,
      data: null,
      fields: {
        sites: [{
          key: 'site',
          label: "Site",
        },{
          key: 'main',
          label: 'Main',
          formatter: value => {
            return value === true ? "Yes" : "No";
          }
        },{
          key: 'registered',
          label: 'Registered',
          formatter: value => {
            return value === true ? "Yes" : "No";
          },
        },{
          key: 'employeesnumber',
          label: "Employees number",
        },{
          key: 'employeeseffectivenumber',
          label: "Employees effective number"
        },{
          key: 'employeesexternal',
          label: 'Employees external',
        },{
          key: 'employeesrepetitive',
          label: 'Employees repetitive',
        },{
          key: 'shifts',
          label: 'Shifts',
        },{
          key: 'naces',
          label: "Naces"
        }],
        scopes: [{
          key: 'lang',
          label: 'Lang',
        },{
          key: 'scope',
          label: 'Scope'
        }],
        releases: [{
          key: 'revnumber',
          label: "Rev",
          sortable: true
        },{
          key: 'issuedate',
          label: "Issue date",
          sortable: true,
        },{
          key: 'validfromdate',
          label: 'Valid from date',
          sortable: true,
        },{
          key: 'expiracydate',
          label: 'Expiry date',
          sortable: true,
        },{
          key: 'note',
          label: "Note"
        },{
          key: 'iafdate',
          label: 'IAF date',
          sortable: true,
          formatter: value => {
            if (value === null) return null;
            let d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'iafhash',
          label: 'Hash',
          formatter: value => {
            return value !== null ? "Yes" : "No";
          }
        },{
          key: "id",
          label: ""
        }],
        histories: [{
          key: 'contractsstandardsstatus_id',
          label: "Status"
        },{
          key: 'users_id',
          label: "Decided by"
        },{
          key: 'date',
          label: 'Date',
          sortable: true
        },{
          key: 'audits_id',
          label: "Audit"
        },{
          key: 'contractsstandardsreleases_id',
          label: "Release"
        },{
          key: 'note',
          label: 'Note'
        },{
          key: "id",
          label: ""
        }],
        mails: [{
          key: 'folder',
          label: "Folder",
          sortable: true
        },{
          key: 'from',
          label: 'By',
        },{
          key: 'to',
          label: 'To',
        },{
          key: 'subject',
          label: "Dubject",
        },{
          key: 'datetime',
          label: "Date",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            const today = new Date();
            var d = new Date(value);
            if ( d.getDate() == today.getDate() && d.getMonth() == today.getMonth() && d.getFullYear() == today.getFullYear()){
              return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':');
            } else {
              return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
            }
          },
        }],
        mailsCriteria: null,
        documents: [{
          key: "filename",
          label: "File",
          sortable: true,
        },{
          key: "id",
          label: "",
        }],
      },
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadData(id){
      this.openHttp("certificates/certificate", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    mailNew(){
      this.openHttp("certificates/certificate/mail", { id: this.data.certificate.id }).then((result) => {
        this.$refs.mailnew.loadData(result);
      })
    },
    addRelease(){
      this.data.certificate.releases.push({
        id: 0,
        revnumber: Math.max(...this.data.certificate.releases.map(x => x.revnumber)) + 1,
        issuedate: null,
        validfromdate: null,
        expiracydate: null,
        note: null,
        iafdate: null,
        iafhash: null
      });
    },
    addHistory(){
      this.data.certificate.histories.push({
        id: 0,
        contractsstandardsstatus_id: 1,
        users_id: null,
        date: null,
        audits_id: null,
        contractsstandardsreleases_id: null,
        note: null
      });
    },

    downloadDocument( id ){
      this.$refs.document.downloadData(id);
    },
    saveData(){
      this.saveHttp("certificates/certificate/save", this.data.certificate, "Certificarte saved!" ).then((result) => {
        if (result !== null) this.loadData(this.data.certificate.id);
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>
<template>
    <div>
        <b-modal v-model="show" v-if="data !== null" title="Non conformities to be imported" size="lg" hide-footer>
            <b-table responsive :fields="fields" :items="data.nonconformities" :sticky-header="(winHeight - 475) + 'px'">
                <template v-slot:cell(id)="row">
                    <b-btn variant="primary" size="sm" @click="saveData(row.value)">Import</b-btn>
                </template>
            </b-table>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "auditnonconformitytobeclosedimport",
    data() {
        return {
            show: false,
            data: null,
            fields: [{
                key: 'plandate',
                label: "Date",
                formatter: value => {
                    if (value == null) return null;
                    var d = new Date(value);
                    return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
                }
            },{
                key: "nctype",
                label: "Type",
            },{
                key: "nonconformity",
                label: "Non conformity",
            },{
                key: "causes",
                label: "Causes",
            },{
                key: "causes",
                label: "Causes",
            },{
                key: "treatment",
                label: "Treatment",
            },{
                key: "correctiveaction",
                label: "Corrective action",
            },{
                key: "effectivenessdecision",
                label: "Effectiveness",
                formatter: value => {
                    if (value == null) return null;
                    return value === true ? "Approved" : (value === false ? "Rejected" : "Pending")
                }
            },{
                key: "effectivenessnote",
                label: "Note",
            },{
                key: "standardspoints",
                label: "Points",
            },{
                key: "id",
                label: "",
            }]
        }
    },
    computed: {
        winHeight (){
            return this.$store.getters.getWinHeight;
        },
    },
    methods: {
        loadData( id ){
            this.openHttp("audits/nonconformitytobeclosed/import", { id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData( id ){
            this.saveHttp("audits/nonconformitytobeclosed/importsave", { audits_id: this.data.audits_id, id: id }).then((result) => {
                if ( result !== null ) {
                    this.loadData(this.data.audits_id);
                    this.$emit('update');
                }
            })
        }
    }
}
</script>
<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" title="Checklist" scrollable @hidden="closeForm()" @close="closeForm()">
      <checklistsite ref="site" @update="loadData(data.id)"/>
      <checklistimpartiality ref="impartiality" @update="loadData(data.id)"/>
      <checklistreservation ref="reservation" @update="loadData(data.id)"/>
      <checklistworker ref="worker" @update="loadData(data.id)"/>
      <checklistopportunitytobeclosed ref="opportunitytobeclosed" @update="loadData(data.id)"/>
      <checklistnonconformitytobeclosed ref="nonconformitytobeclosed" @update="loadData(data.id)"/>
      <checkliststrength ref="strength" @update="loadData(data.id)"/>
      <checklistopportunity ref="opportunity" @update="loadData(data.id)"/>
      <checklistnonconformity ref="nonconformity" @update="loadData(data.id)"/>
      <checklistsignature ref="signature" @update="loadData(data.id)"/>
      <checklistreport ref="report" @update="loadData(data.id)"/>
      <checklistsdocument ref="document" @update="loadData(data.id)"/>
      <b-card no-body>
        <b-tabs pills card vertical>
          <b-tab title="Company data" active>
            <b-card no-body>
              <b-tabs card>
                <b-tab v-for="(item, index) of data.audit" :key="index" :title="item.company + ' ' + item.standards">
                  <b-table-simple responsive>
                    <b-thead head-variant="light">
                      <b-tr>
                        <b-th style="width: 25%">Parameter</b-th>
                        <b-th>Value</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr>
                        <b-td>Company</b-td>
                        <b-td>{{ item.company }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td>VAT number</b-td>
                        <b-td>{{ item.vat }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td>Scopes</b-td>
                        <b-td>
                          <div v-for="(item, index) of item.scopes" :key="index"><strong>{{ item.lang }}: </strong> {{ item.scope }}</div>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td>IAF / NACE</b-td>
                        <b-td>
                          {{ item.iafs }} / {{ item.naces }}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td>Sites on the certificate</b-td>
                        <b-td>
                          <div v-for="(item, index) of item.contractssites" :key="index">{{ item }}</div>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td>Standards and phases</b-td>
                        <b-td>
                          <div v-for="(item, index) of item.standardsphases" :key="index">{{ item.standard + ": " + item.phases }}</div>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td>Points not to be verified</b-td>
                        <b-td>
                          <div v-for="(item, index) of item.exclusions" :key="index">{{ item.standard + ': ' + item.exclusion }}</div>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-tab>
              </b-tabs>
            </b-card>
          </b-tab>
          <b-tab title="Sites and employees">
            <b-tabs card>
              <b-tab v-for="(item, index) of data.audit" :key="index" :title="item.company + ' ' + item.standards">
                <b-btn variant="primary" size="sm" @click="$refs.site.loadData(0, item.id)">New</b-btn>
                <b-table responsive :items="item.sites" :fields="fields.sites" :sticky-header="(winHeight - 275) + 'px'" class="mt-3" @row-clicked="$refs.site.loadData($event.id, item.id)" :tbody-tr-class="rowClassSites"/>
              </b-tab>
            </b-tabs>
          </b-tab>
          <b-tab title="Impartialities" v-if="data.auditsstatus_id === 2">
            <b-table responsive :items="data.impartialities" :fields="fields.impartialities" :sticky-header="(winHeight - 285) + 'px'" @row-clicked="$refs.impartiality.loadData($event.id)"/>
          </b-tab>
          <b-tab title="Plan">
            <b-card no-body>
              <b-tabs card>
                <b-tab v-for="(item, index) of data.audit" :key="index" :title="item.company + ' ' + item.standards">
                  <b-table-simple responsive :sticky-header="(winHeight - 385) + 'px'">
                    <b-thead>
                      <b-tr>
                        <b-th>From</b-th>
                        <b-th>To</b-th>
                        <b-th>Type</b-th>
                        <b-th>Team</b-th>
                        <b-th>Points</b-th>
                        <b-th>Sites</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <template v-for="(item2, index2) of item.reservations">
                        <b-tr :key="index2">
                          <b-th colspan="6">
                            <b-btn class="mr-2" size="sm">{{ item2.date }}</b-btn>
                            <b-btn class="mr-2" size="sm" variant="success" @click="$refs.reservation.loadData(item.id, 0, item2.date)">New</b-btn>
                            <b-btn size="sm" :variant="item.todo === item.done ? 'success' : 'warning'">{{ (item2.todo - item2.done) !== 0 ? ( item2.todo > item2.done ? ('Missing ' + (Math.round((item2.todo - item2.done) * 10000) / 10000) + 'h' ) : 'Excess ' + (Math.round((item2.done - item2.todo) * 10000) / 10000) + 'h') : 'OK' }}</b-btn>
                          </b-th>
                        </b-tr>
                        <b-tr v-for="(item3, index3) of item2.reservations" :key="index3" @click="$refs.reservation.loadData(item.id, item3.id, item2.date)">
                          <b-td>{{ item3.fromtime }}</b-td>
                          <b-td>{{ item3.totime }}</b-td>
                          <b-td>{{ item3.auditstype }}</b-td>
                          <b-td>
                            <div v-for="(item4, index4) of item3.usersroles" :key="index4">
                              <nobr><strong>{{ item4.user + ' ' + item4.role }}</strong></nobr> {{ item4.standardsphases }}
                            </div>
                          </b-td>
                          <b-td>
                            <div v-for="(item4, index4) of item3.standardspoints" :key="index4">
                              <nobr><strong>{{ item4.standard }}</strong> {{ item4.points }}</nobr>
                            </div>
                          </b-td>
                          <b-td>{{ item3.site }}</b-td>
                        </b-tr>
                      </template>
                    </b-tbody>
                  </b-table-simple>
                </b-tab>
              </b-tabs>
            </b-card>
          </b-tab>
          <b-tab title="Client approval" v-if="data.auditsstatus_id === 2">
            <b-card no-body>
              <b-tabs card>
                <b-tab v-for="(item, index) of data.audit" :key="index" :title="item.company + ' ' + item.standards">
                  <b-table-simple responsive>
                    <b-thead head-variant="light">
                      <b-tr>
                        <b-th style="width: 25%">Approved by</b-th>
                        <b-th>Signature</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr>
                        <b-td>{{ item.worker }}</b-td>
                        <b-td>
                          <b-btn :variant="item.signature_initial === true ? 'success' : 'danger'" size="sm">{{ item.signature_initial === true ? 'Approved' : 'To be approved' }}</b-btn>                  
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-tab>
              </b-tabs>
            </b-card>
          </b-tab>
          <b-tab title="Participants" v-if="data.auditsstatus_id >= 3">
            <b-card no-body>
              <b-tabs card>
                <b-tab :title="item.company + ' ' + item.standards" v-for="(item, index) of data.presencesworkers" :key="index">
                  <b-btn variant="primary" size="sm" @click="$refs.worker.loadData(0, item.companies_id, data.id)">New</b-btn>
                  <b-table responsive :sticky-header="(winHeight - 435) + 'px'" class="mt-3" :items="item.workers" :fields="fields.presencesworkers" @row-clicked="$refs.worker.loadData($event.workers_id, item.companies_id, data.id)">
                    <template v-slot:cell(fullname)="row">
                      <div class="mb-2">{{ row.value }}</div>
                    </template>
                    <template v-slot:cell(phases)="row">
                      <div v-for="(item2, index2) of row.item.phases" :key="index2" class="mb-1">
                        <nobr>
                          <b-btn :variant="item2.presence === true ? 'success' : 'warning'" class="mr-2" size="sm" @click="markWorkerPresence(item.id, item2.auditsphases_id, row.item.workers_id)">{{ item2.phase }}: Present</b-btn>
                          <b-btn v-show="item2.presence === true" :variant="item2.signaturein_id !== null ? 'success' : 'danger'" class="mr-2" size="sm" @click="markWorkerOpeningMeeting(item2.presences_id)">Opening meeting</b-btn>
                          <b-btn v-show="item2.presence === true" :variant="item2.signatureout_id !== null ? 'success' : 'danger'" class="mr-2" size="sm" @click="markWorkerClosingMeeting(item2.presences_id)">Closing meeting</b-btn>
                        </nobr>
                      </div>
                    </template>
                    <template v-slot:cell(signatures)="row">
                      <div v-for="(item2, index2) of row.item.phases" :key="index2" class="mb-1">
                        <nobr>
                          <b-btn v-show="item2.signaturein_id > 0" :variant="item2.signaturein_check === true ? 'success' : 'danger'" class="mr-2" size="sm" @click="loadSignature(item2.signaturein_id, item.id)">Signature</b-btn>
                          <b-btn v-show="item2.signatureout_id > 0" :variant="item2.signatureout_check === true ? 'success' : 'danger'" class="mr-2" size="sm" @click="loadSignature(item2.signatureout_id, item.id)">Signature</b-btn>
                        </nobr>
                      </div>
                    </template>
                  </b-table>
                </b-tab>
              </b-tabs>
            </b-card>
          </b-tab>
          <b-tab title="Auditors" v-if="data.auditsstatus_id >= 3">
            <b-card no-body>
              <b-tabs card>
                <b-tab :title="item.company + ' ' + item.standards" v-for="(item, index) of data.presencesauditsusers" :key="index">
                  <b-table responsive :sticky-header="(winHeight - 435) + 'px'" class="mt-3" :items="item.users" :fields="fields.presencesusers">
                    <template v-slot:cell(signaturein)="row">
                      <b-btn size="sm" :variant="row.item.signaturein_check === true ? 'success' : 'danger'" @click="loadSignature(row.item.signaturein_id, item.id)">Signature</b-btn>
                    </template>
                    <template v-slot:cell(signatureout)="row">
                      <b-btn size="sm" :variant="row.item.signatureout_check === true ? 'success' : 'danger'" @click="loadSignature(row.item.signatureout_id, item.id)">Signature</b-btn>
                    </template>
                  </b-table>
                </b-tab>
              </b-tabs>
            </b-card>
          </b-tab>
          <b-tab v-for="(paragraph, paragraphIndex) of data.evidences" :key="paragraphIndex" :title="paragraph.paragraph + ' '  + paragraph.percentage + '%'">
            <b-table small responsive :sticky-header="(winHeight - 275) + 'px'" :items="paragraph.questions" :fields="fields.evidences" :tbody-tr-class="rowClassEvidences">
              <template v-slot:cell(question)="row">
                <h6>{{ row.item.question }}</h6>
                <b-form-checkbox switch v-show="row.item.notapplicable === true" v-model="row.item.isnotapplicable" @change="saveNotApplicable(row.item.id, $event)">Not applicable</b-form-checkbox>
                <div v-if="row.item.notapplicable === false || row.item.isnotapplicable === false">
                  <b-row>
                    <b-col v-for="(subquestion, subquestionIndex) in row.item.subquestions" :key="subquestionIndex" :lg="subquestion.width">
                      <b-form-group :label="subquestion.types_id !== 7 ? subquestion.question : ''" v-if="subquestion.types_id !== 9">
                        <b-form-input size="sm" v-if="subquestion.types_id === 1" :value="subquestion.evidence" @change="saveEvidence(subquestion.id, $event)"/>
                        <b-form-textarea size="sm" v-if="subquestion.types_id === 2" :value="subquestion.evidence" rows="2" max-rows="10" @change="saveEvidence(subquestion.id, $event)"/>
                        <b-form-input size="sm" v-if="subquestion.types_id === 3" :value="subquestion.evidence" type="date" @blur="saveEvidence(subquestion.id, $event.target.value)"/>
                        <b-form-input size="sm" v-if="subquestion.types_id === 4" :value="subquestion.evidence" type="number" step="0.01" @change="saveEvidence(subquestion.id, $event)"/>
                        <b-form-select size="sm" v-if="subquestion.types_id === 5" :value="subquestion.evidence" :options="data.possibleanswers.find(x => x.id === subquestion.subquestions_id).answers" value-field="id" text-field="val" @change="saveEvidence(subquestion.id, $event)"/>
                        <b-form-checkbox size="sm" v-if="subquestion.types_id === 7" v-model="subquestion.evidence" value="t" unchecked-value="f" @change="saveEvidence(subquestion.id, $event)">{{ subquestion.question }}</b-form-checkbox>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Non conformities to be verified">
            <b-table responsive :items="data.nonconformitiestobeclosed" :fields="fields.nonconformitiestobeclosed" :sticky-header="(winHeight - 285) + 'px'" @row-clicked="$refs.nonconformitytobeclosed.loadData($event.id, data.id)"/>
          </b-tab>
          <b-tab title="Findings to be verified">
            <b-table responsive :items="data.opportunitiestobeclosed" :fields="fields.opportunitiestobeclosed" :sticky-header="(winHeight - 285) + 'px'" @row-clicked="$refs.opportunitytobeclosed.loadData($event.id, data.id)"/>
          </b-tab>
          <b-tab title="Strengths" v-if="data.auditsstatus_id >= 3">
            <b-btn variant="success" size="sm" @click="$refs.strength.loadData(0,data.id)">New</b-btn>
            <b-table responsive :items="data.strengths" :fields="fields.strengths" :sticky-header="(winHeight - 285) + 'px'" class="mt-2" @row-clicked="$refs.strength.loadData($event.id, data.id)"/>
          </b-tab>
          <b-tab title="Findings" v-if="data.auditsstatus_id >= 3">
            <b-btn variant="success" size="sm" @click="$refs.opportunity.loadData(0, data.id)">New</b-btn>
            <b-table responsive :items="data.opportunities" :fields="fields.opportunities" :sticky-header="(winHeight - 285) + 'px'" class="mt-2" @row-clicked="$refs.opportunity.loadData($event.id, data.id)"/>
          </b-tab>
          <b-tab title="Non conformities" v-if="data.auditsstatus_id >= 3">
            <b-btn variant="success" size="sm" @click="$refs.nonconformity.loadData( 0, data.id)">New</b-btn>
            <b-table responsive :items="data.nonconformities" :fields="fields.nonconformities" :sticky-header="(winHeight - 285) + 'px'" class="mt-2" @row-clicked="$refs.nonconformity.loadData($event.id, data.id)"/>
          </b-tab>
          <b-tab title="Report" v-if="data.auditsstatus_id >= 3">
            <b-btn variant="info" size="sm" class="mb-2" @click="$refs.report.generateData(data.id)">Generate</b-btn>
            <b-card no-body>
              <b-tabs card>
                <b-tab v-for="(item, index) of data.audit" :key="index" :title="item.company + ' ' + item.standards">
                  <b-table responsive :items="item.report" :fields="fields.report" :sticky-header="(winHeight - 435) + 'px'" class="mt-2" @row-clicked="$refs.nonconformity.loadData($event.id, data.id)">
                    <template v-slot:cell(languages)="row">
                      <b-row>
                        <b-col v-for="(item2, index2) of row.value" :key="index2">
                          <b>{{ item2.lang }}</b><span v-html="item2.report" @click="$refs.report.loadData(item.id, row.item.id, item2.id)"></span>
                        </b-col>
                      </b-row>
                    </template>
                  </b-table>
                </b-tab>
              </b-tabs>
            </b-card>
          </b-tab>
          <b-tab title="Closing meeting" v-if="data.auditsstatus_id >= 3">
            <b-card no-body>
              <b-tabs card>
                <b-tab v-for="(item, index) of data.audit" :key="index" :title="item.company + ' ' + item.standards">
                  <b-table-simple responsive>
                    <b-thead head-variant="light">
                      <b-tr>
                      </b-tr>
                      <b-tr>
                        <b-th style="width: 55%">Standard</b-th>
                        <b-th style="width: 15%">Strengths</b-th>
                        <b-th style="width: 15%">Opportunities</b-th>
                        <b-th style="width: 15%">Non conformities</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr v-for="(item2, index2) of item.standardsphases.filter(x => x.phases_id !== 1)" :key="index2">
                        <b-td>{{ item2.standard + ": " + item2.phases }}</b-td>
                        <b-td>{{ item2.strengths }}</b-td>
                        <b-td>{{ item2.opportunities }}</b-td>
                        <b-td>{{ item2.nonconformities }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                  <b-form-group :label="'Lead auditor decision ' + item2.phase" v-for="(item2, index2) of item.decisions" :key="index2">
                    <b-row>
                      <b-col lg="3">
                        <b-form-select size="sm" v-model="item2.decision" @change="saveDecision(item.id, item2.auditsphases_id, $event, $event === false ? item2.note : null)">
                          <b-form-select-option :value=null>please select an option</b-form-select-option>
                          <b-form-select-option :value=true>Positive</b-form-select-option>
                          <b-form-select-option :value=false>Negative</b-form-select-option>
                        </b-form-select>
                      </b-col>
                      <b-col lg="9">
                        <b-form-textarea size="sm" v-show="item2.decision == false" v-model="item2.note" rows="2" @change="saveDecision(item.id, item2.auditsphases_id, item2.decision, $event)"/>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-form-group label="Approval of the company">
                    <b-btn :variant="item.signature_final === true ? 'success' : 'danger'" size="sm" @click="loadSignature(item.signature_final_id, item.id)">{{ item.signature_final === true ? 'Approved' : 'To be approved' }}</b-btn>                  
                  </b-form-group>                  
                </b-tab>
              </b-tabs>
            </b-card>
          </b-tab>
          <b-tab title="Vetos" v-if="data.auditsstatus_id >= 4">
            <b-card no-body>
              <b-tabs card>
                <b-tab v-for="(item, index) of data.audit" :key="index" :title="item.company + ' ' + item.standards">
                  <b-table responsive :items="item.vetos" :fields="fields.vetos" :sticky-header="(winHeight - 430) + 'px'"/>
                </b-tab>
              </b-tabs>
            </b-card>
          </b-tab>
          <b-tab title="Documents">
            <b-btn variant="info" class="mr-2" size="sm" @click="$refs.document.generateData(data.id)">Generate</b-btn>
            <b-btn variant="primary" class="mr-2" size="sm" @click="$refs.document.downloadDataAll(data.id)">Download all</b-btn>
            <b-table class="mt-2" :sticky-header="(winHeight - 305) + 'px'" :tbody-tr-class="rowClassDocuments" hover responsive :items="data.documents" :fields="fields.documents" @row-clicked="$refs.document.loadData(data.id, $event.id)">
              <template v-slot:cell(id)="row">
                <b-btn variant="warning" class="mr-2" v-show="row.item.datetime !== null && row.item.locked === false" size="sm" @click="$refs.document.lockData(row.item.id)">Lock</b-btn>
                <b-btn variant="primary" v-show="row.item.datetime !== null" size="sm" @click="$refs.document.downloadData(row.item.id)">Download</b-btn>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="loadData(data.id)">Refresh</b-btn>
          <b-btn variant="info" :href="'#/externalaudit/' + data.token" target="_blank">Client link</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import checklistsite from './checklistcomponents/checklistsite.vue';
import checklistimpartiality from './checklistcomponents/checklistimpartiality.vue';
import checklistreservation from './checklistcomponents/checklistreservation.vue';
import checklistworker from './checklistcomponents/checklistworker.vue';
import checklistsignature from './checklistcomponents/checklistsignature.vue';
import checklistopportunitytobeclosed from './checklistcomponents/checklistopportunitytobeclosed.vue';
import checklistnonconformitytobeclosed from './checklistcomponents/checklistnonconformitytobeclosed.vue';
import checkliststrength from './checklistcomponents/checkliststrength.vue';
import checklistopportunity from './checklistcomponents/checklistopportunity.vue';
import checklistnonconformity from './checklistcomponents/checklistnonconformity.vue';
import checklistreport from './checklistcomponents/checklistreport.vue';
import checklistsdocument from './checklistcomponents/checklistsdocument.vue';

export default {
  name: "checklist",
  components : { checklistsite, checklistimpartiality, checklistreservation, checklistworker, checklistsignature, checklistopportunitytobeclosed, checklistnonconformitytobeclosed, checkliststrength, checklistopportunity, checklistnonconformity, checklistreport, checklistsdocument },
  data() {
    return {
      show: false,
      data: null,
      fields: {
        sites: [{
          key: "site",
          label: "Site",
        },{
          key: "active",
          label: "Status",
          formatter: value => {
            return value === true ? "Active" : "Not active";
          }
        },{
          key: "presence",
          label: "Sampled",
          formatter: value => {
            return value === true ? "Sampled" : "Not sampled";
          }
        },{
          key: "shifts",
          label: "Shifts",
        },{
          key: "employeesnumber",
          label: "Employees number",
        },{
          key: "employeeseffectivenumber",
          label: "Employees effective number",
        },{
          key: "employeesexternal",
          label: "Employees external",
        },{
          key: "employeesrepetitive",
          label: "Employees repetitive",
        },{
          key: "naces",
          label: "Activities",
        },{
          key: "locked",
          label: "",
          formatter: value => {
            return value === true ? "Confirmed" : "Not confirmed";
          }
        }],
        impartialities: [{
          key: "fullname",
          label: "Fullname",
        },{
          key: "gdpr",
          label: "GDPR",
          formatter: value => {
            return value === true ? "Approved" : (value === false ? "No" : "Pending");
          }
        },{
          key: "noconsultancy3years",
          label: "NO consultancy",
          formatter: value => {
            return value === true ? "Approved" : (value === false ? "No" : "Pending");
          }
        },{
          key: "nointernalaudit3years",
          label: "NO internal audit",
          formatter: value => {
            return value === true ? "Approved" : (value === false ? "No" : "Pending");
          }
        },{
          key: "nocontractual3years",
          label: "NO contractual",
          formatter: value => {
            return value === true ? "Approved" : (value === false ? "No" : "Pending");
          }
        },{
          key: "interestofcompetiting",
          label: "NO interest of competiting",
          formatter: value => {
            return value === true ? "Approved" : (value === false ? "No" : "Pending");
          }
        }],
        workers: [{
          key: "fullname",
          label: "Fullname",
        },{
          key: "active",
          label: "Status",
          formatter: value => {
            return value === true ? "Active" : "Not active";
          }
        },{
          key: "job",
          label: "Job",
        },{
          key: "roles",
          label: "Areas",
        },{
          key: "signatures",
          label: "Signatures",
        }],
        presencesworkers: [{
          key: "fullname",
          label: "Fullname"
        },{
          key: "job",
          label: "Job"
        },{
          key: "roles",
          label: "Areas"
        },{
          key: "phases",
          label: "Phases"
        },{
          key: "signatures",
          label: "Signatures"
        }],
        presencesusers: [{
          key: "fullname",
          label: "Fullname"
        },{
          key: "phase",
          label: "Phase"
        },{
          key: "signaturein",
          label: "Opening meeting"
        },{
          key: "signatureout",
          label: "Closing meeting"
        }],
        users: [{
          key: "fullname",
          label: "Fullname",
        },{
          key: "signatures",
          label: "Signatures",
        }],
        evidences: [{
          key: "points",
          label: "Points",
        },{
          key: "question",
          label: "Question",
        }],
        opportunitiestobeclosed: [{
          key: "opportunity",
          label: "Finding",
        },{
          key: "closure",
          label: "Verify",
        },{
          key: "standardspoints",
          label: "Points",
        }],
        strengths: [{
          key: "strength",
          label: "Strength",
        },{
          key: "standardspoints",
          label: "Points",
        }],
        opportunities: [{
          key: "opportunity",
          label: "Finding",
        },{
          key: "standardspoints",
          label: "Points",
        }],
        nonconformities: [{
          key: "nonconformity",
          label: "Non conformity",
        },{
          key: "nctype",
          label: "Type",
        },{
          key: "cause",
          label: "Cause",
        },{
          key: "treatment",
          label: "Correction",
        },{
          key: "correctiveaction",
          label: "Corrective action",
        },{
          key: "standardspoints",
          label: "Points",
        }],
        nonconformitiestobeclosed: [{
          key: "nonconformity",
          label: "Non conformity",
        },{
          key: "nctype",
          label: "Type",
        },{
          key: "causes",
          label: "Causes",
        },{
          key: "treatment",
          label: "Correction",
        },{
          key: "correctiveaction",
          label: "Corrective action",
        },{
          key: "effectivenessdecision",
          label: "Effectiveness",
          formatter: value => {
            return value === true ? "Accepted" : (value === false ? "Not accepted" : "Pending");
          }
        },{
          key: "effectivenessnote",
          label: "Note"
        },{
          key: "standardspoints",
          label: "Points",
        }],
        report: [{
          key: "point",
          label: "Point",
        },{
          key: "languages",
          label: "Report",
        }],
        vetos: [{
          key: "standard",
          label: "Standard",
        },{
          key: "point",
          label: "Point",
        },{
          key: "status",
          label: "Status",
          formatter: value => {
            return value === true ? "Accepted" : (value === false ? "Not accepted" : "Pending");
          }
        },{
          key: "note",
          label: "Note",
        }],
        documents: [{
          key: "filename",
          label: "File",
          sortable: true,
        },{
          key: "id",
          label: "",
        }],
      }
    }
  },
  computed: {
    token (){
      return this.$store.getters.getToken;
    },
    winHeight (){
      return this.$store.getters.getWinHeight;
    }
  },
  methods: {
    rowClassSites(item, type) {
      if (!item || type !== 'row') return
      return "table-" + (item.active === false ? 'danger' : (item.locked === true ? "success" : "warning"));
    },
    rowClassEvidences(item, type) {
      if (!item || type !== 'row') return
      return item.isnotapplicable === true ? 'table-danger' : item.done === false ? 'table-warning' : '';
    },
    rowClassDocuments(item) {
      return item !== null && item.datetime === null ? "table-danger" : (item.locked === false ? "table-warning" : "");
    },
    fieldsCleanedEvidences(){
      return this.fields.evidences.map((element) => {
        element.label = this.translate(element.label);
        return element
      });
    },
    loadData(id){
      if ( this.data === null ) this.show = false;
      this.openHttp("checklists/checklist", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveWorkerRepresentative(auditsId, workerId){
      this.saveHttp("checklists/worker/representativesave", { audits_id: auditsId, workers_id: workerId }).then((result) => {
        if ( result !== null ) this.loadData(this.data.id);
      })
    },
    markWorkerPresence(auditsId, auditsPhasesId, workersId){
      this.saveHttp("checklists/worker/presence", { audits_id: auditsId, auditsphases_id: auditsPhasesId, workers_id: workersId }).then((result) => {
        if ( result !== null ) this.loadData(this.data.id);
      })
    },
    markWorkerOpeningMeeting(presencesId){
      this.saveHttp("checklists/worker/presenceopening", { presences_id: presencesId }).then((result) => {
        if ( result !== null ) this.loadData(this.data.id);
      })
    },
    markWorkerClosingMeeting(presencesId){
      this.saveHttp("checklists/worker/presenceclosing", { presences_id: presencesId }).then((result) => {
        if ( result !== null ) this.loadData(this.data.id);
      })
    },
    loadSignature(id, auditsId){
      this.$refs.signature.loadData( id, auditsId );
    },
    saveEvidence( subQuestionsId, evidence ){
      this.saveHttp("checklists/evidence/save", { audits_id: this.data.id, subquestions_id: subQuestionsId, evidence: evidence }).then((result) => {
        if ( result !== null ) this.loadData(this.data.id);
      })
    },
    saveNotApplicable( questionsId, notApplicable ){
      this.saveHttp("checklists/evidence/notapplicablesave", { audits_id: this.data.id, questions_id: questionsId, notapplicable: notApplicable }).then((result) => {
        if ( result !== null ) this.loadData(this.data.id);
      })
    },
    saveDecision(auditsId, auditsphasesId, decision, note){
      this.saveHttp("checklists/checklist/decisionsave", { audits_id: auditsId, auditsphases_id: auditsphasesId, decision: decision, note: note } ).then((result) => {
        if ( result !== null ) this.loadData(this.data.id);
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    }
  }
}
</script>

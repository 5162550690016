<template>
    <div>
        <b-modal v-model="show" v-if="data !== null" size="lg" title="Report" @hidden="closeForm()" @close="closeForm()">
            <customeditor v-model="data.report.report" :height="winHeight - 360" :showToolbar="true"/>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Save</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import customeditor from '../../customeditor.vue';

export default {
    name: "checklistreport",
    components: {
        customeditor
    },
    data() {
        return {
            show: false,
            data: null,
        }
    },
    computed: {
        winHeight (){
            return this.$store.getters.getWinHeight;
        }
    },
    methods: {
        generateData(id){
            this.openHttp("checklists/report/generate", { id: id }).then((result) => {
                this.closeForm();
            })
        },
        loadData( auditsId, standardspointsId, languagesId ){
            this.openHttp("checklists/report", { audits_id: auditsId, standardspoints_id: standardspointsId, languages_id: languagesId }).then((result) => {
                this.show = true;
                this.data = result;
            })
        },
        regenerateData(){
            this.saveHttp("checklists/report/regenerate", this.data.report).then((result) => {
                if (result !== null) this.loadData(this.data.report.audits_id, this.data.report.standardspoints_id, this.data.report.languages_id);
            })
        },
        saveData(){
            this.saveHttp("checklists/report/save", this.data.report).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        closeForm(){
            this.data = null;
            this.show = false;
            this.$emit("update");
        },
    },
}
</script>
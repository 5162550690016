<template>
    <div>
        <b-row v-if="showToolbar">
            <b-col>
                <b-button-group class="mr-2 mb-2">
                    <b-btn variant="light" @click="stylize('bold')"><b-icon icon="type-bold"></b-icon></b-btn>
                    <b-btn variant="light" @click="stylize('italic')"><b-icon icon="type-italic"></b-icon></b-btn>
                    <b-btn variant="light" @click="stylize('underline')"><b-icon icon="type-underline"></b-icon></b-btn>
                </b-button-group>
                <b-button-group class="mr-2 mb-2">
                    <b-btn variant="light" @click="stylize('justifyLeft')"><b-icon icon="text-left"></b-icon></b-btn>
                    <b-btn variant="light" @click="stylize('justifyCenter')"><b-icon icon="text-center"></b-icon></b-btn>
                    <b-btn variant="light" @click="stylize('justifyRight')"><b-icon icon="text-right"></b-icon></b-btn>
                </b-button-group>
                <b-button-group class="mr-2 mb-2">
                    <b-btn variant="light" @click="stylize('insertOrderedList')"><b-icon icon="list-ol"></b-icon></b-btn>
                    <b-btn variant="light" @click="stylize('insertUnorderedList')"><b-icon icon="list-check"></b-icon></b-btn>
                </b-button-group>
                <b-button-group class="mr-2 mb-2">
                    <b-dropdown right text="Font" variant="light">
                        <template #button-content>
                            <b-icon icon="type"></b-icon>
                        </template>
                        <b-dropdown-item @click="stylize('fontName', false, 'Arial')">Arial</b-dropdown-item>
                        <b-dropdown-item @click="stylize('fontName', false, 'Calibri')">Calibri</b-dropdown-item>
                        <b-dropdown-item @click="stylize('fontName', false, 'Comic Sans MS')">Comic Sans MS</b-dropdown-item>
                        <b-dropdown-item @click="stylize('fontName', false, 'Impact')">Impact</b-dropdown-item>
                        <b-dropdown-item @click="stylize('fontName', false, 'Times New Roman')">Times New Roman</b-dropdown-item>
                    </b-dropdown>
                </b-button-group>
                <b-btn variant="light" @click="stylize('insertHorizontalRule')" class="mr-2 mb-2">HR</b-btn>
                <b-button-group class="mr-2 mb-2">
                    <b-dropdown right text="Size" variant="light">
                        <b-dropdown-item @click="fontSize(8)">8px</b-dropdown-item>
                        <b-dropdown-item @click="fontSize(10)">10px</b-dropdown-item>
                        <b-dropdown-item @click="fontSize(12)">12px</b-dropdown-item>
                        <b-dropdown-item @click="fontSize(14)">14px</b-dropdown-item>
                        <b-dropdown-item @click="fontSize(16)">16px</b-dropdown-item>
                        <b-dropdown-item @click="fontSize(18)">18px</b-dropdown-item>
                        <b-dropdown-item @click="fontSize(24)">24px</b-dropdown-item>
                        <b-dropdown-item @click="fontSize(32)">32px</b-dropdown-item>
                    </b-dropdown>
                </b-button-group>
                <b-button-group class="mr-2 mb-2">
                    <b-dropdown right text="Color" variant="light">
                        <b-dropdown-item @click="stylize('foreColor', false, '#000000')">Black</b-dropdown-item>
                        <b-dropdown-item @click="stylize('foreColor', false, '#0000ff')">Blue</b-dropdown-item>
                        <b-dropdown-item @click="stylize('foreColor', false, '#ffff00')">Yellow</b-dropdown-item>
                        <b-dropdown-item @click="stylize('foreColor', false, '#00ff00')">Green</b-dropdown-item>
                        <b-dropdown-item @click="stylize('foreColor', false, '#ff0000')">Red</b-dropdown-item>
                    </b-dropdown>
                </b-button-group>
                <b-button-group class="mr-2 mb-2">
                    <b-dropdown right text="Background" variant="light">
                        <b-dropdown-item @click="stylize('backColor', false, '#0000ff')">Blue</b-dropdown-item>
                        <b-dropdown-item @click="stylize('backColor', false, '#ffff00')">Yellow</b-dropdown-item>
                        <b-dropdown-item @click="stylize('backColor', false, '#00ff00')">Green</b-dropdown-item>
                        <b-dropdown-item @click="stylize('backColor', false, '#ff0000')">Red</b-dropdown-item>
                        <b-dropdown-item @click="stylize('backColor', false, '#ffffff')">White</b-dropdown-item>
                    </b-dropdown>
                </b-button-group>
                <b-button-group class="mr-2 mb-2">
                    <b-dropdown right text="Link" variant="light">
                        <template #button-content>
                            <b-icon icon="link"/>
                        </template>
                        <b-dropdown-item @click="createLink()">Add</b-dropdown-item>
                        <b-dropdown-item @click="stylize('unlink')">Remove</b-dropdown-item>
                    </b-dropdown>
                </b-button-group>
                <b-button-group class="mr-2 mb-2">
                    <b-dropdown right  variant="light">
                        <template #button-content>
                            <b-icon icon="card-image"/>
                        </template>
                        <b-dropdown-item v-b-modal.modal-upload-image>From file</b-dropdown-item>
                        <b-dropdown-item @click="imageLink()">From Link</b-dropdown-item>
                    </b-dropdown>
                </b-button-group>
                <b-modal id="modal-upload-image" v-model="showUploadImage" title="Upload File" hide-footer>
                    <b-form-file accept="image/*" @change="fileChangeImage"/>
                </b-modal>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <div id="parent" :style="{ height: height + 'px' }">
                    <div id="editable" ref="editable" contenteditable="true" @blur="onInput"></div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: "customeditor",
    props: [ "value", "height", "showToolbar" ],
    data() {
        return {
            isCode: false,
            showUploadImage: false,
            content: this.value,
        }
    },
    mounted() {
        this.isCode = false;
        this.$refs.editable.innerHTML = this.value;
    },
    methods: {
        onInput(e){
            if ( this.isCode === false){
                this.$emit('input', e.target.innerHTML);
            } else {
                this.$emit('input', e.target.innerText);
            }
        },
        stylize(style, ui, value) {
            if (this.isCode === false){
                var inui = false;
                var ivalue = null;
                if (arguments[1]) inui = ui;
                if (arguments[2]) ivalue = value;
                document.execCommand('styleWithCSS', true, null);            
                document.execCommand(style, inui, ivalue);
            }
        },
        fontSize( fontSize ){
            if (this.isCode === false){
                if (window.getSelection) {
                    var range = window.getSelection().getRangeAt(0);
                    var content = range.extractContents();
                    var span = document.createElement('SPAN');
                    span.setAttribute("style", " font-size: " + fontSize + "pt !important;");
                    span.appendChild(content);                  
                    var htmlContent = span.innerHTML;
                    htmlContent = htmlContent.replace(/<\/?span[^>]*>/g, "");                  
                    htmlContent = htmlContent.replace(/font-size:[^;]+/g, '');
                    htmlContent = htmlContent.replace(/<font/g, "<span").replace(/<\/font>/g, "</span>");     
                        
                    if (span.innerHTML.toString() == "")
                        htmlContent = htmlContent + " ";

                        var cursorPosition = htmlContent.length;
                        span.innerHTML = htmlContent;
                        span.setAttribute("style", " font-size: " + fontSize + "pt !important;");

                    range.insertNode(span);                  

                    var sel = window.getSelection();
                    range = sel.getRangeAt(0);
                    range.collapse(true);
                    //var lastChildElement = span.childNodes.length - 1;
                    if (cursorPosition == 1) {
                        range.setStart(span.childNodes[0], 1);
                    } else {
                        range.setEndAfter(span);
                    }
                    sel.removeAllRanges();
                    sel.addRange(range);
                } 
                /*
                var wrappedselection = '<span style="font-size:' + size + 'px">' + selection + '</span>';
                document.execCommand('insertHTML', false, wrappedselection);
                */
            }
        },
        createLink() {
            if (this.isCode === false){
                var linkUrl = prompt("URL", "https://");
                var sText = prompt("Text", "");
                this.insertHtml('<a href="' + linkUrl + '" target="_blank">' + sText + '</a>');
            }
        },
        imageLink() {
            if (this.isCode === false){
                var linkUrl = prompt("URL", "https://");
                this.insertHtml('<img src="' + linkUrl + '">');
            }
        },
        fileChangeImage(event){
            if (this.isCode === false){
                var reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = ()=> {
                    this.showUploadImage = false;
                    this.insertHtml('<img src="' + reader.result + '">');
                };
            }
        },
        insertHtml(html){
            document.getElementById("editable").focus();
            document.execCommand('insertHTML', false, html);
        },
        getCode(){
            if ( this.isCode === true ){
                this.$refs.editable.innerHTML = this.$refs.editable.innerText;
                this.isCode = false;
            } else {
                this.$refs.editable.innerText = this.$refs.editable.innerHTML;
                this.isCode = true;
            }
        }
    },
    watch: {
        value(v) {
            if ( this.isCode === false ){
                this.$refs.editable.innerHTML = v;
            } else {
                console.log(v);
                this.$refs.editable.innerText = v;
            }
        }
    },
}
</script>

<style>
#parent{
    width: 100%;
    overflow: hidden;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

}
#editable {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}
</style>
<template>
  <div>
    <monitoring ref="monitoring" @update="loadData()"/>
    <monitoringquestion ref="question" @update="loadData()"/>
    <monitoringdocument ref="document" @update="loadData()"/>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Deadlines" active>
          <b-modal v-model="deadlines.showFilter" title="Filters">
            <!--
            <b-form-group label="Standard">
              <b-form-select v-model="monitorings.tempFilter.standards_id" :options="addNullCombo(monitorings.filtersMenu.standards.filter(x => x.certificationbodies.includes(133)))" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="User">
              <b-form-select v-model="monitorings.tempFilter.users_id" :options="addNullCombo(monitorings.filtersMenu.users.filter(x => x.certificationbodies.includes(133)))" value-field="id" text-field="val"/>
            </b-form-group>
            -->
            <template v-slot:modal-footer>
              <div class="w-100">
                <b-btn variant="primary" class="mr-2" @click="filterSetDeadlines()">Filter</b-btn>
                <b-btn variant="warning" @click="filterResetDeadlines()">Reset</b-btn>
              </div>
            </template>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadDeadlines()">Refresh</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="filterOpenDeadlines()">Filter</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="deadlines.items.data" :fields="deadlines.fields" :busy="deadlines.isBusy" :no-local-sorting="true" :sort-by.sync="deadlines.search.sortby" :sort-desc.sync="deadlines.search.sortdesc" :tbody-tr-class="rowClass" @sort-changed="sortingChangeDeadlines">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>There are no results</template>
          </b-table>
          <tablebottom :busy="monitorings.isBusy" :currentpage="deadlines.search.page" :total="deadlines.items.total" @updateCurrentPage="deadlines.search.page = $event" @loadData="loadDeadlines()"/>
        </b-tab>
        <b-tab title="Monitorings">
          <b-modal v-model="monitorings.showFilter" title="Filters">
            <b-form-group label="Standard">
              <b-form-select v-model="monitorings.tempFilter.standards_id" :options="addNullCombo(monitorings.filtersMenu.standards)" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="User">
              <b-form-select v-model="monitorings.tempFilter.users_id" :options="addNullCombo(monitorings.filtersMenu.users)" value-field="id" text-field="val"/>
            </b-form-group>
            <template v-slot:modal-footer>
              <div class="w-100">
                <b-btn variant="primary" class="mr-2" @click="filterSetMonitorings">Filter</b-btn>
                <b-btn variant="warning" @click="filterResetMonitorings">Reset</b-btn>
              </div>
            </template>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadMonitorings()">Refresh</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="filterOpenMonitorings()">Filter</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="monitorings.items.data" :fields="monitorings.fields" :busy="monitorings.isBusy" :no-local-sorting="true" :sort-by.sync="monitorings.search.sortby" :sort-desc.sync="monitorings.search.sortdesc" @sort-changed="sortingChangeMonitorings" @row-clicked="$refs.monitoring.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>There are no results</template>
            <template v-slot:cell(documents_id)="row">
              <b-btn variant="primary" v-if="row.value > 0" size="sm" @click="$refs.document.downloadData(row.value)">Download</b-btn>
            </template>
          </b-table>
          <tablebottom :busy="monitorings.isBusy" :currentpage="monitorings.search.page" :total="monitorings.items.total" @updateCurrentPage="monitorings.search.page = $event" @loadData="loadMonitorings()"/>
        </b-tab>
        <b-tab title="Questions">
          <b-modal v-model="questions.showFilter" title="Filters">
            <b-form-group label="Status">
              <b-form-select v-model="questions.tempFilter.hide" :options="addNullCombo(questions.filtersMenu.hide)" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Question">
              <b-form-input v-model="questions.tempFilter.question"/>
            </b-form-group>
            <template v-slot:modal-footer>
              <div class="w-100">
                <b-btn variant="primary" class="mr-2" @click="filterSetQuestions()">Filter</b-btn>
                <b-btn variant="warning" @click="filterResetQuestions()">Reset</b-btn>
              </div>
            </template>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadQuestions()">Refresh</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="questions.showFilter = true">Filter</b-btn>
          <b-btn variant="success" @click="$refs.question.loadData(0)">New</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="questions.items.data" :fields="questions.fields" :busy="questions.isBusy" :no-local-sorting="true" :sort-by.sync="questions.search.sortby" :sort-desc.sync="questions.search.sortdesc" @sort-changed="sortingChangeQuestions" @row-clicked="$refs.question.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>There are no results</template>
            <template v-slot:cell(languages)="row">
              <div v-for="(item, index) of row.value" :key="index">{{ item.lang + ": " + item.question }}</div>
            </template>
          </b-table>
          <tablebottom :busy="questions.isBusy" :currentpage="questions.search.page" :total="questions.items.total" @updateCurrentPage="questions.search.page = $event" @loadData="loadQuestions()"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import monitoring from './monitoring.vue';
import monitoringquestion from './monitoringquestion.vue';
import monitoringdocument from './monitoringdocument.vue';

export default {
  name: "monitorings",
  components : { monitoring, monitoringquestion, monitoringdocument },
  data() {
    return {
      deadlines: {
        isBusy: true,
        showFilter: false,
        search: {
          page: 1,
          filters: {
            standards_id: null,
            certificationbodies_id: 133,
            users_id: null
          },
          sortby: 'status',
          sortdesc: false
        },
        tempFilter: {
          standards_id: null,
          certificationbodies_id: 133,
          users_id: null
        },
        filtersMenu: {
          standards:[],
          certificationbodies: [],
          users_id: []
        },
        fields: [{
          key: "fullname",
          label: "Fullname",
          sortable: true,
        },{
          key: 'standard',
          label: 'Standard',
          sortable: true,
        },{
          key: 'lastpasseddate',
          label: "Passed",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'lastdate',
          label: "Last",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "status",
          label: "Status",
          formatter: (value, key, item) => {
            return ( item.necessary === false ? "Not necessary" : (item.passed === true ? "Passed" : ( item.pending === true ? "Pending" : ( item.expired === true ? "Expired" : "?"))));
          }
        }],
        items: {
          total: 0,
          data: []
        }
      },
      monitorings: {
        isBusy: true,
        showFilter: false,
        search: {
          page: 1,
          filters: {
            standards_id: null,
            certificationbodies_id: 133,
            users_id: null
          },
          sortby: 'date',
          sortdesc: true,
        },
        tempFilter: {
          standards_id: null,
          certificationbodies_id: 133,
          users_id: null
        },
        filtersMenu: {
          standards:[],
          certificationbodies: [],
          users_id: []
        },
        fields: [{
          key: "fullname",
          label: "Monitored",
          sortable: true,
        },{
          key: 'standards',
          label: 'Standards',
        },{
          key: 'usersrole',
          label: 'Role'
        },{
          key: 'date',
          label: 'Date',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "fullnamemonitoring",
          label: "Monitored by",
        },{
          key: "passed",
          label: "Status",
          formatter: value => {
            return value === null ? "Pending" : (value === true ? "Passed" : "Not passed") ;
          }
        },{
          key: "note",
          label: "Note"
        },{
          key: "documents_id",
          label: ""
        }],
        items: {
          total: 0,
          data: []
        }
      },
      questions: {
        isBusy: true,
        showFilter: false,
        search: {
          page: 1,
          filters: {
            hide: false,
            question: null,
          },
          sortby: 'orderquestion',
          sortdesc: false
        },
        tempFilter: {
          hide: false,
          question: null
        },
        filtersMenu: {
          hide:[{
            id: true,
            val: "Hidden"
          },{
            id: false,
            val: "Active"
          }]
        },
        fields: [{
          key: 'orderquestion',
          label: 'Order',
          sortable: true,
        },{
          key: 'languages',
          label: 'Question',
        },{
          key: 'hide',
          label: 'Status',
          formatter: value => {
            return value === true ? "Hidden" : "Active";
          }
        }],
        items: {
          total: 0,
          data: [],
        }
      },
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    rowClass(item, type){
      if (!item || type !== 'row') return "";
      return "table-" + ( item.necessary === false ? "info" : (item.passed === true ? "success" : ( item.pending === true ? "warning" : ( item.expired === true ? "danger" : ""))));
    },
    loadData(){
      this.loadDeadlines();
      this.loadMonitorings();
      this.loadQuestions();
    },
    loadDeadlines(){
      this.deadlines.isBusy = true;
      this.openHttp("monitorings/deadlines", this.deadlines.search ).then((result) => {
        this.deadlines.isBusy = false;
        this.deadlines.items = result;
      })
    },
    filterOpenDeadlines(){
      this.openHttp("monitorings/filters", {} ).then((result) => {
        this.deadlines.filtersMenu = result;
        this.deadlines.showFilter = true;
      })
    },
    filterSetDeadlines(){
      this.deadlines.showFilter = false;
      this.deadlines.search.filters = this.deadlines.tempFilter;
      this.loadDeadlines();
    },
    filterResetDeadlines(){
      this.deadlines.tempFilter = {
        standards_id: null,
        certificationbodies_id: 133,
        users_id: null
      };
      this.filterSetDeadlines();
    },
    sortingChangeDeadlines(ctx) {
      this.deadlines.search.sortby = ctx.sortBy;
      this.deadlines.search.sortdesc = ctx.sortDesc;
      this.loadDeadlines();
    },
    loadMonitorings(){
      this.monitorings.isBusy = true;
      this.openHttp("monitorings", this.monitorings.search ).then((result) => {
        this.monitorings.isBusy = false;
        this.monitorings.items = result;
      })
    },
    filterOpenMonitorings(){
      this.openHttp("monitorings/filters", {} ).then((result) => {
        this.monitorings.filtersMenu = result;
        this.monitorings.showFilter = true;
      })
    },
    filterSetMonitorings(){
      this.monitorings.showFilter = false;
      this.monitorings.search.filters = this.monitorings.tempFilter;
      this.loadMonitorings();
    },
    filterResetMonitorings(){
      this.monitorings.tempFilter = {
        standards_id: null,
        certificationbodies_id: 133,
        users_id: null
      };
      this.filterSetMonitorings();
    },
    sortingChangeMonitorings(ctx) {
      this.monitorings.search.sortby = ctx.sortBy;
      this.monitorings.search.sortdesc = ctx.sortDesc;
      this.loadMonitorings();
    },
    loadQuestions(){
      this.questions.isBusy = true;
      this.openHttp("monitorings/questions", this.questions.search ).then((result) => {
        this.questions.items = result;
        this.questions.isBusy = false;
      })
    },
    filterSetQuestions(){
      this.questions.showFilter = false;
      this.questions.search.filters = this.questions.tempFilter;
      this.loadQuestions();
    },
    filterResetQuestions(){
      this.questions.tempFilter = {
        hide: false,
        question: null
      };
      this.filterSetQuestions();
    },
    sortingChangeQuestions(ctx) {
      this.questions.search.sort.by = ctx.sortBy;
      this.questions.search.sort.desc = ctx.sortDesc;
      this.loadQuestions();
    }
  }
}
</script>
<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Law" @hidden="closeForm()" @close="closeForm()">
      <b-row>
        <b-col lg="8">
          <b-form-group label="Law">
            <b-form-input v-model="data.law.law"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="From">
            <b-form-input type="date" v-model="data.law.datefrom"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="To">
            <b-form-input type="date" v-model="data.law.dateto"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Note">
        <b-form-textarea v-model="data.law.note" rows="5"/>
      </b-form-group>
      <b-form-group label="Link">
        <b-input-group>
          <b-form-input v-model="data.law.link"/>
          <b-input-group-append>
            <b-btn variant="primary" :href="data.law.link" target="_blank">Open</b-btn>                
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-form-group label="Standards">
        <multiselect v-model="standardsSupport" :options="data.standards" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.law.standards = updateCombo(standardsSupport)"/>
      </b-form-group>
      <b-form-group label="States">
        <multiselect v-model="statesSupport" :options="data.states" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.law.states = updateCombo(statesSupport)"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData()">Save</b-btn>
          <b-btn variant="danger" v-if="data.law.id > 0" @click="deleteData()">Delete</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "law",
  data() {
    return {
      inreportCombo: [{
        id: true,
        val: "Si",
      },{
        id: false,
        val: "No",
      }],
      show: false,
      data: null,
      standardsSupport: [],
      statesSupport: [],
    }
  },
  methods: {
    loadData(id){
      this.openHttp("laws/law", { id: id }).then((result) => {
        this.standardsSupport = this.convertCombo(result.standards, result.law.standards);
        this.statesSupport = this.convertCombo(result.states, result.law.states);
        this.data = result;
        this.show = true;
      })
    },
    newlink(){
      this.data.law.links.push( { link: "" } );
    },
    saveData(){
      this.saveHttp("laws/law/save", this.data.law).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("laws/law/delete", { id: this.data.law.id }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit("update");
    },
  },
}
</script>

<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" title="Site" @hidden="closeForm()" @close="closeForm()">
            <b-form-group label="Site">
                <b-form-select v-model="data.site.sites_id" :options="data.sites" value-field="id" text-field="val"/>
            </b-form-group>
            <b-row>
                <b-col lg="4">
                    <b-form-group label="Main">
                        <b-form-select v-model="data.site.ismain" :options="yesNoCombo" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
                <b-col lg="4">
                    <b-form-group label="Registered">
                        <b-form-select v-model="data.site.isregistered" :options="yesNoCombo" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
                <b-col lg="4">
                    <b-form-group label="Operative">
                        <b-form-select v-model="data.site.isoperative" :options="yesNoCombo" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="3">
                    <b-form-group label="Total workers">
                        <b-form-input v-model="data.site.employeesnumber" type="number" min="0" step="1"/>
                    </b-form-group>
                </b-col>
                <b-col lg="3">
                    <b-form-group label="Effective workers">
                        <b-form-input v-model="data.site.employeeseffectivenumber" type="number" min="0" step="1"/>
                    </b-form-group>
                </b-col>
                <b-col lg="3">
                    <b-form-group label="External workers">
                        <b-form-input v-model="data.site.employeesexternal" type="number" min="0" step="1"/>
                    </b-form-group>
                </b-col>
                <b-col lg="3">
                    <b-form-group label="Repetitive jobs worker">
                        <b-form-input v-model="data.site.employeesrepetitive" type="number" min="0" step="1"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-form-group label="Shifts">
                <b-form-input v-model="data.site.shifts" type="number" min="0" step="1"/>
            </b-form-group>
            <b-form-group label="Activities">
                <multiselect v-model="nacesSupport" :options="data.naces" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.site.naces = updateCombo(nacesSupport)"/>
            </b-form-group>
            <b-form-group label="Note">
                <b-form-textarea v-model="data.site.note" rows="3" max="6"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData()">Save</b-btn>
                    <b-btn variant="danger" v-show="data.site.sites_id > 0" @click="deleteData()">Delete</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "applicationsite",
    data() {
        return {
            show: false,
            data: null,
            nacesSupport: [],
            yesNoCombo: [{
                id: false,
                val: "No"
            },{
                id: true,
                val: "Yes"
            }],
        }
    },
    methods: {
        loadData( applicationsId, id ){
            this.show = false;
            this.openHttp("applications/site", { applications_id: applicationsId, id: id } ).then((result) => {
                this.nacesSupport = this.convertCombo(result.naces, result.site.naces);
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("applications/site/save", this.data.site).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("applications/site/delete", { sites_id: this.data.site.sites_id, contracts_id: this.data.site.contracts_id }).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>

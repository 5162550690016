<template>
  <div>
    <div class="containter-white">
      <b-row class="mb-2">
        <b-col>
          <b-button-group>
            <b-button v-for="(item, index) of languages" :key="index" :variant="lang === item.lang ? 'success' : 'secondary'" @click="lang = item.lang">{{ item.langFull }}</b-button>
          </b-button-group>
        </b-col>
      </b-row>
      <b-card no-body v-if="data!== null">
        <b-tabs pills card vertical>
          <b-tab :title="translateCheck('contract')" active>
            <b-table-simple responsive :sticky-header="(winHeight - 255) + 'px'">
              <b-thead head-variant="light">
                <b-tr>
                  <b-th style="width: 25%">{{ translateCheck('parameter') }}</b-th>
                  <b-th>{{ translateCheck('value') }}</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td>{{ translateCheck('company') }}</b-td>
                  <b-td>{{ data.contract.company }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td>{{ translateCheck('vat number') }}</b-td>
                  <b-td>{{ data.contract.vat }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td>{{ translateCheck('company representative') }}</b-td>
                  <b-td>{{ data.contract.worker }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td>{{ translateCheck('scopes') }}</b-td>
                  <b-td>
                    <div v-for="(item, index) of data.contract.scopes" :key="index"><strong>{{ item.lang }}: </strong> {{ item.scope }}</div>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>{{ translateCheck('standards') }}</b-td>
                  <b-td>
                    {{ data.contract.standards }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>IAF</b-td>
                  <b-td>{{ data.contract.iafs }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td>{{ translateCheck('sites on the certificate') }}</b-td>
                  <b-td>
                    <div v-for="(item2, index2) of data.contract.sitesoncertificates" :key="index2">
                      {{ item2.site + (item2.ismain === true ? ' (' + translateCheck('main') + ')' : '') + (item2.isregistered === true ? ' (' + translateCheck('registered') + ')' : '') }}
                    </div>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>{{ translateCheck('points not to be verified') }}</b-td>
                  <b-td>
                    <div v-for="(item, index) of data.contract.exclusions" :key="index">{{ item.standard + ': ' + item.exclusion }}</div>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-tab>
          <b-tab :title="translateCheck('sites')">
            <b-modal v-if="dataSite !== null" size="lg" v-model="showSite" :title="translateCheck('site')">
              <b-form-group :label="translateCheck('address')">
                <b-form-input v-model="dataSite.address" disabled/>
              </b-form-group>
              <b-form-group :label="translateCheck('city')">
                <b-form-input v-model="dataSite.city" disabled/>
              </b-form-group>
              <b-form-group :label="translateCheck('state')">
                <b-form-input v-model="dataSite.state" disabled/>
              </b-form-group>
              <b-row>
                <b-col>
                  <b-form-group :label="translateCheck('active')">
                    <b-form-select v-model="dataSite.active" :options="yesnoCombo" value-field="id" text-field="val"/>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group :label="translateCheck('main')">
                    <b-form-select v-model="dataSite.ismain" :options="yesnoCombo" value-field="id" text-field="val"/>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group :label="translateCheck('registered')">
                    <b-form-select v-model="dataSite.isregistered" :options="yesnoCombo" value-field="id" text-field="val"/>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group :label="translateCheck('employees number')">
                    <b-form-input type="number" v-model="dataSite.employeesnumber"/>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group :label="translateCheck('employees effective number')">
                    <b-form-input type="number" v-model="dataSite.employeeseffectivenumber"/>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group :label="translateCheck('employees external')">
                    <b-form-input type="number" v-model="dataSite.employeesexternal"/>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group :label="translateCheck('employees repetitive')">
                    <b-form-input type="number" v-model="dataSite.employeesrepetitive"/>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group :label="translateCheck('shifts')">
                    <b-form-input type="number" v-model="dataSite.shifts"/>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group :label="translateCheck('naces')">
                    <b-form-checkbox-group v-model="dataSite.naces">
                      <b-form-checkbox v-for="(item, index) of data.naces" :key="index" :value="item.id">{{ item.val }}</b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <template v-slot:modal-footer>
                <div class="w-100">
                  <b-btn variant="success" class="mr-2" @click="saveSite()">{{ translateCheck('save') }}</b-btn>
                </div>
              </template>
            </b-modal>
            <b-table-simple responsive :sticky-header="(winHeight - 155) + 'px'">
              <b-thead head-variant="light">
                <b-tr>
                  <b-th>{{ translateCheck("address") }}</b-th>
                  <b-th>{{ translateCheck("city") }}</b-th>
                  <b-th>{{ translateCheck("state") }}</b-th>
                  <b-th>{{ translateCheck("active") }}</b-th>
                  <b-th>{{ translateCheck("main") }}</b-th>
                  <b-th>{{ translateCheck("registered") }}</b-th>
                  <b-th>{{ translateCheck("employees number") }}</b-th>
                  <b-th>{{ translateCheck("employees effective number") }}</b-th>
                  <b-th>{{ translateCheck("employees external") }}</b-th>
                  <b-th>{{ translateCheck("employees repetitive") }}</b-th>
                  <b-th>{{ translateCheck("shifts") }}</b-th>
                  <b-th>{{ translateCheck("naces") }}</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(item, index) in data.sites" :key="index" @click="loadSite(item)">
                  <b-td>{{ item.address }}</b-td>
                  <b-td>{{ item.city }}</b-td>
                  <b-td>{{ item.state }}</b-td>
                  <b-td>{{ translateCheck(yesnoCombo.find(x => x.id === item.active).val) }}</b-td>
                  <b-td>{{ translateCheck(yesnoCombo.find(x => x.id === item.ismain).val) }}</b-td>
                  <b-td>{{ translateCheck(yesnoCombo.find(x => x.id === item.isregistered).val) }}</b-td>
                  <b-td>{{ item.employeesnumber }}</b-td>
                  <b-td>{{ item.employeeseffectivenumber }}</b-td>
                  <b-td>{{ item.employeesexternal }}</b-td>
                  <b-td>{{item.employeesrepetitive }}</b-td>
                  <b-td>{{ item.shifts }}</b-td>
                  <b-td>{{ data.naces.filter(x => item.naces.includes(x.id)).map(x => x.val).join(", ") }}</b-td>
                </b-tr>
                <b-tr v-show="data.parameters.length === 0">
                  <b-td colspan="2">{{ translateCheck('empty') }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-tab>
          <b-tab :title="translateCheck('parameters')">
            <b-modal v-if="dataParameter !== null" v-model="showParameter" size="lg" :title="translateCheck('parameter')">
              <b-form-group :label="data.parameterstypes.find(x => x.lang === lang).parameters.find(x => x.id === dataParameter.id).val">
                <b-form-input v-if="dataParameter.format === 'string'" v-model="dataParameter.val"/>
                <b-form-textarea v-if="dataParameter.format === 'text'"  v-model="dataParameter.val" rows="6"/>
                <b-form-input type="number" v-if="dataParameter.format === 'number'" v-model="dataParameter.val"/>
                <b-form-input type="date" v-if="dataParameter.format === 'date'" v-model="dataParameter.val"/>
                <!--
                <b-form-select v-if="dataParameter.format === 'bool'" v-model="dataParameter.val" :options="yesnoCombo" value-field="id" text-field="val"/>
                -->
                <!--
                <b-form-select v-if="dataParameter.format === 'select'" v-model="dataParameter.val" :options="data.parameterstypes.findIndex(x => x.contractsparameterstypes_id === dataParameter.id) > 0 ? data.parameterstypes.find(x => x.contractsparameterstypes_id === item.id).optionselect : []" value-field="id" text-field="val"/>
                -->
              </b-form-group>
              <template v-slot:modal-footer>
                <div class="w-100">
                  <b-btn variant="success" @click="saveParameter()">{{ translateCheck('save') }}</b-btn>
                </div>
              </template>
            </b-modal>
            <b-table-simple responsive :sticky-header="(winHeight - 155) + 'px'">
              <b-thead head-variant="light">
                <b-tr>
                  <b-th>{{ translateCheck("parameter") }}</b-th>
                  <b-th>{{ translateCheck("value") }}</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(item, index) in data.parameters" :key="index" @click="loadParameter(item)">
                  <b-td>{{ data.parameterstypes.find(x => x.lang === lang).parameters.find(x => x.id === item.id).val }}</b-td>
                  <b-td>{{ item.val }}                    
                    {{ yesnoCombo.find(x => x.id === item.val) }}
                    {{ yesnoCombo.find(x => x.id === item.val)}}


                    {{ item.format === 'string' || item.format === 'text' || item.format === 'number' || item.format === 'date' ? item.val : '' }}
                    {{ item.format === 'bool' ? yesnoCombo.find(x => x.id === item.val) : item.val }}
                 </b-td>
                </b-tr>
                <b-tr v-show="data.parameters.length === 0">
                  <b-td colspan="2">{{ translateCheck('empty') }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-tab>
          <b-tab :title="translateCheck('complaints') + '/' + translateCheck('appeals')">
            <b-modal v-if="dataComplaint !== null" v-model="showComplaint" :title="translateCheck('complaint') + ' ' + translateCheck('appeal')">
              <b-form-group :label="translateCheck('complaint') + '/' + translateCheck('appeal')">
                <b-form-textarea v-model="dataComplaint.complaint" rows="2"/>
              </b-form-group>
              <b-form-group :label="translateCheck('date')">
                <b-form-input v-model="dataComplaint.date" type="date"/>
              </b-form-group>
              <b-form-group :label="translateCheck('note')">
                <b-form-textarea v-model="dataComplaint.note" rows="4"/>
              </b-form-group>
              <b-form-group :label="translateCheck('contacts')">
                <b-form-textarea v-model="dataComplaint.contacts" rows="2"/>
              </b-form-group>
              <template v-slot:modal-footer>
                <div class="w-100">
                  <b-btn variant="success" class="mr-2" @click="saveComplaint()">{{ translateCheck('save') }}</b-btn>
                  <b-btn variant="danger" @click="deleteComplaint()">{{ translateCheck('delete') }}</b-btn>
                </div>
              </template>
            </b-modal>
            <b-btn variant="primary" size="sm" @click="loadComplaint({id: 0, contracts_id: data.contract.id, date: null, complaint: null, note: null, contacts: null})">{{ translateCheck('new') }}</b-btn>
            <b-table-simple responsive :sticky-header="(winHeight - 255) + 'px'" class="mt-2">
              <b-thead head-variant="light">
                <b-tr>
                  <b-th>{{ translateCheck("date") }}</b-th>
                  <b-th>{{ translateCheck("complaint") + " " + translateCheck("appeal") }}</b-th>
                  <b-th>{{ translateCheck("note") }}</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(item, index) in data.complaints" :key="index" @click="loadComplaint(item)">
                  <b-td>{{ item.date }}</b-td>
                  <b-td>{{ item.complaint }}</b-td>
                  <b-td>{{ item.note }}</b-td>
                </b-tr>
                <b-tr v-show="data.complaints.length === 0">
                  <b-td colspan="3">{{ translateCheck('empty') }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-tab>
          <b-tab :title="translateCheck('documents')">
            <b-table-simple responsive :sticky-header="(winHeight - 255) + 'px'">
              <b-thead head-variant="light">
                <b-tr>
                  <b-th>{{ translateCheck("document") }}</b-th>
                  <b-th></b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(item, index) in data.documents" :key="index">
                  <b-td>{{ item.filename }}</b-td>
                  <b-td>
                    <b-btn variant="primary" size="sm" :href="'api/externaldocument/' + item.token" target="_blank">{{ translateCheck('download') }}</b-btn>
                  </b-td>
                </b-tr>
                <b-tr v-show="data.documents.length === 0">
                  <b-td colspan="2">{{ translateCheck('empty') }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "externalcontract",
  props: ["token"],
  data() {
    return {
      show: false,
      data: null,
      lang: "it",
      showSite: false,
      dataSite: null,
      showParameter: false,
      dataParameter: null,
      showComplaint: false,
      dataComplaint: null,
      yesnoCombo: [{
        id: false,
        val: 'no',
      },{
        id: true,
        val: 'yes',
      }],
      languages: [{
        lang: "en",
        langFull: "English",
        dictionary: [
          { word: "contract", translate: "Contract"},
          { word: "documents", translate: "Documents"},
          { word: "document", translate: "Document"},
          { word: "download", translate: "Download"},
          { word: "parameter", translate: "Parameter"},
          { word: "value", translate: "Value"},
          { word: "company", translate: "Company"},
          { word: "vat number", translate: "VAT number"},
          { word: "scopes", translate: "Scopes"},
          { word: "sites on the certificate", translate: "Sites on the Certificate"},
          { word: "points not to be verified", translate: "Points not to be verified"},
          { word: "company representative", translate: "Company representative"},
          { word: "standards", translate: "Standards"},
          { word: "sites", translate: "Sites"},
          { word: "parameters", translate: "Parameters"},



          { word: "address", translate: "Address"},
          { word: "city", translate: "City"},
          { word: "state", translate: "Stato"},
          { word: "active", translate: "Active"},
          { word: "employees number", translate: "Employees number"},
          { word: "employees effective number", translate: "Employees effective number"},
          { word: "employees external", translate: "Employees external"},
          { word: "employees repetitive", translate: "Employees repetitive"},
          { word: "shifts", translate: "Shifts"},
          { word: "naces", translate: "NACEs"},
          { word: "yes", translate: "Yes"},
          { word: "no", translate: "No"},


          



          { word: "fullname", translate: "Fullname"},
          { word: "job", translate: "Job"},
          { word: "date", translate: "Date"},
          { word: "from", translate: "From"},
          { word: "to", translate: "To"},


          { word: "type", translate: "Type"},          
          
          { word: "site", translate: "Site"},
          
          
          
          { word: "negative", translate: "Negative"},
          { word: "approved", translate: "Approved"},
          { word: "to be approved", translate: "To be approved"},
          { word: "download", translate: "Download"},
          { word: "file", translate: "File"},
          { word: "date_and_time", translate: "Date and time"},
          { word: "main", translate: "Main"},
          { word: "registered", translate: "Registered"},
          { word: "yard", translate: "Yard"},
          { word: "empty", translate: "Empty"},

          { word: "complaints", translate: "Complaints"},
          { word: "appeals", translate: "appeals"},
          { word: "new", translate: "New"},
          { word: "complaint", translate: "Complaint"},
          { word: "appeal", translate: "Appeal"},
          { word: "note", translate: "Note"},
          { word: "contacts", translate: "Contacts"},
          { word: "save", translate: "Save"},
          { word: "delete", translate: "Delete"},

        ]
      },{
        lang: "it",
        langFull: "Italiano",
        dictionary: [
          { word: "contract", translate: "Contratto"},
          { word: "documents", translate: "Documenti"},
          { word: "document", translate: "Documento"},
          { word: "download", translate: "Scarica"},
          { word: "parameter", translate: "Parametro"},
          { word: "value", translate: "Valore"},
          { word: "company", translate: "Azienda"},
          { word: "vat number", translate: "Partita IVA"},
          { word: "scopes", translate: "Scopi"},
          { word: "sites on the certificate", translate: "Siti sul Certificato"},
          { word: "points not to be verified", translate: "Punti da non verificare"},
          { word: "audited sites", translate: "Siti Auditati"},
          { word: "company representative", translate: "Rappresentante della direzione"},
          { word: "approval of the company", translate: "Approvazione dell'azienda"},
          { word: "standards", translate: "Norme"},
          { word: "sites", translate: "Siti"},
          { word: "parameters", translate: "Parametri"},







          { word: "address", translate: "Indirizzo"},
          { word: "city", translate: "Città"},
          { word: "state", translate: "Stato"},
          { word: "active", translate: "Attivo"},
          { word: "employees number", translate: "Lavoratori total"},
          { word: "employees effective number", translate: "Lavoratori equivalenti"},
          { word: "employees external", translate: "Lavoratori esterni"},
          { word: "employees repetitive", translate: "Lavoratori ripetivi"},
          { word: "shifts", translate: "Turni"},
          { word: "naces", translate: "Ateco"},
          { word: "yes", translate: "Si"},
          { word: "no", translate: "No"},









          { word: "standard", translate: "Norma"},
          { word: "date", translate: "Data"},
          { word: "type", translate: "Tipo"},
          { word: "site", translate: "Sito"},

          { word: "negative", translate: "Negativo"},
          { word: "approved", translate: "Approvato"},
          { word: "to be approved", translate: "Da approvare"},
          { word: "download", translate: "Download"},
          { word: "file", translate: "File"},
          { word: "main", translate: "Principale"},
          { word: "registered", translate: "Legale"},
          { word: "yard", translate: "Cantiere"},
          { word: "empty", translate: "Vuoto"},



          { word: "complaints", translate: "Reclami"},
          { word: "appeals", translate: "Ricorsi"},
          { word: "new", translate: "Nuovo"},
          { word: "complaint", translate: "Reclamo"},
          { word: "appeal", translate: "Ricorso"},
          { word: "note", translate: "Note"},
          { word: "contacts", translate: "Contatti"},
          { word: "save", translate: "Salva"},
          { word: "delete", translate: "Elimina"},


        ]
      }]
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    }
  },
  created(){
    this.loadData();
  },
  methods: {
    translateCheck(word){
      if (this.languages.find(x => x.lang === this.lang).dictionary.findIndex(x => x.word === word) === -1) return '??? ' + word;
      return this.languages.find(x => x.lang === this.lang).dictionary.find(x => x.word === word).translate;
    },
    loadData(){
      this.showComplaint = false;
      this.showSite = false;
      this.showParameter = false;
      this.openHttp("externalcontract/", { token: this.token }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    loadSite(item){
      this.dataSite = JSON.parse(JSON.stringify(item));
      this.showSite = true;
    },
    saveSite(){
      this.saveHttp("externalcontract/site/save", { token: this.token, site: this.dataSite }).then((result) => {
        if (result !== null) this.loadData();
      })
    },
    loadParameter(item){
      this.dataParameter = JSON.parse(JSON.stringify(item));
      this.showParameter = true;
    },
    saveParameter(){
      this.saveHttp("externalcontract/parameter/save", { token: this.token, parameter: this.dataParameter }).then((result) => {
        if (result !== null) this.loadData();
      })
    },
    loadComplaint(item){
      this.dataComplaint = JSON.parse(JSON.stringify(item));
      this.showComplaint = true;
    },
    saveComplaint(){
      this.saveHttp("externalcontract/complaint/save", { token: this.token, complaint: this.dataComplaint }).then((result) => {
        if (result !== null) this.loadData();
      })
    },
    deleteComplaint(){
      this.deleteHttp("externalcontract/complaint/delete", { token: this.token, complaint: this.dataComplaint }).then((result) => {
        if (result !== null) this.loadData();
      })
    },
    downloadData(token){
      this.$refs.downloadFormToken.value = token;
      this.$refs.downloadForm.submit();
    }
  }
}
</script>

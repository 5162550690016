<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Education" @hidden="closeForm()" @close="closeForm()">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Form" active>
            <b-row>
              <b-col lg="2">
                <b-form-group label="From">
                  <b-form-input v-model="data.education.fromdate" type="date"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="To">
                  <b-form-input v-model="data.education.todate" type="date"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="School">
              <b-form-input v-model="data.education.school"/>
            </b-form-group>
            <b-form-group label="Discipline">
              <b-input-group v-for="item of data.education.languages" :key="item.id" v-show="item.id === 2">
                <b-form-input v-model="item.discipline"/>
              </b-input-group>
            </b-form-group>
            <b-form-group label="Areas">
              <multiselect v-model="standardsareasSupport" :options="data.standardsareas" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.education.standardsareas = updateCombo(standardsareasSupport)"/>
            </b-form-group>
            <b-form-group label="Title">
              <b-input-group v-for="item of data.education.languages" :key="item.id" v-show="item.id === 2">
                <b-form-input v-model="item.title"/>
              </b-input-group>
            </b-form-group>
            <b-form-group label="IAFs">
              <multiselect v-model="nacesiafcodesSupport" :options="data.nacesiafcodes" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.education.nacesiafcodes = updateCombo(nacesiafcodesSupport)"/>
            </b-form-group>
          </b-tab>
          <b-tab :title="'Documents ' + data.documents.length"  v-if="data.education.id > 0">
            <document ref="document" @update="loadData(data.education.users_id, data.education.id)"/>
            <b-btn variant="primary" @click="loadDocument(null)">New</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 380) + 'px'" responsive class="mt-3" :items="data.documents" :fields="fields" @row-clicked="loadDocument($event.file)">
              <template v-slot:cell(id)="row">
                <b-btn variant="primary" size="sm" @click="$refs.document.downloadFile(row.value)">Download</b-btn>
              </template>            
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Save</b-btn>
          <b-btn variant="danger" v-show="data.education.id > 0" @click="deleteData">Delete</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import document from './document.vue';

export default {
  name: "education",
  components: { document },
  data() {
    return {
      show: false,
      data: null,
      standardsareasSupport: [],
      nacesiafcodesSupport: [],
      fields: [{
        key: 'filename',
        label: 'Document',
      },{
        key: 'id',
        label: "",
      }]
    }
  },
  computed: {
    winHeight(){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadData(usersId, id){
      this.openHttp("curricula/education", { id: id, users_id: usersId } ).then((result) => {
        this.standardsareasSupport = this.convertCombo(result.standardsareas, result.education.standardsareas);
        this.nacesiafcodesSupport = this.convertCombo(result.nacesiafcodes, result.education.nacesiafcodes);
        this.data = result;
        this.show = true;
      })
    },
    loadDocument(fileName){
      this.$refs.document.loadData("education", this.data.education.id, fileName);
    },
    saveData(){
      this.saveHttp("curricula/education/save", this.data.education).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("curricula/education/delete", { id: this.data.education.id }).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    closeForm(reload = false){
      this.data = null;
      this.show = false;
      if (reload === true) this.$emit('update');
    }
  }
}
</script>
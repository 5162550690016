<template>
  <div>
    <b-btn class="mr-2" variant="primary" @click="loadData">Refresh</b-btn>
    <form method="post" action="/api/documentsconsultation/download" ref="downloadFormSubmit" target="_blank">
      <input type="hidden" name="token" :value="token">
      <input type="hidden" ref="downloadFormId" name="id">
    </form>
    <b-table no-border-collapse :sticky-header="(winHeight - 210) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="items.data" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sortby" :sort-desc.sync="search.sortdesc" @sort-changed="sortingChange">
      <template v-slot:table-busy>
        <div class="text-center text-primary"><b-spinner class="align-middle"/></div>
      </template>    
      <template v-slot:empty>There are no results</template>
      <template v-slot:cell(revisiondate)="row">
        <b-btn variant="primary" size="sm" v-if="row.item.documentsrevisions_check === true" @click="downloadData(row.item.documentsrevisions_id)"><nobr>{{ row.value + " rev. " + row.item.revision }} <b-icon-download/></nobr></b-btn>
      </template>
      <template v-slot:cell(recorddate)="row">
        <b-btn variant="primary" size="sm" v-if="row.item.documentsrecords_check === true" @click="downloadData(row.item.documentsrecords_id)"><nobr>{{ row.value }} <b-icon-download/></nobr></b-btn>
      </template>
    </b-table>
    <tablebottom :currentpage="search.page" @updateCurrentPage="search.page = $event" :total="items.total" @loadData="loadData"/>
  </div>
</template>

<script>
export default {
  name: "documentsconsultation",
  data() {
    return {
      isBusy: true,
      search:{
        page: 1,
        filters: {},
        sortby: "code",
        sortdesc: false,
      },
      fields: [{
        key: 'code',
        label: 'Code',
        sortable: true,
      },{
        key: 'documentname',
        label: 'Name',
        sortable: true,
      },{
        key: 'documenttype',
        label: 'Type',
        sortable: true,
      },{
        key: 'revisiondate',
        label: 'Revision',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'recorddate',
        label: "Record",
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      }],
      items: {
        total: 0,
        data: [],
      },
    }  
  },
  computed: {
    token (){
      return this.$store.getters.getToken;
    },
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.isBusy = true;
      console.log(this.search);
      this.openHttp("documentsconsultation", this.search).then((result) => {
        this.items = result;
        this.isBusy = false;
      })
    },
    sortingChange(ctx) {
      this.search.sortby = ctx.sortBy;
      this.search.sortdesc = ctx.sortDesc;
      this.loadData();
    },
    downloadData(id){
      this.$refs.downloadFormId.value = id;
      this.$refs.downloadFormSubmit.submit();
    }
  }
}
</script>
<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" title="Site" @hidden="closeForm" @close="closeForm">
            <b-form-group label="Site">
                <b-form-select v-model="data.site.sites_id" :options="data.sites" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Status">
                <b-form-select v-model="data.site.active" :options="activeCombo" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Total workers">
                <b-form-input v-model="data.site.employeesnumber" type="number" min="0" step="1"/>
            </b-form-group>
            <b-form-group label="Effective workers">
                <b-form-input v-model="data.site.employeeseffectivenumber" type="number" min="0" step="1"/>
            </b-form-group>
            <b-form-group label="External esterni">
                <b-form-input v-model="data.workers.employeesexternal" type="number" min="0" step="1"/>
            </b-form-group>
            <b-form-group label="Repetitive workers">
                <b-form-input v-model="data.site.employeesrepetitive" type="number" min="0" step="1"/>
            </b-form-group>
            <b-form-group label="Shifts">
                <b-form-input v-model="data.site.shifts" type="number" min="0" step="1"/>
            </b-form-group>
            <b-form-group label="Note">
                <b-form-textarea v-model="data.site.note" rows="2"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Save</b-btn>
                    <b-btn variant="danger" v-show="data.site.sites_id > 0" @click="deleteData">Delete</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "contractsite",
    data() {
        return {
            show: false,
            data: null,
            activeCombo: [{
                id: false,
                val: "Not active"
            },{
                id: true,
                val: "Active"
            }],
        }
    },
    methods: {
        loadData( contractsId, id ){
            this.show = false;
            this.openHttp("contracts/site", { contracts_id: contractsId, id: id } ).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("contracts/site/save", this.data.site).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("contracts/site/delete", { sites_id: this.data.site.sites_id, contracts_id: this.data.site.contracts_id }).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>

<template>
    <div>
        <b-modal v-model="show" v-if="data !== null" title="Site" size="lg" @hidden="closeForm()" @close="closeForm()">
            <div v-if="data.site.contractssites_id > 0">
                <b-row>
                    <b-col lg="6">
                        <b-form-group label="Site">
                            <b-form-select v-model="data.site.contractssites_id" :options="data.contractssites" value-field="id" text-field="val" :disabled="data.site.locked === true"/>
                        </b-form-group>
                    </b-col>
                    <b-col lg="2">
                        <b-form-group label="Status">
                            <b-form-select v-model="data.site.active" :options="activeCombo" value-field="id" text-field="val" :disabled="data.site.locked === true"/>
                        </b-form-group>
                    </b-col>
                    <b-col lg="2">
                        <b-form-group label="Sampled">
                            <b-form-select v-model="data.site.presence" :options="sampledCombo" value-field="id" text-field="val" :disabled="data.site.locked === true"/>
                        </b-form-group>
                    </b-col>
                    <b-col lg="2" v-if="data.site.presence === true">
                        <b-form-group label="Location">
                            <b-form-select v-model="data.site.onremote" :options="onRemoteCombo" value-field="id" text-field="val" :disabled="data.site.locked === true"/>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="2">
                        <b-form-group label="Shifts">
                            <b-form-input type="number" v-model="data.site.shifts" :disabled="data.site.locked === true"/>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="Employees number">
                            <b-form-input type="number" v-model="data.site.employeesnumber" :disabled="data.site.locked === true"/>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="Employees effective number">
                            <b-form-input type="number" v-model="data.site.employeeseffectivenumber" :disabled="data.site.locked === true"/>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="Employees external">
                            <b-form-input type="number" v-model="data.site.employeesexternal" :disabled="data.site.locked === true"/>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="Employees repetitive">
                            <b-form-input type="number" v-model="data.site.employeesrepetitive" :disabled="data.site.locked === true"/>
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>
            <div v-else>
                <b-row>
                    <b-col lg="5">
                        <b-form-group label="Address">
                            <b-form-input v-model="data.site.address"/>
                        </b-form-group>
                    </b-col>
                    <b-col lg="5">
                        <b-form-group label="City">
                            <multiselect v-model="cities_idSupport" :options="data.cities" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.site.cities_id = updateComboSimple(cities_idSupport);"/>
                        </b-form-group>
                    </b-col>
                    <b-col lg="2">
                        <b-form-group label="Location">
                            <b-form-select v-model="data.site.onremote" :options="onRemoteCombo" value-field="id" text-field="val" :disabled="data.site.locked === true"/>
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>
            <b-form-group label="Activities">
                <b-form-checkbox-group v-model="data.site.naces" :options="data.naces.filter(x => (data.site.contractssites_id === null) ? (x.yardnec === true) : (x.id > 0))" value-field="id" text-field="val" stacked :disabled="data.site.locked === true"/>
            </b-form-group>
            <b-row>
                <b-col lg="2">
                    <b-form-group label="Confirmed">
                        <b-form-select v-model="data.site.locked" :options="lockedCombo" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData()">Save</b-btn>
                    <b-btn variant="danger" v-show="data.site.id > 0 && data.site.contractssites_id === null" @click="deleteData()">Delete</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "auditsite",
    data() {
        return {
            show: false,
            data: null,
            cities_idSupport: [],
            activeCombo: [{
                id: false,
                val: "Not active"
            },{
                id: true,
                val: "Active"
            }],
            sampledCombo: [{
                id: false,
                val: "No"
            },{
                id: true,
                val: "Sampled"
            }],
            onRemoteCombo: [{
                id: false,
                val: "On site"
            },{
                id: true,
                val: "Remote"
            }],
            lockedCombo: [{
                id: false,
                val: "Editable"
            },{
                id: true,
                val: "Confirmed"
            }]
        }
    },
    methods: {
        loadData( id, audits_id ){
            this.openHttp("audits/site", { id: id, audits_id: audits_id }).then((result) => {
                this.cities_idSupport = this.convertComboSimple(result.cities, result.site.cities_id);
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            if (this.data.site.contractssites_id > 0){
                this.data.site.address = null;
                this.data.site.cities_id = null;
            } else {
                this.data.site.shifts = 0;
                this.data.site.employeesnumber = 0;
                this.data.site.employeeseffectivenumber = 0;
                this.data.site.employeesexternal = 0;
                this.data.site.employeesrepetitive = 0;
                this.data.site.contractssites_id = null;
                this.data.site.presence = true;
                this.data.site.active = true;
            }
            this.saveHttp("audits/site/save", this.data.site).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("audits/site/delete", { id: this.data.site.id }).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        }
    }
}
</script>
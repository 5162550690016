<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" title="Document" @hidden="closeForm()" @close="closeForm()">
            <b-form-group label="File name" v-if="data.document.filename !== null">
                <b-form-input v-model="data.document.filename"/>
            </b-form-group>
            <b-form-group label="File">
                <b-form-file @change="fileChange"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" v-show="data.document.lock_datetime === null" class="mr-2" @click="saveData">Save</b-btn>
                    <b-btn variant="danger" class="mr-2" v-show="data.document.id > 0 && data.document.lock_datetime === null" @click="deleteData">Delete</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "applicationdocument",
    data() {
        return {
            show: false,
            data: null,
        }
    },
    methods: {
        loadData( contractsId, id ){
            this.openHttp("applications_document", { contracts_id: contractsId, id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        fileChange(event){
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = ()=> {
                if ( this.data.document.id > 0 ){
                    if ( event.target.files[0].name != this.data.filename ){
                        if ( confirm("Change file name?") == true ) this.data.filename = event.target.files[0].name;
                    }
                } else {
                    this.data.document.filename = event.target.files[0].name;
                }
                this.data.document.file = reader.result;
            };
        },
        saveData(){
            this.saveHttp("applications_document_save", this.data.document, "Document saved!" ).then((result) => {
                if ( result.result == true ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("applications_document_delete", { contracts_id: this.data.document.contracts_id, id: this.data.document.id }, "Document deleted!").then((result) => {
                if ( result.result == true ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>
<template>
  <div>
    <b-modal v-if="data != null" v-model="show" size="lg" title="Internal audit program" scrollable @hidden="closeForm()" @close="closeForm()">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Program" active>
            <b-row>
              <b-col lg="2">
                <b-form-group label="Open date">
                  <b-form-input type="date" v-model="data.internalaudit.fromdate"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Closure date">
                  <b-form-input type="date" v-model="data.internalaudit.todate"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Period from">
                  <b-form-input type="date" v-model="data.internalaudit.periodfrom"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Period to">
                  <b-form-input type="date" v-model="data.internalaudit.periodto"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Standards">
              <multiselect v-model="standardsSupport" :options="data.standards" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.internalaudit.standards = updateCombo(standardsSupport)"/>
            </b-form-group>
            <b-form-group label="Audit team">
              <multiselect v-model="usersSupport" :options="data.users" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.internalaudit.users = updateCombo(usersSupport)"/>
            </b-form-group>
            <b-form-group label="Scope and sites">
              <b-form-textarea v-model="data.internalaudit.scope_site" rows="2"/>
            </b-form-group>
            <b-row>
              <b-col lg="6">
                <b-form-group label="Objectives">
                  <b-form-textarea v-model="data.internalaudit.objectives" rows="3"/>
                </b-form-group>
              </b-col>
              <b-col lg="6">  
                <b-form-group label="Risks and opportunities">
                  <b-form-textarea v-model="data.internalaudit.risks_opportunities" rows="3"/>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group label="Criteria">
                  <b-form-textarea v-model="data.internalaudit.criteria" rows="3"/>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group label="Methods">
                  <b-form-textarea v-model="data.internalaudit.methods" rows="3"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Note">
              <b-form-textarea v-model="data.internalaudit.note" rows="4"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Planning">
            <internalauditreservation ref="reservation" @update="loadData(data.internalaudit.id)"/>
            <b-btn variant="primary" class="mb-3" @click="$refs.reservation.loadData(0, data.internalaudit.id)">New</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 380) + 'px'" hover responsive :items="data.reservations" :fields="fields" @row-clicked="$refs.reservation.loadData($event.id, data.internalaudit.id)"/>
          </b-tab>
          <b-tab title="Approval">
            <b-row>
              <b-col lg="6">
                <b-form-group label="Approved by">
                  <b-form-select v-model="data.internalaudit.approve_users_id" :options="data.users" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Approve date">
                  <b-form-input type="date" v-model="data.internalaudit.approve_date"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData()">Save</b-btn>
          <b-btn variant="danger" v-show="data.internalaudit.id > 0" @click="deleteData()">Delete</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import internalauditreservation from './internalauditreservation.vue'

export default {
  name: "internalauditprogram",
  components: { internalauditreservation },
  data() {
    return {
      show: false,
      data: null,
      usersSupport: [],
      fields: [{
        key: 'date',
        label: 'Date',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
      },{
        key: 'fromtime',
        label: 'From',
      },{
        key: 'totime',
        label: 'To',
      },{
        key: 'users',
        label: 'Team',
      },{
        key: 'points',
        label: 'Points',
      },{
        key: 'note',
        label: 'Note',
      }],
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadData(id){
      this.openHttp("internalaudits/program", { id: id }).then((result) => {
        this.usersSupport = this.convertCombo(result.users, result.internalaudit.users);
        this.standardsSupport = this.convertCombo(result.standards, result.internalaudit.standards);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("internalaudits/program/save", this.data.internalaudit).then((result) => {
        if ( result !== null) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("internalaudits/program/delete", { id: this.data.internalaudit.id }).then((result) => {
        if ( result !== null) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    }
  }
}
</script>

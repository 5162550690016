<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" title="Archive" size="lg">
      <b-form-group label="Folder">
        <b-form-select v-model="data.archive.folder" :options="data.folders" value-field="id" text-field="val" @change="loadEmpty"/>
      </b-form-group>
      <b-form-group label="Archive">
        <multiselect v-model="folders_idSupport" :options="data.folders2" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.archive.folders_id = updateComboSimple(folders_idSupport)" @search-change="asyncMails"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" :disabled="data.archive.folders_id === null" @click="saveData">Save</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "mailarchive",
  data() {
    return {
      show: false,
      data: null,
      comboSupport: [],
      folders_idSupport: null,
    }
  },
  methods: {
    loadData( id ){
      this.openHttp("mails_archive", { id: id }).then((result) => {
        this.folders_idSupport = this.convertComboSimple( result.folders2, result.archive.folders_id );
        this.data = result;
        this.show = true;
      })
    },
    loadEmpty(){
      this.data.archive.folders_id = null;
      this.data.folder2 = [];
      this.openHttp("mails_archive", { id: this.data.archive.id, folder: this.data.archive.folder }).then((result) => {
        result.folders_idSupport = this.convertComboSimple( result.folders2, result.archive.folders_id );
        this.data = result;
        this.show = true;
      })
    },
    asyncMails ( query ) {
      this.openHttp("mails_archive", { id: this.data.archive.id, folder: this.data.archive.folder, search: query }).then((result) => {
        for ( let item of result.folders2 ) {
          if ( this.data.folders2.findIndex( x => x.id === item.id ) === -1 ) this.data.folders2.push(item);
        }
        this.data.folders2 = this.data.folders2.sort((a,b) => ( a.val > b.val ) ? 1 : 0 ); 
      })
    },
    saveData(){
      this.saveHttp("mails_archive_save", this.data.archive, "Mail saved!" ).then((result) => {
        if ( result.result == true ) this.closeForm();
      })
    },
    closeForm(){
      this.data = null;
      this.show = false;
      this.$emit('update');
    },
  },
}
</script>
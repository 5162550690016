<template>
    <div>
        <b-modal v-if="show" v-model="show" size="lg" title="Transfer audit review" @hidden="closeForm()" @close="closeForm()">
            <b-card no-body>
                <b-tabs card>
                    <b-tab title="Data" active>
                        <b-row>
                            <b-col lg="4">
                                <b-form-group label="Date">
                                    <b-form-input v-model="data.previousaudit.plandate" type="date"/>
                                </b-form-group>
                            </b-col>
                            <b-col lg="8">
                                <b-form-group label="Phase">
                                    <b-form-select v-model="data.previousaudit.contractsphases_id" :options="data.contractsphases" value-field="id" text-field="val"/>
                                </b-form-group>                    
                            </b-col>
                        </b-row>
                        <b-form-group label="Points">
                            <b-form-checkbox-group v-model="data.previousaudit.standardspoints">
                                <div v-for="(item, index) of data.standardspoints" :key="index">
                                    <strong>{{ item.standard }}</strong>
                                    <br>
                                    <b-form-checkbox v-for="(item2, index2) of item.points" :key="index2" :value="item2.id">{{ item2.val }}</b-form-checkbox>
                                </div>
                            </b-form-checkbox-group>
                        </b-form-group>
                        <b-form-group label="Sites">
                            <multiselect v-model="sitesSupport" :options="data.sites" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.previousaudit.sites = updateCombo(sitesSupport)"/>
                        </b-form-group>
                    </b-tab>
                    <b-tab title="Findings">
                        <div v-for="(item, index) in data.standardspoints" :key="index">
                            <label>{{ item.standard }}</label>
                            <b-row>
                                <b-col v-for="(item2, index2) in item.points" :key="index2" lg="1">
                                    <b-form-group :label="item2.val">
                                        <b-form-input :value="data.previousaudit.findings.findIndex(x => x.id === item2.id) > -1 ? (data.previousaudit.findings.find( x=> x.id === item2.id).findingsnumber) : 0" type="number" size="sm"  @change="updateFindings(item2.id, $event)"/>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </div>
                    </b-tab>
                    <b-tab title="Non Conformities">
                        <div v-for="(item, index) in data.standardspoints" :key="index">
                            <label>{{ item.standard }}</label>
                            <b-row>
                                <b-col v-for="(item2, index2) in item.points" :key="index2" lg="1">
                                    <b-form-group :label="item2.val">
                                        <b-input-group v-for="(item3, index3) in data.nonconformitiesypes" :prepend="item3.val" size="sm" :key="index3">
                                            <b-form-input :value="data.previousaudit.nonconformities.findIndex(x => x.id === item2.id && x.nonconformitiestypes_id === item3.id) > -1 ? (data.previousaudit.nonconformities.find(x=> x.id === item2.id && x.nonconformitiestypes_id === item3.id).nonconformitiesnumber) : 0" type="number" size="sm" @change="updateNonConformities(item2.id, item3.id, $event)"/>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </div>
                    </b-tab>
                    <b-tab title="Review">
                        <b-form-group label="Considerations">
                            <b-form-textarea v-model="data.previousaudit.considerations" rows="4"/>
                        </b-form-group>
                        <b-form-group label="Complaints">
                            <b-form-textarea v-model="data.previousaudit.complaints" rows="4"/>
                        </b-form-group>
                        <b-form-group label="Actions taken (relating to complaints)">
                            <b-form-textarea v-model="data.previousaudit.actionstaken" rows="4"/>
                        </b-form-group>
                        <b-form-group label="Engagement with regulatory bodies">
                            <b-form-textarea v-model="data.previousaudit.regulatorybodies" rows="4"/>
                        </b-form-group>
                        <b-row>
                            <b-col lg="4">
                                <b-form-group label="Reviewer">
                                    <b-form-input v-model="data.previousaudit.fullname"/>
                                </b-form-group>                    
                            </b-col>
                            <b-col lg="3">
                                <b-form-group label="Date">
                                    <b-form-input v-model="data.previousaudit.reviewdate" type="date"/>
                                </b-form-group>                    
                            </b-col>
                            <b-col lg="4">
                                <b-form-group label="Review">
                                    <b-form-select v-model="data.previousaudit.reviewsuccess" :options="reviewsuccessCombo" value-field="id" text-field="val"/>
                                </b-form-group>                    
                            </b-col>
                        </b-row>
                        <b-form-group label="Note">
                            <b-form-textarea v-model="data.previousaudit.reviewnote" rows="2" :disabled="data.previousaudit.reviewsuccess !== null"/>
                        </b-form-group>
                    </b-tab>
                </b-tabs>
            </b-card>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Save</b-btn>
                    <b-btn variant="danger" v-show="data.previousaudit.id > 0" @click="deleteData">Delete</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "auditspreviousaudit",
    data() {
        return {
            show: false,
            data: null,
            sitesSupport: [],
            reviewsuccessCombo: [{
                id: true,
                val: "Accepted"
            },{
                id: false,
                val: "Not accepted"
            },{
                id: null,
                val: "Pending"
            }]
        }
    },
    methods: {
        updateNonConformities(standardsPointsId, nonconformititesTypesId, number){
            if (this.data.previousaudit.nonconformities.findIndex(x => x.id === standardsPointsId && x.nonconformitiestypes_id === nonconformititesTypesId) > -1){
                this.data.previousaudit.nonconformities.find(x => x.id === standardsPointsId && x.nonconformitiestypes_id === nonconformititesTypesId).nonconformitiesnumber = number
            } else {
                this.data.previousaudit.nonconformities.push({ id:standardsPointsId, nonconformitiestypes_id: nonconformititesTypesId, nonconformitiesnumber:number });
            }
        },
        updateFindings(standardsPointsId, number){
            if (this.data.previousaudit.findings.findIndex(x => x.id === standardsPointsId) > -1){
                this.data.previousaudit.findings.find(x => x.id === standardsPointsId).findingsnumber = number
            } else {
                this.data.previousaudit.findings.push({ id:standardsPointsId, findingsnumber: number });
            }
        },
        loadData( auditsId, id ){
            this.openHttp("audits/previousaudit", { audits_id: auditsId, id: id } ).then((result) => {
                this.sitesSupport = this.convertCombo(result.sites, result.previousaudit.sites);
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("audits/previousaudit/save", this.data.previousaudit).then((result) => {
                if ( result !== null ) this.closeForm(true);
            })
        },
        deleteData(){
            this.deleteHttp("audits/previousaudit/delete", { id: this.data.previousaudit.id, audits_id: this.data.previousaudit.audits_id }).then((result) => {
                if ( result !== null ) this.closeForm(true);
            })
        },
        closeForm(reload = false){
            this.show = false;
            this.data = null;
            if (reload === true) this.$emit("update");
        }
    },
}
</script>
<template>
  <div>
    <job ref="job" @update="loadData()"/>
    <requirement ref="requirement" @update="loadData()"/>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Jobs" active>
          <b-modal v-model="jobs.showFilter" title="Filter">
            <b-form-group label="Job">
              <b-form-input v-model="jobs.tempFilter.job"/>
            </b-form-group>
            <b-form-group label="Requirement">
              <b-form-input v-model="jobs.tempFilter.requirement"/>
            </b-form-group>
            <template v-slot:modal-footer>
              <div class="w-100">
                <b-btn variant="primary" class="mr-2" @click="filterSetJobs">Filter</b-btn>
                <b-btn variant="warning" @click="filterResetJobs">Reset</b-btn>
              </div>
            </template>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadDataJobs()">Refresh</b-btn>
          <b-btn variant="secondary" class="mr-2" @click="jobs.showFilter = true">Filter</b-btn>
          <b-btn class="mr-2" variant="success" @click="$refs.job.loadData(0)">New</b-btn>
          <b-btn variant="info" @click="generateData()">Generate</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="jobs.items.data" :fields="jobs.fields" :busy="jobs.isBusy" :no-local-sorting="true" :sort-by.sync="jobs.search.sortby" :sort-desc.sync="jobs.search.sortdesc" @sort-changed="sortingChangeJobs" @row-clicked="$refs.job.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>There are no results</template>
            <template v-slot:cell(authorities)="row">
              <div v-for="(item, index) of row.value" :key="index">{{ item }}</div>
            </template>
            <template v-slot:cell(responsibilities)="row">
              <div v-for="(item, index) of row.value" :key="index">{{ item }}</div>
            </template>
            <template v-slot:cell(requirements)="row">
              <div v-for="(item, index) of row.value" :key="index">{{ item }}</div>
            </template>
            <template v-slot:cell(trainingstypes)="row">
              <div v-for="(item, index) of row.value" :key="index">{{ item }}</div>
            </template>
          </b-table>
          <tablebottom :currentpage="jobs.search.page" @updateCurrentPage="jobs.search.page = $event" :total="jobs.items.total" @loadData="loadDataJobs"/>
        </b-tab>
        <b-tab title="Requirements">
          <b-modal v-model="requirements.showFilter" title="Filter">
            <b-form-group label="Requirement">
              <b-form-input v-model="requirements.tempFilter.requirement"/>
            </b-form-group>
            <b-form-group label="Job">
              <b-form-input v-model="requirements.tempFilter.job"/>
            </b-form-group>
            <b-form-group label="Question">
              <b-form-input v-model="requirements.tempFilter.question"/>
            </b-form-group>
            <template v-slot:modal-footer>
              <div class="w-100">
                <b-btn variant="primary" class="mr-2" @click="filterSetRequirements">Filter</b-btn>
                <b-btn variant="warning" @click="filterResetRequirements">Reset</b-btn>
              </div>
            </template>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadDataRequirements">Refresh</b-btn>
          <b-btn variant="secondary" class="mr-2" @click="requirements.showFilter = true">Filter</b-btn>
          <b-btn variant="success" @click="$refs.requirement.loadData(0)">New</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight-305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="requirements.items.data" :fields="requirements.fields" :busy="requirements.isBusy" :no-local-sorting="true" :sort-by.sync="requirements.search.sortby" :sort-desc.sync="requirements.search.sortdesc" @sort-changed="sortingChangeRequirements" @row-clicked="$refs.requirement.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:cell(evaluationmethod)="row">
              <div v-for="(item, index) of row.value" :key="index">{{ item }}</div>
            </template>
            <template v-slot:cell(note)="row">
              <div v-for="(item, index) of row.value" :key="index">{{ item }}</div>
            </template>
            <template v-slot:cell(jobs)="row">
              <div v-for="(item, index) of row.value" :key="index">{{ item }}</div>
            </template>
            <template v-slot:empty>There are no results</template>
          </b-table>
          <tablebottom :currentpage="requirements.search.page" @updateCurrentPage="requirements.search.page = $event" :total="requirements.items.total" @loadData="loadDataRequirements"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import job from './job.vue';
import requirement from './requirement.vue';

export default {
  name: "jobs",
  components: { job, requirement },
  data() {
    return {
      jobs: {
        showFilter: false,
        fields: [{
          key: "job",
          label: "Job",
          sortable: true,
        },{
          key: "authorities",
          label: "Authorities",
        },{
          key: "responsibilities",
          label: "Responsibilities",
        },{
          key: "requirements",
          label: "Requirements",
        },{
          key: "requirements",
          label: "Requirements",
        },{
          key: "trainingstypes",
          label: "Trainings",
        },{
          key: "date",
          label: "Date",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        }],
        isBusy: true,
        items: {
          total: 0,
          data: [],
          records_id: null,
          records_date: null
        },
        search:{
          page: 1,
          filters: {
            job: null,
            requirement: null,
          },
          sortby: "jobdescription",
          sortdesc: false,
        },
        tempFilter: {
          job: null,
          requirement: null,
        }
      },
      requirements: {
        showFilter: false,
        fields: [{
          key: "requirement",
          label: "Requirement",
          sortable: true,
          thStyle: { width: "25%" }
        },{
          key: "evaluationmethod",
          label: "Evaluation method",
          thStyle: { width: "20%" }
        },{
          key: "note",
          label: "Note",
          thStyle: { width: "20%" }
          
        },{
          key: "jobs",
          label: "Jobs",
          thStyle: { width: "15%" }
        }],
        isBusy: true,
        items: {
          total: 0,
          data: [],
        },
        search:{
          page: 1,
          filters: {
            requirement: null,
            job: null,
            question: null,
          },
          sortby: 'competence',
          sortdesc: false,
        },
        tempFilter: {
          requirement: null,
          job: null,
          question: null,
        }
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    }
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.loadDataJobs();
      this.loadDataRequirements();
    },
    loadDataJobs(){
      this.jobs.isBusy = true;
      this.openHttp("jobs", this.jobs.search).then((result) => {
        this.jobs.items = result;
        this.jobs.isBusy = false;
      })
    },
    filterSetJobs(){
      this.jobs.showFilter = false;
      this.jobs.search.filters = this.jobs.tempFilter;
      this.loadDataJobs();
    },
    filterResetJobs(){
      this.jobs.tempFilter = {
        users_id: null,
        jobdescriptions_id: null,
        type: 2
      }
      this.filterSetJobs();
    },
    sortingChangeJobs(ctx) {
      this.jobs.search.sortby = ctx.sortBy;
      this.jobs.search.sortdesc = ctx.sortDesc;
      this.loadDataJobs();
    },
    loadDataRequirements(){
      this.requirements.isBusy = true;
      this.openHttp("jobs/requirements", this.requirements.search).then((result) => {
        this.requirements.items = result;
        this.requirements.isBusy = false;
      })
    },
    filterSetRequirements(){
      this.requirements.showFilter = false;
      this.requirements.search.filters = this.requirements.tempFilter;
      this.loadDataRequirements();
    },
    filterResetRequirements(){
      this.requirements.tempFilter = {
        requirement: null,
        job: null,
        question: null
      }
      this.filterSetRequirements();
    },
    sortingChangeRequirements(ctx) {
      this.requirements.search.sortby = ctx.sortBy;
      this.requirements.search.sortdesc = ctx.sortDesc;
      this.loadDataRequirements();
    },
    generateData(){
      this.openHttp("jobs/generate", {}).then((result) => {
        if (result === true) this.$bvToast.toast("Document in prepatation, please, check in the documents", { title: "Eurocertifications", variant: "info", appendToast: true });
      })
    }
  }
}
</script>
<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Document" scrollable @hidden="closeForm()" @close="closeForm()">
      <documentrevision ref="revision" @update="loadData(data.document.id)"/>
      <documentrecord ref="record" @update="loadData(data.document.id)"/>  
      <document ref="document"/>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Document" active>
            <b-row>
              <b-col lg="2">
                <b-form-group label="Code">
                  <b-form-input v-model="data.document.code"/>
                </b-form-group>
              </b-col>
              <b-col lg="8">
                <b-form-group label="Name">
                  <b-form-input v-model="data.document.documentname"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Origin">
                  <b-form-select v-model="data.document.external" :options="typeCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Type">
                  <b-form-select v-model="data.document.documentssystemtypes_id" :options="data.types" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Status">
                  <b-form-select v-model="data.document.active" :options="activeCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Avaiable personnel">
                  <b-form-select v-model="data.document.avaiablepersonnel" :options="avaiableCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Avaiable Web">
                  <b-form-select v-model="data.document.avaiableweb" :options="avaiableCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Jobs">
              <multiselect v-model="jobsSupport" :options="data.jobs" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.document.jobs = updateCombo(jobsSupport)"/>
            </b-form-group>
            <b-form-group label="Points">
              <multiselect v-model="standardspointsSupport" :options="data.standardspoints" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.document.standardspoints = updateCombo(standardspointsSupport)"/>
            </b-form-group>
            <b-form-group label="Note">
              <b-form-textarea v-model="data.document.note" rows="4"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Revisions" v-if="data.document.id > 0">
            <b-btn variant="primary" size="sm" @click="$refs.revision.loadData(0, data.document.id)">New</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 380) + 'px'" class="mt-2" responsive :items="data.revisions" :fields="fields.revisions" @row-clicked="$refs.revision.loadData($event.id, data.document.id)">
              <template v-slot:cell(documents_id)="row">
                <b-btn variant="primary" size="sm" v-if="row.item.documents_check" @click="$refs.document.downloadData(row.value)"><b-icon-download/></b-btn>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Records" v-if="data.document.id > 0">
            <b-btn variant="primary" size="sm" @click="$refs.record.loadData(0, data.document.id)">New</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 380) + 'px'" class="mt-2" responsive :items="data.records" :fields="fields.records" @row-clicked="$refs.record.loadData($event.id, data.document.id)">
              <template v-slot:cell(documents_id)="row">
                <b-btn variant="primary" size="sm" v-if="row.item.documents_check" @click="$refs.document.downloadData(row.value)"><b-icon-download/></b-btn>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" v-show="data.document.id > 0" class="mr-2" @click="loadData(data.document.id)">Refresh</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData">Save</b-btn>
          <b-btn variant="danger" v-show="data.document.id > 0" @click="deleteData">Delete</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import documentrevision from './documentrevision.vue'
import documentrecord from './documentrecord.vue'
import document from './document.vue';

export default {
  name: "documentdocument",
  components: { documentrevision, documentrecord, document },
  data() {
    return {
      show: false,
      data: null,
      jobdescriptionsSupport: [],
      standardspointsSupport: [],
      activeCombo: [{
        id: true,
        val: "Active"
      },{
        id: false,
        val: "Not Active"
      }],
      typeCombo: [{
        id: false,
        val: "Internal"
      },{
        id: true,
        val: "External"
      }],
      avaiableCombo: [{
        id: true,
        val: "Yes"
      },{
        id: false,
        val: "No"
      }],
      fields: {
        revisions: [{
          key: "create_user",
          label: "Created by",
        },{
          key: 'create_date',
          label: 'Creation Date',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'revision',
          label: 'Revision',
          sortable: true,
        },{
          key: 'approve_date',
          label: 'Approval Date',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'approve_user',
          label: 'Approved by',
        },{
          key: 'expiry_date',
          label: "Expiry date",
          sortable: true,
          formatter: value => {
            if (value == null) return "Not expire";
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "documents_id",
          label: "",
        }],
        records: [{
          key: 'date',
          label: 'Record Date',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "note",
          label: "Note",
        },{
          key: "documents_id",
          label: "",
        }]
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadData(id){
      this.openHttp("documentssystem/documentdocument", { id: id }).then((result) => {
        this.jobsSupport = this.convertCombo(result.jobs, result.document.jobs);
        this.standardspointsSupport = this.convertCombo(result.standardspoints, result.document.standardspoints);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("documentssystem/documentdocument/save", this.data.document).then((result) => {
        if ( result !== null ) this.closeForm(true);
      })
    },
    deleteData(){
      this.deleteHttp("documentssystem/documentdocument/delete", { id: this.data.document.id }).then((result) => {
        if ( result !== null ) this.closeForm(true);
      })
    },
    closeForm(reload = false){
      this.show = false;
      this.data = null;
      if ( reload === true ) this.$emit('update');
    }
  }
}
</script>

<template>
  <div>
    <b-modal v-if="data != null" v-model="show" size="lg" title="Risk" scrollable @hidden="closeForm" @close="closeForm">
      <riskanalysis ref="riskanalysis" @update="loadData(data.risk.id)"/>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Risk" active>
            <b-form-group label="Areas">
              <multiselect v-model="risksareasSupport" :options="data.risksareas" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.risk.risksareas = updateCombo(risksareasSupport)"/>
            </b-form-group>
            <b-form-group label="Risk">
              <b-form-textarea v-model="data.risk.risk" required rows="3"/>
            </b-form-group>
            <b-form-group label="Sites">
              <b-form-textarea v-model="data.risk.sites" required rows="3"/>
            </b-form-group>
            <b-form-group label="Causes">
              <b-form-textarea v-model="data.risk.causes" required rows="3"/>
            </b-form-group>
            <b-form-group label="Interested parties">
              <multiselect v-model="interestedpartiesSupport" :options="data.interestedparties" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.risk.interestedparties = updateCombo(interestedpartiesSupport)"/>
            </b-form-group>
            <b-row>
              <b-col lg="3">
                <b-form-group label="Date">
                  <b-form-input type="date" v-model="data.risk.date"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Probability">
                  <b-form-select v-model="data.risk.probability" :options="probabilityCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Seriousness">
                  <b-form-select v-model="data.risk.seriousness" :options="seriousnessCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Detectability">
                  <b-form-select v-model="data.risk.detectability" :options="detectabilityCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Analysis" :disabled="data.risk.id === 0">
            <b-btn size="sm" variant="primary" @click="$refs.riskanalysis.loadData(0, data.risk.id)">New analysis</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" class="mt-3" responsive show-empty :items="data.analysis" :fields="fields" @row-clicked="$refs.riskanalysis.loadData($event.id, data.risk.id)">
              <template v-slot:empty>There are no results</template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Save</b-btn>
          <b-btn variant="danger" v-show="data.risk.id > 0" @click="deleteData">Delete</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import riskanalysis from './riskanalysis.vue'

export default {
  name: "risk",
  components: { riskanalysis },
  data() {
    return {
      show: false,
      data: null,
      risksareasSupport: [],
      interestedpartiesSupport: [],
      probabilityCombo: [{
        id: 1,
        val: 'Low'
      },{
        id: 2,
        val: 'Medium'
      },{
        id: 3,
        val: 'High'
      }],
      seriousnessCombo: [{
        id: 1,
        val: 'Low'
      },{
        id: 2,
        val: 'Medium'
      },{
        id: 3,
        val: 'High'
      }],
      detectabilityCombo: [{
        id: 1,
        val: 'Easy'
      },{
        id: 2,
        val: 'Medium'
      },{
        id: 3,
        val: 'Hard'
      }],
      fields: [{
        key: "date",
        label: "Date",
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: "mitigation",
        label: "Correction"
      },{
        key: "prevenction",
        label: "Corrective action"
      },{
        key: "probability",
        label: "Probability"
      },{
        key: "seriousness",
        label: "Seriousness"
      },{
        key: "detectability",
        label: "Detectability"
      },{
        key: "evaluation",
        label: "Evaluation"
      },{
        key: "residualrisk",
        label: "Residual risk"
      }]
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadData(id){
      this.openHttp("risks/risk", { id: id }).then((result) => {
        this.risksareasSupport = this.convertCombo(result.risksareas, result.risk.risksareas);
        this.interestedpartiesSupport = this.convertCombo(result.interestedparties, result.risk.interestedparties);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("risks/risk/save", this.data.risk).then((result) => {
        if (result !== null) this.loadData(result);
      })
    },
    deleteData(){
      this.deleteHttp("risks/risk/delete", { id: this.data.risk.id }).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    }
  }
}
</script>

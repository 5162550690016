<template>
  <div>
    <div class="containter-white">
      <b-row>
        <b-col>
          <b-button-group>
            <b-button v-for="(item, index) of languages" :key="index" :variant="lang === item.lang ? 'success' : 'secondary'" @click="lang = item.lang">{{ item.langFull }}</b-button>
          </b-button-group>
        </b-col>
      </b-row>
      <b-row v-if="data !== null">
        <b-col class="mt-2">
          <b-card no-body v-if="data!== null">
            <b-tabs pills card vertical>
              <b-tab :title="translateCheck('plan confirmation')" active>
                <b-card no-body>
                  <b-tabs card>
                    <b-tab v-for="(item, index) of data.audit" :key="index" :title="item.company + ' ' + item.standards">
                      <b-table-simple responsive :sticky-header="(winHeight - 255) + 'px'">
                        <b-thead head-variant="light">
                          <b-tr>
                            <b-th style="width: 25%">{{ translateCheck('parameter') }}</b-th>
                            <b-th>{{ translateCheck('value') }}</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr>
                            <b-td>{{ translateCheck('company and vat number') }}</b-td>
                            <b-td>{{ item.company + ' - ' + item.vat }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-td>{{ translateCheck('scopes') }}</b-td>
                            <b-td>
                              <div v-for="(item, index) of item.scopes" :key="index"><strong>{{ item.lang }}: </strong> {{ item.scope }}</div>
                            </b-td>
                          </b-tr>
                          <b-tr>
                            <b-td>IAF</b-td>
                            <b-td>{{ item.iafs }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-td>{{ translateCheck('sites on the certificate') }}</b-td>
                            <b-td>
                              <div v-for="(item2, index2) of item.sitesoncertificates" :key="index2">
                                {{ item2.site + (item2.ismain === true ? ' (' + translateCheck('main') + ')' : '') + (item2.isregistered === true ? ' (' + translateCheck('registered') + ')' : '') }}
                              </div>
                            </b-td>
                          </b-tr>
                          <b-tr>
                            <b-td>{{ translateCheck('points not to be verified') }}</b-td>
                            <b-td>
                              <div v-for="(item, index) of item.exclusions" :key="index">{{ item.standard + ': ' + item.exclusion }}</div>
                            </b-td>
                          </b-tr>
                          <b-tr>
                            <b-td>{{ translateCheck('company representative') }}</b-td>
                            <b-td>{{ item.worker }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-td>{{ translateCheck('approval of the company') }}</b-td>
                            <b-td>
                              <b-btn :variant="item.signature_initial_check === true ? 'success' : 'danger'" size="sm" @click="loadSignature(item.signature_initial_token)">{{ item.signature_initial_check === true ? translateCheck('approved') : translateCheck('to be approved') }}</b-btn>
                            </b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </b-tab>
              <b-tab :title="translateCheck('plan')" v-if="[2,3].includes(data.auditsstatus_id)">
                <b-card no-body>
                  <b-tabs card>
                    <b-tab v-for="(item, index) of data.plan" :key="index" :title="item.company + ' ' + item.standards">
                      <div>
                        <b-table-simple responsive :sticky-header="(winHeight - 255) + 'px'">
                          <b-thead>
                            <b-tr>
                              <b-th>{{ translateCheck('date') }}</b-th>
                              <b-th>{{ translateCheck('from') }}</b-th>
                              <b-th>{{ translateCheck('to') }}</b-th>
                              <b-th>{{ translateCheck('type') }}</b-th>
                              <b-th>{{ translateCheck('site') }}</b-th>
                              <b-th>{{ translateCheck('team') }}</b-th>
                              <b-th>{{ translateCheck('points') }}</b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>
                            <b-tr v-for="(item3, index3) of item.plans" :key="index3">
                              <b-td>{{ item3.date }}</b-td>
                              <b-td>{{ item3.fromtime }}</b-td>
                              <b-td>{{ item3.totime }}</b-td>
                              <b-td>{{ item3.type }}</b-td>
                              <b-td>{{ item3.site }}</b-td>
                              <b-td>
                                <div v-for="(item4, index4) of item3.users" :key="index4">
                                  <b>{{ item4.role }}</b>: {{ item4.user }}
                                </div>
                              </b-td>
                              <b-td>
                                <div v-for="(item4, index4) of item3.points" :key="index4">
                                  <b>{{ item4.standard }}</b>: {{ item4.points }}
                                </div>
                              </b-td>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>
                      </div>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </b-tab>
              <b-tab :title="translateCheck('participants')" v-if="data.auditsstatus_id === 3">
                <b-card no-body>
                  <b-tabs card>
                    <b-tab :title="item.company + ' ' + item.standards" v-for="(item, index) of data.presences" :key="index">
                      <b-table-simple responsive :sticky-header="(winHeight - 255) + 'px'">
                        <b-thead head-variant="light">
                          <b-tr>
                            <b-th>{{ translateCheck("fullname") }}</b-th>
                            <b-th>{{ translateCheck("job") }}</b-th>
                            <b-th>{{ translateCheck("signatures") }}</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr v-for="(item2, index2) in item.presences" :key="index2">
                            <b-td>{{ item2.fullname }}</b-td>
                            <b-td>{{ item2.job }}</b-td>
                            <b-td>
                              <span v-for="(item3, index3) of item2.phases" :key="index3">
                                <b-btn v-for="(item4, index4) of item3.signatures" :key="index4" class="mb-1 ml-1" :variant="item4.check === true ? 'success' : 'danger'" size="sm" @click="loadSignature(item4.token)">{{ translateCheck("signature") }}</b-btn>  
                              </span>
                            </b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </b-tab>
              <b-tab :title="translateCheck('strengths')"  v-if="data.auditsstatus_id >= 3">
                <b-card no-body>
                  <b-tabs card>
                    <b-tab :title="item.company + ' ' + item.standards" v-for="(item, index) of data.strengths" :key="index">
                      <b-table-simple responsive :sticky-header="(winHeight - 255) + 'px'">
                        <b-thead head-variant="light">
                          <b-tr>
                            <b-th>{{ translateCheck("strength") }}</b-th>
                            <b-th>{{ translateCheck("points") }}</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr v-for="(item2, index2) in item.strengths" :key="index2">
                            <b-td>{{ item2.strength }}</b-td>
                            <b-td>{{ item2.standardspoints }}</b-td>
                          </b-tr>
                          <b-tr v-show="item.strengths.length === 0">
                            <b-td colspan="2">{{ translateCheck('empty') }}</b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </b-tab>
              <b-tab :title="translateCheck('opportunities')" v-if="data.auditsstatus_id >= 3">
                <b-card no-body>
                  <b-tabs card>
                    <b-tab :title="item.company + ' ' + item.standards" v-for="(item, index) of data.opportunities" :key="index">
                      <b-table-simple responsive :sticky-header="(winHeight - 255) + 'px'">
                        <b-thead head-variant="light">
                          <b-tr>
                            <b-th>{{ translateCheck("opportunity") }}</b-th>
                            <b-th>{{ translateCheck("points") }}</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr v-for="(item2, index2) in item.opportunities" :key="index2">
                            <b-td>{{ item2.opportunity }}</b-td>
                            <b-td>{{ item2.standardspoints }}</b-td>
                          </b-tr>
                          <b-tr v-show="item.opportunities.length === 0">
                            <b-td colspan="2">{{ translateCheck('empty') }}</b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </b-tab>
              <b-tab :title="translateCheck('non conformities')" v-if="data.auditsstatus_id >= 3">
                <externalauditnonconformity ref="nonconformity" @update="loadData()"/>
                <b-card no-body>
                  <b-tabs card>
                    <b-tab :title="item.company + ' ' + item.standards" v-for="(item, index) of data.nonconformities" :key="index">
                      <b-table-simple responsive :sticky-header="(winHeight - 255) + 'px'">
                        <b-thead head-variant="light">
                          <b-tr>
                            <b-th>{{ translateCheck("nonconformity") }}</b-th>
                            <b-th>{{ translateCheck("decision by client") }}</b-th>
                            <b-th>{{ translateCheck("causes") }}</b-th>
                            <b-th>{{ translateCheck("treatment") }}</b-th>
                            <b-th>{{ translateCheck("correctiveaction") }}</b-th>
                            <b-th>{{ translateCheck("effectiveness") }}</b-th>
                            <b-th>{{ translateCheck("points") }}</b-th>
                            <b-th></b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr v-for="(item2, index2) in item.nonconformities" :key="index2" @click="$refs.nonconformity.loadData(item2.id, token, lang)">
                            <b-td>{{ item2.nonconformity }}</b-td>
                            <b-td>{{ item2.acceptdecision === true ? translateCheck("accepted") : (item2.acceptdecision === false ? translateCheck("not accepted") : translateCheck("pending")) }}</b-td>
                            <b-td>{{ item2.causes }}</b-td>
                            <b-td>{{ item2.treatment }}</b-td>
                            <b-td>{{ item2.correctiveaction }}</b-td>
                            <b-td>{{ item2.effectivenessdecision === true ? translateCheck("accepted") : (item2.effectivenessdecision === false ? translateCheck("not accepted") : translateCheck("pending")) }}</b-td>
                            <b-td>{{ item2.standardspoints }}</b-td>
                          </b-tr>
                          <b-tr v-show="item.nonconformities.length === 0">
                            <b-td colspan="7">{{ translateCheck('empty') }}</b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </b-tab>
              <b-tab :title="translateCheck('complaints') + '/' + translateCheck('appeals')" v-if="data.auditsstatus_id >= 2">
                <b-modal v-if="dataComplaint !== null" v-model="showComplaint" :title="translateCheck('complaint') + ' ' + translateCheck('appeal')">
                  <b-form-group :label="translateCheck('complaint') + '/' + translateCheck('appeal')">
                    <b-form-textarea v-model="dataComplaint.complaint" rows="2"/>
                  </b-form-group>
                  <b-form-group :label="translateCheck('date')">
                    <b-form-input v-model="dataComplaint.date" type="date"/>
                  </b-form-group>
                  <b-form-group :label="translateCheck('note')">
                    <b-form-textarea v-model="dataComplaint.note" rows="4"/>
                  </b-form-group>
                  <b-form-group :label="translateCheck('contacts')">
                    <b-form-textarea v-model="dataComplaint.contacts" rows="2"/>
                  </b-form-group>
                  <template v-slot:modal-footer>
                    <div class="w-100">
                      <b-btn variant="success" class="mr-2" @click="saveComplaint()">{{ translateCheck('save') }}</b-btn>
                      <b-btn variant="danger" @click="deleteComplaint()">{{ translateCheck('delete') }}</b-btn>
                    </div>
                  </template>
                </b-modal>
                <b-btn variant="primary" size="sm" @click="loadComplaint({id: 0, audits_id: data.id, date: null, complaint: null, note: null, contacts: null})">{{ translateCheck('new') }}</b-btn>
                <b-table-simple responsive :sticky-header="(winHeight - 255) + 'px'" class="mt-2">
                  <b-thead head-variant="light">
                    <b-tr>
                      <b-th>{{ translateCheck("date") }}</b-th>
                      <b-th>{{ translateCheck("complaint") + " " + translateCheck("appeal") }}</b-th>
                      <b-th>{{ translateCheck("note") }}</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr v-for="(item, index) in data.complaints" :key="index" @click="loadComplaint(item)">
                      <b-td>{{ item.date }}</b-td>
                      <b-td>{{ item.complaint }}</b-td>
                      <b-td>{{ item.note }}</b-td>
                    </b-tr>
                    <b-tr v-show="data.complaints.length === 0">
                      <b-td colspan="3">{{ translateCheck('empty') }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-tab>
              <b-tab :title="translateCheck('closing meeting')" v-if="data.auditsstatus_id >= 3">
                <b-card no-body>
                  <b-tabs card>
                    <b-tab v-for="(item, index) of data.audit" :key="index" :title="item.company + ' ' + item.standards">
                      <b-table-simple responsive :sticky-header="(winHeight - 255) + 'px'">
                        <b-thead head-variant="light">
                          <b-tr>
                            <b-th style="width: 25%">{{ translateCheck('parameter') }}</b-th>
                            <b-th>{{ translateCheck('value') }}</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr>
                            <b-td>{{ translateCheck('company and vat number') }}</b-td>
                            <b-td>{{ item.company + ' - ' + item.vat }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-td>{{ translateCheck('strengths') }}</b-td>
                            <b-td>{{ data.strengths.find(x => x.id === item.id).strengths.length }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-td>{{ translateCheck('opportunities') }}</b-td>
                            <b-td>{{ data.opportunities.find(x => x.id === item.id).opportunities.length }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-td>{{ translateCheck('non conformities') }}</b-td>
                            <b-td>{{ data.nonconformities.find(x => x.id === item.id).nonconformities.length }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-td>{{ translateCheck('company representative') }}</b-td>
                            <b-td>{{ item.worker }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-td>{{ translateCheck('approval of the company') }}</b-td>
                            <b-td>
                              <b-btn :variant="item.signature_final_check === true ? 'success' : 'danger'" size="sm" @click="loadSignature(item.signature_final_token)">{{ item.signature_final_check === true ? translateCheck('approved') : translateCheck('to be approved') }}</b-btn>
                            </b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </b-tab>
              <b-tab :title="translateCheck('documents')" v-if="data.auditsstatus_id >= 2">
                <b-card no-body>
                  <b-tabs card>
                    <b-tab v-for="(item, index) of data.documents" :key="index" :title="item.company + ' ' + item.standards">
                      <b-table-simple responsive :sticky-header="(winHeight - 255) + 'px'">
                        <b-thead head-variant="light">
                          <b-tr>
                            <b-th>{{ translateCheck("document") }}</b-th>
                            <b-th></b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr v-for="(item2, index2) in item.documents" :key="index2">
                            <b-td>{{ item2.filename }}</b-td>
                            <b-td>
                              <b-btn variant="primary" size="sm" :href="'api/externaldocument/' + item2.token" target="_blank">{{ translateCheck('download') }}</b-btn>
                            </b-td>
                          </b-tr>
                          <b-tr v-show="item.documents.length === 0">
                            <b-td colspan="2">{{ translateCheck('empty') }}</b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
    </b-row>
    </div>
  </div>
</template>

<script>
import externalauditnonconformity from './externalauditnonconformity.vue';

export default {
  name: "checklistexternal",
  components: { externalauditnonconformity },
  props: ["token"],
  data() {
    return {
      show: false,
      data: null,
      lang: "it",
      showComplaint: false,
      dataComplaint: null,
      languages: [{
        lang: "en",
        langFull: "English",
        dictionary: [
          { word: "plan confirmation", translate: "Plan confirmation"},
          { word: "plan", translate: "Plan"},
          { word: "participants", translate: "Participants"},
          { word: "opportunities to be closed", translate: "Opportunities to be closed"},
          { word: "strengths", translate: "Strengths"},
          { word: "opportunities", translate: "Opportunities"},
          { word: "non conformities", translate: "Non conformities"},
          { word: "closing meeting", translate: "Closing meeting"},
          { word: "documents", translate: "Documents"},
          { word: "document", translate: "Document"},
          { word: "download", translate: "Download"},
          { word: "parameter", translate: "Parameter"},
          { word: "value", translate: "Value"},
          { word: "company and vat number", translate: "Company and VAT number"},
          { word: "scopes", translate: "Scopes"},
          { word: "sites on the certificate", translate: "Sites on the Certificate"},
          { word: "points not to be verified", translate: "Points not to be verified"},
          { word: "audited sites", translate: "Audited sites"},
          { word: "company representative", translate: "Company representative"},
          { word: "approval of the company", translate: "Approval of the company"},
          { word: "standards", translate: "Standards"},
          { word: "standard", translate: "Standard"},
          { word: "lead auditor decision", translate: "Lead Auditor Decision"},
          { word: "fullname", translate: "Fullname"},
          { word: "job", translate: "Job"},
          { word: "signatures", translate: "Signatures"},
          { word: "signature", translate: "Signature"},
          { word: "date", translate: "Date"},
          { word: "from", translate: "From"},
          { word: "to", translate: "To"},
          { word: "type", translate: "Type"},          
          { word: "site", translate: "Site"},
          { word: "team", translate: "Team"},
          { word: "points", translate: "Points"},
          { word: "strength", translate: "Strength"},
          { word: "opportunity", translate: "Opportunity"},
          { word: "nonconformity", translate: "Non Conformity"},
          { word: "decision by client", translate: "Decision by client"},
          { word: "accepted", translate: "Accepted"},
          { word: "not accepted", translate: "Not accepted"},
          { word: "causes", translate: "Causes"},
          { word: "treatment", translate: "Treatment"},
          { word: "correctiveaction", translate: "Corrective Action"},
          { word: "effectiveness", translate: "Effectiveness"},
          { word: "positive", translate: "Positive"},
          { word: "negative", translate: "Negative"},
          { word: "approved", translate: "Approved"},
          { word: "to be approved", translate: "To be approved"},
          { word: "download", translate: "Download"},
          { word: "file", translate: "File"},
          { word: "date_and_time", translate: "Date and time"},
          { word: "main", translate: "Main"},
          { word: "registered", translate: "Registered"},
          { word: "yard", translate: "Yard"},
          { word: "empty", translate: "Empty"},

          { word: "complaints", translate: "Complaints"},
          { word: "appeals", translate: "appeals"},
          { word: "new", translate: "New"},
          { word: "complaint", translate: "Complaint"},
          { word: "appeal", translate: "Appeal"},
          { word: "note", translate: "Note"},
          { word: "contacts", translate: "Contacts"},
          { word: "save", translate: "Save"},
          { word: "delete", translate: "Delete"},

        ]
      },{
        lang: "it",
        langFull: "Italiano",
        dictionary: [
          { word: "plan confirmation", translate: "Conferma piano"},
          { word: "plan", translate: "Piano"},
          { word: "participants", translate: "Partecipanti"},
          { word: "opportunities to be closed", translate: "Opportunità da chiudere"},
          { word: "strengths", translate: "Punti di forza"},
          { word: "opportunities", translate: "Opportunità"},
          { word: "non conformities", translate: "Non conformità"},
          { word: "closing meeting", translate: "Riunione di chiusura"},
          { word: "documents", translate: "Documenti"},
          { word: "document", translate: "Documento"},
          { word: "download", translate: "Scarica"},
          { word: "parameter", translate: "Parametro"},
          { word: "value", translate: "Valore"},
          { word: "company and vat number", translate: "Azienda e partita IVA"},
          { word: "scopes", translate: "Scopi"},
          { word: "sites on the certificate", translate: "Siti sul Certificato"},
          { word: "points not to be verified", translate: "Punti da non verificare"},
          { word: "audited sites", translate: "Siti Auditati"},
          { word: "company representative", translate: "Rappresentante della direzione"},
          { word: "approval of the company", translate: "Approvazione dell'azienda"},
          { word: "standards", translate: "Norme"},
          { word: "standard", translate: "Norma"},
          { word: "lead auditor decision", translate: "Decisione Lead Auditor"},
          { word: "fullname", translate: "Nome completo"},
          { word: "job", translate: "Mansione"},
          { word: "signatures", translate: "Firme"},
          { word: "signature", translate: "Firma"},
          { word: "date", translate: "Data"},
          { word: "from", translate: "Da"},
          { word: "to", translate: "A"},
          { word: "type", translate: "Tipo"},
          { word: "site", translate: "Sito"},
          { word: "team", translate: "Team"},
          { word: "points", translate: "Punti"},
          { word: "strength", translate: "Punto di forza"},
          { word: "opportunity", translate: "Opportunità"},
          { word: "nonconformity", translate: "Non Conformità"},
          { word: "decision by client", translate: "Decisione del cliente"},
          { word: "accepted", translate: "Accettata"},
          { word: "not accepted", translate: "Non accettata"},
          { word: "causes", translate: "Cause"},
          { word: "treatment", translate: "Trattamento"},
          { word: "correctiveaction", translate: "Azione correttiva"},
          { word: "effectiveness", translate: "Efficacia"},
          { word: "positive", translate: "Positivo"},
          { word: "negative", translate: "Negativo"},
          { word: "approved", translate: "Approvato"},
          { word: "to be approved", translate: "Da approvare"},
          { word: "download", translate: "Download"},
          { word: "file", translate: "File"},
          { word: "main", translate: "Principale"},
          { word: "registered", translate: "Legale"},
          { word: "yard", translate: "Cantiere"},
          { word: "empty", translate: "Vuoto"},



          { word: "complaints", translate: "Reclami"},
          { word: "appeals", translate: "Ricorsi"},
          { word: "new", translate: "Nuovo"},
          { word: "complaint", translate: "Reclamo"},
          { word: "appeal", translate: "Ricorso"},
          { word: "note", translate: "Note"},
          { word: "contacts", translate: "Contatti"},
          { word: "save", translate: "Salva"},
          { word: "delete", translate: "Elimina"},


        ]
      }]
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    }
  },
  created(){
    this.loadData();
  },
  methods: {
    translateCheck(word){
      if (this.languages.find(x => x.lang === this.lang).dictionary.findIndex(x => x.word === word) === -1) return '??? ' + word;
      return this.languages.find(x => x.lang === this.lang).dictionary.find(x => x.word === word).translate;
    },
    loadData(){
      this.showComplaint = false;
      this.openHttp("externalaudit/", { token: this.token }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    loadSignature(token){
      window.open("/#/externalsignature/" + token)
    },
    loadComplaint(item){
      this.dataComplaint = JSON.parse(JSON.stringify(item));
      this.showComplaint = true;
    },
    saveComplaint(){
      this.saveHttp("externalaudit/complaint/save", { token: this.token, complaint: this.dataComplaint }).then((result) => {
        if (result !== null) this.loadData();
      })
    },
    deleteComplaint(){
      this.deleteHttp("externalaudit/complaint/delete", { token: this.token, complaint: this.dataComplaint }).then((result) => {
        if (result !== null) this.loadData();
      })
    },
    downloadData(token){
      this.$refs.downloadFormToken.value = token;
      this.$refs.downloadForm.submit();
    }
  }
}
</script>

<template>
    <div>
        <b-modal v-model="show" v-if="data !== null" title="Opportunity to be closed" size="lg">
            <b-form-group label="Opportunity">
                <b-row>
                    <b-col v-for="(item, index) in data.opportunity.languages" :key="index">
                        <b-input-group :prepend="item.lang">
                            <b-form-textarea v-model="item.opportunity" rows="3"/>
                        </b-input-group>
                    </b-col>
                </b-row>
            </b-form-group>
            <b-form-group label="Points">
                <b-form-checkbox-group v-model="data.opportunity.standardspoints">
                    <div v-for="(item, index) of data.points" :key="index">
                        <strong>{{ item.standard }}: </strong>
                        <b-form-checkbox v-for="(item2, index2) of item.points" :key="index2" :value="item2.id">{{ item2.val }}</b-form-checkbox>
                    </div>
                </b-form-checkbox-group>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Save</b-btn>
                    <b-btn variant="danger" v-show="data.opportunity.id > 0" @click="deleteData">Delete</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "auditsopportunitytobeclosed",
    data() {
        return {
            show: false,
            data: null,
        }
    },
    methods: {
        loadData( auditsId, id ){
            this.openHttp("audits/opportunitytobeclosed", { audits_id: auditsId, id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("audits/opportunitytobeclosed/save", this.data.opportunity).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("audits/opportunitytobeclosed/delete", { id: this.data.opportunity.id }).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>
<template>
  <div>
    <contract ref="contract" @update="loadData"/>
    <b-modal v-model="showFilter" title="Filters">
      <b-form-group label="Contract">
        <b-form-input v-model="tempFilter.contract"/>
      </b-form-group>
      <b-form-group label="Status">
        <b-form-select v-model="tempFilter.active" :options="comboActive" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Company">
        <b-form-select v-model="tempFilter.companies_id" :options="addNullCombo(filtersMenu.companies)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Standard">
        <b-form-select v-model="tempFilter.standards_id" :options="addNullCombo(filtersMenu.standards)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="IAF">
        <b-form-select v-model="tempFilter.nacesiafcodes_id" :options="addNullCombo(filtersMenu.nacesiafcodes)" value-field="id" text-field="val"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="filterSet">Filter</b-btn>
          <b-btn variant="warning" @click="filterReset">Reset</b-btn>
        </div>
      </template>
    </b-modal>
    <b-btn class="mr-2" variant="primary" @click="loadData">Refresh</b-btn>
    <b-btn class="mr-2" variant="secondary" @click="filterOpen">Filter</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="computedData" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sortby" :sort-desc.sync="search.sortdesc" @sort-changed="sortingChange" @row-clicked="$refs.contract.loadData($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>There are no results</template>
      <template v-slot:cell(standards)="row">
        <div v-for="(item, index) of row.item.standards" :key="index">
          {{ item }}
        </div>
      </template>
    </b-table>
    <tablebottom :busy="isBusy" :currentpage="search.page" :total="items.total" @updateCurrentPage="search.page = $event" @loadData="loadData"/>
  </div>
</template>

<script>
export default {
  name: "contracts",
  data() {
    return {
      isBusy: true,
      showFilter: false,
      comboActive: [{
        id: true,
        val: "Active"
      },{
        id: null,
        val: "See All"
      }],
      search: {
        page: 1,
        filters: {
          id: null,
          active: true,
          contract: null,
          companies_id: null,
          standards_id: null,
          certificationbodies_id: 133,
          nacesiafcodes_id: null,
        },
        sortby: 'expiracydate',
        sortdesc: false,
      },
      tempFilter: {
        id: null,
        contract: null,
        active: true,
        companies_id: null,
        standards_id: null,
        certificationbodies_id: 133,
        nacesiafcodes_id: null,
      },
      filtersMenu: {
        companies:[],
        standards:[],
        certificationbodies: [],
        nacesiafcodes: [],
      },
      fields: [{
        key: 'issuedate',
        label: 'Issue',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        }
      },{
        key: 'company',
        label: 'Company',
        sortable: true,
      },{
        key: 'contract',
        label: 'Contract',
        sortable: true,
      },{
        key: 'standards',
        label: 'Standards',
      },{
        key: 'typecertification',
        label: 'Type',
      },{
        key: 'expiracydate',
        label: 'Expiracy',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        },
      }],
      items: {
        total: 0,
        data: [],
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
    computedData(){
      return this.items.data.map(function( item ) {
        let today = new Date();
        let expiracyDate = new Date(item.expiracydate);
        item._cellVariants = {
          expiracydate: item.active === true && today.getTime() >= expiracyDate.getTime() ? 'danger' : ( item.active === false ? 'warning' : 'success' ),
        };
        return item;
      });
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.isBusy = true;
      this.openHttp("contracts", this.search ).then((result) => {
        this.isBusy = false;
        this.items = result;
      })
    },
    filterOpen(){
      this.openHttp("contracts/filters", null ).then((result) => {
        this.filtersMenu = result;
        this.showFilter = true;
      })
    },
    filterSet(){
      this.showFilter = false;
      this.search.page = 1;
      this.search.filters = this.tempFilter;
      this.loadData();
    },
    filterReset(){
      this.tempFilter = {
        id: null,
        active: true,
        contract: null,
        companies_id: null,
        standards_id: null,
        certificationbodies_id: 133,
        naces_id: null,
      }
      this.filterSet();
    },
    sortingChange(ctx) {
      this.search.sortby = ctx.sortBy;
      this.search.sortdesc = ctx.sortDesc;
      this.loadData();
    }
  }
}
</script>
<template>
    <div>
        <b-modal v-if="data!== null" v-model="show" title="Plan" size="lg">
            <b-row>
                <b-col lg="3">
                    <b-form-group label="From">
                        <b-form-input type="time" v-model="data.reservation.fromtime"/>
                    </b-form-group>
                </b-col>
                <b-col lg="3">
                    <b-form-group label="To">
                        <b-form-input type="time" v-model="data.reservation.totime"/>
                    </b-form-group>
                </b-col>
                <b-col lg="6">
                    <b-form-group label="Type">
                        <b-form-select v-model="data.reservation.auditstypes_id" :options="data.auditstypes" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-form-group label="Site">
                <b-form-select v-model="data.reservation.auditssites_id" :options="data.auditssites.map(function(element) { return { id: element.id, val: element.val + (element.main ? ' Main' : '') + (element.registered ? ' Registered' : '') + (element.yard ? ' Temporary' : '')} })" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Team">
                <b-form-checkbox-group v-model="data.reservation.usersstandardsphasesrolesdates">
                    <div v-for="(item, index) of data.usersstandardsphasesrolesdates" :key="index">
                        <strong>{{ item.role + " " + item.user }}</strong>
                        <div v-for="(item2, index2) of item.phasesstandards" :key="index2">
                            {{ item2.phase }}
                            <b-form-checkbox v-for="(item3, index3) of item2.standards" :key="index3" :value="item3.id">{{ item3.standard }}</b-form-checkbox>
                        </div>
                    </div>
                </b-form-checkbox-group>
            </b-form-group>
            <b-form-group label="Points">
                <b-form-checkbox-group v-model="data.reservation.standardspoints">
                    <div v-for="(item, index) of data.points" :key="index">
                        <strong>{{ item.standard }}</strong>
                        <br>
                        <b-form-checkbox v-for="(item2, index2) of item.points" :key="index2" :value="item2.id">{{ item2.val }}</b-form-checkbox>
                    </div>
                </b-form-checkbox-group>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Save</b-btn>
                    <b-btn variant="danger" v-show="data.reservation.id > 0" @click="deleteData">Delete</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "checklistreservation",
    data() {
        return {
            data: null,
            show: true
        }
    },
    methods: {
        selectAllAuditors(){
            let toBeSelected = [];
            this.data.usersstandardsphasesrolesdates.forEach(function(entry) {
                entry.phasesstandards.forEach(function(entry) {
                    entry.standards.forEach(function(entry) {
                        toBeSelected.push(entry.id);               
                    });
                });
            });
            this.data.reservation.usersstandardsphasesrolesdates = toBeSelected;
        },
        loadData( auditsId, id, date){
            this.openHttp("checklists/reservation", { audits_id: auditsId, id: id, date: date }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("checklists/reservation/save", this.data.reservation).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("checklists/reservation/delete", { id: this.data.reservation.id }).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit("update");
        },
    },
}
</script>